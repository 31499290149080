import './styles.css';
import template from './template.html';
import * as rules from '../../core/answering-rules';
import * as navActions from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
  '$mdToast',
  '$translate',
];

function controller(
  $ngRedux,
  $state,
  $mdToast,
  $translate,
) {
  let unsubscribe;
  const { ruleID } = $state.params;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
      selectedRule: rules.getAnsweringRule(state, ruleID),
      dateFormat: state.configs.get('portalLocalizationDateFormat'),
      timeFormat: state.configs.get('portalLocalizationTimeFormat'),
    }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.enable = () => {
    this.selectedRule.enable = 'yes';
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('RULE_SAVED'))
        .position('bottom left')
        .hideDelay(1500),
    );
    $ngRedux.dispatch(
      rules.updateEnabled(this.selectedRule),
    );
  };

  this.disable = () => {
    this.selectedRule.enable = 'no';
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('RULE_SAVED'))
        .position('bottom left')
        .hideDelay(1500),
    );
    $ngRedux.dispatch(
      rules.updateEnabled(this.selectedRule),
    );
  };

  this.delete = () => {
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('RULE_DELETED'))
        .position('bottom left')
        .hideDelay(1500),
    );
    $ngRedux.dispatch(
      rules.deleteAnswerRule(this.selectedRule.time_frame),
    );
    this.navBack();
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackBack());
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    $state.go(lastOnStack.name, lastOnStack.params);
  };
}

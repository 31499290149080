import './styles.css';
import template from './template.html';
import {
  getResponders, updateQueueEntry, createAgentLog, postAgentStatus, getAgentType,
} from '../../../../core/agent-center';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];
function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe = null;

  this.$onInit = () => {
    this.queue = $state.params.queue;
    unsubscribe = $ngRedux.connect((state) => ({
      responders: getResponders(state, this.queue.queue_name),
      agenttype: getAgentType(state),
      screenSize: state.screenSize,
      configs: state.configs,
    }))((state) => {
      this.screenSize = state.screenSize;
      this.configs = state.configs;
      this.responders.refresh(state.responders);
      this.allowGranularLogin = state.configs.get('callQueueGranularLogin') === 'yes';
      this.devicePostFix = `${this.configs.get('devicePostFix')}@`;
      this.agentDevice = state.agenttype.includes(this.devicePostFix) ? this.configs.get('userId') + this.configs.get('devicePostFix') : this.configs.get('userId');
    });
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.responders = {
    items: [],
    refresh: (list) => {
      this.responders.items = list;
    },
    getItemAtIndex: (index) => this.responders.items[index],
    getLength: () => this.responders.items.length,
  };

  this.agentLoggedin = () => {
    const responders = this.responders.items;
    const responder = responders.find(({ device }) => device === this.agentDevice);
    return responder.entry_option === 'immediate' || responder.entry_status === 'available';
  };

  this.queueLogOut = ($event, queue) => {
    $event.stopPropagation();
    // $ngRedux.dispatch(updateQueueEntry({
    //     queue_name: queue.queue_name,
    //     option: 'manual',
    //     action: 'update',
    //     toAsQueue: 'yes'
    //
    // }));
    $ngRedux.dispatch(updateQueueEntry({
      queue_name: queue.queue_name,
      action: 'make_un',
      toAsQueue: 'yes',
    }));
    // $ngRedux.dispatch(createAgentLog('Auto')); //portal doesnt make agentlog || setstatus
    // $ngRedux.dispatch(postAgentStatus(''));

    // $mdToast.show(
    //     $mdToast.simple()
    //         .textContent($translate.instant('GREETING_DELETED'))
    //         .position('bottom left')
    //         .hideDelay(1500)
    // );
  };

  this.queueLogIn = ($event, queue) => {
    $event.stopPropagation();
    const entry = {
      queue_name: queue.queue_name,
      option: 'immediate',
      action: 'make_im',
      toAsQueue: 'no',
    };
    $ngRedux.dispatch(updateQueueEntry(entry));
    $ngRedux.dispatch(createAgentLog('Auto'));
    $ngRedux.dispatch(postAgentStatus(''));
    // $mdToast.show(
    //     $mdToast.simple()
    //         .textContent($translate.instant('GREETING_DELETED'))
    //         .position('bottom left')
    //         .hideDelay(1500)
    // );
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

import { call, select, takeEvery } from 'redux-saga/effects';

import { PATH } from '../ringtones';
import { ringerSrc } from '../audio';

export default function* () {
  yield takeEvery(PATH, _updateRingerSrc);
}

const _updateRingerSrc = function* () {
  const path = yield select((state) => state.ringtones.get('path'));
  yield call(ringerSrc, path);
};

import template from './template.html';
import { setExpanded } from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = ['$ngRedux'];
function controller($ngRedux) {
  let unsubscribe;
  this.show = 'queue';

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({ screenSize: state.screenSize }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.closeModal = () => {
    $ngRedux.dispatch(setExpanded(null));
  };

  /**
     *
     * @param workflow
     */
  this.showContactList = (workflow, queueKey) => {
    this.contactsWorkflow = workflow;
    this.queueKey = queueKey;
    this.show = 'contacts';
  };

  /**
     *
     * @param contactId
     */
  this.showContactDetails = (contactId) => {
    this.contactID = contactId;
    this.show = 'contact-details';
  };
}

export const getKey = (event) => {
  switch (event.which) {
    case 48:
    case 96:
      return 0;
    case 49:
    case 97:
      return 1;
    case 50:
    case 98:
      return 2;
    case 51:
    case 99:
      if (event.key === '#') {
        return '#';
      }
      return 3;
    case 52:
    case 100:
      return 4;
    case 53:
    case 101:
      return 5;
    case 54:
    case 102:
      return 6;
    case 55:
    case 103:
      return 7;
    case 56:
    case 104:
      if (event.key === '*') {
        return '*';
      }
      return 8;
    case 57:
    case 105:
      return 9;
    case 42:
    case 106:
      return '*';
    case 35:
      return '#';
    case 13:
      return 'enter';
    case 8:
      event.preventDefault();
      return 'backspace';
    case 38:
      return 'up';
    case 40:
      return 'down';
    default:
      return null;
  }
};

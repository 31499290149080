import netsAddContactCompact from './components/add-contact-compact/nets-add-contact-compact';
import netsCallHistoryMenuCompact from './components/call-history-menu-compact/nets-call-history-menu-compact';
import netsContactFavoriteCompact from './components/contact-favorite-compact/nets-contact-favorite-compact';
import netsContactKabobMenuCompact from './components/contact-kabob-menu-compact/nets-contact-kabob-menu-compact';
import netsContactsMenuCompact from './components/contacts-menu-compact/nets-contacts-menu-compact';
import netsContactsSearchCompact from './components/contacts-search-compact/nets-contacts-search-compact';
import netsContactsSortCompact from './components/contacts-sort-compact/nets-contacts-sort-compact';
import netsNavCompact from './nets-nav-compact';
import netsQueueDetailsCompact from './components/queue-details-compact/nets-queue-details-compact';
import netsVoicemailMenuCompact from './components/voicemail-menu-compact/nets-voicemail-menu-compact';

export default angular
  .module('app.navCompact', [])
  .component('netsAddContactCompact', netsAddContactCompact)
  .component('netsCallHistoryMenuCompact', netsCallHistoryMenuCompact)
  .component('netsContactFavoriteCompact', netsContactFavoriteCompact)
  .component('netsContactKabobMenuCompact', netsContactKabobMenuCompact)
  .component('netsContactsMenuCompact', netsContactsMenuCompact)
  .component('netsContactsSearchCompact', netsContactsSearchCompact)
  .component('netsContactsSortCompact', netsContactsSortCompact)
  .component('netsNavCompact', netsNavCompact)
  .component('netsQueueDetailsCompact', netsQueueDetailsCompact)
  .component('netsVoicemailMenuCompact', netsVoicemailMenuCompact);

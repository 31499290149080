import { store } from '../../store';

export const getCardId = ({
  contactId, sessionId, queueId, type = null,
}) => {
  const cards = store.getState().cards.get('cards').toArray();

  let paramCnt = 0;
  if (contactId) { paramCnt++; }
  if (sessionId) { paramCnt++; }
  if (queueId) { paramCnt++; }
  if (type) { paramCnt++; }

  let matchCnt;
  for (let i = 0; i < cards.length; i += 1) {
    matchCnt = 0;
    if (contactId
            && cards[i].meta
            && cards[i].meta.contactId === contactId
    ) {
      matchCnt++;
    }
    if (sessionId
            && cards[i].meta
            && cards[i].meta.sessionId === sessionId
    ) {
      matchCnt++;
    }
    if (queueId
            && cards[i].meta
            && cards[i].meta.queueId === queueId
    ) {
      matchCnt++;
    }
    if (type && cards[i].type === type) {
      matchCnt++;
    }
    if (paramCnt === matchCnt) {
      return cards[i].id;
    }
  }

  return null;
};

import './styles.css';
import template from './template.html';
import { getAnsweringRules, getLoading, updatePriority } from '../../core/answering-rules';
import { navStackPush } from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$mdToast',
  '$state',
  '$translate',
];

function controller(
  $ngRedux,
  $mdToast,
  $state,
  $translate,
) {
  let unsubscribe;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      loaded: !getLoading(state),
      rules: getAnsweringRules(state),
      screenSize: state.screenSize,
    }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.navPush = (ruleID) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navStackPush({
        name: 'wrapper.answering-rule-details',
        params: { ruleID },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.answering-rule-details', { ruleID });
  };

  // DRAG EVENTS
  this.sortableRules = {
    containment: '#nets-answering-rule-list-sort',
    accept: (sourceItemHandleScope, destSortableScope) => sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id,
    orderChanged: (event) => {
      const rules = event.dest.sortableScope.modelValue;
      const arr = [];
      for (let i = 0; i < rules.length; i += 1) {
        arr.push(rules[i].priority);
      }
      $ngRedux.dispatch(updatePriority(arr));

      $mdToast.show(
        $mdToast.simple()
          .textContent($translate.instant('PRIORITY_SAVED'))
          .position('bottom left')
          .hideDelay(1500),
      );
    },
  };
}

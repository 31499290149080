export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.contact-details', {
      url: 'contact-details',
      params: {
        contactID: null, // target contact's id
        workflow: null, // call-details-add-to-contact, voicemail-forward
      },
      template: '<nets-contact-details></nets-contact-details>',
    });
};

import './styles.css';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import * as deviceActions from '../../core/devices';
import * as navActions from '../../core/state-history';
import * as sessionActions from '../../core/sessions';
import * as uaActions from '../../core/ua';
import * as voicemailActions from '../../core/voicemail';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$mdToast',
  'ngAudio',
  '$mdDialog',
  '$state',
  '$translate',
];

function controller(
  $ngRedux,
  $mdToast,
  ngAudio,
  $mdDialog,
  $state,
  $translate,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    this.loaded = false;
    unsubscribe = $ngRedux.connect((state) => ({
      allowCall: sessionActions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-677]
      listType: voicemailActions.getListType(state),
      loaded: !voicemailActions.getLoading(state),
      screenSize: state.screenSize,
      voicemail: voicemailActions.getResultSet(state),
      sip: state.ua.get('ua'),
      transcription: state.configs.get('portalTranscriptionShow'),
      dateFormat: state.configs.get('portalLocalizationDateFormat'),
      timeFormat: state.configs.get('portalLocalizationTimeFormat'),
    }))((state) => {
      this.allowCall = state.allowCall; // [WP-677]
      this.listType = state.listType;
      this.loaded = state.loaded;
      this.screenSize = state.screenSize;
      this.notRegister = state.sip;
      this.dateFormat = state.dateFormat;
      this.timeFormat = state.timeFormat;
      this.dataset.refresh(state.voicemail);
      if (state.transcription) {
        const ui_transcription = state.transcription.toLowerCase();
        switch (ui_transcription) {
          case 'yes':
            this.showTranscription = 'yes';
            break;
          case 'y':
            this.showTranscription = 'yes';
            break;
          case 'true':
            this.showTranscription = 'yes';
            break;
          case '1':
            this.showTranscription = 'yes';
            break;
          default:
            this.showTranscription = 'no';
        }
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.dataset = {
    items: [],
    refresh: (list) => {
      this.dataset.items = list;
    },
    getItemAtIndex: (index) => this.dataset.items[index],
    getLength: () => this.dataset.items.length,
  };

  /**
     *
     * @param voicemail
     */
  this.saveVoicemail = (voicemail) => {
    $ngRedux.dispatch(voicemailActions.saveVoicemail(voicemail));
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('VOICEMAIL_SAVED'))
        .position('bottom left')
        .hideDelay(1500),
    );
  };

  /**
     *
     * @param voicemail
     */
  this.deleteVoicemail = (voicemail) => {
    $ngRedux.dispatch(voicemailActions.deleteVoicemail(voicemail));
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('VOICEMAIL_DELETED'))
        .position('bottom left')
        .hideDelay(1500),
    );
  };

  /**
     *
     * @param voicemail
     */
  this.focusCall = (voicemail) => {
    const callFromDevice = deviceActions.selectCallFrom($ngRedux.getState());

    if (!callFromDevice) {
      const activeSessions = sessionActions.getActiveCount(
        sessionActions.getSessions($ngRedux.getState()),
      );

      if (activeSessions > 1) {
        // todo disable call button if more than one active call
        return;
      }

      $ngRedux.dispatch(uaActions.sendInvite(voicemail.FromUser));
    } else {
      $mdToast.show(
        $mdToast.simple()
          .textContent(`${$translate.instant('CALLING_FROM')} ${callFromDevice.display}`)
          .position('bottom left')
          .hideDelay(1500),
      );

      $ngRedux.dispatch(deviceActions.callFrom(voicemail.FromUser));
    }
  };

  /**
     *
     * @param voicemail
     */
  this.forwardVoicemail = (voicemail) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-list',
        params: {
          type: voicemail.vmail_type,
          index: voicemail.index,
          workflow: 'voicemail-forward',
        },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-list', {
      type: voicemail.vmail_type,
      index: voicemail.index,
      workflow: 'voicemail-forward',
    });
  };

  /**
     * fetches and loads the audio file, BUT DOES NOT PLAY
     * alerts the user if there is an error
     * @param {string} path
     */
  this.loadPlayButtonOnly = (path) => {
    this.loadControls = false;
    if (this.audio) {
      this.audio.stop();
    }
    setTimeout(() => {
      this.audio = ngAudio.load(path);
    }, 0);
    setTimeout(() => {
      this.loadControls = true;
    }, 150);
  };

  /**
     * fetches and loads the audio file
     * alerts the user if there is an error
     * @param {string} path
     */
  this.loadPlay = (path) => {
    this.loadControls = false;
    if (this.audio) {
      this.audio.stop();
    }
    setTimeout(() => {
      this.audio = ngAudio.load(path);
      this.audio.play();
    }, 0);
    setTimeout(() => {
      this.loadControls = true;
    }, 150);
  };
  /**
     * plays the audio
     * and has a callback for when the audio is complete to set playing to false
     * and resets the track back to the beginning
     */
  this.playAudio = () => {
    if (isNaN(this.audio.duration)) {
      this.audioError();
    }
    this.audio.play();
  };

  /**
     * show error modal
     */
  this.audioError = () => {
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('ERROR_PLAYING_VOICEMAIL'))
        .position('bottom left')
        .hideDelay(1500),
    );
  };

  /**
     * Toggles the correct v-pane, uses voicemail.index to identfy the unique v-pane
     * @param index
     */
  this.toggleExpanded = (index) => {
    if (this.expandedIndex === index) {
      this.expandedIndex = null;
      for (let i = 0; i < this.dataset.items.length; i += 1) {
        this.dataset.items[i].expanded = false;
      }
    } else {
      if (this.audio && this.audio.stop) {
        this.audio.stop();
      }
      this.expandedIndex = index;
      for (let i = 0; i < this.dataset.items.length; i += 1) {
        this.dataset.items[i].expanded = this.dataset.items[i].index === index;
      }
    }
  };
}

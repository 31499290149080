import moduleConfig from './module-config';
import netsAddContact from './components/add-contact/nets-add-contact';
import netsContactList from './nets-contact-list';
import netsContactsMenu from './components/contacts-menu/nets-contacts-menu';
import netsContactsSearch from './components/contacts-search/nets-contacts-search';
import netsContactsSort from './components/contacts-sort/nets-contacts-sort';

export default angular
  .module('app.contacts', [])
  .config(['$stateProvider', moduleConfig])
  .component('netsAddContact', netsAddContact)
  .component('netsContactList', netsContactList)
  .component('netsContactsMenu', netsContactsMenu)
  .component('netsContactsSearch', netsContactsSearch)
  .component('netsContactsSort', netsContactsSort);

import _ from 'lodash';

import './styles.css';
import template from './template.html';
import * as contactActions from '../../core/contacts';
import * as navActions from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$mdToast',
  '$state',
  '$translate',
];

function controller(
  $ngRedux,
  $mdToast,
  $state,
  $translate,
) {
  let unsubscribe;
  const { contactID } = $state.params;
  const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
      selectedContact: contactActions.getContact(state, contactID),
      lastOnStack: navActions.getLastOnStack(state, 'navStack'),
    }))(this);

    // make a copy of the contact
    // so the object isn't altered before saving the changes in redux
    this.contact = { ...this.selectedContact };

    // handle type/number params for call-details "add to contact" workflow
    if ($state.params.type && $state.params.number) {
      this.contact[`${$state.params.type}_phone`] = $state.params.number;
    }

    // format email
    if (this.contact.email) {
      // make a copy of the contact
      this.contact.email = this.contact.email.slice();
      this.contact.email.push('');
    } else {
      this.contact.email = [''];
    }
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param index
     * @param error
     */
  this.emailInput = (index, error) => {
    if (!error.email) {
      if (this.contact.email[index] && (index + 1) === this.contact.email.length) {
        this.contact.email.push('');
      } else if (!this.contact.email[index]) {
        const temp = _.chain(this.contact.email)
          .compact()
          .filter((value) => EMAIL_REGEXP.test(value))
          .value();
        temp.push('');
        this.contact.email = temp;
      }
    }
  };

  /**
     *
     * @param contactSaved
     */
  this.navBack = (contactSaved) => {
    let lastOnStack;

    switch ($state.params.workflow) {
      case 'call-details-add-to-contact':
        // back stack up one
        $ngRedux.dispatch(navActions.navStackBack());
        // if contact was saved back up one more to origin of workflow
        if (contactSaved) { $ngRedux.dispatch(navActions.navStackBack()); }
        lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
        $state.go(lastOnStack.name, lastOnStack.params);
        break;
      default:
        $ngRedux.dispatch(navActions.navStackBack());
        $state.go('wrapper.contact-details', { contactID: this.contact.id });
    }
  };

  /**
     *
     * @param $event
     * @param valid
     */
  this.saveContact = ($event, valid) => {
    $event.preventDefault();
    if (valid) {
      $mdToast.show(
        $mdToast.simple()
          .textContent($translate.instant('CONTACT_SAVED!'))
          .position('bottom left')
          .hideDelay(1500),
      );
      $ngRedux.dispatch(contactActions.updateContact(this.contact));
      this.navBack(true);
    }
  };
}

import { store } from '../../store';

export const getParticipantNames = (contact_arr) => {
  let partNames = '';

  if (!contact_arr) {
    return null;
  }

  for (let i = 0; i < contact_arr.length; i += 1) {
    const contact = contact_arr[i];
    if (contact === undefined) {
      continue;
    }
    if (contact.first_name === undefined && contact.last_name === undefined && contact.name !== undefined) {
      partNames += contact.name;
    }

    if (contact.first_name != undefined) {
      partNames += contact.first_name;
    }
    if (contact.last_name != undefined && contact.last_name != '') {
      partNames += (` ${contact.last_name}`);
    }
    if (i != (contact_arr.length - 1)) {
      partNames += ', ';
    }
  }

  return partNames;
};

import _ from 'lodash';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

/**
 * @param {[]} contactList
 * @param {string} filterBy 'all', 'favorites', 'my_contacts', 'coworkers', 'online', 'busy', 'offline', custom e.g 'department 1'
 * @param {boolean} filterUser excludes the user from the contact list, used by voicemail forward
 * @returns {[]}
 */
export const filterContactList = (contactList, filterBy, filterUser) => {
  const token = nsToken.getDecoded();
  switch (filterBy) {
    case 'all':
      if (filterUser) {
        return _.filter(contactList, (contact) => contact.user !== token.user);
      }
      return contactList;
    case 'favorites':
      return _.filter(contactList, (contact) => contact.tags && (contact.tags.indexOf('fav') !== -1));
    case 'my_contacts':
      return _.filter(contactList, (contact) => !contact.uid);
    case 'coworkers':
      return _.filter(contactList, (contact) => contact.uid && (!filterUser || contact.uid && contact.user !== token.user));
    case 'online':
      return _.filter(contactList, (contact) => contact.presence === 'open'
                    || contact.presence === 'progressing'
                    || contact.presence === 'alerting'
                    || contact.presence === 'inuse'
                    || contact.presence === 'held-private'
                    || contact.presence === 'held');
    case 'busy':
      return _.filter(contactList, (contact) => contact.presence === 'progressing'
                    || contact.presence === 'alerting'
                    || contact.presence === 'inuse'
                    || contact.presence === 'held-private'
                    || contact.presence === 'held');
    case 'offline':
      return _.filter(contactList, (contact) => contact.presence === 'inactive');
    default:
      return _.filter(contactList, (contact) => contact.group === filterBy);
  }
};

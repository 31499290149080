import './styles.css';
import template from './template.html';
import * as navActions from '../../core/state-history';
import { updateUser } from '../../core/user';

export default {
  template,
  controller,
};
controller.$inject = [
  '$ngRedux',
  '$state',
  '$mdToast',
  '$translate',
];

function controller(
  $ngRedux,
  $state,
  $mdToast,
  $translate,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    // bind redux to component
    unsubscribe = $ngRedux.connect((state) =>
    // map redux to component
      ({
        screenSize: state.screenSize,
        user: state.user,
        edit: state.configs.get('PORTAL_USERS_ALLOW_NAME_EDIT') === 'yes',
      }))((state) => {
      // update component state
      this.screenSize = state.screenSize;
      // make a copy of the user
      this.user = {
        ...state.user,
        displayName: state.user.displayName,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        subscriberLogin: state.user.subscriberLogin,
        email: state.user.email,
        gravatar: state.user.gravatar,
        initials: state.user.initials,
        subscriber_pin: state.user.subscriber_pin,
      };
      // remove empty emails in object
      this.user.email = this.user.email.filter((entry) => entry.trim() !== '');
      this.allowEditName = state.edit;
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param index
     * @param error
     */
  this.emailInput = (index, error) => {
    if (!error.email) {
      if (this.user.email[index] && (index + 1) === this.user.email.length) {
        this.requirePassword = true;
      }
    }
  };

  this.addEmail = () => {
    this.user.email.push('');
    this.requirePassword = false;
  };

  this.removeEmail = (index) => {
    this.user.email.splice(index, 1);
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackBack());
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    $state.go(lastOnStack.name, lastOnStack.params);
  };

  /**
     *
     * @param $event
     * @param valid
     */
  this.submitForm = ($event, valid) => {
    $event.preventDefault();
    if (valid) {
      $mdToast.show(
        $mdToast.simple()
          .textContent($translate.instant('USER_SAVED'))
          .position('bottom left')
          .hideDelay(1500),
      );
      $ngRedux.dispatch(updateUser(this.user));
      $ngRedux.dispatch(navActions.navStackBack());
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
      $state.go(lastOnStack.name, lastOnStack.params);
    } else if (!this.user.pwd) {
      $mdToast.show(
        $mdToast.simple()
          .textContent($translate.instant('PASSWORD_REQUIRED'))
          .position('bottom left')
          .hideDelay(1500),
      );
    }
  };
}

import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import { put, takeEvery, select } from 'redux-saga/effects';

import * as angularActions from '../angular';
import * as deviceActions from '../devices';
import * as cardActions from '../card-management';
import * as uaActions from '../ua';
import { store } from '../store';

const launchCallNumber = nsBrowser.getQuery('call');
let preReq = 2;

export default function* () {
  yield takeEvery(cardActions.LOADING, _launchCall);
  yield takeEvery(uaActions.UA, _launchCall);
}

const _launchCall = function* () {
  preReq -= 1;
  if (preReq > 0) return;

  const cardsLoaded = yield select((state) => !cardActions.getLoading(state));

  // check call url param, when launching a call from the portal
  // also test if cards has been initialized, its a bug fix, don't worry about it

  if (launchCallNumber && cardsLoaded) {
    const callFromDevice = deviceActions.selectCallFrom(store.getState());

    if (!callFromDevice) {
      yield put(uaActions.sendInvite(launchCallNumber));
    } else {
      const $mdToast = yield select((state) => angularActions.getObject(state, '$mdToast'));
      const $translate = yield select((state) => angularActions.getObject(state, '$translate'));

      $mdToast.show(
        $mdToast.simple()
          .textContent(`${$translate.instant('CALLING_FROM')} ${callFromDevice.display}`)
          .position('bottom left')
          .hideDelay(1500),
      );

      store.dispatch(deviceActions.callFrom(launchCallNumber));
    }
  }
};

/* eslint-disable no-use-before-define */
import _ from 'lodash';
import {
  call, fork, put, select, takeEvery,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsConfig from '@netsapiens/netsapiens-js/dist/config';
import nsDevice from '@netsapiens/netsapiens-js/dist/device';
import nsPortal from '@netsapiens/netsapiens-js/dist/portal';
import nsMedia from '@netsapiens/netsapiens-js/dist/media';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUiConfig from '@netsapiens/netsapiens-js/dist/ui-config';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import pack from '../../../package.json';

import * as angularActions from '../angular';
import * as navActions from '../state-history';
import { fetchAnsweringRules } from '../answering-rules';
import {
  fetchAgentQueues,
  fetchWrapUp,
  setShowAgentCenter,
} from '../agent-center';
import { fetchCallParks } from '../call-park';
import { fetchUser } from '../user';
import { filteredBy, sortedBy, sortedDirection } from '../contacts';
import { INIT } from '../redux';
import { unsubscribePush } from '../push';
import { initUA } from '../ua';
import { ringtones } from '../ringtones';
import { setAudioDevice, setCallFromDevice } from '../devices';
import { setBackgroundPort, messaging, setExtensionId } from '../extension';
import { setConfig, setConfigs } from '../configs';
import { init as tabCommInit } from '../tab-comm';
import * as userMedia from '../user-media';
import { bugsnagClient } from '../../bugsnag';
import localForage from "localforage";

import networkConnection from '../network/networkConnection';

import { AUTHENTICATED } from '../../components/login';
import { AUTHENTICATED as LEGACY_AUTHENTICATED } from '../../components/legacy-reset';
import { AUTHENTICATED as PASSWORD_RESET } from '../../components/password-reset';

const BROWSER_CHECK_FAIL = 'BROWSER_CHECK_FAIL';
const STOP_INIT = 'STOP_INIT';

let $state;
let _decodedToken = null;
let _initMediaTask = null;
let _isExtension = null;
let _logoTask = null;

export default function* start() {
  yield takeEvery(INIT, _init);
  yield takeEvery([AUTHENTICATED, LEGACY_AUTHENTICATED, PASSWORD_RESET], _loggedIn);
}

/**
 *
 * @private
 */
const _init = function* init() {
  try {
    yield fork(networkConnection);

    // set $state for later use
    $state = yield select((state) => angularActions.getObject(state, '$state'));

    // test if the browser is supported
    // if the _browser is not supported
    // the catch will change the state to display the browser-support-msg component
    yield call(_browserSupport);

    // configure the sdk with the app name and portal/api url
    yield call(_configureSDK);

    // get server ui configs and save to the core configs module
    yield call(_uiConfigsToSettings);

    // connect to extension if it exists
    // handles port connection and setting the extension app (Office365 ect.)
    yield call(_extensionConnect);
    _isExtension = yield select((state) => !!state.extension.get('port'));

    if (!_isExtension) {
      // fork fetching and setting the favicon
      // fetches from the api for custom branding
      yield fork(_favicon);

      // fork setting the application title
      // ui configurable
      yield fork(_setTitle);
    }

    // verify the uc licensing is valid
    // if uc license has expired the catch will display the uc-license-msg component
    const ucCheck = yield nsPortal.ucCheck();
    yield put(setConfig('apiHash', ucCheck.api_hash));

    // check legacy-reset refresh
    // if legacy-reset reset display the legacy-reset component
    yield call(_checkLegacyReset);

    // check auth_code
    // if password reset display the password-reset component
    yield call(_checkAuthCode);

    // let user = null;
    // if (_isExtension) {
    //     const appName = yield select((state => state.configs.get('appName')));
    //     user = localStorage.getItem(`${appName}-extension_user`);
    // }

    // call sdk proxy authentication
    // if proxy authentication fails the catch will display the login-form component
    yield call(nsApi.proxyAuthentication, ucCheck.api_hash);

    // call the next generator function to continue the load process
    yield call(_loggedIn);
  } catch (err) {
    // get error constants from sdk
    const {
      UC_LICENSE_EXPIRED,
      API_TOKEN_REFRESH_FAIL,
      INVALID_TOKEN,
      NO_PORTAL_SESSION,
      NO_PORTAL_TOKEN,
      NO_STORED_TOKEN,
    } = nsApi;

    // error handler
    switch (err) {
      case BROWSER_CHECK_FAIL:
        yield $state.go('browser-support-msg');
        break;
      case API_TOKEN_REFRESH_FAIL:
      case INVALID_TOKEN:
      case NO_PORTAL_SESSION:
      case NO_PORTAL_TOKEN:
      case NO_STORED_TOKEN:
        // if no portal token, then unsubscribe push
        unsubscribePush();
        yield $state.go('login');
        break;
      case nsConfig.NO_SDK_CONFIG:
        break;
      case UC_LICENSE_EXPIRED:
        yield $state.go('uc-license-msg');
        break;
      case STOP_INIT:
        break;
      default:
        bugsnagClient.notify(err);
        yield $state.go('generic-error', {
          body: err,
        });
    }
  }
};

/**
 *
 * @private
 */
const _loggedIn = function* loggedIn() {
  try {
    // get the decoded token from the sdk and store a ref in this module
    _decodedToken = yield call(nsToken.getDecoded);

    bugsnagClient.user = {
      domain: _decodedToken.domain,
      id: _decodedToken.sub,
      user: _decodedToken.user,
      scope: _decodedToken.user_scope,
    };

    // set a keepalive check
    setInterval(() => {
      if (localStorage.getItem('ns_t') == null || !localStorage.getItem('ns_t')) {
        window.location.reload();
      }
    }, 2500);

    window.ga('send', 'event', 'user', 'login');
    window.ga('send', 'event', 'user hostname', global.location.hostname);
    window.ga('send', 'event', 'user domain', _decodedToken.domain);

    // get the user id from the token and save it to core state
    yield put(setConfig('userId', _decodedToken.user));

    if (_isExtension) {
      window.ga('send', 'event', 'extension');
      const appName = yield select(((state) => state.configs.get('appName')));
      localStorage.setItem(`${appName}-extension_user`, _decodedToken.user);
    }

    // update ui configs after login
    // there may be different configs specific to the domain/user
    yield call(_uiConfigsToSettings);

    if (!_isExtension) {
      // fork fetching and setting the favicon
      // fetches from the api for custom branding
      yield fork(_favicon);

      // fork setting the application title
      // ui configurable
      yield fork(_setTitle);
    }

    // check for legacy
    // handles proxy authentication auth flow
    yield call(_checkAuthFlow);

    // call the next generator function to continue the load process
    yield call(_postLogin);
  } catch (err) {
    switch (err) {
      default:
        bugsnagClient.notify(err);
    }
  }
};

/**
 *
 * @private
 */
const _postLogin = function* postLogin() {
  try {
    // init contact sort directions
    yield call(_setContactSorting);

    // fetch the user record
    yield put(fetchUser());

    // user media streams
    // save a ref to the task for joining later in the load process
    _initMediaTask = yield fork(_initUserMedia);

    // fork fetching the logo
    // fetches from the api for custom branding
    // save a ref to the task for joining later in the load process
    _logoTask = yield fork(_fetchLogo);

    // save the logo to the core config module
    const image = yield _logoTask.toPromise();
    yield put(setConfig('logo', image));

    const initNav = {
      name: 'wrapper.contact-list',
      params: null,
      fromName: null,
      fromParams: null,
    };

    yield put(setShowAgentCenter());
    const showAc = yield select(((state) => state.agent.get('showAgentCenter')));
    if (showAc) {
      initNav.name = 'wrapper.agent-center-list';
      yield put(fetchAgentQueues());
    }

    yield put(fetchCallParks());

    // push state onto nav stack
    yield put(navActions.currentState(initNav));

    // push state onto nav stack
    yield put(navActions.navStackPush(initNav));

    // show the contact-list view
    yield $state.go(initNav.name, {
      user: _decodedToken.user,
    });

    // start tracking current state
    yield put(navActions.startTracking());

    // fetch audio device
    const devicePostFix = yield select((state) => state.configs.get('devicePostFix'));
    const fetchAudioTask = yield fork(_fetchAudioDevice, devicePostFix);

    // setup ringtones
    yield put(ringtones());

    yield put(fetchWrapUp());

    // fetch answering answering-rule-list
    yield put(fetchAnsweringRules());

    // test if the user media stream request is still running
    if (_initMediaTask.isRunning()) {
      // todo pop up dialog
    }

    // pause until the user device task is done
    const fetchAudioTaskRes = yield fetchAudioTask.toPromise();
    yield fork(_setCallFrom, fetchAudioTaskRes);

    // pause until the user media task is done
    const mediaTaskRes = yield _initMediaTask.toPromise();

    if (fetchAudioTaskRes && mediaTaskRes) {
      yield put(userMedia.hasPermission(true));

      // stop stream
      userMedia.stopStream(mediaTaskRes);

      // set the stream with no tracks
      // yield put(userMedia.stream(mediaTaskRes));

      const appName = yield select(((state) => state.configs.get('appName')));

      // get default/selected media devices
      yield call(userMedia.initDevices, appName, _decodedToken.user);

      // will be used for offline tab header
      const wpNameConfig = yield select((state) => state.configs.get('PORTAL_WEBPHONE_NAME'));
      localStorage.setItem('wpNameConfig', wpNameConfig);

      const wpPWANameConfig = yield select((state) => state.configs.get('PORTAL_WEBPHONE_PWA_NAME'));
      localStorage.setItem('wpPWANameConfig', wpPWANameConfig);

      localForage.setItem('wpPWANameConfig', wpPWANameConfig);
      
      // set the user's audio device
      yield put(setAudioDevice(fetchAudioTaskRes));

      // put toggle on init push
      const pwaEnabled = yield select(((state) => state.configs.get('PORTAL_WEBPHONE_ENABLE_PWA')));
      if (pwaEnabled === 'yes') {
        // set up push notifications
        yield call(_initPush, fetchAudioTaskRes);
      }
      // prepare sip.js user agent for in/outbound calls
      yield put(initUA());

      // init tab-comms for launching calls from the portal
      yield call(tabCommInit, $state);
    } else {
      yield put(userMedia.hasPermission(false));
      // todo pop up dialog about not being able to make calls
    }
  } catch (err) {
    bugsnagClient.notify(err);
  }
};

const _initPush = function* initPush(fetchAudioTaskRes) {
  // console.log('this is fetchAudioTaskRes: ', fetchAudioTaskRes);

  if ('serviceWorker' in navigator) {
    const serviceWorkerRegistration = yield navigator.serviceWorker.register('/webphone/root-service-worker.js');

    let sub = yield _getSubscription(serviceWorkerRegistration);

    // console.log('this is sub check 1', sub);

    // if the current user does not match the one in local storage,
    // unsub so can resub with brand new token
    const pushSubscriber = localStorage.getItem('pushSubscriber');
    if (pushSubscriber !== fetchAudioTaskRes.sub_login) {
      sub = null;
      // call the one that can yield
      yield _unsubscribePush();
    }

    if (sub == null) {
      // Update UI to ask user to register for Push
      // did not have push subscription so need to get the subscription
      // console.log('Not subscribed to push service!!!!!!!');
      sub = yield _subscribeUser(); // subscribe a push notification

      // console.log('sub after _subscribeUser: ', sub);
      // console.log('sub after _subscribeUser22: ', JSON.stringify(sub));

      if (sub !== undefined) {
        yield _updateServerPush(JSON.stringify(sub), fetchAudioTaskRes);
      }
    } else {
      // console.log('Subscription object exists 2: ', JSON.stringify(sub));

      yield _updateServerPush(JSON.stringify(sub), fetchAudioTaskRes);

      // console.log('after _updateServerPush');

      // uncomment in order to force unsubscribe during development
      // yield call(unsubscribePush());
    }
    localStorage.setItem('pushSubscriber', fetchAudioTaskRes.sub_login);
  }
};

// unsubscribe the service worker from listening to push requests
// const _unsubPush = function* unsubPush() {
//   console.log('in _unsubPush');
//   const serviceWorkerRegistration = yield navigator.serviceWorker.register('/webphone/root-service-worker.js');
//   const sub = yield _getSubscription(serviceWorkerRegistration);
//
//   console.log('this is sub to unsubscribe: ', sub);
//   const unsubResult = yield sub.unsubscribe();
//   console.log('this is unsubResult: ', unsubResult);
//
//   if (unsubResult) {
//     console.log('unsub success');
//   } else {
//     console.log('unsub failure');
//   }
//
//   return subResult;
// };

const _getSubscription = function* getSubscription(registration) {
  // console.log('this is registration2: ', registration);
  // notificationsRegistration = registration;
  // console.log("registration complete");
  // console.log(notificationsRegistration);

  if ('Notification' in window && navigator.serviceWorker) {
    console.log('Display the UI to let the user toggle notifications');
  }
  if (Notification.permission === 'granted') {
    /* do our magic */
    // console.log('granted');
  } else if (Notification.permission === 'blocked') {
    /* the user has previously denied push. Can't reprompt. */
    // console.log('blocked');
  } else {
    /* show a prompt to the user */
    // console.log('show prompt');
  }

  // register getsubscriptions

  const sub = yield registration.pushManager.getSubscription();
  // console.log('this is sub in _getSubscription: ', sub);

  return sub;
};

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}

// put the push token and params to push_configs table
const _updateServerPush = function* updateServerPush(subscription, fetchAudioTaskRes) {
  // console.log('in _updateServerPush: ', subscription);

  const decodedToken = nsToken.getDecoded();

  const { endpoint, keys } = JSON.parse(subscription);
  const device_token = {
    endpoint,
    auth: keys.auth,
    p256dh: keys.p256dh,
  };

  let params;
  const displayMode = getPWADisplayMode();

  if (displayMode !== 'standalone') {
    // webphone was openned via a browser instead, delete the push
    params = {
      object: 'push',
      action: 'delete',
      domain: decodedToken.domain,
      user: decodedToken.user,
      device: fetchAudioTaskRes.aor,
      device_token: encodeURIComponent(JSON.stringify(device_token)),
      network: 'pwa',
      continue: true,
    };
  } else {
    // pwa was openned, create the push
    params = {
      object: 'push',
      action: 'create',
      domain: decodedToken.domain,
      user: decodedToken.user,
      device: fetchAudioTaskRes.aor,
      device_token: encodeURIComponent(JSON.stringify(device_token)),
      network: 'pwa',
    };
  }

  // post push create
  const res = yield nsApi.post(params);

  // console.log('this is api post res: ', res);
  // console.log('this is api post res response: ', res.response);

  // console.log('this is endpoint: ', subscription['endpoint']);
  // console.log('this is ps256dh: ', subscription['keys']);
  // eslint-disable-next-line max-len
  // let copypaste = "web-push send-notification --endpoint="+ subscription['endpoint'] +" --key=" + subscription['keys']['p256dh'] + " --auth="+ subscription['keys']['auth'] +"  --payload=hello+there" +" --vapid-subject=mailto:cwei@netsapiens.com" +" --vapid-pubkey=BMliW2pX2AmOb9J4ck4OOxCTMxvEmBO01QLHeuDsfRvMaxfaIBmOWAgUykaFo2Y0l_v9DDz97xi-Wa7QhPilMeE --vapid-pvtkey=n50s7fjp7ja6vqfsHLvJylC-5YIRu_R425Qg6pAttVk";

  // console.log('this is copypaste: ');
  // console.log(copypaste);

  if (res.response === 'ok') {
    // yield put(setPushSubscription(subscription));
  }
};

const _subscribeUser = function* subscribeUser() {
  if ('serviceWorker' in navigator) {
    const newRegistrationWorker = yield navigator.serviceWorker.ready;
    try {
      const sub = yield newRegistrationWorker.pushManager.subscribe({
        userVisibleOnly: true,
        sampleField: true,
        // hardcoded for now in order to match the node setVapidDetails
        applicationServerKey: _urlBase64ToUint8Array(
          'BMliW2pX2AmOb9J4ck4OOxCTMxvEmBO01QLHeuDsfRvMaxfaIBmOWAgUykaFo2Y0l_v9DDz97xi-Wa7QhPilMeE',
        ),
      });

      // console.log('Endpoint URL: ', sub.endpoint);
      // console.log('sub: ', sub);

      return sub;
    } catch (e) {
      if (Notification.permission === 'denied') {
        console.log('Permission for notifications was denied');
        console.warn('Permission for notifications was denied');
      } else {
        console.log('Unable to subscribe to push', e);
        console.error('Unable to subscribe to push', e);
      }
    }
  }

  return undefined;
};

/**
 *
 * @param N/A
 * @return N/A
 * will unsubscribe the webphone's service worker from listening to any more push notifications
 */
const _unsubscribePush = function* () {
  const serviceWorker = yield navigator.serviceWorker.register('/webphone/root-service-worker.js');
  const sub = yield serviceWorker.pushManager.getSubscription();

  if (sub !== null) {
    try {
      const successful = yield sub.unsubscribe();

      if (successful) {
        // console.log('_unsubscribePush succeed');
      } else {
        // console.log('_unsubscribePush fail');
      }
    } catch (e) {
      console.log('unsub fail error: ', e);
    }
  } else {
    // console.log('sub did not exist to unsub');
  }
};

// to format the applicationServerKey
const _urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);

  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

/**
 * Checks if the browser is compatible with the app
 * Supported browsers are configured in the app package.json file
 * @return {Promise} promise returns null or rejects with BROWSER_CHECK_FAIL
 * @private
 */
const _browserSupport = () => {
  if (nsBrowser.isSupported(pack.config.supportedBrowsers)) {
    return Promise.resolve(true);
  }
  return Promise.reject(BROWSER_CHECK_FAIL);
};

/**
 * Checks if the auth flow is for password reset
 * @private
 */
const _checkAuthCode = function* checkAuthCode() {
  const authCode = nsBrowser.getQuery('auth_code');
  const username = nsBrowser.getQuery('username');
  const isPasswordReset = !!authCode && !!username;

  // check for password reset
  if (isPasswordReset) {
    const apiHash = yield select(((state) => state.configs.get('apiHash')));

    // authenticate the user
    const res = yield nsApi.authenticate({
      username: nsUtils.encoding.base64Decode(username),
      authCode,
      apiHash,
      scope: 'webphone',
    }).then(() => true).catch((err) => err);

    if (res === true) {
      const decodedToken = nsToken.getDecoded();
      _decodedToken = decodedToken;

      // get uiconfigs again after authenticated for password reset
      yield call(_uiConfigsToSettings);

      yield $state.go('password-reset', {
        user: decodedToken.user,
        prAuthCode: authCode,
      });

      yield Promise.reject(STOP_INIT);
    } else {
      // todo password reset error message
      yield Promise.reject(res);
    }
  }
};

/**
 *
 * @private
 */
const _checkAuthFlow = function* checkAuthFlow() {
  if (_decodedToken.legacy) {
    const apiHash = yield select((state) => state.configs.get('apiHash'));
    const pathname = yield select((state) => state.configs.get('pathname'));
    const portalLegacyBehavior = yield select((state) => state.configs.get('portalLegacyBehavior'));
    const wpName = yield select((state) => state.configs.get('wpName'));

    const decodedApiHash = nsUtils.encoding.base64Decode(apiHash);
    const [client_id] = decodedApiHash.split(':');

    let res;

    switch (portalLegacyBehavior) {
      case 'reject_and_email':

        res = yield nsApi.post({
          object: 'email',
          action: 'create',
          subject: `Your Login ${wpName} Recovery`,
          template: 'password_reset_email.php',
          app_uri: `${global.location.origin}${pathname}?username=<USERNAME>&auth_code=<AUTH_CODE>`,
          recipient: _decodedToken.user_email,
          user: _decodedToken.user,
          client_id,
        });

        if (res.sent) {
          yield $state.go('login', {
            wpPassResetMsg: true,
          });
          // eslint-disable-next-line prefer-promise-reject-errors
          yield Promise.reject('legacy recover');
        } else {
          yield $state.go('login', {
            wpPassRecoverFail: true,
          });
          // eslint-disable-next-line prefer-promise-reject-errors
          yield Promise.reject('legacy recover fail');
        }
        break;
      case 'warn':
      case 'silent':
        break;
      default:
        yield $state.go('legacy-reset', {
          showAuthRecoverMsg: true,
          user: _decodedToken.user,
        });
        // eslint-disable-next-line prefer-promise-reject-errors
        yield Promise.reject('auth recover');
    }
  } else if (_decodedToken.user_scope === '') {
    if (_decodedToken.recover) {
      yield $state.go('login', {
        wpPassResetMsg: true,
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      yield Promise.reject('auth recover');
    } else {
      yield $state.go('login', {
        wpPassRecoverFail: true,
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      yield Promise.reject('auth recover fail');
    }
  }
};

/**
 * Checks if the browser was refreshed during the legacy-reset window flow
 * Prevents users from hitting the legacy-reset flow and
 * refresh the browser to bypass the password reset
 * @return Promise
 * @private
 */
const _checkLegacyReset = function* checkLegacyReset() {
  const appName = yield select(((state) => state.configs.get('appName')));
  const user = nsBrowser.getQuery('user');
  const legacy = localStorage.getItem(`${appName}-${user}_legacy`) === 'true';
  const isLegacy = !!user && legacy;

  // check if the browser is being refreshed
  // from the password reset or legacy-reset reset page
  if (isLegacy) {
    // verify the token is still legacy-reset
    const dt = nsToken.getDecoded();
    if (dt.legacy) {
      yield $state.go('legacy-reset', {
        user: dt.user,
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      yield Promise.reject('password reset');
    } else {
      localStorage.removeItem(`${appName}-${user}_legacy`);
    }
  }
};

/**
 * Configures the netsapiens sdk
 * @private
 */
const _configureSDK = function* configureSDK() {
  const configs = yield select(((state) => state.configs));

  const sdkConfig = {
    appName: configs.get('appName'),
    apiURL: configs.get('apiURL'),
    portalURL: configs.get('portalURL'),
    tokenStorageUserId: true,
  };

  const userId = configs.get('userId');

  if (userId) {
    sdkConfig.userId = userId;
  }

  nsConfig.configure(sdkConfig);
};

/**
 *
 * @returns {IterableIterator<*>}
 * @private
 */
const _extensionConnect = function* extensionConnect() {
  const extensionId = nsBrowser.getQuery('extension');
  if (!extensionId) {
    yield Promise.resolve(false);
  }

  if (!extensionId
    || !window.chrome
    || !window.chrome.runtime
    || !window.chrome.runtime.sendMessage
  ) {
    yield Promise.resolve(false);
  }

  yield put(setExtensionId(extensionId));

  try {
    const port = window.chrome.runtime.connect(extensionId);
    yield put(setBackgroundPort(port));
    yield put(messaging());
    yield Promise.resolve(true);
  } catch (e) {
    yield Promise.resolve(false);
  }
};

/**
 * Fetch favicon img and append to dom
 * @private
 */
const _favicon = function* favicon() {
  const apiURL = yield select((state) => state.configs.get('apiURL'));
  const server = yield select((state) => state.configs.get('hostname'));
  let faviconURL = `${apiURL}?object=image&action=read&server=${server}&filename=favicon.gif`;

  if (_decodedToken && _decodedToken.domain) {
    faviconURL += `&domain=${_decodedToken.domain}`;
  }
  if (_decodedToken && _decodedToken.territory) {
    faviconURL += `&territory=${_decodedToken.territory}`;
  }

  yield put(setConfig('faviconURL', faviconURL));

  const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconURL;
  document.getElementsByTagName('head')[0].appendChild(link);
};

/**
 *
 * @return {Promise}
 * @private
 */
const _fetchDevices = () => {
  const token = nsToken.getDecoded();
  return nsApi.get({
    object: 'device',
    action: 'read',
    user: token.user,
    domain: token.domain,
    noNDP: 'true',
    mode: 'registered_endpoint',
    format: 'json',
  }).then((res) => {
    let devices = _.compact(res);
    // remove conference bridge device
    devices = _.filter(devices, (device) => device
      && device.aor
      && device.aor.indexOf('conference-bridge') === -1
      && device.aor.indexOf('vb') === -1);
    return devices;
  });
};

/**
 *
 * @param devicePostFix
 * @return {Promise}
 * @private
 */
const _fetchAudioDevice = (devicePostFix) => {
  const token = nsToken.getDecoded();
  return nsDevice.get({
    device: `sip:${token.user}${devicePostFix}@${token.domain}`,
    domain: token.domain,
    userId: token.user,
    createDevice: true,
  }).then((res) => res).catch(() => false);
};

/**
 *
 * @return {Promise} promise returns img or false
 * @private
 */
const _fetchLogo = () => {
  const token = nsToken.getDecoded();

  return new Promise((resolve) => {
    nsApi.getLogo({
      object: 'image',
      action: 'read',
      server: global.location.hostname,
      territory: token?.territory || '*',
      domain: token?.domain || '*',
      user: token?.user || '*',
      filename: 'webphone_main_top_left.png',
    }).then((res) => {
      if (res !== 'data:') {
        resolve(res);
      } else {
        nsApi.getLogo({
          object: 'image',
          action: 'read',
          territory: token?.territory || '*',
          domain: token?.domain || '*',
          user: token?.user || '*',
          server: global.location.hostname,
          filename: 'portal_main_top_left.png',
        }).then((res2) => {
          resolve(res2);
        }).catch(() => {
          resolve(false);
        });
      }
    }).catch(() => {
      nsApi.getLogo({
        object: 'image',
        action: 'read',
        server: global.location.hostname,
        territory: token?.territory || '*',
        domain: token?.domain || '*',
        user: token?.user || '*',
        filename: 'portal_main_top_left.png',
      }).then((res) => {
        resolve(res);
      }).catch(() => {
        resolve(false);
      });
    });
  });
};

/**
 *
 * @return {Promise}
 * @private
 */
/* const _fetchUser = () => {
    return new Promise(resolve => {
        store.dispatch(fetchUser());
        const unsubscribe = store.subscribe(() => {
            if(store.getState().user) {
                unsubscribe();
                resolve();
            }
        });
    });
}; */

/**
 * {Promise} promise returns Stream|false
 * @private
 */
const _initUserMedia = () => nsMedia
  .getUserMedia({
    audio: true,
    video: false,
  })
  .then((res) => {
    // close user media stream,
    // this is only a test for permissions and device availability
    // disabled after revert of sip.js n v39
    // res.getTracks()[0].stop();
    localStorage.setItem('WPmicrophone', 'true');
    return res;
  })
  .catch(() => {
    localStorage.setItem('WPmicrophone', 'false');
    return false;
  });

const _setCallFrom = function* setCallFrom(webphoneDevice) {
  const appName = yield select(((state) => state.configs.get('appName')));
  const storageDevice = localStorage.getItem(`${appName}-${_decodedToken.user}_callfrom`);
  if (storageDevice) {
    const storedDevice = JSON.parse(storageDevice || '{}');
    if (storedDevice.aor === webphoneDevice.aor) {
      yield put(setCallFromDevice(webphoneDevice));
    } else {
      const devices = yield _fetchDevices();
      if (devices && devices.length) {
        let match;
        for (let i = 0; i < devices.length; i += 1) {
          if (storedDevice.aor === devices[i].aor) {
            devices[i].display = devices[i].aor.replace('sip:', '');
            devices[i].display = devices[i].display.replace(`@${devices[i].subscriber_domain}`, '');
            match = devices[i];
            break;
          }
        }

        if (match) {
          yield put(setCallFromDevice(match));
        } else {
          yield put(setCallFromDevice(webphoneDevice));
        }
      } else {
        yield put(setCallFromDevice(webphoneDevice));
      }
    }
  } else {
    localStorage.setItem(
      `${appName}-${_decodedToken.user}_callfrom${_isExtension ? '_extension' : ''}`,
      JSON.stringify(webphoneDevice),
    );
    yield put(setCallFromDevice(webphoneDevice));
  }
};

/**
 *
 * @private
 */
const _setContactSorting = function* setContactSorting() {
  const appName = yield select(((state) => state.configs.get('appName')));

  const fb = localStorage.getItem(`${appName}-${_decodedToken.user}_contacts-filtered-by`) || 'all';
  const sd = localStorage.getItem(`${appName}-${_decodedToken.user}_contacts-sort-direction`) || 'asc';
  const sb = localStorage.getItem(`${appName}-${_decodedToken.user}_contacts-sorted-by`) || 'last_name';

  yield put(filteredBy(fb));
  yield put(sortedDirection(sd));
  yield put(sortedBy(sb));
};

/**
 * Appends the title tag to the DOM
 * @private
 */
const _setTitle = function* setTitle() {
  const title = yield select((state) => state.configs.get('wpName'));

  const searchElem = document.getElementsByTagName('title')[0];
  const titleElem = (searchElem) || document.createElement('title');
  titleElem.innerText = title;
  if (!searchElem) {
    document.getElementsByTagName('head')[0].appendChild(titleElem);
  }
};

/**
 * Fetches ui configs needed for this app and saves to the core config module
 * the function also maps ui configs names to app config names
 * @return {Promise}
 * @private
 */
const _uiConfigsToSettings = function* uiConfigsToSettings() {
  const configs = [];

  const hostname = yield select((state) => state.configs.get('hostname'));

  yield Promise.all([
    nsUiConfig.get({
      configName: 'WS_SERVERS',
      domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
      user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
      hostname,
      role: _decodedToken && _decodedToken.user_scope ? _decodedToken.user_scope : '*',
      reseller: _decodedToken && _decodedToken.territory ? _decodedToken.territory : '*',

      returnFirst: true,
    }),

    nsUiConfig.get({
      configName: 'PORTAL_*',
      domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
      user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
      hostname,
      role: _decodedToken && _decodedToken.user_scope ? _decodedToken.user_scope : '*',
      reseller: _decodedToken && _decodedToken.territory ? _decodedToken.territory : '*',

      // filter: configFilter // see ../configs/configsFilter.json
    }),

    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_GOOGLE_SSO_ENABLED_'+hostname,
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),
    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_GOOGLE_SSO_CLIENT_ID_'+hostname,
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),

    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_OFFICE_SSO_ENABLED_'+hostname,
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),
    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_OFFICE_SSO_CLIENT_ID_'+hostname,
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),

    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_APPLE_SSO_ENABLED',
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),
    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_APPLE_SSO_ENABLED_'+hostname,
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),
    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_APPLE_SSO_CLIENT_ID',
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),
    // nsUiConfig.get({
    //     configName: 'PORTAL_LOGIN_APPLE_SSO_CLIENT_ID_'+hostname,
    //     domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
    //     user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
    // }),
  ]).then((res) => {
    // process socket response
    if (res[0]
            && res[0].config_value
            && res[0].config_value.trim()
    ) {
      const tempWs = res[0].config_value.split(',');
      if (tempWs) {
        const uaSockets = [];
        let weight = 100;
        for (let i = 0; i < tempWs.length; i += 1) {
          if (tempWs[i] && tempWs[i].trim()) {
            tempWs[i] = tempWs[i].trim();
            /* Allow defining wsServers parameter as:
             *  String: "host"
             *  Array of Strings: ["host1", "host2"]
             *  Array of Objects: [{ws_uri:"host1", weight:1}, {ws_uri:"host2", weight:0}]
             *  Array of Objects and Strings: [{ws_uri:"host1"}, "host2"]
            */
            // #NOTE: This will be different in newer SIP.js versions
            if (tempWs[i].indexOf(':') === -1) {
              uaSockets.push({ ws_uri: `wss://${tempWs[i]}:9002`, weight });
            } else {
              uaSockets.push({ ws_uri: `wss://${tempWs[i]}`, weight });
            }
            weight /= 10;
            if (weight < 1) weight = 0;
          }
        }
        if (uaSockets.length > 0) {
          configs.push({
            config_name: 'uaSocketURLs',
            config_value: uaSockets,
          });
        }
      }
    }

    // process configs
    if (
      res[1]
            && res[1].length
    ) {
      for (let i = 0; i < res[1].length; i += 1) {
        // process ns sockets
        if (res[1][i].config_name === 'nsSocketHostName'
                    && res[1][i].config_value
                    && res[1][i].config_value.trim()
        ) {
          const tempWs = res[1][i].config_value.split(',');
          if (tempWs) {
            const nsSockets = [];
            for (let index = 0; index < tempWs.length; index += 1) {
              if (tempWs[index] && tempWs[index].trim()) {
                tempWs[index] = tempWs[index].trim();
                if (tempWs[index].indexOf(':') === -1) {
                  nsSockets.push(`wss://${tempWs[index]}:8001`);
                } else {
                  nsSockets.push(`wss://${tempWs[index]}`);
                }
              }
            }
            if (nsSockets) {
              configs.push({
                config_name: 'nsSocketURLs',
                config_value: nsSockets,
              });
            }
          }
        } else {
          // process configs
          if (res[1][i].config_name === 'countryCode' && !res[1][i].config_value) {
            const browserLanguage = navigator.language;
            res[1][i].config_value = browserLanguage.indexOf('-') !== -1 ? browserLanguage.split('-')[1] : 'US';
          }
          configs.push(res[1][i]);
        }
      }
    }

    // // look for hostname override of portalLoginGoogleSsoEnabled
    // if ( res[2] && res[2].length ) {
    //     const thisConf = res[2][0];
    //     thisConf.config_name = 'portalLoginGoogleSsoEnabled';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
    //
    // // look for hostname override of portalLoginGoogleSsoClientId
    // if ( res[3] && res[3].length ) {
    //     const thisConf = res[3][0];
    //     thisConf.config_name = 'portalLoginGoogleSsoClientId';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
    //
    // // look for hostname override of portalLoginOfficeSsoEnabled
    // if ( res[4] && res[4].length ) {
    //     const thisConf = res[4][0];
    //     thisConf.config_name = 'portalLoginOfficeSsoEnabled';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
    //
    // // look for hostname override of portalLoginOfficeSsoClientId
    // if ( res[5] && res[5].length ) {
    //     const thisConf = res[5][0];
    //     thisConf.config_name = 'portalLoginOfficeSsoClientId';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
    //
    // // look for portalLoginAppleSsoEnabled
    // if ( res[6] && res[6].length ) {
    //     const thisConf = res[6][0];
    //     thisConf.config_name = 'portalLoginAppleSsoEnabled';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
    //
    // // look for hostname override of portalLoginAppleSsoEnabled
    // if ( res[7] && res[7].length ) {
    //     const thisConf = res[7][0];
    //     thisConf.config_name = 'portalLoginAppleSsoEnabled';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
    //
    // // look for portalLoginAppleSsoClientId
    // if ( res[8] && res[8].length ) {
    //     const thisConf = res[8][0];
    //     thisConf.config_name = 'portalLoginAppleSsoClientId';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
    //
    // // look for hostname override of portalLoginAppleSsoClientId
    // if ( res[9] && res[9].length ) {
    //     const thisConf = res[9][0];
    //     thisConf.config_name = 'portalLoginAppleSsoClientId';
    //
    //     if (thisConf.config_value && thisConf.config_value.trim()) {
    //         configs.push(thisConf);
    //     }
    // }
  }).catch((err) => err);

  yield put(setConfigs(configs));
};

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.agent-queue-details', {
      url: 'agent-queue-details',
      params: {
        queue: null,
      },
      template: '<nets-agent-queue-details></nets-agent-queue-details>',
    });
};

import './styles.css';
import template from './template.html';
import * as chatActions from '../../core/chat';
import { getContact } from '../../core/contacts';

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@',
  },
};

controller.$inject = [
  '$ngRedux',
];

function controller(
  $ngRedux,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) =>
    // TODO set a contact array here as well, so session can always be changed
      ({
        contact: getContact(state, this.card.meta.contactId),
        messagesMeta: chatActions.getMessages(state, this.card.meta.sessionId),
        screenSize: state.screenSize,
        session: chatActions.getSession(state, this.card.meta.sessionId),
        user: state.user,
        dateFormat: state.configs.get('portalLocalizationDateFormat'),
        timeFormat: state.configs.get('portalLocalizationTimeFormat'),
      }))((state) => {
      // get sms number array
      const smsNumbers = [];
      if (state.user.sms) {
        for (let i = 0; i < state.user.sms.length; i += 1) {
          smsNumbers.push(state.user.sms[i].number);
        }
        /* smsNumbers = state.user.sms.map(num => {
                    if (num.number.indexOf('1') === 0) {
                        num.number = num.number.substring(1);
                    }
                    return num.number;
                }); */
      }

      // change hight of chat scroll based on context
      let chatHeight;
      let loaderSize = 20;
      switch (this.context) {
        case 'compact':
          chatHeight = `${state.screenSize.height - 132}px`;
          loaderSize = 50;
          break;
        case 'card':
          chatHeight = '244px';
          break;
        case 'modal':
          chatHeight = '395px';
          break;
      }

      this.chatHeight = chatHeight;
      this.contact = state.contact;
      this.loaderSize = loaderSize;
      this.screenSize = state.screenSize;
      this.session = state.session;
      this.smsNumbers = smsNumbers;
      this.user = state.user;
      this.groupMessagesMeta = state.messagesMeta;
      this.dateFormat = state.dateFormat;
      this.timeFormat = state.timeFormat;
    });

    // Fetch messages if there is a sessionId and the messages have not already been fetched.
    if (this.card.meta && this.card.meta.sessionId && !this.messagesMeta) {
      $ngRedux.dispatch(chatActions.fetchMessages(this.card.meta.sessionId));
    }

    // Set the input box in focus if the context is not a small card.
    // We only want expanded and full screen card inputs to get input focus
    if (this.context !== 'card') {
      const interval = setInterval(() => {
        const inputElem = document.getElementById(`chat-session-input_${this.card.id}_${this.context}`);
        if (inputElem) {
          inputElem.focus();
          clearInterval(interval);
        }
      }, 200);
    }

    // set listener for name change input
    const interval = setInterval(() => {
      const inputElem = document.getElementById('name-change-input');
      if (inputElem) {
        inputElem.focus();
        clearInterval(interval);
      }
    }, 200);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
    // window.removeEventListener('keyup', keyUp);
  };

  /**
     *
     * @param session
     * @return {boolean}
     */
  this.highlightHeader = (session) => {
    // test if the status is unread
    if (session && session.lastStatus === 'unread') {
      if (session.lastSender === this.user.uid) {
        return false;
      } if (this.user.sms) {
        // search for sms number match
        for (let i = 0; i < this.user.sms.length; i += 1) {
          if (session.lastSender === this.user.sms[i].number) {
            return false;
          }
        }
        return true;
      }
      return true;
    }
    return false;
  };

  /**
     *  Cancel state of name change for the session
     */
  this.cancelConversationNameChange = () => {
    this.card.changeSessionName = false;
  };

  /**
     *  Confirm name change for the session, change state to normal card state
     */
  this.confirmNameChange = () => {
    if (!this.nameChangeInput || !this.nameChangeInput.trim()) {
      this.nameChangeInput = '';
      return;
    }
    const params = { session_name: this.nameChangeInput, ...this.card.meta.sendMessageParams };
    $ngRedux.dispatch(chatActions.changeSessionName(params));
    this.nameChangeInput = '';

    // change converation name on card instantly
    this.session.session_name = `${this.nameChangeInput}`;

    // remove state of changename for card
    if (!this.card.changeSessionName) this.card.changeSessionName = true;
    else this.card.changeSessionName = false;
  };
}

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.phone', {
      url: 'phone',
      params: {
        transferId: null,
      },
      template: '<nets-phone></nets-phone>',
    });
};

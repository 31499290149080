export default ($stateProvider) => {
  $stateProvider.state('mfa-form', {
    url: `${location.pathname}mfa-form`,
    params: {
      username: null,
      mfa_type: null,
      mfa_vendor: null,
    },
    template: '<nets-mfa-form></nets-mfa-form>',
  });
};

import { init } from '../../core/redux';

export default ($stateProvider) => {
  $stateProvider
    .state('init', {
      url: `${location.pathname}init?user`,
      template: '<nets-loader></nets-loader>',
      controller: ['$ngRedux', function ($ngRedux) {
        $ngRedux.dispatch(init());
      }],
    });
};

import './styles.css';
import template from './template.html';
import * as navActions from '../../core/state-history';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

export default {
  template,
  controller
};

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$mdToast',
  '$state',
  'ngAudio',
  '$translate',
  '$sce'
];



function controller(
  $ngRedux,
  $mdDialog,
  $mdToast,
  $state,
  ngAudio,
  $translate,
  $sce
) {
  let unsubscribe;

  /**
     *
     */

  this.$onInit = () => {
    this.loaded = true;
    this.iframeUrl = $ngRedux.getState().configs.get('PORTAL_WEB_PHONE_IFRAME_URL');
    this.iframeName = $ngRedux.getState().configs.get('PORTAL_WEB_PHONE_IFRAME_NAME');

    //this.safeSrc=  DomSanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/c9F5kMUfFKk");
    unsubscribe = $ngRedux.connect((state) => ({
      greetings: getGreetings(state),
      screenSize: state.screenSize,
      user: state.user,
      iframeName: state.configs.get('PORTAL_WEB_PHONE_IFRAME_NAME'),
      iframeUrl: state.configs.get('PORTAL_WEB_PHONE_IFRAME_URL')
    }))((state) => {
      this.iframeName = state.iframeName;
      this.iframeUrl = state.iframeUrl;
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  this.getSourceURL = () => {
      const decodedToken = nsToken.getDecoded();
      let cookies = document.cookie.split(';');
      for(var i = 0; i < cookies.length; i++) {
          var name = cookies[i].substring(0, cookies[i].indexOf('=')).trim();
          var value = cookies[i].substring(cookies[i].indexOf('=')+1).trim();
          var user = decodedToken.user;
          if (name.startsWith("ns-") && name.endsWith("-"+decodedToken.user))
          {
            this.iframeUrl = this.iframeUrl.replace("[COOKIE_NAME]",name);
            this.iframeUrl = this.iframeUrl.replace("[COOKIE]",value);
            this.iframeUrl = this.iframeUrl.replace("[JWT_TOKEN]",localStorage.getItem('ns_t'));
            console.log("getSourceURL - " + this.iframeUrl);
            return $sce.trustAsResourceUrl(this.iframeUrl);
          }
      }
  };

  /**
     *
     */
  this.navPush = (route) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: route.name,
        params: route.params || null,
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go(route.name, route.params || null);
  };


}

import { put, select, takeEvery } from 'redux-saga/effects';

import { GREETINGS, fetchGreetings } from '../greetings';
import { SET_USER, updateUser } from '../user';

export default function* () {
  yield takeEvery(GREETINGS, _updateUserGreeting);
  yield takeEvery(SET_USER, _fetchGreetings);
}

const _updateUserGreeting = function* () {
  const user = yield select((state) => state.user);
  const greetings = yield select((state) => state.greetings.get('greetings'));

  // This case handles when a user does not have a voicemail greeting set and there are greetings available.
  // Auto select the first greeting available for the user.
  if (!user.vmailGreeting && greetings.length) {
    user.vmailGreeting = greetings[0].index;
    yield put(updateUser(user));
  }
  // This case handles when a greeting is deleted and there are greetings available.
  // Check if the user's vmailGreeting was deleted, if so set the next available greeting.
  else if (greetings.length) {
    let match = false;
    for (let i = 0; i < greetings.length; i += 1) {
      if (user.vmailGreeting === greetings[i].index) {
        match = true;
        break;
      }
    }
    if (!match) {
      user.vmailGreeting = greetings[0].index;
      yield put(updateUser(user));
    }
  }
  // This case handles when a greeting is deleted and there are no available greetings left to set.
  // Update the user's vmailGreeting to null
  else if (user.vmailGreeting && !greetings.length) {
    user.vmailGreeting = '';
    yield put(updateUser(user));
  }
};

const _fetchGreetings = function* () {
  yield put(fetchGreetings());
};

import './styles.css';
import template from './template.html';
import { getExpandedId } from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({ expandedId: getExpandedId(state) }))((state) => { this.showCard = this.card.id !== state.expandedId; });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

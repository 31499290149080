import './styles.css';
import { webHidPairing } from '@gnaudio/jabra-js';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import template from './template.html';
import { selectShowHidConsent, showHidConsent } from '../../core/hid-consent';

function controller(
  $ngRedux,
  $mdDialog,
  $translate,
) {
  const decodedToken = nsToken.getDecoded();
  let unsubscribe;
  let alreadyShowing = false;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      appName: state.configs.get('appName'),
      show: selectShowHidConsent(state),
    }))((state) => {
      if (state.show && !alreadyShowing) {
        alreadyShowing = true;
        $mdDialog.show({
          template: `
            <md-dialog aria-label="HID Consent" style="max-width: 360px">
                <md-dialog-content style="padding: 20px;">
                    <h3 class="m-b-0">${$translate.instant('HID_CONSENT_TITLE')}</h3>
                    <p>${$translate.instant('HID_CONSENT_BODY')}</p>
                    <p>${$translate.instant('HID_CONSENT_BODY_ADDITIONAL')}</p>
                    <md-checkbox class="m-t-8 md-primary" ng-model="dontAsk">
                        ${$translate.instant('HID_CONSENT_DONT_ASK_AGAIN')}
                    </md-checkbox>
                </md-dialog-content>
                <md-dialog-actions layout="row">
                    <md-button ng-click="cancel()" class="md-primary">
                        ${$translate.instant('HID_CONSENT_CANCEL')}
                    </md-button>
                    <md-button id="web-hid-pair" ng-click="pair()" class="md-primary">
                        ${$translate.instant('HID_CONSENT_PAIR')}
                    </md-button>
                </md-dialog-actions>
            </md-dialog>`,
          controllerAs: '$ctrl',
          controller: ['$scope', ($scope) => {
            $scope.dontAsk = false;
            $scope.cancel = () => {
              localStorage.setItem(`${state.appName}-${decodedToken.user}_hid_dont_ask_again`, $scope.dontAsk);
              $mdDialog.hide();
              $ngRedux.dispatch(showHidConsent(false));
              alreadyShowing = false;
            };
            $scope.pair = async () => {
              localStorage.setItem(`${state.appName}-${decodedToken.user}_hid_dont_ask_again`, $scope.dontAsk);
              $mdDialog.hide();
              $ngRedux.dispatch(showHidConsent(false));
              alreadyShowing = false;
              await webHidPairing();
            };
          }],
        });
      }
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$translate',
];

export default {
  template,
  controller,
};

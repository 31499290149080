import { all } from 'redux-saga/effects';

import { sagaMiddleware } from './config';
import * as angularActions from '../angular';
import audio from '../middleware/audio';
import callHistory from '../middleware/callHistory';
import cards from '../middleware/cards';
import chat from '../middleware/chat';
import contacts from '../middleware/contacts';
import greetings from '../middleware/greetings';
import launchCall from '../middleware/launchCall';
import { init as initSaga } from '../init';
import stateHistory from '../middleware/stateHistory';
import voicemail from '../middleware/voicemail';
import numbers from '../middleware/numbers';

export let store = null;

export default ($ngRedux, $state, $mdToast, $translate, $mdDialog) => {
  store = $ngRedux;

  const sagas = function* () {
    yield all([
      audio(),
      callHistory(),
      cards(),
      chat(),
      contacts(),
      greetings(),
      initSaga(),
      launchCall(),
      stateHistory(),
      voicemail(),
      numbers(),
    ]);
  };

  sagaMiddleware.run(sagas);

  console.log('$mdDialog', $mdDialog);

  // add angular objects
  store.dispatch(angularActions.setObject('$state', $state));
  store.dispatch(angularActions.setObject('$mdToast', $mdToast));
  store.dispatch(angularActions.setObject('$translate', $translate));
  store.dispatch(angularActions.setObject('$mdDialog', $mdDialog));
};

import template from './template.html';
import { getCallStats } from '../../../../core/agent-center';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe = null;
  this.queueColors = ['blue', 'green', 'yellow', 'red', 'purple'];

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      callstats: getCallStats(state),
    }))((state) => {
      this.barformat = this.barFormat(state.callstats);
      this.donutformat = this.donutFormat(state.callstats);
      this.totalcalls = this.totalCalls(state.callstats);
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.donutFormat = (callstats) => {
    const donutStats = [
      ['Task', 'Hours per Day'],
    ];
    callstats.forEach((item) => {
      donutStats.push([item[0], item[1]]);
    });
    return donutStats;
  };

  this.barFormat = (callstats) => {
    const barStats = [];
    callstats.forEach((item, i) => {
      barStats.push({ by_sub: item[0], volume: item[1], color: this.queueColors[i] });
    });
    return barStats;
  };

  this.totalCalls = (callstats) => callstats.reduce((acc, cur) => acc + cur[1], 0);
}

import './styles.css';
import template from './template.html';
import { filteredBy, getCallsFilteredBy } from '../../../../core/call-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$translate',
];

function controller(
  $ngRedux,
  $translate,
) {
  let unsubscribe = null;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({ filteredBy: getCallsFilteredBy(state) }))((state) => {
      this.filteredBy = state.filteredBy;
      _setCallHistoryLabel(this.filteredBy);
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param name
     */
  this.filterBy = (name) => {
    $ngRedux.dispatch(filteredBy(name));
  };

  /**
     *
     * @param label
     * @private
     */
  const _setCallHistoryLabel = (label) => {
    switch (label) {
      case 'all': this.callHistoryMenuLabel = $translate.instant('CALL_HISTORY'); break;
      case 'missed': this.callHistoryMenuLabel = $translate.instant('MISSED'); break;
      case 'inbound': this.callHistoryMenuLabel = $translate.instant('INBOUND'); break;
      case 'outbound': this.callHistoryMenuLabel = $translate.instant('OUTBOUND'); break;
      default: this.callHistoryMenuLabel = label;
    }
  };
}

import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import './styles.css';
import template from './template.html';
import * as chatActions from '../../core/chat';
import {
  getCard, getCardId, newCard, setExpanded,
} from '../../core/card-management';
import { getUser } from '../../core/user';
import { getCurrentState, navStackPush } from '../../core/state-history';

function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe;
  this.loaded = false;
  this.glued = true;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      loaded: !chatActions.getLoading(state),
      screenSize: state.screenSize,
      sessionsList: chatActions.getSessions(state),
      user: getUser(state),
      dateFormat: state.configs.get('portalLocalizationDateFormat'),
      timeFormat: state.configs.get('portalLocalizationTimeFormat'),
    }))(this);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param session
     * @return {boolean}
     */
  this.boldLastMessage = (session) => {
    // test if the status is unread
    if (session.lastStatus === 'unread') {
      if (session.lastSender === this.user.uid) {
        return false;
      } if (this.user.sms) {
        // search for sms number match
        for (let i = 0; i < this.user.sms.length; i += 1) {
          if (session.lastSender === this.user.sms[i].number) {
            return false;
          }
        }
        return true;
      }
      return true;
    }
    return false;
  };

  /**
     *
     * @param session
     */
  this.openChat = (session) => {
    // flag message as read
    $ngRedux.dispatch(chatActions.updateLastRead(session.id));

    // pre-build api call for sending messages
    // sms
    const sendMessageParams = { sessionId: session.id };
    if (session.smsani && session.remote.indexOf('@') === -1) {
      if (session.remote.includes(',')) {
        // for groupMMS
        const destArrStr = session.remote.replace(/['"\][]+/g, '');// will be string of remote numbers, comma seperated
        sendMessageParams.type = 'sms';

        sendMessageParams.destination = destArrStr;
        sendMessageParams.fromNum = nsUtils.cleanPhoneNumber(session.smsani);
      } else {
        // for regular sms
        sendMessageParams.destination = nsUtils.cleanPhoneNumber(session.remote);
        sendMessageParams.fromNum = nsUtils.cleanPhoneNumber(session.smsani);
      }
    }
    // setting sendMessageParams by group, getting remote user list
    else if (session.remote.indexOf(',') !== -1) {
      let remote = session.remote.replace(/['"\][]+/g, '');
      remote = remote.replace(new RegExp(`@${this.user.domain}`, 'g'), '');
      sendMessageParams.remote = session.remote.replace(/['"\][]+/g, '');
      sendMessageParams.destination = remote;
      sendMessageParams.fromUID = this.user.uid;
      sendMessageParams.type = 'chat';
    } else {
      // by extension
      sendMessageParams.destination = session.remote.split('@')[0];
      sendMessageParams.fromUID = this.user.uid;
    }

    // build card meta
    const cardMeta = {
      sendMessageParams,
      sessionId: session.id,
    };

    // might need to set a participants array in the cardMeta
    // add conatct id if contact is set AND is NOT group
    if (session.contact && (session.remote.indexOf(',') === -1)) {
      cardMeta.contactId = session.contact.id;
    }

    let cardId = getCardId(cardMeta);

    if (!cardId) {
      $ngRedux.dispatch(chatActions.fetchMessages(session.id));
      $ngRedux.dispatch(newCard(cardMeta));
      cardId = getCardId(cardMeta);
    }

    $ngRedux.dispatch(setExpanded(cardId));

    if (this.screenSize.display === 'compact') {
      const state = getCurrentState($ngRedux.getState());
      const card = getCard($ngRedux.getState(), cardId);
      $ngRedux.dispatch(
        navStackPush({
          name: 'wrapper.chat-session',
          params: { card },
          fromName: state.name,
          fromParams: state.params,
        }),
      );
      $state.go('wrapper.chat-session', { card });
    }
  };
}

controller.$inject = [
  '$ngRedux',
  '$state',
];

export default {
  template,
  controller,
};

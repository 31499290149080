import { currentState } from './core/state-history';

export default (
  $location,
  $ngRedux,
  $mdDialog,
  $rootScope,
  $state,
  $transitions,
) => {
  // make the $state object available to the $rootScope/views
  $rootScope.$state = $state;

  $transitions.onBefore('*', (state) => {
    if ($ngRedux.getState().stateHistory.get('init')) {
      $ngRedux.dispatch(currentState({
        name: state.targetState().name(),
        params: state.targetState().params() || null,
        fromName: state.from().name,
        fromParams: state.from().params || null,
      }));
      window.ga('send', 'event', 'route change', state.targetState().name());
    }
  });
};

import { Map } from 'immutable';

/** ********************************************
 * Redux constants
 ******************************************** */

export const INIT = 'core/INIT';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({});

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

export const init = () => ({
  type: INIT,
});

import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import './styles.css';
import template from './template.html';
import { getConfig } from '../../core/configs';

function controller(
  $mdDialog,
  $ngRedux,
  $translate,
) {
  let unsubscribe = null;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      apiHash: getConfig(state, 'apiHash'),
      pathname: getConfig(state, 'pathname'),
      screenSize: state.screenSize,
      wpName: getConfig(state, 'wpName'),
    }))(this);
    this.spinner = false;
    this.loginName = '';
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.send = (form) => {
    if (form.$valid) {
      this.spinner = true;

      const decodedApiHash = nsUtils.encoding.base64Decode(this.apiHash);
      const client_id = decodedApiHash.split(':');

      nsApi.post({
        object: 'email',
        action: 'create',
        subject: `Your password ${this.wpName} recovery`,
        template: 'password_reset_email.php',
        username: form.loginName.$viewValue,
        client_id,
        app_uri: `${global.location.origin}${this.pathname}?username=<USERNAME>&auth_code=<AUTH_CODE>`,
      }).then((res) => {
        if (res.sent) {
          $mdDialog.show($mdDialog.alert()
            .clickOutsideToClose(true)
            .textContent($translate.instant('AN_EMAIL_HAS_BEEN_SENT_TO_THE_SPECIFIED_ADDRESS.'))
            .ok('OK'));
        } else {
          $mdDialog.show($mdDialog.alert()
            .clickOutsideToClose(true)
            .textContent(
              'There was a problem retrieving your account. Please contact your administrator.',
            )
            .ok('OK'));
        }

        this.spinner = false;
      }).catch(() => {
        $mdDialog.show($mdDialog.alert()
          .clickOutsideToClose(true)
          .textContent(
            'There was a problem retrieving your account. Please contact your administrator.',
          )
          .ok('OK'));
        this.spinner = false;
      });
    }
  };
}

controller.$inject = [
  '$mdDialog',
  '$ngRedux',
  '$translate',
];

export default {
  template,
  controller,
};

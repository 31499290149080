/* eslint-disable no-console */
import { Map } from 'immutable';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import {
  Registerer, UserAgent, SIPExtension, Inviter, RegistererState,
} from 'sip.js';

import { setDeviceRegistered } from '../extension';
import CallSession from '../sessions/models/CallSession';
import * as sessionActions from '../sessions'; // { add, putSessionsOnHold, setActiveId }
import { activeCount, getActiveCall, getActiveCount } from '../sessions';
import { stripCodecs } from '../sessions/utils/stripCodecs';
import * as audioActions from '../audio';
import { matchContact } from '../contacts';
import { getObject } from '../angular';
import { store } from '../store';

import * as cardActions from '../card-management';
import * as navActions from '../state-history';
import * as userMedia from '../user-media';
import { addCall } from '../user-media';

/** ********************************************
 * Redux constants
 ******************************************** */

export const IC = 'core/ua/IC';
export const IC_UPDATED = 'core/ua/IC_UPDATED';
export const UA = 'core/ua/UA';
export const TS = 'core/ua/TIMESTAMP';
export const REGISTERER = 'core/ua/REGISTERER';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  incomingCalls: [],
  ua: null,
  ts: new Date().getTime(),
  registerer: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case IC:
      return state.set('incomingCalls', action.payload);
    case IC_UPDATED: {
      const sessions = state.get('incomingCalls');
      return state.set('incomingCalls', [...sessions]);
    }
    case UA:
      return state.set('ua', action.payload);
    case TS:
      return state.set('ts', action.payload);
    case REGISTERER:
      return state.set('registerer', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

export const initUA = () => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  let sipTrace = localStorage.getItem(`${appName}_sip-trace`);
  let sipLog = localStorage.getItem(`${appName}_sip-log`);

  if (sipTrace === null) {
    sipTrace = false;
    localStorage.setItem(`${appName}_sip-trace`, 'false');
  } else if (sipTrace === 'true') {
    sipTrace = true;
  } else if (sipTrace === 'false') {
    sipTrace = false;
  }
  if (sipLog === null) {
    sipLog = 'error';
    localStorage.setItem(`${appName}_sip-log`, sipLog);
  } else {
    const validLogLevels = ['debug', 'log', 'warn', 'error'];
    if (!validLogLevels.includes(sipLog)) {
      console.error(
        `misconfigured logLevel: please set localStorage value:${appName}_sip-log to (debug|log|warn|error)`,
      );
      sipLog = 'debug';
    }
  }

  const codecsAudio = getState().configs.get('codecsAudio');
  const device = getState().devices.get('audioDevice');
  const iceCheckingTimeout = getState().configs.get('iceCheckingTimeout');
  const uaSocketURLs = getState().configs.get('uaSocketURLs');
  const version = getState().configs.get('version');
  const wpName = getState().configs.get('wpName');
  const stunServers = getState().configs.get('stunServers');

  const UserAgentOptions = {
    uri: UserAgent.makeURI(device.aor),
    transportOptions: { server: uaSocketURLs[0].ws_uri || uaSocketURLs[0] },
    codecsVideo: false,
    displayName: device.sub_fullname,
    iceGatheringTimeout: iceCheckingTimeout,
    logLevel: sipLog,
    noAnswerTimeout: 180,
    authorizationUsername: device.aor,
    authorizationPassword: device.authentication_key,
    register: true,
    sipExtension100rel: SIPExtension.Supported,
    sipExtensionReplaces: SIPExtension.Supported,
    traceSip: sipTrace,
    userAgentString: `${wpName} ${version} (${nsBrowser.name} ${nsBrowser.fullVersion})`,
  };

  if (stunServers) {
    UserAgentOptions.stunServers = stunServers.split(/[, ]+/);
  } else {
    UserAgentOptions.stunServers = ['stun.l.google.com:19302'];
  }

  if (codecsAudio) {
    UserAgentOptions.codecsAudio = codecsAudio;
  }

  const ua = new UserAgent(UserAgentOptions);

  // const registererOptions: RegistererOptions = { /* ... */ };

  /*
   * Setup handling for incoming INVITE requests
   */
  ua.delegate = {
    onInvite(invitation) {
      console.log('ua.delegate - onInvite');
      window.ga('send', 'event', 'call', 'inbound');

      // [WP-677] limit calls
      if (getState().sessions.get('activeCount') >= getState().configs.get('maxActiveCalls')) {
        return;
      }

      // An Invitation is a Session
      const incomingSession = invitation;

      // get the user or phone number
      const devicePostFix = getState().configs.get('devicePostFix');
      const userId = incomingSession.remoteIdentity.uri.user.replace(devicePostFix, '');

      // new call session
      const callSession = new CallSession({
        direction: 'inbound',
        id: incomingSession.id,
        isMuted: false,
        number: userId,
        name: incomingSession.remoteIdentity.displayName || null,
        session: incomingSession,
        status: 'inboundProgressing',
        userId,
        from_tag: incomingSession.request.fromTag, // WP-781, WP-1088, WP-1264
      });

      // Setup incoming session delegate
      // incomingSession.delegate = {
      //   // Handle incoming REFER request.
      //   onRefer(referral: Referral){}
      //  };

      audioActions.updateElementSinkId();

      const contact = matchContact(userId);
      const isConferenceInbound = incomingSession.remoteIdentity.uri.user.includes('adhoc');

      // add contact if found
      if (contact) {
        callSession.contact = contact;
        callSession.name = callSession.contact.name;
      } else if (isConferenceInbound) {
        callSession.name = 'Multiple Callers';
        callSession.auto_ans = true;
        dispatch(acceptInvite(callSession));
        dispatch(sessionActions.add(callSession));
        addCall(); // notify hid device
        return;
      }

      // add session to sessions array
      dispatch(sessionActions.add(callSession));

      // dispatch incoming call to trigger modal
      dispatch(addIc(callSession));

      // notify hid device
      const callControl = store.getState().userMedia.get('hidDevice');
      if (callControl) {
        callControl.signalIncomingCall().then((accepted) => {
          // make sure the call hasn't already been accepted by the webphone
          const activeCall = getActiveCall(store.getState());
          if (activeCall?.id !== callSession?.id) {
            if (accepted) {
              dispatch(acceptInvite(callSession, false));
            } else {
              callSession.reject(false);
              dispatch(removeIc(callSession.id));
            }
          }
        }).catch(console.error);
      }

      setTimeout(() => {
        dispatch(updateTS()); // This will force a Update to the incoming calls
      }, 1200);

      setTimeout(() => {
        dispatch(updateTS()); // This will force a Update to the incoming calls
      }, 2200);
    },
    onConnect() {
      // On connecting, register the user agent
      const registerer = getState().ua.get('registerer');
      // debugger;
      if (registerer && registerer.state !== 'Terminated' && !registerer.disposed) {
        registerer.register()
          .catch((e) => {
            // debugger;
            if (e) {
              console.log(e);
              dispatch(attemptReconnection(ua));
            }
            // Register failed
          });
      } else {
        dispatch(addRegistrar(ua));
      }
    },
    onDisconnect(error) {
      // On disconnect, cleanup invalid registrations
      const registerer = getState().ua.get('registerer');
      registerer.unregister()
        .catch((e) => {
          console.log(e);
          // Unregister failed
        });
      // Only attempt to reconnect if network/server dropped the connection (if there is an error)
      if (error) {
        dispatch(attemptReconnection(ua));
      }
    },
  };

  /*
   * Start the user agent
   */
  ua.start().then(() => {
    // dispatch(addRegistrar(ua));
    console.log('ua', ua);
  })
    .catch((error) => {
      console.error('Failed to Connect', error);
      dispatch({
        type: UA,
        payload: false,
      });
      dispatch(setDeviceRegistered(false));
      dispatch(attemptReconnection(ua));
    });

  // eslint-disable-next-line consistent-return
  window.onbeforeunload = () => {
    // const push = getState().push.get('pushSubscription');
    // const device                = getState().devices.get('audioDevice');
    // const device2                = getState().devices.selectPush();
    // const push = select(state => state.push.getSubscription());

    // unsubscribe from push on reload, TODO remove, this is only for testing
    // dispatch(pushActions.unsubscribePush());

    // dispatch(unRegister());

    // display alert if there are any active calls
    // https://netsapiens.atlassian.net/browse/WP-1090
    if (activeCount(getState())) {
      return true;
    }
  };

  window.ononline = () => {
    console.log('Online attempt re-register');
  };
};

let registrationTimeoutTimer = null;

export const addRegistrar = (ua) => (dispatch) => {
  const registerer = new Registerer(ua);
  registerer.stateChange.addListener((newState) => {
    switch (newState) {
      case RegistererState.Initial:
        dispatch(setDeviceRegistered(false));
        break;
      case RegistererState.Registered:
        if (registrationTimeoutTimer) {
          clearTimeout(registrationTimeoutTimer);
          registrationTimeoutTimer = null;
        }
        dispatch(setDeviceRegistered(true));
        break;
      case RegistererState.Terminated:
        dispatch(setDeviceRegistered(false));
        break;
      case RegistererState.Unregistered:
        if (!registrationTimeoutTimer) {
          registrationTimeoutTimer = setTimeout(() => {
            ua.stop().then(() => {
              dispatch(setDeviceRegistered(false));
              dispatch(attemptReconnection(ua));
            });
            registrationTimeoutTimer = null;
          }, 3000);
        }
        break;
      default:
    }
  });
  dispatch({
    type: UA,
    payload: ua,
  });
  // Register the user agent
  registerer.register()
    .then(() => {
      dispatch({
        type: REGISTERER,
        payload: registerer,
      });
    })
    .catch((error) => {
      console.error('Failed to Register', error);
      dispatch({
        type: UA,
        payload: false,
      });
      dispatch(setDeviceRegistered(false));
      dispatch(attemptReconnection(ua));
    });
};

// Number of seconds to wait between reconnection attempts
const reconnectionDelay = 4;
// Used to guard against overlapping reconnection attempts
let attemptingReconnection = false;
let connectionAttempt = 0;

// Function which recursively attempts reconnection
const attemptReconnection = (ua) => (dispatch, getState) => {
  // Reconnection attempt already in progress
  if (attemptingReconnection) {
    return;
  }

  // We're attempting a reconnection
  attemptingReconnection = true;

  setTimeout(() => {
    const uaSocketURLs = getState().configs.get('uaSocketURLs');
    connectionAttempt += 1;
    console.log(`connectionAttempt= ${connectionAttempt}`);
    const serverListSize = uaSocketURLs.length || uaSocketURLs.length;
    const newIndex = connectionAttempt % serverListSize;

    // eslint-disable-next-line no-param-reassign
    ua.transport.configuration.server = uaSocketURLs[newIndex].ws_uri || uaSocketURLs[newIndex];
    console.log(`new server = ${ua.transport.configuration.server}`);
    // Attempt reconnect

    if (ua.state === 'Stopped') {
      console.log('UA stopped is starting...');
      ua.start().then(() => {
        dispatch(addRegistrar(ua));
        attemptingReconnection = false;
      }).catch((error) => {
        attemptingReconnection = false;
        console.error('Failed to start', error);
        dispatch({
          type: UA,
          payload: false,
        });
        dispatch(setDeviceRegistered(false));
        dispatch(attemptReconnection(ua));
      });
    } else {
      ua.reconnect().then(() => {
        console.log('reconnect success!');

        const registerer = getState().ua.get('registerer');

        if (!registerer) {
          dispatch(addRegistrar(ua));
        }

        // Reconnect attempt succeeded
        attemptingReconnection = false;
      }).catch((error) => {
        // Reconnect attempt failed
        console.log('reconnect failed!');
        if (error) console.error(error);
        attemptingReconnection = false;
        dispatch(attemptReconnection(ua));
      });
    }
  }, reconnectionDelay * 1000);
};

/**
 *
 * @param callSession
 * @return {function(*, *)}
 */
export const addIc = (callSession) => (dispatch, getState) => {
  const ic = getState().ua.get('incomingCalls');
  dispatch({
    type: IC,
    payload: ic.concat([callSession]),
  });
};

/**
 *
 * @returns {(function(*): void)|*}
 */
export const updateTS = () => (dispatch) => {
  dispatch({
    type: TS,
    payload: new Date().getTime(),
  });

  dispatch({
    type: IC_UPDATED,
  });
};

/**
 *
 * @param id
 * @return {function(*, *)}
 */
export const removeIc = (id) => (dispatch, getState) => {
  // debugger;
  const ic = getState().ua.get('incomingCalls');
  const filteredIc = [];
  for (let i = 0; i < ic.length; i += 1) {
    if (ic[i].id !== id) {
      filteredIc.push(ic[i]);
    }
  }
  dispatch({
    type: IC,
    payload: filteredIc,
  });
};

/**
 *
 * @returns {{type: string, payload: *}}
 */
export const icUpdated = () => ({
  type: IC_UPDATED,
});

/**
 *
 * @param call
 * @return {function(*, *)}
 */
export const acceptInvite = (call, signalHid = true) => (dispatch, getState) => {
  call.accept(signalHid);
  // get or create call card and set card to expand
  let cardId = cardActions.getCardId({ type: 'call' });
  if (!cardId) {
    dispatch(cardActions.newCard({ type: 'call' }));
    cardId = cardActions.getCardId({ type: 'call' });
  }
  dispatch(cardActions.setExpanded(cardId));

  // if compact and not already in the phone state, go to phone state
  const currentState = navActions.getCurrentState(getState());
  if (getState().screenSize.display === 'compact' && currentState.name !== 'wrapper.phone') {
    const card = cardActions.getCard(getState(), cardId);
    dispatch(
      navActions.callStackRebase({
        name: 'wrapper.phone',
        params: { card },
        fromName: null,
        fromParams: null,
      }),
    );
    const $state = getState().angular.get('$state');
    $state.go('wrapper.phone', { card });
  }
  dispatch(removeIc(call.id));

  audioActions.updateElementSinkId();
  setTimeout(audioActions.updateElementSinkId,250);
  setTimeout(audioActions.updateElementSinkId,1000);
  setTimeout(audioActions.updateElementSinkId,5000);
};

/**
 *
 * @param phoneNumber
 * @return {function(*, *)}
 */
export const sendInvite = (phoneNumber, genCard = true) => async (dispatch, getState) => {
  let directUri = null;
  if (phoneNumber.includes('sip:')) {
    directUri = phoneNumber;
  }

  // clean number
  phoneNumber = phoneNumber.replace(/[^0-9*#+]/g, '');

  if (phoneNumber.length < 1) {
    return;
  }

  const decodedToken = nsToken.getDecoded();

  // check if the browser has audio permission
  const hasPermission = getState().userMedia.get('hasPermission');
  if (!hasPermission) {
    return;
  }

  const devices = await userMedia.getMediaDevices();
  const appName = await getState().configs.get('appName');
  const inputDeviceId = userMedia.getDefaultAudioInput(appName, decodedToken.user, devices);
  // const outputDeviceId = userMedia.getDefaultAudioOutput(appName, decodedToken.user, devices);

  const uri = directUri || `sip:${phoneNumber}@${decodedToken.domain}`;

  const ua = getState().ua.get('ua');

  if (getActiveCount(getState().sessions.get('sessions').toArray()) < 1) {
    // set active id to null
    dispatch(sessionActions.setActiveId(null));
  }

  // put any previous call sessions on hold by checking if any are active
  dispatch(sessionActions.putSessionsOnHold());

  // match contact
  const contact = matchContact(phoneNumber);

  // Send an outgoing INVITE request
  const target = UserAgent.makeURI(uri.replace('#', '%23'));
  if (!target) {
    throw new Error('Failed to create target URI.');
  }

  // Create new Session instance in "initial" state
  const outgoingSession = new Inviter(ua, target);

  window.ga('send', 'event', 'call', 'outbound');

  // new session props
  const props = {
    contact,
    direction: 'outbound',
    id: outgoingSession.id,
    isMuted: false,
    number: phoneNumber,
    session: outgoingSession,
    status: 'outboundProgressing',
    from_tag: outgoingSession.fromTag, // WP-781, WP-1088
  };

  if (contact) {
    props.name = contact.name || null;
  }

  if (directUri && directUri.includes('adhoc')) {
    props.name = directUri;
  }

  // initial state - {isMuted: false, status: 'trying'},
  const callSession = new CallSession(props);

  // Options including delegate to capture response messages
  // Replaces attachOutboundHandlers
  const inviteOptions = {
    iceGatheringTimeout: 500,
    requestDelegate: {
      onAccept: (response) => {
        console.log('Positive response = ', response);

        audioActions.updateElementSinkId();
        setTimeout(audioActions.updateElementSinkId,250);
        setTimeout(audioActions.updateElementSinkId,1000);
        setTimeout(audioActions.updateElementSinkId,5000);
        // const pc = outgoingSession.sessionDescriptionHandler.peerConnection;
        // const remoteStream = new MediaStream();
        // pc.getReceivers().forEach((receiver) => {
        //   const { track } = receiver;
        //   if (track) {
        //     remoteStream.addTrack(track);
        //   }
        // });
        //
        // const audioElement = getAudio('remote');
        // audioElement.srcObject = remoteStream;
        //
        // if (DetectRTC.isSetSinkIdSupported) {
        //   audioElement.setSinkId(outputDeviceId);
        // }
        // audioElement.play();
      },
      onProgress: (response) => {
        if (response.message.statusCode) {
          const code = response.message.statusCode;
          if (code === 180) {
            audioActions.playRingback();
          } else {
            audioActions.playRingback();
          }
        }

        console.log('Positive response = ', response);
      },
      onReject: (response) => {
        if (response.message.statusCode) {
          console.log(`Negative response (code) = ${response.message.statusCode}`);

          if (response.message.statusCode === 407) return;
          if (response.message.statusCode === 487) return;

          const $mdToast = getObject(getState(), '$mdToast');
          const $translate = getObject(getState(), '$translate');
          const errorMessage = response.message.reasonPhrase || response.message.statusCode;

          if (response.message.statusCode === 486 || response.message.statusCode === 603) {
            audioActions.stopRingback();

            audioActions.playBusy();
            $mdToast.show(
              $mdToast
                .simple()
                .textContent($translate.instant('CALL_BUSY'))
                .position('bottom left')
                .hideDelay(6000),
            );
          } else if (response.message.statusCode >= 400) {
            audioActions.stopRingback();

            audioActions.playFastBusy();
            $mdToast.show(
              $mdToast
                .simple()
                .textContent(`${$translate.instant('CALL_ERROR')}: ${errorMessage}`)
                .position('bottom left')
                .hideDelay(6000),
            );
          }
        }

        console.log('Negative response (onReject) = ', response);
      },
      onTerminated: (response) => {
        console.log('Negative response (onTerminated)= ', response);
      },
    },
  };

  const handlerOptions = {
    iceGatheringTimeout: ua.options.iceGatheringTimeout,
    constraints: {
      audio: { deviceId: inputDeviceId },
      video: false,
    },
  };

  outgoingSession.sessionDescriptionHandlerOptions = handlerOptions;
  const allowedCodecs = store.getState().configs.get('PORTAL_WEBRTC_CODEC_LIST_AUDIO');
  outgoingSession.sessionDescriptionHandlerModifiers = [allowedCodecs && allowedCodecs.length > 0 ? stripCodecs(allowedCodecs) : null];
  outgoingSession.sessionDescriptionHandlerOptionsReInvite = handlerOptions;

  outgoingSession.invite(inviteOptions).then(() => {
    console.log('outgoingSession.invite');

    // add the new audio session
    dispatch(sessionActions.add(callSession));

    // set the active id as the new audio session
    dispatch(sessionActions.setActiveId(callSession.id));

    // notify HID device
    addCall();

    if (genCard) {
      let cardId = cardActions.getCardId({ type: 'call' });
      if (!cardId) {
        dispatch(cardActions.newCard({ type: 'call' }));
        cardId = cardActions.getCardId({ type: 'call' });
      }
      dispatch(cardActions.setExpanded(cardId));

      // if compact and not already in the phone state, go to phone state
      const currentState = navActions.getCurrentState(getState());
      if (getState().screenSize.display === 'compact' && currentState.name !== 'wrapper.phone') {
        const card = cardActions.getCard(getState(), cardId);
        dispatch(
          navActions.callStackRebase({
            name: 'wrapper.phone',
            params: { card },
            fromName: null,
            fromParams: null,
          }),
        );
        const $state = getState().angular.get('$state');
        $state.go('wrapper.phone', { card });
      }
    }
  }).catch((error) => {
    console.error(error);
  });
};

/**
 *
 * @return {function(*, *)}
 */
export const unRegister = () => (dispatch, getState) => {
  const registerer = getState().ua.get('registerer');
  if (registerer && registerer.state === RegistererState.Registered) {
    registerer.unregister().catch(() => {});
    dispatch(setDeviceRegistered(false));
  }
};

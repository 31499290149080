import './styles.css';
import template from './template.html';
import { filteredBy, getCallParkFilteredBy } from '../../../../core/call-park';

export default {
  template,
  controller,
  bindings: {
    context: '@', // modal
    workflow: '@', // in-call, in-call-transfer
  },
};

controller.$inject = [
  '$ngRedux',
  '$translate',
  '$state',
];

function controller(
  $ngRedux,
  $translate,
  $state,
) {
  let unsubscribe = null;

  /**
     *
     */
  this.$onInit = () => {
    this.workflow = this.workflow || $state.params.workflow;
    this.context = this.context || $state.params.context;
    this.menuClass = 'md-light';
    if (this.context === 'modal' || this.context === 'card') {
      this.menuClass = 'md-dark';
    }

    switch (this.workflow) {
      case 'in-call':
        this.menuClass = 'md-dark';
        break;
    }

    unsubscribe = $ngRedux.connect((state) => ({ filteredBy: getCallParkFilteredBy(state) }))((state) => {
      this.filteredBy = state.filteredBy;
      _setCallParkLabel(this.filteredBy);
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param name
     */
  this.filterParksBy = (name) => {
    $ngRedux.dispatch(filteredBy(name));
  };

  /**
     *
     * @param label
     * @private
     */
  const _setCallParkLabel = (label) => {
    switch (label) {
      case 'all': this.callParkMenuLabel = $translate.instant('CALL_PARK'); break;
      case 'site': this.callParkMenuLabel = $translate.instant('SITE'); break;
      case 'department': this.callParkMenuLabel = $translate.instant('DEPARTMENT'); break;
      default: this.callParkMenuLabel = label;
    }
  };
}

import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import _ from 'lodash';
// import hark         from 'hark';
import DetectRTC from 'detectrtc';

import './styles.css';
import template from './template.html';
import { getConfig } from '../../core/configs';
import { getRingtones, getSelectedRingtone, getSelectedCallWaiting } from '../../core/ringtones';
import * as navActions from '../../core/state-history';
import * as userMedia from '../../core/user-media';
import { updateElementSinkId } from '../../core/audio';
import { getSessions } from '../../core/sessions';

function controller(
  $ngRedux,
  $scope,
  $state,
) {
  let unsubscribe;
  let deviceInterval;
  let audioStream;
  // let speechEvents;
  let user;

  /**
     *
     */
  this.$onInit = async () => {
    unsubscribe = $ngRedux.connect((state) => ({
      callSessions: getSessions(state),
      appName: state.configs.get('appName'),
      callFrom: state.devices.get('callFromDevice'),
      ringtones: getRingtones(state),
      screenSize: state.screenSize,
      selectedRingtone: getSelectedRingtone(state),
      version: getConfig(state, 'version'),
      selectedCallWaiting: getSelectedCallWaiting(state),
      hasPermission: state.userMedia.get('hasPermission'),
      inputDevice: state.userMedia.get('inputDevice'),
      inputDevices: state.userMedia.get('inputDevices'),
      outputDevice: state.userMedia.get('outputDevice'),
      outputDeviceRinger: state.userMedia.get('outputDeviceRinger'),
      outputDevices: state.userMedia.get('outputDevices'),
      pwaEnabled: (state.configs.get('PORTAL_WEBPHONE_ENABLE_PWA') === 'yes'),
    }))((state) => {
      this.callSessions = state.callSessions;
      this.appName = state.appName;
      this.callFrom = state.callFrom;
      this.ringtones = state.ringtones;
      this.screenSize = state.screenSize;
      this.selectedRingtone = state.selectedRingtone;
      this.version = state.version;
      this.selectedCallWaiting = state.selectedCallWaiting;
      this.hasPermission = state.hasPermission;
      this.inputDevice = state.inputDevice;
      this.inputDevices = state.inputDevices;
      this.outputDevice = state.outputDevice;
      this.outputDeviceRinger = state.outputDeviceRinger;
      this.outputDevices = state.outputDevices;
      this.pwaEnabled = state.pwaEnabled;
      this.selectedInputAudioDevice = _.find(
        state.inputDevices,
        { deviceId: state.inputDevice },
      );
      this.selectedOutputAudioDevice = _.find(
        state.outputDevices,
        { deviceId: state.outputDevice },
      );
      this.selectedOutputAudioDeviceRinger = _.find(
        state.outputDevices,
        { deviceId: state.outputDeviceRinger },
      );
    });

    // show or hide audio output configuration
    this.isSetSinkIdSupported = DetectRTC.isSetSinkIdSupported;
    this.isChrome = nsBrowser.name === 'Chrome';

    // will not display the install_pwa button,
    // deferredPrompt is set to not null if the before beforeinstallprompt was called
    this.pwaInstalled = (deferredPrompt == null);

    user = nsToken.getDecoded().user;
  };

  /**
   *
   */
  this.$onDestroy = () => {
    unsubscribe();

    if (deviceInterval) {
      clearInterval(deviceInterval);
    }

    if (!_.size(this.callSessions)) {
      if (audioStream) userMedia.stopStream(audioStream);
      $ngRedux.dispatch(userMedia.stopStreams());
    }

    if (audioStream) {
      audioStream = null;
    }

    // if (speechEvents) {
    //     speechEvents.stop();
    // }
  };

  /**
   *
   * @param route
   */
  this.navPush = (route) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: route.name,
        params: route.params || null,
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go(route.name, route.params || null);
  };

  /**
   *
   */
  this.installPwaPrompt = () => {
    // eslint-disable-next-line no-undef
    deferredPrompt.prompt();

    // eslint-disable-next-line no-undef
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  };

  /**
   *
   * @param device
   */
  this.setInputDevice = async (device) => {
    const deviceId = _.get(device, 'deviceId');

    if (deviceId !== this.inputDevice) {
      localStorage.setItem(`${this.appName}-${user}_inputAudioDevice`, deviceId);
      $ngRedux.dispatch(userMedia.inputDevice(deviceId));

      // set output device the same as input if possible
      const outputDevice = _.find(this.outputDevices, { label: _.get(device, 'label') });
      if (outputDevice) {
        this.setOutputDevice(outputDevice);
      }

      userMedia.setCallControlByDeviceId(deviceId);

      if (audioStream) {
        userMedia.stopStream(audioStream);
        audioStream = null;
      }

      // if (speechEvents) {
      //     speechEvents.stop();
      // }

      audioStream = await userMedia.getAudioStream(deviceId);
      $ngRedux.dispatch(userMedia.addStream(audioStream));

      // speechEvents = hark(audioStream, { interval: 100 });
      //
      // speechEvents.on('volume_change', (level) => {
      //     this.energy = level + 100;
      //     $scope.$evalAsync();
      // });

      const newTrack = audioStream.getTracks()[0];

      newTrack.onended = () => {
        audioStream = null;
        newTrack.stop();
        // speechEvents.stop();
      };

      if (this.callSessions) {
        this.callSessions.forEach((callSession) => {
          // todo update hid signal subscriptions
          const pc = callSession.session.sessionDescriptionHandler.peerConnection;
          if (pc.getSenders()) {
            pc.getSenders().forEach((sender) => {
              sender.replaceTrack(newTrack);
            });
          }
        });
      }
    }
  };

  /**
   *
   * @param device
   */
  this.setOutputDevice = (device) => {
    this.selectedOutputAudioDevice = device;
    const deviceId = _.get(device, 'deviceId');
    localStorage.setItem(`${this.appName}-${user}_outputAudioDevice`, deviceId);
    $ngRedux.dispatch(userMedia.outputDevice(deviceId));
    //updateElementSinkId(); // update the output audio elements sinkId
    setTimeout(updateElementSinkId, 100);
  };

  this.setOutputDeviceRinger = (device) => {
    this.selectedOutputAudioDeviceRinger = device;
    const deviceId = _.get(device, 'deviceId');
    localStorage.setItem(`${this.appName}-${user}_outputAudioDeviceRinger`, deviceId);
    $ngRedux.dispatch(userMedia.outputDeviceRinger(deviceId));
    //updateElementSinkId(); // update the output audio elements sinkId
    setTimeout(updateElementSinkId, 100);
  };
}

controller.$inject = [
  '$ngRedux',
  '$scope',
  '$state',
];

export default {
  template,
  controller,
};

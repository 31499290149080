import { put, select, takeEvery } from 'redux-saga/effects';

import * as chatActions from '../chat';
import { CONTACTS } from '../contacts';

export default function* () {
  yield takeEvery(CONTACTS, _fetchNumbers);
}

const _fetchNumbers = function* () {
  // get MMS capable numbers
  const length = yield select((state) => state.chat.get('mmsCapableNumbers').length);
  if (!length) {
    yield put(chatActions.fetchMmsCapableNumbers());
  }

  // get GROUP MMS capable numbers
  const groupLength = yield select((state) => state.chat.get('groupMmsCapableNumbers').length);
  if (!groupLength) {
    yield put(chatActions.fetchGroupMmsCapableNumbers());
  }
};

export default () => ({
  restrict: 'A',
  link(scope, elem) {
    elem.bind('click', (e) => {
      e.preventDefault();

      // backwards compatible
      if (typeof e.stopPropagation === 'function') {
        e.stopPropagation();
      }
    });
  },
});

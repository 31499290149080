import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import './styles.css';
import template from './template.html';
import { authenticated } from './redux';
import { getConfig } from '../../core/configs';

function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe;
  const self = this;
  self.formMessage = '';
  self.spinner = false;
  self.submit = submit;
  self.newPassword = '';
  self.confirm = '';
  self.passReq = '';
  self.testPassRegx = testPassRegx();
  self.testConfRegx = testConfRegx();

  this.$onInit = () => {
    this.prAuthCode = $state.params.prAuthCode;

    unsubscribe = $ngRedux.connect((state) => ({
      appName: getConfig(state, 'appName'),
      portalAllowExtInPass: getConfig(state, 'portalAllowExtInPass'),
      screenSize: state.screenSize,
      securePassForceNum: getConfig(state, 'securePassForceNum'),
      securePassMinCapCnt: getConfig(state, 'securePassMinCapCnt'),
      securePassMinLength: getConfig(state, 'securePassMinLength'),
      securePassMinNumCnt: getConfig(state, 'securePassMinNumCnt'),
      securePassMinSpecialCharCnt: getConfig(state, 'securePassMinSpecialCharCnt'),
      securePassStrong: getConfig(state, 'securePassStrong'),
    }))((state) => {
      this.securePassForceNum = state.securePassForceNum;
      this.securePassMinCapCnt = state.securePassMinCapCnt;
      this.securePassMinLength = state.securePassMinLength;
      this.securePassMinNumCnt = state.securePassMinNumCnt;
      this.securePassMinSpecialCharCnt = state.securePassMinSpecialCharCnt;
      this.securePassStrong = state.securePassStrong;
      this.portalAllowExtInPass = state.portalAllowExtInPass;

      if (self.passReq === '') {
        self.passReq = getPassReq();
      }
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  function getPassReq() {
    let str = `Minimum length of ${self.securePassMinLength} characters, `;
    if (self.securePassMinCapCnt
      && _.toNumber(self.securePassMinCapCnt)
    ) {
      str += `minimum of ${self.securePassMinCapCnt} capital letter(s), `;
    }
    if (self.securePassMinNumCnt
      && _.toNumber(self.securePassMinNumCnt)
    ) {
      str += `minimum of ${self.securePassMinNumCnt} number(s), `;
    }
    if (self.securePassMinSpecialCharCnt
      && _.toNumber(self.securePassMinSpecialCharCnt)
    ) {
      str += `minimum of ${self.securePassMinSpecialCharCnt} special character(s), `;
    }
    if (self.securePassForceNum && self.securePassForceNum !== 'no') {
      str += 'numbers only, ';
    }
    if (self.securePassStrong !== 'no' && self.portalAllowExtInPass !== 'yes') {
      str += 'your extension cannot be part of your password, ';
    }
    return `${str.substring(0, str.length - 2)}.`;
  }

  function testPassRegx() {
    return {
      test(value) {
        if (value.length < self.securePassMinLength) {
          self.passErr = `Minimum length of ${self.securePassMinLength} characters required`;
          return false;
        }

        if (self.securePassMinNumCnt) {
          const numCount = (value.match(/\d/g) || []).length;
          if (!numCount || numCount < self.securePassMinNumCnt) {
            self.passErr = `Minimum of ${self.securePassMinNumCnt} number(s) required`;
            return false;
          }
        }

        if (self.securePassMinCapCnt) {
          const capsCount = (value.match(/[A-Z]/g) || []).length;
          if (!capsCount || capsCount < self.securePassMinCapCnt) {
            self.passErr = `Minimum of ${self.securePassMinCapCnt} capital letter(s) required`;
            return false;
          }
        }

        if (self.securePassMinSpecialCharCnt) {
          const cnt = new RegExp(`[!"#$%&'()*+,-.:;<=>?@[\\]^_\`{|}~]{${self.securePassMinSpecialCharCnt}}`, 'g');
          if (!cnt.test(value)) {
            self.passErr = `Minimum of ${self.securePassMinSpecialCharCnt} special character(s)`;
            return false;
          }
        }
        return true;
      },
    };
  }

  function testConfRegx() {
    return {
      test(value) {
        if (value !== self.newPassword) {
          self.confErr = 'The new password and password confirmation must match';
          return false;
        }
        return true;
      },
    };
  }

  function submit(form) {
    self.formMessage = '';
    if (form.$valid) {
      self.spinner = true;
      const decodedToken = nsToken.getDecoded();
      nsApi.post({
        object: 'subscriber',
        action: 'update',
        domain: decodedToken.domain,
        user: decodedToken.user,
        pwd_hash: form.password.$viewValue,
        subscriber_login: decodedToken.login,
        auth_code: $state.params.prAuthCode,
      }).then(() => {
        self.spinner = false;
        $ngRedux.dispatch(authenticated());
      }).catch(() => {
        // todo error message
        self.spinner = false;
      });
    }
  }
}

controller.$inject = [
  '$ngRedux',
  '$state',
];

export default {
  template,
  controller,
  bindings: {
    prAuthCode: '@', // auth code
  },
};

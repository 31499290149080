import _ from 'lodash';
import { Map } from 'immutable';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import naturalSort from 'javascript-natural-sort';

/** ********************************************
 * Redux constants
 ******************************************** */

export const ANSWERING_RULES = 'core/answering-answering-rule-list/ANSWERING_RULES';
export const LOADING = 'core/answering-answering-rule-list/LOADING';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  answeringRules: nsUtils.normalizeList('id', []),
  loading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ANSWERING_RULES:
      return state.set('answeringRules', nsUtils.normalizeList('id', action.payload));
    case LOADING:
      return state.set('loading', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {function(*=)}
 */
export const fetchAnsweringRules = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });

  const decodedToken = nsToken.getDecoded();

  const params = {
    object: 'answerrule',
    action: 'read',
    uid: decodedToken.sub,
    user: decodedToken.user,
    domain: decodedToken.domain,
  };

  return nsApi.get(params).then((res) => {
    _prepList(res).then((list) => {
      dispatch({
        type: ANSWERING_RULES,
        payload: list,
      });
      dispatch({
        type: LOADING,
        payload: false,
      });
    });
  }).catch(() => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @param rule
 * @return {function(*)}
 */
export const updateEnabled = (rule) => () => {
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'answerrule',
    action: 'update',
    user: decodedToken.user,
    domain: decodedToken.domain,
    time_frame: rule.time_frame,
    enable: rule.enable,
  };
  nsApi.post(params);
};

/**
 *
 * @param answer_rule
 * @return {function(*, *)}
 */
export const deleteAnswerRule = (answer_rule) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'answerrule',
    action: 'delete',
    uid: decodedToken.sub,
    user: decodedToken.user,
    domain: decodedToken.domain,
    time_frame: answer_rule,
  };
  nsApi.post(params).then(() => {
    const decodedToken = nsToken.getDecoded();
    const params = {
      object: 'answerrule',
      action: 'read',
      uid: decodedToken.sub,
      user: decodedToken.user,
      domain: decodedToken.domain,
    };
    return nsApi.get(params).then((res) => {
      _prepList(res).then((list) => {
        dispatch({
          type: ANSWERING_RULES,
          payload: list,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
      });
    });
  });
};

export const updatePriority = (list) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'answerrule',
    action: 'reorder',
    user: decodedToken.user,
    domain: decodedToken.domain,
    uid: decodedToken.sub,
    priority: list,
    application: 'webphone',
  };
  nsApi.post(params).then(() => {
    const decodedToken = nsToken.getDecoded();
    const params = {
      object: 'answerrule',
      action: 'read',
      uid: decodedToken.sub,
      user: decodedToken.user,
      domain: decodedToken.domain,
    };
    return nsApi.get(params).then((res) => {
      _prepList(res).then((list) => {
        dispatch({
          type: ANSWERING_RULES,
          payload: list,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
      });
    }).catch(() => {
      dispatch({
        type: LOADING,
        payload: false,
      });
    });
  });
};
/**
 *
 * @param rule
 * @return {function(*)}
 */
export const updateSimring = (rule) => () => {
  const decodedToken = nsToken.getDecoded();

  const params = {
    object: 'answerrule',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    time_frame: '*',
    enable: true,
    sim_control: 'e',
  };

  if (!rule.sim_parameters) {
    params.sim_parameters = '<OwnDevices>';
  } else if (rule.sim_parameters.indexOf('<OwnDevices>') === -1) {
    params.sim_parameters = `${rule.sim_parameters} <OwnDevices>`;
  } else {
    params.sim_parameters = rule.sim_parameters;
  }

  nsApi.post(params);
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @return {[]}
 */
export const getAnsweringRules = (state) => state.answeringRules.get('answeringRules').toArray();

/**
 *
 * @param state
 * @param id
 * @return {*}
 */
export const getAnsweringRule = (state, id) => {
  const answeringRule = state.answeringRules.get('answeringRules').entities[id] || null;
  if (typeof (answeringRule.sim_parameters) === 'object') {
    return answeringRule;
  }
  // convert to object to use ng-repeat
  let simParams = [];
  simParams = answeringRule.sim_parameters.split(' ');
  // add x to each number
  for (let i = 0; i < simParams.length; i += 1) {
    if (simParams[i] != '<OwnDevices>' || simParams[i].length < 4) {
      if (simParams[i] == '') {
        simParams[i] = `x${answeringRule.user}`;
      } else {
        simParams[i] = `x${simParams[i]}`;
      }
    } else if (simParams[i] === '<OwnDevices>') {
      // remove string <OwnDevices> from array
      simParams.splice(i, 1);
    }
  }
  answeringRule.sim_parameters = simParams;
  return answeringRule;
};

/**
 *
 * @param state
 * @return {*}
 */
export const getEnabled = (state) => {
  const answeringRules = state.answeringRules.get('answeringRules').toArray();
  let answeringRule = null;
  for (let i = 0; i < answeringRules.length; i += 1) {
    if (answeringRules[i].active) {
      answeringRule = answeringRules[i];
      break;
    }
  }
  return answeringRule;
};

/**
 *
 * @param state
 * @return {boolean}
 */
export const getLoading = (state) => state.answeringRules.get('loading');

/** ********************************************
 * Private Helper functions
 ******************************************** */

/**
 *
 * @param answeringRules
 * @return Promise
 * @private
 */
function _prepList(answeringRules) {
  return new Promise((resolve, reject) => {
    answeringRules = _.compact(answeringRules);

    if (!answeringRules.length) {
      resolve([]);
    }

    _getDevices(answeringRules)
      .then((arList) => _getSubscribers(arList, 'for')
        .then((list) => _getSubscribers(list, 'fbu'))
        .then((list) => _getSubscribers(list, 'fna'))
        .then((list) => _getSubscribers(list, 'fnr'))).then((arList) => {
        for (let i = 0; i < arList.length; i += 1) {
          arList[i].id = nsUtils.randomId();

          if (arList[i].for_control === 'e') {
            arList[i].for_number = arList[i].for_user.replace('vmail_', '');
            arList[i].for_number = arList[i].for_number.replace('user_', '');
            arList[i].for_number = arList[i].for_number.replace('phone_', '');
          }
          if (arList[i].foa_control === 'e') {
            arList[i].foa_number = arList[i].foa_parameters.replace('vmail_', '');
            arList[i].foa_number = arList[i].foa_number.replace('user_', '');
            arList[i].foa_number = arList[i].foa_number.replace('phone_', '');
          }
          if (arList[i].fbu_control === 'e') {
            arList[i].fbu_number = arList[i].fbu_user.replace('vmail_', '');
            arList[i].fbu_number = arList[i].fbu_number.replace('user_', '');
            arList[i].fbu_number = arList[i].fbu_number.replace('phone_', '');
          }
          if (arList[i].fna_control === 'e') {
            arList[i].fna_number = arList[i].fna_user.replace('vmail_', '');
            arList[i].fna_number = arList[i].fna_number.replace('user_', '');
            arList[i].fna_number = arList[i].fna_number.replace('phone_', '');
          }
          if (arList[i].fnr_control === 'e') {
            arList[i].fnr_number = arList[i].fnr_user.replace('vmail_', '');
            arList[i].fnr_number = arList[i].fnr_number.replace('user_', '');
            arList[i].fnr_number = arList[i].fnr_number.replace('phone_', '');
          }
          if (arList[i].time_range_data
                        && arList[i].time_range_data.length
                        && arList[i].time_range_data[0].days === '*'
                        && arList[i].time_range_data[0].date_from === 'now'
                        && arList[i].time_range_data[0].date_to === 'never'
          ) {
            arList[i].time_frame_always = true;
          }
        }

        resolve(arList);
      }).catch((err) => {
        reject(err);
      });
  });
}

/**
 *
 * @param {[]} list
 * @return Promise
 * @private
 */
function _getDevices(list) {
  const token = nsToken.getDecoded();

  // defer until all answering-rule-list are finished mapping with devices
  return Promise.all(list.map((ar) => {
    // remove conference-bridge from simring list
    /* if (ar.sim_control === 'e' &&
            ar.sim_parameters &&
            ar.sim_parameters.indexOf('conference-bridge') !== -1
        ) {
            const simArr = ar.sim_parameters.split(' ');
            const newSimArr = [];
            for(let i = 0; i < simArr.length; i += 1) {
                if(simArr[i].indexOf('conference-bridge') === -1) {
                    newSimArr.push(simArr[i]);
                }
            }
            ar.sim_parameters = newSimArr.join(' ');
        } */

    // test if sim control is enabled
    if (ar.sim_control === 'e'
            && ar.sim_parameters
            && ar.sim_parameters.indexOf('<OwnDevices>') !== -1
    ) {
      return nsApi.get({
        object: 'device',
        action: 'read',
        user: token.user,
        domain: token.domain,
        noNDP: 'true',
        mode: 'registered_endpoint',
        format: 'json',
      }).then((res) => {
        ar.devices = _.compact(res);

        // remove conference bridge device
        ar.devices = _.filter(ar.devices, (device) => device.aor && device.aor.indexOf('conference-bridge') === -1 && device.aor.indexOf('vb') === -1);

        // format number
        for (let i = 0; i < ar.devices.length; i += 1) {
          ar.devices[i].aor = ar.devices[i].aor.replace('sip:', '');
          ar.devices[i].aor = ar.devices[i].aor.replace(`@${ar.domain}`, '');
        }

        ar.sim_delay = {};
        const simParams = ar.sim_parameters.split(' ');

        for (let idx = 0; idx < simParams.length; idx++) {
          if (simParams[idx].indexOf(';delay=') !== -1) {
            const tmp = simParams[idx].split(';delay=');
            if (!ar.sim_delay[tmp[1]]) {
              ar.sim_delay[tmp[1]] = {
                confirm: tmp[0].indexOf('confirm_') !== -1,
                numbers: [tmp[0].replace('confirm_', '')],
              };
            } else {
              ar.sim_delay[tmp[1]].numbers.push(tmp[0].replace('confirm_', ''));
            }
          } else {
            let push = false;
            for (let d = 0; d < ar.devices.length; d++) {
              if (simParams[idx] === '<OwnDevices>'
                                || ar.devices[d].aor === simParams[idx]
              ) {
                break;
              }
              push = true;
            }
            if (push) {
              ar.devices.push({
                aor: simParams[idx].replace('confirm_', ''),
              });
            }
          }
        }

        ar.devices = ar.devices.sort((a, b) => naturalSort(a.aor, b.aor));

        return ar;
      });
    }
    return ar;
  }));
}

/**
 *
 * @param {[]} list
 * @param {string} property
 * @returns {*}
 */
function _getSubscribers(list, property) {
  const token = nsToken.getDecoded();

  return Promise.all(list.map((ar) => {
    // test if sim control is enabled
    if (ar[`${property}_control`] === 'e'
            && ar[`${property}_parameters`]
            && ar[`${property}_parameters`].indexOf('user_') !== -1
    ) {
      // var token = $auth.getPayload();
      const usernum = ar[`${property}_parameters`].replace('user_', '');

      // get the forward user
      return nsApi.get({
        object: 'subscriber',
        action: 'read',
        uid: token.sub,
        format: 'json',
      }).then((res) => {
        let str = usernum;
        if (res.length) {
          if (res[0].first_name) {
            str += ` (${res[0].first_name}`;
          }
          if (res[0].last_name) {
            str += (str) ? ` ${res[0].last_name}` : res[0].last_name;
          }
          if (str) {
            str += ')';
          }
        }

        ar[`${property}_user`] = str;
        return ar;
      });
    }
    ar[`${property}_user`] = ar[`${property}_parameters`];
    return ar;
  }));
}

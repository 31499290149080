import './styles.css';
import template from './template.html';
import * as navActions from '../../core/state-history';
import * as parkActions from '../../core/call-park';
import { getActiveCall } from '../../core/sessions';
import { matchContact } from '../../core/contacts';

// import { getCard, getCardId, newCard, setExpanded } from '../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    context: '@', // modal
    workflow: '@', // in-call, in-call-transfer
    onPhone: '&', // call back for phone modal, navigates back to the phone
  },
};

controller.$inject = [
  '$ngRedux',
  '$state',
];

function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    this.workflow = this.workflow || $state.params.workflow;
    this.context = this.context || $state.params.context;
    this.showExpander = this.workflow !== 'in-call';
    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
      callPark: parkActions.getCallParkResultSet(state),
      parkLot: parkActions.getParkingLot(state),
      activeCall: getActiveCall(state),
      parked: parkActions.getParked(state),
    }))((state) => {
      this.screenSize = state.screenSize;
      this.callparks.refresh(state.callPark);
      this.parkLot = state.parkLot;
      if (state.activeCall) {
        this.parkId = state.activeCall.parkId;
      } else {
        this.parkId = null;
      }
      this.parked = state.parked;
      this.allowDynamicPark = !!this.parkId;

      switch (this.context) {
        case 'modal':
          this.menuClass = 'bluemenu';
          this.floatClass = 'modalview';
          this.showDivider = false;
          this.containerHeight = 370;
          break;
        case 'card':
          this.menuClass = 'bluemenu';
          this.floatClass = 'cardview';
          this.showDivider = false;
          this.containerHeight = 225;
          break;
        default:
          this.floatClass = state.screenSize.display == 'compact' ? 'listview-compact' : 'listview';
          this.showDivider = true;
          this.containerHeight = this.screenSize.height;
      }

      switch (this.workflow) {
        case 'in-call':
          this.inParkClass = 'in-call';
          this.menuClass = 'bluemenu';
          this.showBackButton = 'true';
          this.backClass = 'whiteback';
          this.floatClass = 'modalview';
          break;
        case 'fab':
          this.menuClass = 'bluemenu';
          this.showExpander = false;
          break;
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.navBack = () => {
    let lastOnStack;
    switch (this.workflow) {
      case 'in-call':
        if (this.context === 'modal') {
          this.onPhone();
        } else {
          $ngRedux.dispatch(navActions.callStackBack());
          lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
          $state.go(lastOnStack.name, lastOnStack.params);
        }
        break;
      default:
        $ngRedux.dispatch(navActions.navStackBack());
        lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
        $state.go(lastOnStack.name, lastOnStack.params);
    }
  };

  this.expand = () => {
    $ngRedux.dispatch(parkActions.createCard());
  };
  /**
     *
     */
  this.putParked = ($event, parkExt) => {
    if (this.parkId) {
      $ngRedux.dispatch(parkActions.parkCall({
        parkId: this.parkId,
        parkExt,
      }));
    }
  };

  /**
     *
     */
  this.retrieveParked = ($event, parkId) => {
    $ngRedux.dispatch(parkActions.retrieveParked({
      parkId,
    }));
  };

  /**
     *
     */
  this.dynamicPark = () => {
    if (this.parkId && this.parkLot) {
      $ngRedux.dispatch(parkActions.parkCall({
        parkId: this.parkId,
        parkExt: this.parkLot.join(),
        dynamic: true,
      }));
    }
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.callparks = {
    items: [],
    refresh: (list) => {
      this.callparks.items = list;
    },
    getItemAtIndex: (index) => this.callparks.items[index],
    getLength: () => this.callparks.items.length,
  };

  this.hasParked = (park) => this.parked.filter((p) => p.term_user === park).length > 0;

  this.getAgentName = (user) => {
    const agent = matchContact(user);
    if (agent) {
      return agent.name;
    }
    return '';
  };

  this.getParkDescription = (park) => {
    const parkDesc = parkActions.getParkDescription($ngRedux.getState(), park);
    return parkDesc;
  };
}

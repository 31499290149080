import './styles.css';
import template from './template.html';
import * as cardActions from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
];

function controller(
  $ngRedux,
) {
  /**
     *
     */
  this.closeModal = () => {
    $ngRedux.dispatch(cardActions.removeCard(this.card.id));
  };
}

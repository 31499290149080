import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import './styles.css';
import crypto from 'crypto-browserify';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import * as chatActions from '../../core/chat';
import * as contactActions from '../../core/contacts';
import * as navActions from '../../core/state-history';
import * as userActions from '../../core/user';
import gravatarSessionId from '../../core/utils/gravatarSessionId';

function controller(
  $ngRedux,
  $state,
  $translate,
  $scope,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      contact: contactActions.getContact(state, this.card.meta.contactId),
      searchResultSet: contactActions.getSearchResultSet(state),
      badgeList: contactActions.getBadgeList(state),
      messagesMeta: chatActions.getMessages(state, this.card.meta.sessionId),
      screenSize: state.screenSize,
      session: chatActions.getSession(state, this.card.meta.sessionId),
      user: userActions.getUser(state),
      arrowkeyLocation: state.arrowkeyLocation,
      allGroupMMSNums: chatActions.getAllGroupMMSCapableNumbers(state) || '',
    }))((state) => {
      this.contact = state.contact;
      this.searchResultSet = state.searchResultSet;
      this.badgeList = state.badgeList;
      this.messagesMeta = state.messagesMeta;
      this.screenSize = state.screenSize;
      this.session = state.session;
      this.user = state.user;
      this.allowCustom = true;

      this.allGroupMMSNums = state.allGroupMMSNums;

      this.sms = state.user.sms;
      if (this.sms) {
        for (let i = 0; i < this.sms.length; i += 1) {
          if (this.sms[i].default) {
            this.selectedSMS = i;
          }
        }
      }
      // this is all the contacts available to be searched on
      this.allContacts = this.loadContacts(this.searchResultSet);
      // this is all the contacts available to be searched on
      this.allContactsCopy = this.loadContacts(this.searchResultSet);
      this.contacts = [];

      this.arrowkeyLocation = -1;

      // focus on input
      $('.md-chip-input-container input').focus();
    });
  };

  // get the actual contacts
  // filter out the contacts that are not part of SMS or Chat (if the type is known)
  this.loadContacts = (contactList) => contactList.map((c) => {
    const { number } = c; // will be the formatted number to or the extention

    const contact = {
      name: c.contact.name,
      initials: c.contact.initials,
      number,
      image: `//www.gravatar.com/avatar/${c.contact.gravatar}?s=50&d=mp&hash=${gravatarSessionId}`,
      type: c.type,
      contact: c.contact,
      sms: (c.type !== 'extension'),
    };
    if (c.contact.gravatar !== undefined && c.contact.gravatar !== '') {
      contact.gravatar = c.contact.gravatar;
    }
    // add uid for only contacts
    if (c.contact.uid !== undefined) {
      contact.uid = c.contact.uid;
    }

    contact._lowername = contact.name.toLowerCase();
    contact._number = contact.number;
    return contact;
  });

  /**
     *
     */
  this.$onDestroy = () => {
    // reset badgelist to nothing onDestroy
    $ngRedux.dispatch(contactActions.setBadgeList([]));
    unsubscribe();
  };

  /**
     *
     */
  this.selectSMS = (sms) => {
    // could clear out the badgelist
    $ngRedux.dispatch(contactActions.setBadgeList([]));

    // shrink chip container when resetting badgelist
    const scrollBarExists = document.getElementById('scrollbar_part');
    if (scrollBarExists) {
      $('#scrollbar_part').css({
        height: '0px',
      });
    }

    this.selectedSMS = sms;
  };

  /**
     *
     * @param item, is a contact item
     */
  this.openNewChat = (item) => {
    // generate session id and message params
    let sendMessageParams;
    let sessionId;
    switch (item.type) {
      case 'extension':
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termUID: item.contact.uid,
        });
        sendMessageParams = {
          destination: item.contact.user,
          fromUID: this.user.uid,
          sessionId,
        };
        break;
      case 'cell':
      case 'home':
      case 'work':
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termNum: item.contact[`${item.type}_phone`],
          dialed: this.user.sms[this.selectedSMS].number,
        });
        sendMessageParams = {
          destination: item.contact[`${item.type}_phone`],
          fromNum: this.user.sms[this.selectedSMS].number,
          sessionId,
        };
        break;
      case 'groupChat':
        sessionId = item.sessionId;
        sendMessageParams = {
          destination: item.destination,
          remote: item.remote,
          fromUID: this.user.uid,
          sessionId,
          nameList: item.nameList,
          numPart: item.numPart,
        };
        break;
      case 'groupmms': // TODO need groupmms case, this would be for starting new card from conversation plus sign
        var selectedSMS = '';
        if (this.user.sms[this.selectedSMS].number !== undefined) {
          selectedSMS = this.user.sms[this.selectedSMS].number;
        } else {
          selectedSMS = item.session.smsani;
        }
        sessionId = item.sessionId;
        sendMessageParams = {
          destination: item.destination,
          fromNum: this.user.sms[this.selectedSMS].number,
          sessionId: item.sessionId,
          type: 'sms',
        };

        // set namelist and number of participatns, usually done when making new session
        if (item.nameList !== undefined) {
          sendMessageParams.nameList = item.nameList;
          sendMessageParams.numPart = item.numPart;
        }
        if (item.temp_contact_arr !== undefined) {
          sendMessageParams.contact_arr = item.contact_arr;
          sendMessageParams.temp_contact_arr = item.temp_contact_arr;
        }

        break;
      case 'unknownSMS':
        // todo if no contact and 5 digit string of numbers allow without valid phone check
        // todo the number is not 5 do a valid phone check
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termNum: item.number,
          dialed: this.user.sms[this.selectedSMS].number,
        });
        sendMessageParams = {
          destination: item.number,
          fromNum: this.user.sms[this.selectedSMS].number,
          sessionId,
        };
        break;
    }

    // build card meta
    const cardMeta = {
      sendMessageParams,
      sessionId,
    };

    // add contact id if contact is set
    if (item.contact) {
      cardMeta.contactId = item.contact.id;
    }

    // remove the current new-chat card
    $ngRedux.dispatch(cardActions.removeCard(this.card.id));

    // if session does not exist, need to create the session
    const actualFoundSession = chatActions.getSession($ngRedux.getState(), sessionId);

    // if conversation does not exist yet, then create it
    if (actualFoundSession == undefined && item.type == 'unknownSMS') {
      // HERE I NEED TO CREATE A SESSION IF 1:1 AS WELL IF IT DOESNT EXIST
      const contact_arr = [];
      contact_arr.push(item.contact);
      item.nameList = contactActions.getParticipantNames(contact_arr);

      const sessionToCreate = {
        contact: item.contact || null,
        contactId: item.contact.contact_id || null,
        contact_arr,
        temp_contact_arr: contact_arr, // this will always be the original contact_arr
        partNames: item.number,
        nameList: item.nameList,
        numPart: item.numPart || '1',
        session_name: null,
        domain: this.user.domain,
        id: sessionId,
        lastMessage: null,
        lastStatus: 'read',
        lastSender: null,
        lastTimestamp: null,
        muted: false,
        remote: item.number,
        smsani: this.user.sms[this.selectedSMS].number,
        startTimestamp: null,
      };

      $ngRedux.dispatch(chatActions.addSession(sessionToCreate));
    }

    let cardId;
    // this logic makes sure duplicate cards are not made
    if (cardActions.hasCard(cardMeta)) {
      // get the card id and expand it
      cardId = cardActions.getCardId(cardMeta);
      $ngRedux.dispatch(cardActions.setExpanded(cardId));
    } else {
      // start fetching the messages for the session
      $ngRedux.dispatch(chatActions.fetchMessages(sessionId));

      // add the new card
      $ngRedux.dispatch(cardActions.newCard(cardMeta));

      // get the new card id and expand it
      cardId = cardActions.getCardId(cardMeta);
      $ngRedux.dispatch(cardActions.setExpanded(cardId));
    }

    if (this.screenSize.display === 'compact') {
      // remove the current state (new-chat) from stack
      // the nav stack will now have the previous state
      $ngRedux.dispatch(navActions.navStackBack());

      // get the last on the navStack to add to history
      const state = navActions.getLastOnStack($ngRedux.getState(), 'navStack');

      // get the card for state params
      const card = cardActions.getCard($ngRedux.getState(), cardId);
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.chat-session',
          params: { card },
          fromName: state.name,
          fromParams: state.params,
        }),
      );
      $state.go('wrapper.chat-session', { card });
    }
  };

  /**
     *
     */
  this.confirmOpenChat = () => {
    $ngRedux.connect(() => {
      const badges = this.contacts;

      // for single contact, use regular openNewChat
      if (badges.length === 1) {
        this.openNewChat(badges[0]);
      } else {
        // for group chat or group mms
        this.openGroupConversation(badges);
      }
    });
  };

  /**
     * itemList is the list of badges
     */
  this.openGroupConversation = (badges) => {
    $ngRedux.connect((state) => {
      const item = {};

      // get the list of remote and destination from the badgelist
      const remoteArr = []; // 1123@portal,25002@portal,etc. OR nothing for groupmms
      const destinationArr = []; // 1123,25002,etc. OR list of
      const contact_arr_badges = []; // 1123,25002,etc
      let itemType = '';
      for (let i = 0; i < badges.length; i += 1) {
        // could use the phone number, else check if the extension exists
        // add check whether or not it is group chat or group mms
        if (badges[i].number && (badges[i].sms || badges[i].type === 'unknownSMS')) {
          itemType = 'groupmms';
          // need numbers to be 12223334444 format
          if (badges[i].number.length === 10) destinationArr.push(`1${badges[i].number}`);
          else destinationArr.push(badges[i].number);
          contact_arr_badges.push(badges[i].contact);
        } else if (badges[i].contact.uid && badges[i].contact.extension) {
          itemType = 'groupChat';
          remoteArr.push(badges[i].contact.uid);
          destinationArr.push(badges[i].contact.extension);
          contact_arr_badges.push(badges[i].contact);
        }
      }
      const remote = remoteArr.join(',');
      const destination = destinationArr.join(',');

      // get list of sessions
      // check whether and of sessions in sessionsList's contactlist matches with badges
      let found = false;
      let foundSession;
      const sessionsList = chatActions.getSessions(state);
      for (let j = 0; j < sessionsList.length; j += 1) {
        if (sessionsList[j].contact_arr) {
          if (this.arraysEqual(sessionsList[j].contact_arr, contact_arr_badges)) {
            found = true;
            foundSession = sessionsList[j];
          }
        }
      }

      if (found) {
        // if session exists already, check if card is open (hasCard), use the sessionId that was found
        if (itemType === 'groupChat') {
          item.type = 'groupChat';
          item.session = foundSession;
          item.destination = destination;
          item.remote = remote;
          item.sessionId = foundSession.id;

          this.openNewChat(item);
        } else if (itemType === 'groupmms') {
          item.type = 'groupmms';
          item.session = foundSession;
          item.destination = destination;
          item.remote = remote;
          item.sessionId = foundSession.id;

          this.openNewChat(item);
        }
      } else {
        // if session DOES NOT exist already, create new sessionId, create new messagesMeta open new card
        if (itemType === 'groupChat') {
          item.type = 'groupChat';
          item.session = foundSession;
          item.destination = destination;
          item.remote = remote;
          item.nameList = contactActions.getParticipantNames(contact_arr_badges);
          item.numPart = badges.length;
          // create new hash for new contactId
          const secret = new Date() + destination;
          const hash = crypto.createHmac('md5', secret)
            .update(destination)
            .digest('hex');
          item.sessionId = hash;

          this.openNewChat(item);
        } else if (itemType == 'groupmms') {
          item.type = 'groupmms';
          item.session = foundSession;
          item.destination = destination;
          item.remote = remote;
          item.nameList = contactActions.getParticipantNames(contact_arr_badges);
          // // need to make the contact_arr too
          let contact;
          let searchStr;
          const contact_arr = [];
          const temp_contact_arr = [];
          const contact_uids = item.destination.split(',');
          for (let x = 0; x < contact_uids.length; x++) {
            const uidArr = contact_uids[x].split('@');
            searchStr = uidArr[0];
            searchStr = searchStr.replace(/['"\]\[]+/g, '');
            contact = contactActions.matchContact(searchStr);
            // if contact is not found, make temp one for this group
            if (contact == undefined) {
              contact = new Object();
              contact.name = searchStr;
              contact.cell_phone = searchStr;
            }
            contact_arr.push(contact);
            temp_contact_arr.push(contact);
          }
          item.contact_arr = contact_arr;
          item.temp_contact_arr = temp_contact_arr;

          item.numPart = badges.length;

          // create new hash for new contactId
          let toHash = `${destination},${this.user.sms[this.selectedSMS].number}`;
          const toHashArr = toHash.split(',');
          toHashArr.sort();
          toHash = toHashArr.join('');
          const hash = crypto.createHash('md5').update(toHash).digest('hex');
          item.sessionId = hash;

          // create new temp session now, try to get session here
          const actualFoundSession = chatActions.getSession(state, item.sessionId);

          if (actualFoundSession == undefined) {
            // NEED TO CREATE A SESSION IF GROUP AS WELL IF IT DOESNT EXIST
            const sessionToCreate = {
              contact: null,
              contact_arr,
              temp_contact_arr, // this will always be the original contact_arr
              partNames: item.nameList,
              numPart: badges.length,
              session_name: null,
              domain: this.user.domain,
              id: hash,
              lastMessage: null,
              lastStatus: 'read',
              lastSender: null,
              lastTimestamp: null,
              muted: false,
              remote: destination,
              smsani: this.user.sms[this.selectedSMS].number,
              startTimestamp: null,
            };

            $ngRedux.dispatch(chatActions.addSession(sessionToCreate));
          }

          this.openNewChat(item);
        }
      }
    }); // end of ngRedux.connect
  };// end of openGroupConversation

  // check whether two arrays are equal, regardless of order
  this.arraysEqual = (_arr1, _arr2) => {
    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

    const arr1 = _arr1.sort((a, b) => ((a.id > b.id) ? 1 : -1));
    const arr2 = _arr2.sort((a, b) => ((a.id > b.id) ? 1 : -1));

    for (let i = 0; i < arr1.length; i += 1) {
      if ((arr1[i].id !== arr2[i].id) || (arr1[i].first_name !== arr2[i].first_name)) {
        return false;
      }
    }
    return true;
  };

  /// ///////////////// new autofill functions ////////////

  /**
     * Return the proper object when the append is called.
     */
  this.transformChip = (chip) => {
    // get the contact index of that is being selected
    if (this.arrowkeyLocation >= 0
          && this.allContactsCopy !== undefined
          && this.allContactsCopy[this.arrowkeyLocation] !== undefined
    ) {
      if (this.allContactsCopy[this.arrowkeyLocation].type !== undefined
        && this.allContactsCopy[this.arrowkeyLocation].type === 'unknownSMS'
      ) {
        this.allContactsCopy[this.arrowkeyLocation].name = this.allContactsCopy[this.arrowkeyLocation].number;
      }

      let chipToReturn = {
        name: this.allContactsCopy[this.arrowkeyLocation].name,
        number: this.allContactsCopy[this.arrowkeyLocation].number,
        uid: this.allContactsCopy[this.arrowkeyLocation].uid || null,
        gravatar: this.allContactsCopy[this.arrowkeyLocation].gravatar || null,
        initials: this.allContactsCopy[this.arrowkeyLocation].initials || null,
        contact: this.allContactsCopy[this.arrowkeyLocation].contact || null,
        type: this.allContactsCopy[this.arrowkeyLocation].type || 'cell',
        sms: (this.allContactsCopy[this.arrowkeyLocation].type !== 'extension'),
      };

      // check if current is already in chips, if so, should not return ?
      let existsAlready = false;
      for (let i = this.contacts.length - 1; i >= 0; i--) {
        if (this.contacts[i].number == chipToReturn.number) {
          existsAlready = true;
          chipToReturn = null;
        }
      }

      this.arrowkeyLocation = -1;

      var isMMSValid = this.allGroupMMSNums.includes(this.user.sms[this.selectedSMS].number);

      // if it is not group mms capable, automatically create new card
      if (!isMMSValid && chipToReturn.type !== 'extension') {
        this.openNewChat(chipToReturn);
      }

      return chipToReturn;
    }

    // the arrowkeylocation is 0 yet there is not valid
    if (this.arrowkeyLocation == 0
          && this.allContactsCopy[this.arrowkeyLocation] == undefined
          && chip !== ''
          && chip !== undefined
    ) {
      let chipToReturn = {
        name: chip,
        number: chip,
        gravatar: null,
        initials: null,
        type: 'unknownSMS',
      };

      const innerContact = {
        cell_phone: chip,
        first_name: chip,
        last_name: '',
        name: chip,
      };

      chipToReturn.contact = innerContact;
      this.arrowkeyLocation = -1;

      // TODO could start conversation right here if chip is sms and not group mms capable
      var isMMSValid = this.allGroupMMSNums.includes(this.user.sms[this.selectedSMS].number);
      if (!isMMSValid && chipToReturn.type !== 'extension') {
        this.openNewChat(chipToReturn);
      }

      // if the chip is length not 10 or 11, then do not allow adding
      const regExp = /[a-zA-Z]/g;
      if ((chip.length != 10 && chip.length != 11) || regExp.test(chip)) {
        chipToReturn = null;
      }

      // if the first chip is already in and is a chat contact, do not allow for a non-contact after
      if (this.contacts[0] !== undefined && this.contacts[0].type !== undefined) {
        if (this.contacts[0].type == 'extension') {
          chipToReturn = null;
        }
      }

      return chipToReturn;
    }
  };

  // when input changed then should change the list of contacts that are shown in the list
  this.inputChanged = (input, target = null) => {
    if (input == '' || input == undefined) {
      // reset the counter to be -1
      this.arrowkeyLocation = -1;
      this.allContactsCopy = this.allContacts;

      // input changed, so scroll to the top of the contact list
      const topContactRow = document.querySelector('.list-item-index-0');
      if (topContactRow !== undefined && topContactRow !== null) {
        topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    } else {
      this.arrowkeyLocation = 0;
      this.allContactsCopy = input ? this.allContacts.filter(this.createFilterFor(input)) : [];

      // if all contacts is empty, and input meets SMS number criteria, then insert the custom chip
      if (this.allContactsCopy.length === 0 && !isNaN(input) && input.length >= 5 && this.allowCustom) {
        const innerContact = {
          cell_phone: input,
          first_name: input,
          last_name: '',
          name: input,
        };
        const sendToString = $translate.instant('ADD_NUMBER_TO_GROUP');
        const contact = {
          name: sendToString,
          initials: null,
          number: input,
          image: null,
          type: 'unknownSMS',
          contact: innerContact,
        };

        this.allContactsCopy.push(contact);
      }

      // input changed, so scroll to the top of the contact list
      const topContactRow = document.querySelector('.list-item-index-0');
      if (topContactRow !== undefined) {
        topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }

    // only if not typing anything should it update contact list
    if (input == undefined || input == '') {
      this.updateContactList();
    }
    $scope.$apply();
  };

  /**
     * Check if string is numeric
     */
  this.isNumeric = (string) => {
    if (typeof str !== 'string') return false; // we only process strings!
    return !isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                 && !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
  };

  /**
     * Create filter function for a query string
     */
  this.createFilterFor = (query) => {
    const lowercaseQuery = query.toLowerCase();

    return function filterFn(contact) {
      // return false;
      return ((contact._lowername.indexOf(lowercaseQuery) !== -1) || contact.number.indexOf(lowercaseQuery) !== -1);
    };
  };

  this.keyUpFunc = function (keyEvent) {
    if (keyEvent.which === 13) {
      // if there is nothing in input, then that means the user clicked on row still, should enter contact
      const inputText2 = keyEvent.target.value;

      // if there is nothing in input and there are chips, then start the conversation, needs to equal blank string, NOT undefined
      if (inputText2 != undefined && inputText2 === '' && this.arrowkeyLocation == -1 && this.contacts.length != 0) {
        this.confirmOpenChat();
      }

      if ((inputText2 == undefined || inputText2 == '') && this.arrowkeyLocation !== -1) {
        this.contacts.push(this.transformChip());

        // TODO if sms number is 1:1 only then, start if the contact is an sms number
      }

      this.arrowkeyLocation = -1;

      // scroll down to chips input
      setTimeout(() => {
        // scroll to input
        const elmnt = $('.md-chip-input-container').find(':input')[0];
        if (elmnt !== undefined) {
          elmnt.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          elmnt.focus();
        }

        // scroll to top of contact list
        const topContactRow = document.querySelector('.list-item-index-0');
        if (topContactRow !== undefined) {
          topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      }, 0);

      this.inputChanged(inputText2);
      this.updateContactList(); // update list after entering new offnet
    } else if (keyEvent.which == 38) {
      // limit to 0
      if (this.arrowkeyLocation > 0) {
        this.arrowkeyLocation--;
        // scroll to the element
        setTimeout(() => {
          const el = document.querySelector('.active-chip-class');
          if (el !== undefined) {
            el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
        }, 0);
      }
    } // this is the ascii of arrow up
    else if (keyEvent.which == 40) {
      // cap to allContactsCopy-1
      if (this.arrowkeyLocation >= this.allContactsCopy.length - 1) {
        this.arrowkeyLocation = this.allContactsCopy.length - 1;
      } else {
        this.arrowkeyLocation++;

        // scroll to the element
        setTimeout(() => {
          const el = document.querySelector('.active-chip-class');
          if (el !== undefined) {
            el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
        }, 0);
      }
    } else {
      // was an actual letter
      const inputText2 = keyEvent.target.value;
      // let inputText2 = $('.md-chip-input-container').find(':input')[0].value;
      this.inputChanged(inputText2, keyEvent.target);
    }
  };

  /**
     * there was change on the chip list
     * Operations to do: refresh the allContactsCopy list, refocus on the input for md-chips
     *
     *
     */
  this.onChange = () => {
    setTimeout(() => {
      // match the contacts in allContactsCopy and update this
      this.updateContactList();

      const elmnt = $('.md-chip-input-container').find(':input')[0];
      if (elmnt !== undefined) {
        elmnt.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
      // scroll to top of contact list
      const topContactRow = document.querySelector('.list-item-index-0');
      if (topContactRow !== undefined && topContactRow !== null) {
        topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 0);
  };

  /**
     * Will remove any contacts that do no match the added case
     */
  this.updateContactList = () => {
    // if contacts is empty, then no need to do anything,
    // or even if allContactsCopy is undefined or empty
    if (this.contacts === undefined
      || this.allContactsCopy === undefined
      || this.allContactsCopy.length === 0
    ) {
      return;
    }

    // if the contacts array just became length 0, that's fine,
    // but should just make it so that allcontacts are available
    if (this.contacts.length === 0) {
      const allContactListToUpdate = this.loadContacts(this.searchResultSet);
      this.allContactsCopy = allContactListToUpdate;
      this.allContacts = allContactListToUpdate;
      this.allowCustom = true;

      return;
    }

    const allContactListToUpdate = this.loadContacts(this.searchResultSet);

    // filter out contacts of a certain type
    if (this.contacts[0] !== undefined && this.contacts[0].type !== undefined) {
      if (this.contacts[0].type === 'extension') {
        // should get rid of all the contacts that are mms
        for (let j = allContactListToUpdate.length - 1; j >= 0; j--) {
          if (allContactListToUpdate[j].type !== 'extension') {
            allContactListToUpdate.splice(j, 1);
          }
        }
        // do not allow custom input if only chats
        this.allowCustom = false;
      } else {
        // should get rid of contacts that are chats
        for (let j = allContactListToUpdate.length - 1; j >= 0; j -= 1) {
          if (allContactListToUpdate[j].type === 'extension') {
            allContactListToUpdate.splice(j, 1);
          }
        }
      }
    }

    for (let i = this.contacts.length - 1; i >= 0; i--) {
      for (let j = allContactListToUpdate.length - 1; j >= 0; j--) {
        if (this.contacts[i].number === allContactListToUpdate[j].number) {
          allContactListToUpdate.splice(j, 1);
        }
      }
    }

    this.allContactsCopy = allContactListToUpdate;
    this.allContacts = allContactListToUpdate;
  };

  /**
     * Add clicked contact to the list.
     */
  this.clickedContact = (contact) => {
    $ngRedux.connect((state) => {
      // check if this.contacts has the contact in question already
      let existsAlready = false;
      for (let i = this.contacts.length - 1; i >= 0; i -= 1) {
        if (this.contacts[i].number === contact.number) {
          existsAlready = true;
        }
      }

      // parse out the ADD_NUMBER_TO_GROUP
      if (contact.type != undefined && contact.type == 'unknownSMS') {
        contact.name = contact.number;
      }

      // search for contact to attach to contact to add
      if (!existsAlready) {
        this.contacts.push(contact);
      }
      this.updateContactList();

      // if sms number is 1:1 only then, start if the contact is an sms number
      const isMMSValid = chatActions.getMMSCapableNumbers(
        state,
        this.user.sms[this.selectedSMS].number,
      );
      if (!isMMSValid && contact.type !== 'extension') {
        this.openNewChat(contact);
      }

      setTimeout(() => {
        const elmnt = $('.md-chip-input-container').find(':input')[0];
        if (elmnt !== undefined) {
          elmnt.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          elmnt.focus();

          // scroll to top of contact list
          const topContactRow = document.querySelector('.list-item-index-0');
          if (topContactRow !== undefined) {
            topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
        }
      }, 0);
    });
  };

  /// ///////////////// end autofill functions ////////////
}

controller.$inject = [
  '$ngRedux',
  '$state',
  '$translate',
  '$scope',
];

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@',
  },
};

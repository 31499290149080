import template from './template.html';
import { setExpanded } from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
];
function controller($ngRedux) {
  let unsubscribe;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({ screenSize: state.screenSize }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.closeModal = () => {
    $ngRedux.dispatch(setExpanded(null));
  };
}

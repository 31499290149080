import nsApi from '@netsapiens/netsapiens-js/dist/api';

import template from './template.html';
import { authenticated } from '../login';

function controller(
  $ngRedux,
  $mdDialog,
  $rootScope,
  $sce,
  $state,
) {
  const self = this;

  self.imageIsLoaded = false;
  self.displayImage = true;
  self.imageLoaded = imageLoaded;
  self.formMessage = '';
  self.spinner = false;
  self.imgUrl = null;
  self.ssoEnroll = ssoEnroll;
  self.idtoken = null;
  self.vendor = null;

  self.$onInit = () => {
    $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
    }))(this);

    const hostname = $ngRedux.getState().configs.get('hostname');
    self.imgUrl = `https://${hostname}/ns-api/?object=image&action=read&server=${hostname}&filename=portal_landing.png`;
    self.idtoken = $state.params.idtoken;
    self.vendor = $state.params.vendor;
  };

  function imageLoaded(display) {
    self.displayImage = display;
    self.imageIsLoaded = true;
    $rootScope.$digest();
  }

  function ssoEnroll(form) {
    nsApi.ssoEnroll({
      username: form.username.$viewValue,
      password: form.password.$viewValue,
      idtoken: self.idtoken,
      vendor: self.vendor,
      scope: 'webphone',
      apiHash: $ngRedux.getState().configs.get('apiHash'),
    }).then(() => {
      nsApi.ssoLogin({
        username: form.username.$viewValue,
        idtoken: self.idtoken,
        vendor: self.vendor,
        scope: 'webphone',
        apiHash: $ngRedux.getState().configs.get('apiHash'),
      }).then(() => {
        $ngRedux.dispatch(authenticated());
      }).catch(() => {
        self.spinner = false;
        self.formMessage = $sce.trustAsHtml('Login attempt failed.<br>Please try again.');
        $rootScope.$digest();
      });
    }).catch(() => {
      self.spinner = false;
      self.formMessage = $sce.trustAsHtml('Enrollment failed.<br>Please try again.');
      $rootScope.$digest();
    });
  }
}

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$rootScope',
  '$sce',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
};

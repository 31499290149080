export default () => ({
  restrict: 'A',
  scope: {
    callback: '=callback',
  },
  link(scope, elem) {
    elem.bind('load', () => {
      scope.callback(true);
    });
    elem.bind('error', () => {
      scope.callback(false);
    });
  },
});

import _ from 'lodash';

import './styles.css';
import template from './template.html';
import { getContact, updateContact } from '../../../../core/contacts';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];

function controller(
  $ngRedux,
  $state,
) {
  /**
     *
     */
  this.$onInit = () => {
    this.contact = getContact($ngRedux.getState(), $state.params.contactID);
  };

  /**
     *
     */
  this.toggleFav = () => {
    let tags;
    if (!this.contact.tags || this.contact.tags.indexOf('fav') === -1) {
      if (!this.contact.tags) {
        this.contact.tags = 'fav';
      } else {
        tags = this.contact.tags.split(',');
        tags.push('fav');
        tags = tags.join();
        this.contact.tags = tags;
      }
      $ngRedux.dispatch(updateContact(this.contact));
    } else {
      tags = this.contact.tags.split(',');
      tags = _.reject(tags, (tag) => tag === 'fav');
      tags = tags.join();
      this.contact.tags = tags;
      $ngRedux.dispatch(updateContact(this.contact));
    }
  };
}

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.dialer', {
      url: 'dialer',
      params: {
        card: null,
        workflow: null,
      },
      template: '<nets-dialer card="$ctrl.card" workflow="{{$ctrl.workflow}}"></nets-dialer>',
      controller: ['$stateParams', function ($stateParams) {
        this.card = $stateParams.card;
        this.workflow = $stateParams.workflow;
      }],
      controllerAs: '$ctrl',
    });
};

import _ from 'lodash';
import { Map } from 'immutable';
import moment from 'moment';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import { matchContact } from '../contacts';

/** ********************************************
 * Redux constants
 ******************************************** */

export const CALL_HISTORY = 'core/call-history/CALL_HISTORY';
export const CALL_HISTORY_RESULT_SET = 'core/call-history/CALL_HISTORY_RESULT_SET';
export const CALL_CDR_SET = 'core/call-history/CALL_CDR_SET';
export const FILTERED_BY = 'core/call-history/FILTERED_BY';
export const LOADING = 'core/call-history/LOADING';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  callHistory: nsUtils.normalizeList('cdr_id', []),
  callHistoryResultSet: null,
  callCdrSet: nsUtils.normalizeList('cdr_id', []),
  filteredBy: 'all',
  loading: true,
});

export default (state = initialState, action) => {
  let cdrset;

  switch (action.type) {
    case CALL_HISTORY:
      return state.set('callHistory', nsUtils.normalizeList('cdr_id', action.payload));
    case FILTERED_BY:
      return state.set('filteredBy', action.payload);
    case LOADING:
      return state.set('loading', action.payload);
    case CALL_HISTORY_RESULT_SET:
      return state.set('callHistoryResultSet', action.payload);
    case CALL_CDR_SET:
      cdrset = state.get('callCdrSet');
      if (!cdrset.entities[action.payload.cdr_id]) {
        cdrset = cdrset.toArray();
        cdrset.push({ cdr_id: action.payload.cdr_id, cdr: action.payload.cdr });
        return state.set('callCdrSet', nsUtils.normalizeList('cdr_id', cdrset));
      }
      cdrset.entities[action.payload.cdr_id].cdr = action.payload.cdr;
      return state.set('callCdrSet', cdrset);

    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {function(*)}
 */
export const fetchCallHistory = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });

  const token = nsToken.getDecoded();

  return nsApi.get({
    object: 'cdr2',
    action: 'read',
    format: 'json',
    hide: 0,
    uid: token.sub,
    limit: 200,
  }).then((res) => {
    const history = [];
    for (let i = 0; i < res.length; i += 1) {
      try {
        if (res[i].hide) {
          // continue;
        }

        // delete unused props
        delete res[i].hide;
        delete res[i].tag;
        delete res[i].time_answer;
        delete res[i].time_release;

        try {
          res[i].time_start = res[i].time_start
            ? moment.unix(res[i].time_start)
            : res[i].time_start;
        } catch (e) {
          console.error(`Error processing moment, time_start = ${res[i].time_start}`);
          console.error(e);
        }

        if (!res[i].number) {
          console.log('Error call history, number is null  ');
          console.log(res[i]);
          res[i].number = '';
        } else if (res[i].number.indexOf('sip') !== -1) {
          res[i].number = res[i].number.slice(0, res[i].number.indexOf('@'));
          res[i].number = res[i].number.replace(/[^0-9*+]+/g, ''); // WP-1039 do not remove stars
          if (res[i].number.length === 11 && res[i].number[0] === '1') {
            res[i].number = res[i].number.slice(1, res[i].number.length);
          }
        } else if (res[i].number === 'VMail' || res[i].number === 'Auto-Attendant') {
          res[i].name = 'VMail';
          res[i].number = res[i].orig_to_uri.slice(0, res[i].orig_to_uri.indexOf('@'));
          res[i].number = res[i].orig_to_uri.replace(/\D+/g, '');
        } else {
          res[i].number = res[i].number.replace(/[^0-9*+]+/g, '');
        }

        // WP-740
        if (res[i].orig_to_uri != null && res[i].orig_to_uri.indexOf('sip') !== -1) {
          res[i].orig_to_uri = res[i].orig_to_uri.slice(0, res[i].orig_to_uri.indexOf('@'));
          res[i].orig_to_uri = res[i].orig_to_uri.replace(/[^0-9*]+/g, ''); // WP-1039 do not remove stars
          if (res[i].orig_to_uri.length === 11 && res[i].orig_to_uri[0] === '1') {
            res[i].orig_to_uri = res[i].orig_to_uri.slice(1, res[i].orig_to_uri.length);
          }
        }

        // get contact by name
        let contact = matchContact(res[i].name);

        if (!contact) {
          contact = matchContact(res[i].number);
        }

        res[i].contact = contact;

        history.push(res[i]);
      } catch (e) {
        console.log(e);
        continue;
      }
    }

    dispatch({
      type: CALL_HISTORY,
      payload: history,
    });

    if (history) {
      dispatch(processResultSet(true));
    } else {
      dispatch({
        type: LOADING,
        payload: false,
      });
    }
  }).catch((e) => {
    console.error(e);
    console.error(e.toString());
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @param {string} payload 'all', 'outbound', 'inbound', 'missed'
 * @return {function(*, *)}
 */
export const filteredBy = (payload) => (dispatch, getState) => {
  dispatch({
    type: FILTERED_BY,
    payload,
  });

  const callHistoryList = getState().callHistory.get('callHistory').toArray();

  if (callHistoryList) {
    // process result set
    dispatch(processResultSet());
  }
};

/**
 *
 * @param updateLoading
 * @return {function(*, *)}
 */
export const processResultSet = (updateLoading = false) => (dispatch, getState) => {
  const callHistoryList = getState().callHistory.get('callHistory').toArray();
  const filteredBy = getState().callHistory.get('filteredBy');
  let resultSet;

  switch (filteredBy) {
    case 'all':
      resultSet = callHistoryList;
      break;
    case 'outbound':
      resultSet = _.filter(callHistoryList, (rec) => rec.type === '0');
      break;
    case 'inbound':
      resultSet = _.filter(callHistoryList, (rec) => rec.type === '1');
      break;
    case 'missed':
      resultSet = _.filter(callHistoryList, (rec) => rec.type === '2');
      break;
    default:
      resultSet = callHistoryList;
  }

  dispatch({
    type: CALL_HISTORY_RESULT_SET,
    payload: resultSet,
  });

  if (updateLoading) {
    dispatch({
      type: LOADING,
      payload: false,
    });
  }
};

export const fetchCallCDR = (cdrId) => (dispatch) => {
  const token = nsToken.getDecoded();
  return nsApi.get({
    object: 'cdr2',
    action: 'read',
    format: 'json',
    uid: token.sub,
    cdr_id: cdrId,
  }).then((res) => {
    dispatch({
      type: CALL_CDR_SET,
      payload: {
        cdr_id: cdrId,
        cdr: res,
      },
    });
  });
};

export const updateCdr = (update) => (dispatch) => {
  dispatch({
    type: CALL_CDR_SET,
    payload: {
      cdr_id: update.cdr_id,
      cdr: update.cdr,
    },
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @param {string} id - cdr_id
 * @return {*}
 */
export const getCallDetails = (state, id) => state.callHistory.get('callHistory').entities[id];

/**
 *
 * @param state
 * @return {[]}
 */
export const getCallHistory = (state) => state.callHistory.get('callHistory').toArray();

/**
 *
 * @param state
 * @return {[]}
 */
export const getCallHistoryResultSet = (state) => state.callHistory.get('callHistoryResultSet');

/**
 *
 * @param state
 * @param {string} id - cdr_id
 * @return {*}
 */
export const getCallCdrDetails = (state, id) => state.callHistory.get('callCdrSet').entities[id];

/**
 *
 * @param state
 */
export const getCallsFilteredBy = (state) => state.callHistory.get('filteredBy');

/**
 *
 * @param state
 * @return {boolean}
 */
export const getLoading = (state) => state.callHistory.get('loading');

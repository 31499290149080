export const getIsConference = (sessions) => {
  let isconf = false;
  for (let i = 0; i < sessions.length; i += 1) {
    if (sessions[i].number && sessions[i].number.includes('adhoc.')) isconf = true;
    if (sessions[i].name && sessions[i].name.includes('adhoc.')) isconf = true;
    if (sessions[i].conferenceList != null && sessions[i].conferenceList.length > 0) isconf = true;
    if (sessions[i].isConference) isconf = true;
  }
  return isconf;
};

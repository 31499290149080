import { Map } from 'immutable';

/** ********************************************
 * Redux constants
 ******************************************** */

export const PRESENCE = 'core/presence/PRESENCE';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({});

export default (state = initialState, action) => {
  switch (action.type) {
    case PRESENCE:
      return state.set(action.payload.user, action.payload.userPresence);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {function(*, *)}
 */
export const setChatPresence = (user, chatPresence) => (dispatch, getState) => {
  const userPresence = getState().presence.get(user) || {};
  userPresence.chatPresence = chatPresence;
  dispatch({
    type: PRESENCE,
    payload: { user, userPresence },
  });
};

/**
 *
 * @return {function(*, *)}
 */
export const setPresence = (user, presence) => (dispatch, getState) => {
  const userPresence = getState().presence.get(user) || {};
  userPresence.presence = presence;
  dispatch({
    type: PRESENCE,
    payload: { user, userPresence },
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @param user
 * @return {*}
 */
export const getPresence = (state, user) => state.presence.get(user) || null;

/* eslint-disable no-console */
// import _ from 'lodash';
import { Map } from 'immutable';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import moment from 'moment';
import _ from 'lodash';
import { subscribe } from '../socket';
import * as cardActions from '../card-management';

/** ********************************************
 * Redux constants
 ******************************************** */

export const AGENT_QUEUES = 'core/agent-center/AGENT_QUEUES';
export const RESPONDERS = 'core/agent-center/RESPONDERS';
export const QUEUED = 'core/agent-center/QUEUED';
export const AGENT_STATUS = 'core/agent-center/AGENT_STATUS';
export const PORTAL_STATUS = 'core/agent-center/PORTAL_STATUS';
export const DISPOSITIONS_IN = 'core/agent-center/DISPOSITIONS_IN';
export const DISPOSITIONS_OUT = 'core/agent-center/DISPOSITIONS_OUT';
export const HOUR_STATS = 'core/agent-center/HOUR_STATS';
export const DAY_STATS = 'core/agent-center/DAY_STATS';
export const CALL_STATS = 'core/agent-center/CALL_STATS';
export const KPI_STATS = 'core/agent-center/KPI_STATS';

export const SHOWAC = 'core/agent-center/SHOWAC';
export const AGENT_TYPE = 'core/agent-center/AGENT_TYPE';
export const LOADING = 'core/agent-center/LOADING';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  queues: nsUtils.normalizeList('queue_name', []),
  responders: nsUtils.normalizeList('queue_name', []),
  queued: nsUtils.normalizeList('queue_name', []),
  agentstatus: 'Offline',
  portalstatus: '',
  dispositionsin: [],
  dispositionsout: [],
  hourstats: [],
  daystats: [],
  callstats: [],
  kpistats: {},
  loading: true,
  showAgentCenter: false,
  agenttype: '',
});

export default (state = initialState, action) => {
  let queues;
  let responders;
  let queued;
  switch (action.type) {
    case LOADING:
      return state.set('loading', action.payload);
    case SHOWAC:
      return state.set('showAgentCenter', action.payload);
    case AGENT_TYPE:
      return state.set('agenttype', action.payload);
    case AGENT_QUEUES:
      state.set('loading', false);
      queues = nsUtils.normalizeList('queue_name', action.payload);
      return state.set('queues', queues);
    case RESPONDERS:
      responders = state.get('responders');
      if (!responders.entities[action.payload.queueId]) {
        responders = responders.toArray();
        responders.push({ queue_name: action.payload.queueId, responders: action.payload.responders });
        return state.set('responders', nsUtils.normalizeList('queue_name', responders));
      }
      responders.entities[action.payload.queueId].responders = action.payload.responders;
      return state.set('responders', responders);

    case QUEUED:
      queued = state.get('queued');
      if (!queued.entities[action.payload.queueId]) {
        queued = queued.toArray();
        queued.push({ queue_name: action.payload.queueId, queued: action.payload.queued });
        return state.set('queued', nsUtils.normalizeList('queue_name', queued));
      }
      queued.entities[action.payload.queueId].queued = action.payload.queued;
      return state.set('queued', queued);

    case AGENT_STATUS:
      return state.set('agentstatus', action.payload);
    case PORTAL_STATUS:
      return state.set('portalstatus', action.payload);
    case DISPOSITIONS_IN:
      return state.set('dispositionsin', action.payload);
    case DISPOSITIONS_OUT:
      return state.set('dispositionsout', action.payload);
    case HOUR_STATS:
      return state.set('hourstats', action.payload);
    case DAY_STATS:
      return state.set('daystats', action.payload);
    case CALL_STATS:
      return state.set('callstats', action.payload);
    case KPI_STATS:
      return state.set('kpistats', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

export const setShowAgentCenter = () => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();
  const show = (decodedToken.user_scope === 'Call Center Agent'
            || decodedToken.user_scope === 'Call Center Supervisor'
            || getState().configs.get('portalMenuShowCallCenterAgent') === 'yes'
            || (getState().configs.get('portalMenuShowCallCenterAgentSupervisor') === 'yes'
             && decodedToken.user_scope !== 'Site Manager'
             && decodedToken.user_scope !== 'Basic User'
             && decodedToken.user_scope !== 'Simple User'
             && decodedToken.user_scope !== 'Advanced User'
            )
  );
  dispatch({
    type: SHOWAC,
    payload: show,
  });
};
/**
 *
 * @return {function(*)}
 */
export const fetchAgentQueues = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  const decodedToken = nsToken.getDecoded();

  return nsApi.get({
    object: 'callqueue',
    action: 'read',
    agent: decodedToken.sub,
    user: decodedToken.user,
    domain: decodedToken.domain,
    format: 'json',
  }).then((res) => {
    if (!res || res.length === 0) {
      dispatch(fetchAgentDeviceQueues());
      return;
    }
    dispatch({
      type: AGENT_TYPE,
      payload: decodedToken.sub,
    });
    dispatch({
      type: AGENT_QUEUES,
      payload: res,
    });
    let updatedStatus;
    res.forEach((queue) => {
      dispatch(subscribe({
        type: 'queued',
        filter: queue.queue_name,
      }));
      dispatch(fetchResponders(queue.queue_name));
      if (!updatedStatus || (updatedStatus !== 'available' && queue.agent.entry_status === 'available')) {
        updatedStatus = queue.agent.entry_status;
      }
    });
    dispatch({
      type: AGENT_STATUS,
      payload: updatedStatus === 'available' ? 'Online' : 'Offline',
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  }).catch((err) => {
    console.error('Fetching Agent Queues failed:', err);
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @return {function(*)}
 */
export const fetchAgentDeviceQueues = () => (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  const decodedToken = nsToken.getDecoded();
  const devicePostFix = getState().configs.get('devicePostFix');
  return nsApi.get({
    object: 'callqueue',
    action: 'read',
    agent: `sip:${decodedToken.user}${devicePostFix}@${decodedToken.domain}`,
    user: decodedToken.user,
    domain: decodedToken.domain,
    format: 'json',
  }).then((res) => {
    dispatch({
      type: AGENT_TYPE,
      payload: `sip:${decodedToken.user}${devicePostFix}@${decodedToken.domain}`,
    });
    dispatch({
      type: AGENT_QUEUES,
      payload: res,
    });
    res.forEach((queue) => {
      dispatch(subscribe({
        type: 'queued',
        filter: queue.queue_name,
      }));
      dispatch(fetchResponders(queue.queue_name));
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  }).catch((err) => {
    console.error('Fetching Agent Device Queues failed:', err);
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @param queueId
 */
export const fetchResponders = (queueId) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'agent',
    action: 'read',
    user: decodedToken.user,
    domain: decodedToken.domain,
    queue_name: queueId,
    format: 'json',
  }).then((res) => {
    dispatch({
      type: RESPONDERS,
      payload: {
        responders: res,
        queueId,
      },
    });
  }).catch((err) => {
    console.error(`Fetching Responders ${queueId} failed: ${err}`);
  });
};

export const updateResponder = (data) => (dispatch, getState) => {
  const responders = getState().agent.get('responders').entities[data.huntgroup_name];
  if (responders && typeof responders !== undefined) {
    const responder = responders.responders.find(({ device_aor }) => device_aor === data.device_aor);
    if (!responder) {
      return;
    }
    responder.entry_option = data.entry_option;
    responder.entry_status = data.entry_status;
    const newResponders = responders.responders.map((el) => (el.device_aor === responder.device_aor ? responder : el));
    dispatch({
      type: RESPONDERS,
      payload: {
        responders: newResponders,
        queueId: data.huntgroup_name,
      },
    });
  }
};

export const updateQueuesAgentStatus = (data) => (dispatch, getState) => {
  const queues = getState().agent.get('queues');
  const queue = queues.entities[data.huntgroup_name];
  if (queue && queue.agent) {
    queue.agent.entry_option = data.entry_option;
    queue.agent.entry_status = data.entry_status;
    queues.entities[data.huntgroup_name] = queue;
    dispatch({
      type: AGENT_QUEUES,
      payload: queues.toArray(),
    });

    const portalAgentStatus = getState().agent.get('portalstatus');
    let newStatus = portalAgentStatus !== '' ? portalAgentStatus : 'Offline';

    const filter_queues = _.filter(queues.entities, (queue) => queue.agent.entry_status === 'available' || queue.agent.entry_option === 'immediate');

    if (filter_queues.length > 0) {
      newStatus = 'Online';
      const singlecall_queues = _.filter(filter_queues, (queue) => queue.agent.entry_option === 'manual');
      if (singlecall_queues.length > 0) {
        newStatus = 'singlecall';
      }
      setTimeout(() => {
        dispatch({
          type: PORTAL_STATUS,
          payload: '',
        });
      }, 3000);
    }

    dispatch({
      type: AGENT_STATUS,
      payload: newStatus,
    });
  }
};

/**
 *
 * @param data
 */
export const updateAgentPortalStatus = (data) => (dispatch, getState) => {
  const allowed = [' ', '', 'Lunch', 'Break', 'Meeting', 'Other', 'Web'];
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_1') || '');
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_2') || '');
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_3') || '');
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_4') || '');
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_5') || '');
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_6') || '');
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_7') || '');
  allowed.push(getState().configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_8') || '');

  if (data.portal_status && allowed.indexOf(data.portal_status) !== -1) {
    dispatch({
      type: PORTAL_STATUS,
      payload: data.portal_status,
    });
    dispatch({
      type: AGENT_STATUS,
      payload: data.portal_status,
    });
  }
};

const queuedSort = (q) => q.sort((qA, qB) => {
  if (qA.prioritized === qB.prioritized) {
    const time_qA = new Date(`${qA.time_queued} UTC`).getTime();
    const time_qB = new Date(`${qB.time_queued} UTC`).getTime();
    return time_qA - time_qB;
  } if (qA.prioritized === 'yes') {
    return -1;
  } if (qA.prioritized === 'no') {
    return 1;
  }
  return 0;
});

/**
 *
 * @param data
 */
export const updateQueued = (data) => (dispatch, getState) => {
  if (getState().agent.get('queues').entities[data.queue_name]) {
    const queued = getState().agent.get('queued').entities[data.queue_name];
    if (!queued) {
      const queuedArray = [];
      queuedArray.push(data);
      dispatch({
        type: QUEUED,
        payload: {
          queued: queuedSort(queuedArray),
          queueId: data.queue_name,
        },
      });
    } else {
      const waiting = queued.queued.find(({ session_key }) => session_key === data.session_key);
      if (!waiting) {
        queued.queued.push(data);
      } else {
        queued.queued = queued.queued.map((q) => (q.session_key !== data.session_key ? q : data));
      }
      dispatch({
        type: QUEUED,
        payload: {
          queued: queuedSort(queued.queued),
          queueId: data.queue_name,
        },
      });
    }
  }
};

/**
 *
 * @param data
 */
export const removeQueued = (data) => (dispatch, getState) => {
  if (getState().agent.get('queues').entities[data.queue_name]) {
    const queued = getState().agent.get('queued').entities[data.queue_name];
    if (queued) {
      const filter_queued = _.filter(queued.queued, (waiter) => waiter.session_key !== data.session_key);
      // sort filter_queued
      dispatch({
        type: QUEUED,
        payload: {
          queued: filter_queued,
          queueId: data.queue_name,
        },
      });
    }
  }
};

/**
 *
 * @param queue
 */
export const prioritizeQueued = (queue) => () => {
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'queued',
    action: 'update',
    user: decodedToken.user,
    domain: decodedToken.domain,
    queue_name: queue.queue_name,
    session_key: queue.session_key,
    prioritized: 'yes',
  };
  nsApi.post(params);
};

export const transferQueued = (queued_key, destination = null) => () => {
  const decodedToken = nsToken.getDecoded();
  const dest = destination !== null ? destination : decodedToken.sub;
  const params = {
    object: 'call',
    action: 'xfer',
    uid: decodedToken.sub,
    callid: queued_key,
    destination: dest,
  };
  nsApi.post(params);
};

/**
 *
 * @param entry
 */
export const updateQueueEntry = (entry) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();
  let agent = getAgentType(getState());
  if (!agent) { agent = decodedToken.sub; }
  const params = {
    object: 'agent',
    action: 'update',
    user: decodedToken.user,
    domain: decodedToken.domain,
    queue: entry.queue_name,
    device: agent,
    entry_action: entry.action,
    toAsQueue: entry.toAsQueue || 'no',
    format: 'json',
  };
  if (entry.option) {
    params.entry_option = entry.option;
  }
  nsApi.post(params);
};

export const createAgentLog = (mode) => () => {
  const decodedToken = nsToken.getDecoded();
  // let agent = getAgentType(getState());
  // Agent no longer needed. User is enough
  // if(!agent) { agent = decodedToken.sub }
  const params = {
    object: 'agentlog',
    action: 'create',
    user: decodedToken.user,
    domain: decodedToken.domain,
    //  agent       : agent,
    mode,
    format: 'json',
  };
  nsApi.post(params);
};

export const postAgentStatus = (status) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();
  let agent = getAgentType(getState());
  if (!agent) { agent = decodedToken.sub; }
  const params = {
    object: 'subscriber',
    action: 'update',
    user: decodedToken.user,
    domain: decodedToken.domain,
    id: agent,
    message: status,
    noAuditLog: 'yes',
  };
  nsApi.post(params);
};

/**
 *
 * @return {function(*)}
 */
export const fetchSumByHour = () => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'cdr',
    action: 'read',
    option: 'summaryByHour',
    duration: '12 HOUR',
    user: decodedToken.user,
    domain: decodedToken.domain,
  }).then((res) => {
    const hourTable = [
      ['Hour', 'Calls'],
    ];

    while (res[0] && moment.utc(res[0].dayAndHour).local() < moment().subtract(13, 'hours')) { res.shift(); }

    for (let i = 12; i >= 0; i--) {
      if (res[0] && moment.utc(res[0].dayAndHour).local().format('ha') === moment().subtract(i, 'hours').format('ha')) {
        hourTable.push([moment.utc(res[0].dayAndHour).local().format('ha'), Number(res[0].volume)]);
        res.shift();
      } else {
        hourTable.push([moment().subtract(i, 'hours').format('ha'), 0]);
      }
    }

    dispatch({
      type: HOUR_STATS,
      payload: hourTable,
    });
  }).catch((err) => {
    console.error('Fetching Summary bu Hour failed:', err);
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @return {function(*)}
 */
export const fetchSumByDay = () => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'cdr',
    action: 'read',
    option: 'summaryByDay',
    duration: '7 DAY',
    user: decodedToken.user,
    domain: decodedToken.domain,
  }).then((res) => {
    const dayTable = [
      ['Day', 'Calls'],
    ];

    for (let i = 7; i >= 0; i--) {
      if (res[0] && moment(res[0].dayAndHour).format('ddd') === moment().subtract(i, 'days').format('ddd')) {
        dayTable.push([moment(res[0].dayAndHour).local().format('ddd'), Number(res[0].volume)]);
        res.shift();
      } else {
        dayTable.push([moment().subtract(i, 'days').format('ddd'), 0]);
      }
    }

    dispatch({
      type: DAY_STATS,
      payload: dayTable,
    });
  }).catch((err) => {
    console.error('Fetching Summary by Day failed:', err);
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @return {function(*)}
 */
export const fetchSumByUser = () => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'cdr',
    action: 'read',
    option: 'summaryByUser',
    range_interval: '12 HOUR',
    end_date: moment().utcOffset(0).format('YYYY-MM-DD'),
    user: decodedToken.user,
    domain: decodedToken.domain,
  }).then((res) => {
    console.log('summaryByUser', res);
    const callStats = [];
    if (res) {
      res.forEach((item) => {
        if (item.by_sub !== decodedToken.user) {
          callStats.push([item.by_sub, Number(item.volume)]);
        }
      });
    }
    dispatch({
      type: CALL_STATS,
      payload: callStats,
    });
  }).catch((err) => {
    console.error('Fetching Summary by User failed:', err);
  });
};

/**
 *
 * @return {function(*)}
 */
export const fetchAgentKPI = () => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'statistics',
    action: 'read',
    user: decodedToken.user,
    domain: decodedToken.domain,
    type: 'agent',
    fields: 'CH,TT,ATT,IA,IM,IAVG,OATT,OM,OANS',
    op_term_sub: decodedToken.user,
    range_interval: `${Math.abs(moment.tz(getState().user.timeZone).utcOffset() / 60)} HOUR`,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: `${moment().format('YYYY-MM-DD')} 23:59:59`,
  }).then((res) => {
    console.log('fetchAgentKPI', res[decodedToken.user]);
    const kpistats = res[decodedToken.user];
    if (kpistats) {
      if (kpistats.OM) {
        kpistats.OM = Math.round(kpistats.OM / 60);
      }
      if (kpistats.IM) {
        kpistats.IM = Math.round(kpistats.IM / 60);
      }
      dispatch({
        type: KPI_STATS,
        payload: kpistats,
      });
    }
  }).catch((err) => {
    console.error('Fetching Summary by User failed:', err);
  });
};

export const fetchWrapUp = () => (dispatch) => {
  dispatch(fetchInDispositions());
  dispatch(fetchOutDispositions());
};

export const showWrapUpDialog = (details) => (dispatch, getState) => {
  if (
    (getState().configs.get('portalCallPopupShowNotes') === 'yes'
            || getState().configs.get('portalCallPopupShowDispNotes') === 'yes')
        && (getState().agent.get('showAgentCenter'))
        && (details.direction === 'inbound'
          ? getState().agent.get('dispositionsin').length > 0
          : getState().agent.get('dispositionsout').length > 0)
  ) {
    const cardMeta = {
      type: 'wrapup',
      details,
    };
    // create card
    let cardId = cardActions.getCardId(cardMeta);

    if (!cardId) {
      dispatch(cardActions.newCard(cardMeta));
      cardId = cardActions.getCardId(cardMeta);
    }

    dispatch(cardActions.setExpanded(cardId));
  }
};
/**
 *
 * @return {function(*)}
 */
export const fetchInDispositions = () => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'disposition',
    action: 'read',
    disposition_type: 'inbound',
    domain: decodedToken.domain,
  }).then((res) => {
    console.log('fetchInDispositions', res);
    if (res) {
      dispatch({
        type: DISPOSITIONS_IN,
        payload: res,
      });
    }
  }).catch((err) => {
    console.error('Fetching Summary by User failed:', err);
  });
};

/**
 *
 * @return {function(*)}
 */
export const fetchOutDispositions = () => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'disposition',
    action: 'read',
    disposition_type: 'outbound',
    domain: decodedToken.domain,
  }).then((res) => {
    console.log('fetchOutDispositions', res);
    if (res) {
      dispatch({
        type: DISPOSITIONS_OUT,
        payload: res,
      });
    }
  }).catch((err) => {
    console.error('Fetching Summary by User failed:', err);
  });
};

export const postWrapUp = (details) => () => {
  const decodedToken = nsToken.getDecoded();
  nsApi.get({
    object: 'cdr2',
    action: 'read',
    orig_callid: details.callId,
    uid: decodedToken.sub,
    start_date: moment().utcOffset(0).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'),
  }).then((res) => {
    const params = {
      object: 'cdr2',
      action: 'update',
      uid: decodedToken.sub,
      cdr_id: res[0].cdr_id,
      disposition: details.disposition,
      reason: details.reason,
    };
    if (details.notes) {
      params.notes = details.notes;
    }
    nsApi.post(params);
  });
};

/** ********************************************
 * Selectors
 ******************************************** */
/**
  *
  * @param state
  * @return {boolean}
  */
export const getLoading = (state) => state.agent.get('loading');

/**
  *
  * @param state
  * @return {boolean}
  */
export const getShowAgentCenter = (state) => state.agent.get('showAgentCenter');

/**
  *
  * @param state
  * @return {boolean}
  */
export const getAgentType = (state) => state.agent.get('agenttype');

/**
 *
 * @param state
 * @return {[]}
 */
export const getAgentQueues = (state) => state.agent.get('queues').toArray();

/**
 *
 * @param state
 * @return {[]}
 */
export const getAllResponders = (state) => state.agent.get('responders').toArray();

/**
 *
 * @param state
 * @return {[]}
 */
export const getQueue = (state, id) => state.agent.get('queues').entities[id] || null;

export const getQueueName = (state, id) => {
  if (state.agent.get('queues').entities[id]) {
    return state.agent.get('queues').entities[id].description;
  }
  return null;
};

/**
 *
 * @param state
 * @return {[]}
 */
export const getResponders = (state, id) => {
  // console.log('getResponders', id, state.agent.get('responders').entities[id].responders)
  if (state.agent.get('responders').entities[id]) {
    return state.agent.get('responders').entities[id].responders;
  }
  return [];
};

/**
 *
 * @param state
 * @return {[]}
 */
export const getQueued = (state, id) => {
  // console.log('getResponders', id, state.agent.get('responders').entities[id].responders)
  if (state.agent.get('queued').entities[id]) {
    return state.agent.get('queued').entities[id].queued;
  }
  return [];
};

/**
 *
 * @param state
 * @return {[]}
 */
export const getAllQueued = (state) => state.agent.get('queued').entities;

/**
 *
 * @param state
 * @return {[]}
 */
export const getAgentStatus = (state) => state.agent.get('agentstatus');

/**
 *
 * @param state
 * @return {[]}
 */
export const getHourStats = (state) => state.agent.get('hourstats');

/**
 *
 * @param state
 * @return {[]}
 */
export const getDayStats = (state) => state.agent.get('daystats');

/**
 *
 * @param state
 * @return {[]}
 */
export const getCallStats = (state) => state.agent.get('callstats');

/**
 *
 * @param state
 * @return {}
 */
export const getKPIStats = (state) => state.agent.get('kpistats');

/**
 *
 * @param state
 * @return {[]}
 */
export const getDispositions = (state, direction) => {
  if (direction === 'inbound') {
    return state.agent.get('dispositionsin');
  } if (direction === 'outbound') {
    return state.agent.get('dispositionsout');
  }
  console.error('invalid disposition direction');
  return [];
};

/**
 *
 * @param state
 * @return {[]}
 */
export const getDispositionsOut = (state) => state.agent.get('dispositionsout');

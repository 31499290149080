export default (
  $locationProvider,
  $mdThemingProvider,
  $urlRouterProvider,
  $translateProvider,
) => {
  // remove the default hash from the url
  $locationProvider.html5Mode(true);

  // if a valid route is not found, default to the init state
  $urlRouterProvider.otherwise(`${location.pathname}init${location.search}`);

  // $translateProvider.useSanitizeValueStrategy('sanitize');
  $translateProvider.useSanitizeValueStrategy(null);

  // during configuration we save the portalUrl to retrieve language .json files
  const portalURL = localStorage.getItem('portalURL');

  $translateProvider.useStaticFilesLoader({
    prefix: `${portalURL}webroot/locale/wp/`,
    suffix: '.json',
  });
  $translateProvider.preferredLanguage('en_us');
  $translateProvider.useLocalStorage();
};

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.call-park-list', {
      url: 'call-park-list',
      params: {
        context: null, // modal
        workflow: null,
      },
      template: '<nets-call-park-list></nets-call-park-list>',
    });
};

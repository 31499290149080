export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.address-notify', {
      url: 'address-notify',
      params: {
        address: null,
      },
      template: '<nets-address-notify></nets-address-notify>',
    });
};

import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { Map } from 'immutable';
import Push from 'push.js';
import socketIO from 'socket.io-client/dist/socket.io.min';
import truncate from 'truncate';
import crypto from 'crypto-browserify';

import * as angularActions from '../angular';
import {
  updateResponder, updateAgentPortalStatus, updateQueuesAgentStatus, updateQueued, removeQueued,
} from '../agent-center';
import { validateParkId, updateParked, removeParked } from '../call-park';
import {
  addMessage, fetchSession, getMessages,
} from '../chat';
import { bugsnagClient } from '../../bugsnag';
import { fetchCallHistory } from '../call-history';
import { fetchVoicemail } from '../voicemail';
import * as cardActions from '../card-management';
import * as chatActions from '../chat';
import { selectNetworkConnected } from '../network';
import { matchContact } from '../contacts';
import * as navActions from '../state-history';
import * as presenceAction from '../presence';
import * as sessionActions from '../sessions';
import * as uaActions from '../ua';

import en_tt from '../../assets/locals/en_tt.json';
import en_us from '../../assets/locals/en_us.json';
import es_mx from '../../assets/locals/es_mx.json';
import fr_ca from '../../assets/locals/fr_ca.json';

/** ********************************************
 * Redux constants
 ******************************************** */

export const IS_CONNECTED = 'core/socket/IS_CONNECTED';
export const SOCKET = 'core/socket/SOCKET';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  isConnected: null,
  socket: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_CONNECTED:
      return state.set('isConnected', action.payload);
    case SOCKET:
      return state.set('socket', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

// connect error
const sendError = (message, opt) => {
  const { onLine } = navigator; // get network status at the time of the error

  // the loop handles offline cases
  // the error will send after the browser is back online
  const interval = setInterval(() => {
    if (navigator.onLine) {
      bugsnagClient.notify(new Error(message), { metaData: { ...opt, onLine } });
      clearInterval(interval);
    }
  }, 10000);
};

export const connect = () => (dispatch, getState) => {
  const { domain } = nsToken.getDecoded();
  const nsSocketURLs = getState().configs.get('nsSocketURLs');
  const userId = getState().configs.get('userId');
  const token = nsToken.get();

  // preparation for call/uc-item handling
  const appTitle = getState().configs.get('wpName');
  const aLanguage = [en_tt, en_us, es_mx, fr_ca];
  let callIds = [];
  let interval;
  let titleIndex = 0;
  let titlesArray;

  const confBridgebuffer = {};

  const _changeTitle = function () {
    if (titlesArray.length === 0 || callIds.lenth === 0) {
      document.title = getState().configs.get('wpName');
    } else {
      titleIndex = (titleIndex + 1) % titlesArray.length;
      document.title = titlesArray[titleIndex];
    }
  };

  const _pushConferenceList = function (session, data) {
    const mydeviceId = getState().user.user + getState().configs.get('devicePostFix');
    const contact = matchContact(data.orig_from_user);
    if (data.orig_from_user === mydeviceId) return;
    if (contact) {
      if (!session.conferenceList.includes(contact.name)) session.conferenceList.push(contact.name);
    } else if (data.orig_from_user) {
      if (!session.conferenceList.includes(nsUtils.formatPhoneNumber(data.orig_from_user))) {
        session.conferenceList.push(nsUtils.formatPhoneNumber(data.orig_from_user));
      }
    }
    session.callerId = session.conferenceList.join(' + ');
    session.display = true;

    dispatch(sessionActions.sessionsUpdated());
  };

  const _popConferenceList = (session, data) => {
    const contact = matchContact(data.orig_from_user);
    let index = session.conferenceList.indexOf(nsUtils.formatPhoneNumber(data.orig_from_user));
    if (index !== -1) {
      session.conferenceList.splice(index, 1);
    } else if (contact) {
      index = session.conferenceList.indexOf(contact.name);
      if (index !== -1) {
        session.conferenceList.splice(index, 1);
      }
    }
    session.callerId = session.conferenceList.join(' + ');
    session.display = true;

    dispatch(sessionActions.sessionsUpdated());
  };

  return new Promise((resolve) => {
    try {
      const socket = socketIO.connect(
        nsSocketURLs[0], {
          secure: true,
        },
      );

      socket.on('disconnect', () => {
        console.error('Socket connection disconnect');
        dispatch(setConnected(false));
        const isNetworkConnected = selectNetworkConnected(getState());
        if (isNetworkConnected) {
          const $mdToast = angularActions.getObject(getState(), '$mdToast');
          const $translate = angularActions.getObject(getState(), '$translate');
          $mdToast.show(
            $mdToast.simple()
              .textContent($translate.instant('NETWORK_LOST_CONNECTION_TOAST'))
              .position('bottom left')
              .hideDelay(5000),
          );
        }

        sendError('Socket disconnect', { connectionURL: nsSocketURLs[0] });
      });

      socket.on('error', () => {
        console.error('Socket connection error');
        dispatch(setConnected(false));

        const isNetworkConnected = selectNetworkConnected(getState());
        if (isNetworkConnected) {
          const $mdToast = angularActions.getObject(getState(), '$mdToast');
          const $translate = angularActions.getObject(getState(), '$translate');
          $mdToast.show(
            $mdToast.simple()
              .textContent($translate.instant('NETWORK_LOST_CONNECTION_TOAST'))
              .position('bottom left')
              .hideDelay(5000),
          );
        }

        sendError('Socket error', { connectionURL: nsSocketURLs[0] });

        resolve();
      });

      socket.on('reconnect_attempt', () => {
        console.error('Socket connection reconnect_attempt');
        dispatch(setConnected(false));
        const isNetworkConnected = selectNetworkConnected(getState());
        if (isNetworkConnected) {
          const $mdToast = angularActions.getObject(getState(), '$mdToast');
          const $translate = angularActions.getObject(getState(), '$translate');
          $mdToast.show(
            $mdToast.simple()
              .textContent($translate.instant('NETWORK_NO_CONNECTION_TOAST'))
              .position('bottom left')
              .hideDelay(5000),
          );
        }

        sendError('Initial socket connection error', { connectionURL: nsSocketURLs[0] });
      });

      socket.on('connect', () => {
        dispatch(setSocket(socket));
        dispatch(setConnected(true));

        setTimeout(() => {
          if (socket) {
            socket.emit('subscribe', {
              domain,
              type: 'call',
              filter: userId,
              bearer: token,
            });
            socket.emit('subscribe', {
              domain,
              type: 'chat',
              user: userId,
              bearer: token,
            });
            socket.emit('subscribe', {
              domain,
              type: 'voicemail',
              filter: userId,
              bearer: token,
            });

            socket.emit('subscribe', {
              domain,
              type: 'contacts',
              bearer: token,
            });
            socket.emit('subscribe', {
              domain,
              type: 'ucchat',
              filter: userId,
              bearer: token,
            });
            socket.emit('subscribe', {
              domain,
              type: 'agent',
              unit: 'seconds',
              user: userId,
              bearer: token,
            });
            socket.emit('subscribe', {
              domain,
              type: 'queue',
              bearer: token,
            });
          }
        }, 200);

        resolve();
      });

      socket.on('call_unmute', (data) => {
        if (callIds.includes(data.callid) ) {
          const sessions = sessionActions.getSessions(getState());
          if (sessions.length) {
            for (let i = 0; i < sessions.length; i += 1) {
              if (sessions[i].isOnHold)
              {
                sessions[i].unhold();
              }
            }
          }
        }
      });

      socket.on('call', (data) => {
        // ignore, the socket updates twice for some reason when a call has ended
        // the call has been removed already the first time it was called

        if (data.dnis && data.dnis.includes('adhoc.monitor.')) {
          const cacheId = data.dnis.replace('X', '');
          if (!confBridgebuffer[cacheId]) confBridgebuffer[cacheId] = [];
          const contact = matchContact(data.orig_from_user);
          if (contact && contact.name) {
            confBridgebuffer[cacheId].push(contact.name);
          } else if (data.orig_from_user) {
            confBridgebuffer[cacheId].push(nsUtils.formatPhoneNumber(data.orig_from_user));
          }
        }

        if (validateParkId(getState(), data.term_user)) {
          if (data.remove && data.remove === 'yes') {
            dispatch(removeParked(data));
          } else {
            dispatch(updateParked(data));
          }
          return;
        }

        if (!callIds.includes(data.orig_callid) && data.remove === 'yes') {
          return;
        }

        // check if the call has already been added
        if (callIds.includes(data.orig_callid)) {
          // check if the call is being removed
          if (data.remove === 'yes') {
            const sessions = sessionActions.getSessions(getState());
            if (sessions.length) {
              for (let i = 0; i < sessions.length; i += 1) {
                if (data.dnis
                    && data.dnis.includes('adhoc.monitor.')
                    && sessions[i].name
                    && sessions[i].name.includes(data.dnis.replace('X', ''))
                ) {
                  _popConferenceList(sessions[i], data);
                }
              }
            }

            // remove the id from the array
            const updatedArr = [];
            for (let i = 0; i < callIds.length; i += 1) {
              if (callIds[i] !== data.orig_id && callIds[i] !== data.orig_callid) {
                updatedArr.push(callIds[i]);
              }
            }
            // reset the callIds array with the updated array
            callIds = updatedArr;

            // if there are no more calls, clear the interval and set the title
            if (callIds.length === 0) {
              clearInterval(interval);
              interval = null;
              document.title = appTitle;
              titlesArray = [appTitle];
            }

            // update lists
            setTimeout(() => {
              dispatch(fetchCallHistory());
              // dispatch(fetchVoicemail());
            }, 4000);
          } else {
            const sessions = sessionActions.getSessions(getState());
            if (sessions.length) {
              for (let i = 0; i < sessions.length; i += 1) {
                if (data.dnis
                    && data.dnis.includes('adhoc.monitor.')
                    && sessions[i].name
                    && sessions[i].name.includes(data.dnis.replace('X', ''))
                ) {
                  _pushConferenceList(sessions[i], data);
                } else if (sessions[i].name
                    && data.dnis
                    && sessions[i].name.includes(data.dnis.replace('X', ''))
                ) {
                  if (sessions[i].name && confBridgebuffer[sessions[i].name]) {
                    for (let index = 0; index < confBridgebuffer[sessions[i].name].length; index += 1) {
                      _pushConferenceList(sessions[i], confBridgebuffer[sessions[i].name][index]);
                    }
                  }
                }
              }
            }
          }

          return;
        }

        // reset the title array, preps for the update language string
        titlesArray = [appTitle];

        // only start a new interval if one isn't running already
        // this happens when there are multiple inbound calls
        if (!interval) {
          interval = setInterval(_changeTitle, 1300);
        }

        const devicePostfix = getState().configs.get('devicePostFix');

        // update the sessions callId
        const sessions = sessionActions.getSessions(getState());
        if (sessions.length) {
          for (let i = 0; i < sessions.length; i += 1) {
            if (data.dnis
                && data.dnis.includes('adhoc.monitor.')
                && sessions[i].name
                && sessions[i].name.includes(data.dnis.replace('X', ''))
            ) {
              _pushConferenceList(sessions[i], data);
            } else if (data.orig_call_info === 'progressing'
                && (data.ani === sessions[i].userId
                  || data.ani === `${sessions[i].userId}${devicePostfix}`
                  || data.ani === sessions[i].number || data.ani === 'Call-To-Talk'
                  || data.ani === 'Call-Record-Account')
            ) {
              sessions[i].callId = data.orig_callid;
              sessions[i].parkId = data.orig_callid;
              sessions[i].callerId = data.orig_name;
              sessions[i].display = true;
              dispatch(sessionActions.sessionsUpdated());
              dispatch(uaActions.icUpdated());
            } else if (sessions[i].name
                && data.dnis
                && sessions[i].name.includes(data.dnis.replace('X', ''))
            ) {
              if (confBridgebuffer[sessions[i].name]) {
                for (let index = 0; index < confBridgebuffer[sessions[i].name].length; index++) {
                  const d = confBridgebuffer[sessions[i].name][index];
                  _pushConferenceList(sessions[i], d);
                }
              }
              sessions[i].display = true;
              dispatch(sessionActions.sessionsUpdated());
            } else if (data.orig_call_info === 'progressing' && sessions.length === 1 && data) {
              // failcase to show inbound call even if we can't match it properly.
              sessions[i].display = true;
              sessions[i].parkId = getState().configs.get('userId') === data.orig_user ? data.term_callid : data.orig_callid;

              dispatch(sessionActions.sessionsUpdated());
              dispatch(uaActions.icUpdated());
            }
          }
          // keep track of the number so it doesn't get added again
          callIds.push(data.orig_callid);

          // grab user language if not default to en_us
          // it may have changed since the last time this function has run
          const userLanguage = (getState().user.language || 'en_us').toLowerCase();
          for (let i = 0; i < aLanguage.length; i += 1) {
            if (aLanguage[i].name === userLanguage) {
              if (aLanguage[i].ON_A_CALL) {
                titlesArray.push(aLanguage[i].ON_A_CALL);
              }
            }
          }
        }
      });

      socket.on('chatavailable', (data) => {
        if (data && data.length) {
          for (let i = 0; i < data.length; i += 1) {
            dispatch(presenceAction.setChatPresence(data[i], true));
          }
        }
      });

      socket.on('contacts-domain', (data) => {
        dispatch(presenceAction.setPresence(data.user, data.presence));
      });

      socket.on('uc-mesg', (data) => {
        // Get the user's uid
        const uid = getState().user && getState().user.uid ? getState().user.uid : null;

        if (data.type === 'chat-media' || data.type === 'mms') {
          // need to get remotepath/hash for incoming chat-media messages
          const { user } = getState();

          let remotepath = `https://${data.rx_hostname}/ns-api/?object=message&action=read_media`;
          remotepath += `&domain=${user.domain}`;
          remotepath += `&user=${user.userId}`;
          remotepath += `&id=${data.id}`;

          // get date in YmdHis in UTC
          const date = new Date();
          date.setMonth(date.getMonth() + 1); // set to one month else might expire

          const year = date.getUTCFullYear();
          const month = (date.getUTCMonth() < 10 ? '0' : '') + date.getUTCMonth();
          var minute = ((date.getUTCMinutes() + 1) < 10 ? '0' : '') + (date.getUTCMinutes() + 1);
          const day = (date.getUTCDate() < 10 ? '0' : '') + date.getUTCDate();
          const hour = (date.getUTCHours() < 10 ? '0' : '') + date.getUTCHours();
          var minute = (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes();
          const second = (date.getUTCSeconds() < 10 ? '0' : '') + date.getUTCSeconds();
          const timestamp = `${year}${month}${day}${hour}${minute}${second}`;
          remotepath += `&time=${timestamp}`;

          const toHash = `${user.domain}${user.userId}${data.id}${timestamp}${user.subscriberPin}`;

          const auth = crypto.createHash('md5').update(toHash).digest('hex');
          remotepath += `&auth=${auth}`;

          data.remotepath = remotepath;
        }

        // Filter out your own messages being sent
        // let in chat-name-change messages
        if ((uid && data.from_uid != uid)
              || (data.type === 'chat-name-change' && data.from_uid == uid)
              || (data.type === 'chat-participant-add' && data.from_uid == uid)
              || (data.type === 'chat-participant-leave' && data.from_uid == uid)
              || (data.type === 'chat-participant-remove' && data.from_uid == uid)) {
          // Fetch/update session
          dispatch(fetchSession(data.session_id));

          // Match the message to a contact via uid from number.
          let contact;
          if (data.from_uid) {
            contact = matchContact(data.from_uid.split('@')[0]);
          } else {
            contact = matchContact(data.from_num);
          }

          // add from_contact for incoming messages
          data.from_contact = contact;

          // Check if messages are already init, if so add the message.
          // No need to fetchMessages because the card has logic for fetching.
          // The purpose of the logic being in the card is it handles both cases,
          // the first case is here, adding a new message, the other is
          // when the card is opened from the ui.
          const messages = getMessages(getState(), data.session_id);
          if (messages && messages.init) {
            dispatch(addMessage(data));
          }

          // Check if the chat card for this session is already open,
          // if not create one
          const cardId = cardActions.getCardId({
            sessionId: data.session_id,
          });

          // change the card sendMessageParams here when participants have been changed,
          // or even if the remote has more than one destination, need to make card correctly
          if (cardId && (data.type === 'chat-participant-add'
              || data.type === 'chat-participant-leave'
            || data.type === 'chat-participant-remove'
          )
          ) {
            const card = cardActions.getCard(getState(), cardId);

            // set new destination and remote for the existing card on group chat participant changes
            const groupDest = cardActions.getGroupDestination({ data });
            const groupRemote = cardActions.getGroupRemote({ data });
            card.meta.sendMessageParams.destination = groupDest;
            card.meta.sendMessageParams.remote = groupRemote;

            // change cardmeta:
            // console.log('this is new cardMeta: ', card.meta);
            dispatch(cardActions.updateChatContacts());
            // cardActions.updateCardSendParams(cardId, card.meta);
            // dispatch(cardActions.updateCardSendParams(cardId, card.meta));
            // console.log('after updateCardSendParams');
          }

          // if video invite came in, put in video invite state
          if (cardId && data.type === 'chat-video-invite') {
            const card = cardActions.getCard(getState(), cardId);
            card.meta.videoInvite = true;
            card.meta.videoId = data.text;
            card.meta.videoInviteFrom = matchContact(data.from_uid);
          }

          if (!cardId) {
            // Create new card
            // Pre-build sendMessage params used later for sending messages back
            // This also determines if it is another user or sms
            const sendMessageParams = {
              sessionId: data.session_id,
            };

            console.log('data.type', data.type);

            if (data.type === 'chat' || data.type === 'chat-media' || data.type === 'chat-video-invite') {
              // for group chat for card that doesnt exist yet
              if (data.participants && data.participants.includes(',')) {
                sendMessageParams.destination = cardActions.getGroupDestination({ data });
                sendMessageParams.remote = cardActions.getGroupRemote({ data });
                sendMessageParams.fromUID = uid;
              } else {
                sendMessageParams.destination = data.from_uid;
                sendMessageParams.fromUID = data.term_uid;
              }
            } else if (data.type === 'sms' || data.type === 'mms') {
              // for group mms for card that doesnt exist yet
              if (data.participants && data.participants.includes(',')) {
                sendMessageParams.destination = cardActions.getGroupDestination({ data });
                sendMessageParams.fromNum = nsUtils.cleanPhoneNumber(data.from_num);
                contact = undefined; // should not allow contact to be chosen

                if (getState().user.sms !== undefined) {
                  // split the destination by comma and take out the matching current user's sms
                  var destArr = sendMessageParams.destination.split(',');
                  for (let i = destArr.length - 1; i >= 0; i -= 1) {
                    for (let j = 0; j < getState().user.sms.length; j += 1) {
                      if (destArr[i] === getState().user.sms[j].number) {
                        sendMessageParams.fromNum = nsUtils.cleanPhoneNumber(destArr[i]);
                        destArr.splice(i, 1);
                        continue;
                      }
                    }
                  }
                }
                sendMessageParams.destination = destArr.join(',');
                sendMessageParams.remote = destArr.join(',');
                sendMessageParams.numPart = destArr.length;

                // get nameList for new group mms
                const nameListArr = [];
                for (let x = 0; x < destArr.length; x += 1) {
                  const match = matchContact(destArr[x]);
                  if (match) {
                    // matched, add the name of matched contact to namelist
                    nameListArr.push(match.name);
                  } else {
                    // did not match, add the destination number to namelist
                    nameListArr.push(destArr[x]);
                  }
                }
                sendMessageParams.nameList = nameListArr.join(',');

                // filter out this user's own sms number from destination
              } else {
                // for 1 on 1 sms for card that doesnt exist yet
                sendMessageParams.destination = nsUtils.cleanPhoneNumber(data.from_num);
                sendMessageParams.fromNum = nsUtils.cleanPhoneNumber(data.dialed);
              }
            } else {
              throw new Error(`Socket uc-mesg unknown type: ${data.type}`);
            }

            // Build card meta
            const cardMeta = {
              sendMessageParams,
              sessionId: data.session_id,
            };

            // if video invite came in, put in video invite state
            if (data.type === 'chat-video-invite') {
              cardMeta.videoInvite = true;
              cardMeta.videoId = data.text;
              cardMeta.videoInviteFrom = matchContact(data.from_uid);
            }

            // Add contact id to the card meta if contact is set
            if (contact) {
              cardMeta.contactId = contact.id;
            }

            // No need to fetch previous messages
            // The new card on load will fetch the messages
            dispatch(cardActions.newCard(cardMeta));
          }

          // Get variables to test if the push notification should be shown
          const isCompact = getState().screenSize.display === 'compact';
          const windowInFocus = document.hasFocus();
          // const currentState = navActions.getCurrentState(getState());
          // let sessionInFocus = false;
          // if (currentState.params &&
          //     currentState.params.card &&
          //     currentState.params.card.meta.sessionId &&
          //     currentState.params.card.meta.sessionId === data.session_id
          // ) {
          //     sessionInFocus = true;
          // }

          // Test if a push notification should be shown
          // if (!windowInFocus || (isCompact && !sessionInFocus)) {
          if (!windowInFocus) {
            let from;
            if (contact) {
              from = contact.name;
            } else if (data.from_num) {
              from = nsUtils.formatPhoneNumber(data.from_num);
            }
            let tabFrom;
            if (contact) {
              if (contact.name) {
                tabFrom = contact.name;
              } else if (contact.extension) {
                tabFrom = contact.extension;
              } else {
                tabFrom = nsUtils.formatPhoneNumber(data.from_num);
              }
            } else if (data.from_num) {
              tabFrom = nsUtils.formatPhoneNumber(data.from_num);
            }

            // Grab user language if not default to en_us
            const userLanguage = (getState().user.language || 'en_us').toLowerCase();

            // here is the push notification for [wp-984], should get the session data and see if it is muted or not
            const sessionData = chatActions.getSession(getState(), data.session_id);
            // if session is not muted, then show the notification and browser bar title changes
            if (sessionData != undefined && sessionData.muted != 'yes') {
              let pushTitle = `${from} says...`;
              let tabTitle = `${tabFrom} says...`;

              for (let i = 0; i < aLanguage.length; i += 1) {
                if (aLanguage[i].name === userLanguage) {
                  pushTitle = `${from} ${aLanguage[i].SAYS}...`;
                  tabTitle = `${tabFrom} ${aLanguage[i].SAYS}...`;
                }
              }

              // clean html tags for links
              const strippedString = data.text.replace(/(<([^>]+)>)/gi, '');

              Push.create(pushTitle, {
                body: truncate(strippedString, 50, {}),
                icon: getState().configs.get('faviconURL'),
                timeout: 10000,
                onClick() {
                  if (isCompact) {
                    const card = cardActions.getCard(getState(), cardId);
                    const currentState = getState().stateHistory.get('currentState');
                    dispatch(
                      navActions.navStackPush({
                        name: 'wrapper.chat-session',
                        params: {
                          card,
                        },
                        fromName: currentState.name,
                        fromParams: currentState.params,
                      }),
                    );

                    const $state = getState().angular.get('$state');
                    $state.go('wrapper.chat-session', {
                      card,
                    });
                  }

                  window.focus();
                  this.close();
                },
              }).catch(() => {});

              titlesArray = [tabTitle, appTitle];

              if (interval) {
                clearInterval(interval);
                interval = null;
              }

              interval = setInterval(_changeTitle, 1300);

              window.onfocus = function () {
                titlesArray = [];
                clearInterval(interval);
                interval = null;
                document.title = appTitle;
              };
            }
          }
        } else {
          // [WP-885] add the message if doesnt exist (was sent from portal)
          const messages = getMessages(getState(), data.session_id);

          // find in messagesRaw, if not in raw, should have been from another source, so add to webphone's state
          let toAdd = true;
          if (messages !== null && typeof messages !== 'undefined' && typeof messages.messagesRaw !== 'undefined') {
            for (let r = 0; r < messages.messagesRaw.length; r++) {
              if (messages.messagesRaw[r].id == data.id || messages.messagesRaw[r].id.toString() == data.id
                  || messages.messagesRaw[r].dateTimeAPI == data.timestamp) {
                toAdd = false;
              }
            }
          }
          if (messages && messages.init) {
            if (toAdd) {
              dispatch(addMessage(data));
            }
          }
        }
      });

      socket.on('chat_leave', (data) => {
        dispatch(presenceAction.setChatPresence(data, false));
      });

      socket.on('agent', (data) => {
        dispatch(updateResponder(data));
        if (data.device_aor === getState().agent.get('agenttype')) {
          dispatch(updateQueuesAgentStatus(data));
        }
      });

      socket.on('contacts-domain', (data) => {
        if (data.user === getState().configs.get('userId')) {
          dispatch(updateAgentPortalStatus(data));
        }
      });

      socket.on('queued', (data) => {
        if (data.remove && data.remove === 'yes') {
          dispatch(removeQueued(data));
        } else {
          dispatch(updateQueued(data));
        }
      });

      socket.on('voicemail', (data) => {
        dispatch(fetchVoicemail());
        const isCompact = getState().screenSize.display === 'compact';
        const windowInFocus = document.hasFocus();

        if (data && data.action && data.action == 'vmail_update') return;

        // if (!windowInFocus) {
        let from;
        if (data && data.orig_from_name) {
          from = data.orig_from_name;
        } else if (data && data.orig_from_uri) {
          from = nsUtils.formatPhoneNumber(data.orig_from_uri);
        }
        const tabFrom = from;

        // Grab user language if not default to en_us
        const userLanguage = (getState().user.language || 'en_us').toLowerCase();

        let pushTitle = `New Voicemail from ${from}`;

        if (aLanguage && aLanguage != undefined) {
          for (let i = 0; i < aLanguage.length; i += 1) {
            if (aLanguage[i].name === userLanguage) {
              pushTitle = `${aLanguage[i].NEW_VOICEMAIL_FROM} ${from}`;
            }
          }
        }

        Push.create(pushTitle, {
          body: truncate(data.text, 50, {}),
          icon: getState().configs.get('faviconURL'),
          timeout: 15000,
          onClick() {
            const $state = getState().angular.get('$state');
            $state.go('wrapper.voicemail-list');

            if (isCompact) {
              const card = cardActions.getCard(getState(), cardId);
              const currentState = getState().stateHistory.get('currentState');

              dispatch(
                navActions.navStackPush({
                  name: 'wrapper.voicemail-list',

                }),
              );
            }

            window.focus();
            this.close();
          },
        }).catch(() => {});

        //  }
      });
    } catch (err) {
      dispatch(setSocket(false));
      dispatch(setConnected(false));
      resolve();
    }
  });
};

export const disconnect = () => (dispatch, getState) => {
  const socket = getState().socket.get('socket');
  socket.disconnect();
  return Promise.resolve();
};

export const setConnected = (isConnected) => ({
  type: IS_CONNECTED,
  payload: isConnected,
});

export const setSocket = (payload) => ({
  type: SOCKET,
  payload,
});

export const subscribe = (payload) => (dispatch, getState) => {
  if (!getState().socket.get('isConnected')) {
    setTimeout(() => {
      dispatch(subscribe(payload));
    }, 1000);
    return;
  }
  const socket = getState().socket.get('socket');
  const sub = {
    bearer: nsToken.get(),
    domain: nsToken.getDecoded().domain,
    user: getState().configs.get('userId'),
    ...payload,
  };
  socket.emit('subscribe', sub);
};

import './styles.css';
import template from './template.html';

import * as navActions from '../../core/state-history';
import * as contactActions from '../../core/contacts';
import { setExpanded } from '../../core/card-management';
import * as chatActions from '../../core/chat';
import { getShowAgentCenter } from '../../core/agent-center';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdSidenav',
  '$mdColors',
  '$ngRedux',
  '$state',
  '$translate',
];
function controller(
  $mdSidenav,
  $mdColors,
  $ngRedux,
  $state,
  $translate,
) {
  let unsubscribe = null;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => {
      const res = {
        currentState: navActions.getCurrentState(state),
        screenSize: state.screenSize,
        unreadCount: chatActions.getUnreadCount(state),
        allowChat: state.configs.get('portalChatSMS'),
        showAgentCenterMenu: getShowAgentCenter(state),
        newVoicemail: state.voicemail.get('newVoicemail'),
        contactCenterName: state.configs.get('PORTAL_CONTACT_CENTER_FEATURE_NAME'),

      };

      if ($state.params.contactID) {
        res.contactName = contactActions.getContact(state, $state.params.contactID).name;
      }

      if ($state.params.queue) {
        res.queue = $state.params.queue;
      }

      return res;
    })((state) => {
      this.currentState = state.currentState;
      this.screenSize = state.screenSize;
      this.unreadCount = state.unreadCount;
      this.allowChat = state.allowChat;
      this.showAgentCenterMenu = state.showAgentCenterMenu;
      this.newVoicemail = state.newVoicemail;
      this.contactCenterName = state.contactCenterName || $translate.instant('CALL_CENTER');

      const workflow = this.currentState.params ? (this.currentState.params.workflow || false) : false;

      this.display = null;
      this.removeButton = false;
      this.showBackArrow = false;
      this.showHamburger = false;
      this.showNav = false;
      this.title = null;
      this.workflow = null;

      switch (this.currentState.name) {
        case 'wrapper.agent-center-list':
          this.display = 'agent-center-list';
          this.showHamburger = true;
          this.showNav = true;
          this.title = this.contactCenterName || $translate.instant('CALL_CENTER');
          break;
        case 'wrapper.agent-queue-details':
          this.display = 'agent-queue-details';
          this.showBackArrow = true;
          this.workflow = state.queue;
          break;
        case 'wrapper.answering-rule-list':
          this.display = 'answering-rule-list';
          this.showBackArrow = true;
          this.title = $translate.instant('ANSWERING_RULES');
          break;
        case 'wrapper.answering-rule-details':
          this.display = 'answering-rule-details';
          this.showBackArrow = true;
          break;
        case 'wrapper.call-history-list':
          this.display = 'call-history-list';
          this.showHamburger = true;
          this.showNav = true;
          break;
        case 'wrapper.call-details':
          this.display = 'call-details';
          this.showBackArrow = true;
          break;
        case 'wrapper.call-park-list':
          this.display = 'call-park-list';
          this.showBackArrow = true;
          this.title = $translate.instant('CALL_PARK');
          break;
        case 'wrapper.chat-list':
          this.display = 'chat-list';
          this.showHamburger = true;
          this.showNav = true;
          this.title = $translate.instant('CHAT_SMS');
          break;
        case 'wrapper.chat-session':
          this.display = 'chat-session';
          this.showBackArrow = true;
          break;
        case 'wrapper.contact-add':
          this.showBackArrow = true;
          this.title = $translate.instant('CREATE_NEW_CONTACT');
          break;
        case 'wrapper.contact-details':
          this.display = 'contact-details';
          this.showBackArrow = true;
          this.title = state.contactName;
          this.workflow = workflow;
          break;
        case 'wrapper.contact-edit':
          this.showBackArrow = true;
          this.title = $translate.instant('EDIT_CONTACT');
          break;
        case 'wrapper.contact-list':
          this.display = workflow !== 'voicemail-forward' ? 'contact-list' : null;
          this.showBackArrow = workflow;
          this.showHamburger = !workflow;
          this.showNav = !workflow;
          this.title = workflow === 'voicemail-forward' ? 'Coworkers' : null;
          this.workflow = workflow;
          break;
        case 'wrapper.greetings-list':
          this.display = 'greetings-list';
          this.showBackArrow = true;
          this.title = $translate.instant('GREETINGS');
          break;
        case 'wrapper.greetings-new':
          this.display = 'greetings-new';
          this.removeButton = true;
          this.title = $translate.instant('NEW_GREETING');
          break;
        case 'wrapper.settings':
          this.display = 'settings';
          this.showBackArrow = true;
          this.title = $translate.instant('SETTINGS');
          break;
        case 'wrapper.settings-callfrom':
          this.display = 'settings-callfrom';
          this.showBackArrow = true;
          this.title = $translate.instant('CALL_FROM_PHONE');
          break;
        case 'wrapper.settings-callwaiting':
          this.display = 'settings-callwaiting';
          this.showBackArrow = true;
          this.title = $translate.instant('CALL_WAITING_TONE');
          break;
        case 'wrapper.settings-ringtones':
          this.display = 'settings-ringtones';
          this.showBackArrow = true;
          this.title = $translate.instant('CHOOSE_A_RINGTONE');
          break;
        case 'wrapper.voicemail-list':
          this.display = 'voicemail-list';
          this.showHamburger = true;
          this.showNav = true;
          break;
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.navBack = () => {
    let currentState;
    let lastOnStack;
    switch ($state.params.workflow) {
      case 'in-call':

        // remove last on call stack
        $ngRedux.dispatch(navActions.callStackBack());

        // get the last state on the stack
        lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');

        // go to last on stack
        $state.go(lastOnStack.name, lastOnStack.params);

        break;
      default:
        // get the last state on the stack
        currentState = navActions.getLastOnStack($ngRedux.getState(), 'navStack');

        // remove last on stack
        $ngRedux.dispatch(navActions.navStackBack());

        // get last on stack
        lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');

        // go to last on stack
        $state.go(lastOnStack.name, lastOnStack.params);

        // if the current state was a chat session set expanded to null
        if (currentState.name === 'wrapper.chat-session') {
          $ngRedux.dispatch(setExpanded(null));
        }
    }
  };

  /**
     *
     * @param name
     */
  this.navRebase = (name) => {
    $ngRedux.dispatch(
      navActions.navStackRebase({
        name,
        params: null,
        fromName: null,
        fromParams: null,
      }),
    );
  };

  /**
     *
     */
  this.openSideDrawer = () => {
    $mdSidenav('drawer').toggle();
  };
}

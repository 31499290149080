import './styles.css';
import template from './template.html';
import * as navActions from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdDialog',
  '$ngRedux',
  '$state',
  '$translate',
];
function controller(
  $mdDialog,
  $ngRedux,
  $state,
  $translate,
) {
  this.navPush = () => {
    const WPmicrophone = (localStorage.getItem('WPmicrophone') == 'true');

    if (WPmicrophone) {
      const currentState = $ngRedux.getState().stateHistory.get('currentState');
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.greetings-new',
          params: null,
          fromName: currentState.name,
          fromParams: currentState.params,
        }),
      );
      $state.go('wrapper.greetings-new');
    } else {
      $mdDialog.show({
        controller: ['$scope', function ($scope) {
          $scope.cancel = function () { $mdDialog.hide(); };
        }],
        template: `${'<md-dialog class="p-r-10 p-l-10" style="max-width: 600px; width: 90%">'
                  + '   <md-dialog-content class="m-t-20 m-r-10 m-l-10">'
                  + '         <h3 class="m-b-0">'}${$translate.instant('MICROPHONE_REQUIRED')}</h3>`
                  + `         <p>${$translate.instant('ACCESS_TO_MICROPHONE_REQUIRED')}. ${$translate.instant('ENABLE_MICROPHONE_BROWSER')}</p>`
                  + '   </md-dialog-content>'
                  + '   <md-dialog-actions>'
                  + '        <md-button class="md-primary" ng-click="cancel()">'
                  + '           OK'
                  + '       </md-button>'
                  + '   </md-dialog-actions>'
                  + '</md-dialog>',
        clickOutsideToClose: false,
      });
    }
  };
}

import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import Push from 'push.js';

import * as deviceActions from '../../devices';
import { matchContact } from '../../contacts';
import * as sessions from '../../sessions';
import { store } from '../../store';
import * as uaActions from '../../ua';

export const init = () => {
  const userId = nsToken.getDecoded().user;

  localStorage.setItem(`webphone_open_${userId}`, 'true');

  // attach unload handler
  window.onunload = () => {
    localStorage.setItem(`webphone_open_${userId}`, 'false');
  };

  // attach onstorage handler
  window.onstorage = (e) => {
    // handle launching new calls
    if (e.key === `webphone_call_${userId}` && e.newValue) {
      const state = store.getState();
      if (sessions.activeCount(state) === state.configs.get('maxActiveCalls')) {
        return;
      }

      const callFromDevice = deviceActions.selectCallFrom(store.getState());
      const contact = matchContact(e.newValue);
      let displayName = e.newValue;
      if (contact && contact.name) displayName = contact.name;
      if (!callFromDevice) {
        // send invite
        store.dispatch(uaActions.sendInvite(e.newValue));

        // push notice
        Push.create(`Calling ${displayName}`, {
          body: nsUtils.formatPhoneNumber(e.newValue),
          icon: store.getState().configs.get('faviconURL'),
          timeout: 10000,
          onClick() {
            window.focus();
            this.close();
          },
        }).catch(() => {});
      } else {
        // push notice
        Push.create(`Calling ${displayName} from ${callFromDevice.display}`, {
          body: nsUtils.formatPhoneNumber(e.newValue),
          icon: store.getState().configs.get('faviconURL'),
          timeout: 10000,
          onClick() {
            window.focus();
            this.close();
          },
        }).catch(() => {});

        store.dispatch(deviceActions.callFrom(e.newValue));
      }
    }

    // ping response
    if (e.key === `webphone_present_${userId}`) {
      localStorage.setItem(`webphone_open_${userId}`, 'true');
    }
  };
};

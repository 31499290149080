import './styles.css';
import crypto from 'crypto-browserify';
import template from './template.html';
import * as chatActions from '../../core/chat';
import { getContact } from '../../core/contacts';
import * as contactActions from '../../core/contacts';
import * as cardActions from '../../core/card-management';
import gravatarSessionId from '../../core/utils/gravatarSessionId';

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@',
  },
};

controller.$inject = [
  '$ngRedux',
  '$scope',
];

function controller(
  $ngRedux,
  $scope,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) =>
    // set a contact array here as well, so session can always be changed
      ({
        contact: getContact(state, this.card.meta.contactId),
        messagesMeta: chatActions.getMessages(state, this.card.meta.sessionId),
        screenSize: state.screenSize,
        badgeList: contactActions.getBadgeList(state), // this is all the contacts
        contactList: contactActions.getContactsResultSet(state), // this is all the contacts
        searchResultSet: contactActions.getSearchResultSet(state),
        session: chatActions.getSession(state, this.card.meta.sessionId),
        user: state.user,
        arrowkeyLocation: state.arrowkeyLocation,
        dateFormat: state.configs.get('portalLocalizationDateFormat'),
        timeFormat: state.configs.get('portalLocalizationTimeFormat'),
      }))((state) => {
      // get sms number array
      const smsNumbers = [];
      if (state.user.sms) {
        for (let i = 0; i < state.user.sms.length; i += 1) {
          smsNumbers.push(state.user.sms[i].number);
        }
      }

      // change hight of chat scroll based on context
      this.screenSize = state.screenSize;
      let chatHeight;
      let loaderSize = 20;
      switch (this.screenSize.display) {
        case 'compact':
          chatHeight = `${state.screenSize.height - 300}px`;
          loaderSize = 50;
          break;
        case 'card':
          chatHeight = '244px';
          break;
        case 'modal':
          chatHeight = '395px';
          break;
      }

      this.chatHeight = chatHeight;
      this.contact = state.contact;
      this.loaderSize = loaderSize;
      this.session = state.session;
      if (state && state.session && state.session.contact_arr) {
        this.contacts = state.session.contact_arr;
      } else if (this && this.card.meta && this.card.meta.sendMessageParams && this.card.meta.sendMessageParams.contact_arr) {
        this.contacts = this.card.meta.sendMessageParams.contact_arr;
      }
      this.smsNumbers = smsNumbers;
      this.user = state.user;
      this.groupMessagesMeta = state.messagesMeta;
      this.dateFormat = state.dateFormat;
      this.timeFormat = state.timeFormat;
      this.searchResultSet = state.searchResultSet; // this is the full list of contacts

      // this needs to have this.card.meta.sendMessageParams.contact_arr
      if (this.session == undefined && this.card.meta.sendMessageParams.contact_arr !== undefined) {
        this.session = [];
        this.session.contact_arr = this.card.meta.sendMessageParams.contact_arr;
        this.session.smsani = this.card.meta.sendMessageParams.fromNum || undefined;
      }
      if (this.session == undefined && this.card.meta.sendMessageParams.fromUID !== undefined) {
        this.session = [];
        this.session.fromUID = this.card.meta.sendMessageParams.fromUID;
        this.session.type = 'chat';
      }
      // if it has contact_arr then should it be good ?

      this.allContacts = this.loadContacts(this.searchResultSet, this.session); // this is all the contacts available to be searched on
      this.allContactsCopy = this.loadContacts(this.searchResultSet, this.session); // this is all the contacts available to be searched on

      // load contact chips that are already in the conversation
      this.contacts = loadPresentChips(this.card.meta, this.searchResultSet);
      this.contactsOriginalCopy = [...this.contacts];

      // initialize for autofill
      this.keys = [13, 188];
      this.querySearch = this.querySearch;
      this.delayedQuerySearch = this.delayedQuerySearch;
      this.onModelChange = this.onModelChange;

      // call update contacts here too to get rid of contacts that are already in session
      this.updateContactList();

      function loadPresentChips(cardMeta, contactList) {
        // if cannot read contact array, then show empty list
        if (cardMeta.sendMessageParams == undefined
                  || cardMeta.sendMessageParams.destination == undefined
                  || cardMeta.sendMessageParams.destination == ''
                  || contactList == undefined) {
          return [];
        }

        const destArray = cardMeta.sendMessageParams.destination.split(',');

        // go through cardMeta and see if contacts have it
        return destArray.map((c, index) => {
          // let hash = c.contact.gravatar || 'sample';
          let found = false;
          let foundContact;
          // loop through contact array
          for (let i = contactList.length - 1; i >= 0; i--) {
            if (c == contactList[i].number
                        || c == `1${contactList[i].number}`) {
              // // contact matched
              foundContact = contactList[i];
              found = true;
            }
          }

          if (found) {
            // console.log('this is in found: ', foundContact);
            // contact matched
            const { number } = foundContact; // will be the formatted number to or the extention

            var contact = {
              name: foundContact.contact.name,
              initials: foundContact.contact.initials,
              number,
              image: `//www.gravatar.com/avatar/${foundContact.contact.gravatar}?s=50&d=mp&hash=${gravatarSessionId}`,
            };
            if (foundContact.contact.gravatar !== undefined && foundContact.contact.gravatar !== '') {
              contact.gravatar = foundContact.contact.gravatar;
            }
            // if (c.contact.gravatar !== undefined) {
            //   contact.image = '//www.gravatar.com/avatar/' + c.contact.gravatar + '?s=50&d=mp&hash=' + gravatarSessionId
            // }
            // add uid for only contacts
            if (foundContact.contact.uid !== undefined) {
              contact.uid = foundContact.contact.uid;
            }

            contact._lowername = contact.name.toLowerCase();
            contact._number = contact.number;
            return contact;
          }
          // contact did not match, make a new chip
          const number = c; // will be the formatted number to or the extention
          var contact = {
            name: number,
            initials: null,
            number,
            // image : '//www.gravatar.com/avatar/sample' +  + '?s=50&d=mp&hash=' + gravatarSessionId
          };

          contact._lowername = contact.name.toLowerCase();
          contact._number = contact.number;
          return contact;
        });
      }

      // end member variables from codepen

      // add keylisteners for up/down the selected list
      this.arrowkeyLocation = -1;
    });

    // Fetch messages if there is a sessionId and the messages have not already been fetched.
    if (this.card.meta && this.card.meta.sessionId && !this.messagesMeta) {
      $ngRedux.dispatch(chatActions.fetchMessages(this.card.meta.sessionId));
    }

    // Set the input box in focus if the context is not a small card.
    // We only want expanded and full screen card inputs to get input focus
    if (this.context !== 'card') {
      const interval = setInterval(() => {
        const inputElem = document.getElementById(`chat-session-input_${this.card.id}_${this.context}`);
        if (inputElem) {
          inputElem.focus();
          clearInterval(interval);
        }
      }, 200);
    }
  };

  // get the actual contacts
  // filter out the contacts that are not part of SMS or Chat (if the type is known)
  this.loadContacts = (contactList, session) => contactList.filter((contact) => {
    // if smsani is not defined, then check if the contact is an extension return not
    if (session !== undefined && (session.smsani == undefined || session.smsani == '')) {
      if (contact.type == 'extension') {
        return true;
      }
      return false;
    } if (contact.type == 'extension') {
      return false;
    }
    return true;
  })
    .map((c, index) => {
      // let hash = c.contact.gravatar || 'sample';
      const { number } = c; // will be the formatted number to or the extention

      const contact = {
        name: c.contact.name,
        initials: c.contact.initials,
        number,
        image: `//www.gravatar.com/avatar/${c.contact.gravatar}?s=50&d=mp&hash=${gravatarSessionId}`,
        type: c.type,
      };
      if (c.contact.gravatar !== undefined && c.contact.gravatar !== '') {
        contact.gravatar = c.contact.gravatar;
      }
      // add uid for only contacts
      if (c.contact.uid !== undefined) {
        contact.uid = c.contact.uid;
      }

      contact._lowername = contact.name.toLowerCase();
      contact._number = contact.number;
      return contact;
    });

  // functions from codepen
  /**
     * Search for contacts; use a random delay to simulate a remote call
     */
  this.querySearch = (criteria) =>
  // filter the search list here
    (criteria ? this.allContacts.filter(this.createFilterFor(criteria)) : [])
  ;

  // when input changed then should change the list of contacts that are shown in the list
  this.inputChanged = (input, target = null) => {
    if (input == '' || input == undefined) {
      // reset the counter to be -1
      this.arrowkeyLocation = -1;
      this.allContactsCopy = this.allContacts;

      // input changed, so scroll to the top of the contact list
      // setTimeout(() => {
      const topContactRow = document.querySelector('.list-item-index-0');
      if (topContactRow !== undefined && topContactRow !== null) {
        topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
      // }, 0);
    } else {
      this.arrowkeyLocation = 0;
      this.allContactsCopy = input ? this.allContacts.filter(this.createFilterFor(input)) : [];

      // input changed, so scroll to the top of the contact list
      const topContactRow = document.querySelector('.list-item-index-0');
      if (topContactRow !== undefined && topContactRow !== null) {
        topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
    // only if not typing anything should it update contact list
    if (input == undefined || input == '') {
      this.updateContactList();
    }
  };

  this.keyUpFunc = function (keyEvent) {
    if (keyEvent.which === 13) {
      // event.preventDefault();
      // if there is nothing in input, then that means the user clicked on row still, should enter contact
      const inputText2 = keyEvent.target.value;
      // let inputText2 = $('.md-chip-input-container').find(':input')[0].value;
      if ((inputText2 == undefined || inputText2 == '') && this.arrowkeyLocation !== -1) {
        this.contacts.push(this.transformChip());
      }

      this.arrowkeyLocation = -1;

      // scroll down to chips input
      setTimeout(() => {
        // scroll to input
        const elmnt = $('.md-chip-input-container').find(':input')[0];
        if (elmnt !== undefined) {
          elmnt.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          elmnt.focus();
        }

        // scroll to top of contact list
        const topContactRow = document.querySelector('.list-item-index-0');
        if (topContactRow !== undefined && topContactRow !== null) {
          topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      }, 0);

      this.inputChanged(inputText2);
    } else if (keyEvent.which == 38) {
      // limit to 0
      if (this.arrowkeyLocation > 0) {
        this.arrowkeyLocation--;
        // scroll to the element
        setTimeout(() => {
          const el = document.querySelector('.active-chip-class');
          if (el !== undefined) {
            el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
        }, 0);
      }
    } // this is the ascii of arrow up
    else if (keyEvent.which == 40) {
      // cap to allContactsCopy-1
      if (this.arrowkeyLocation >= this.allContactsCopy.length - 1) {
        this.arrowkeyLocation = this.allContactsCopy.length - 1;
      } else {
        this.arrowkeyLocation++;

        // scroll to the element
        setTimeout(() => {
          const el = document.querySelector('.active-chip-class');
          if (el !== undefined) {
            el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
        }, 0);
      }
    } else {
      // was an actual letter
      const inputText2 = keyEvent.target.value;
      this.inputChanged(inputText2, keyEvent.target);
    }
  };

  /**
     * Async search for contacts
     * Also debounce the queries; since the md-contact-chips does not support this
     */
  this.delayedQuerySearch = (criteria) => {
    if (!pendingSearch || !debounceSearch()) {
      cancelSearch();

      return pendingSearch = $q((resolve, reject) => {
        // Simulate async search... (after debouncing)
        cancelSearch = reject;
        $timeout(function () {
          resolve(this.querySearch(criteria));

          refreshDebounce();
        }, Math.random() * 500, true);
      });
    }

    return pendingSearch;
  };
  this.refreshDebounce = () => {
    lastSearch = 0;
    pendingSearch = null;
    // cancelSearch = angular.noop;
  };

  /**
    * Debounce if querying faster than 300ms
     */
  this.debounceSearch = () => {
    const now = new Date().getMilliseconds();
    lastSearch = lastSearch || now;

    return ((now - lastSearch) < 300);
  };

  /**
     * Create filter function for a query string
     */
  this.createFilterFor = (query) => {
    const lowercaseQuery = query.toLowerCase();

    return function filterFn(contact) {
      // return false;
      return ((contact._lowername.indexOf(lowercaseQuery) !== -1) || contact.number.indexOf(lowercaseQuery) !== -1);
    };
  };

  /**
     * Return the proper object when the append is called.
     */
  this.transformChip = (chip) => {
    // get the contact index of that is being selected if the

    if (this.arrowkeyLocation >= 0
          && this.allContactsCopy !== undefined
          && this.allContactsCopy[this.arrowkeyLocation] !== undefined) {
      let chipToReturn = {
        name: this.allContactsCopy[this.arrowkeyLocation].name,
        number: this.allContactsCopy[this.arrowkeyLocation].number,
        uid: this.allContactsCopy[this.arrowkeyLocation].uid || null,
        gravatar: this.allContactsCopy[this.arrowkeyLocation].gravatar || null,
        initials: this.allContactsCopy[this.arrowkeyLocation].initials || null,
      };

      // check if current is already in chips, if so, should not return ?
      let existsAlready = false;
      for (let i = this.contacts.length - 1; i >= 0; i--) {
        if (this.contacts[i].number == chipToReturn.number) {
          existsAlready = true;
          chipToReturn = null;
        }
      }

      this.arrowkeyLocation = -1;

      return chipToReturn;
    }

    // the arrowkeylocation is 0 yet there is not valid
    if (this.arrowkeyLocation == 0
          && this.allContactsCopy[this.arrowkeyLocation] == undefined
          && chip !== ''
          && chip !== undefined
    ) {
      let chipToReturn = {
        name: chip,
        number: chip,
        gravatar: null,
        initials: null,
      };
      this.arrowkeyLocation = -1;

      // if the session is already a chat session, do not allow for adding an off-net contact
      if (this.card.meta != undefined && this.card.meta.sendMessageParams !== undefined && (this.card.meta.sendMessageParams.type == 'chat' || this.card.meta.sendMessageParams.type == 'groupchat' || this.card.meta.sendMessageParams.fromUID != undefined)) {
        chipToReturn = null;
      }

      // if the chip is length not 10 or 11, then do not allow adding
      const regExp = /[a-zA-Z]/g;
      if ((chip.length != 10 && chip.length != 11) || regExp.test(chip)) {
        chipToReturn = null;
      }
      return chipToReturn;
    }
  };

  /**
     * Add clicked contact to the list.
     */
  this.clickedContact = (contact) => {
    // check if this.contacts has the contact in question already
    let existsAlready = false;
    for (let i = this.contacts.length - 1; i >= 0; i--) {
      if (this.contacts[i].number == contact.number) {
        existsAlready = true;
      }
    }

    if (!existsAlready) {
      this.contacts.push(contact);
    }
    this.updateContactList();

    setTimeout(() => {
      const elmnt = $('.md-chip-input-container').find(':input')[0];
      if (elmnt !== undefined) {
        elmnt.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        elmnt.focus();

        // scroll to top of contact list
        const topContactRow = document.querySelector('.list-item-index-0');
        if (topContactRow !== undefined && topContactRow !== null) {
          topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      }
    }, 0);
  };

  this.onModelChange = (newModel) => {
    const newModelList = [];

    newModel.forEach((item, index) => {
      if (typeof item === 'object') {
        newModelList.push(item);
      } else {
        const newContact = {
          name: item,
          email: '',
          image: `https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?hash=${gravatarSessionId}`,
        };
        newModelList.push(newContact);
      }
    });
    this.contacts = newModelList;
  };

  // holds all that are available, shortenning list
  this.filteredResultSet = () => this.allContacts;

  // end of functions from codepen

  /**
     * there was change on the chip list
     * Operations to do: refresh the allContactsCopy list, refocus on the input for md-chips
     *
     *
     */
  this.onChange = () => {
    setTimeout(() => {
      this.updateContactList();

      const elmnt = $('.md-chip-input-container').find(':input')[0];
      if (elmnt !== undefined) {
        elmnt.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
      // scroll to top of contact list
      const topContactRow = document.querySelector('.list-item-index-0');
      if (topContactRow !== undefined && topContactRow !== null) {
        topContactRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 0);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
    // window.removeEventListener('keydown', _keyDown);
  };

  this.removeParticipant = (contact) => {
    // remove that contact from the list of temp_contacts of that session/card
    for (let i = 0; i < this.contacts.length; i += 1) {
      if (this.contacts[i] == contact) {
        this.contacts.splice(i, 1);
      }
    }
    // set the add participant icon to confirm checkmark
    this.card.changedParticipantListToggle = true;
  };

  // header functions
  /**
     *  Cancel state of name change for the session
     */
  this.cancelParticipantChange = () => {
    // need to get check if session is available

    // reset to the original contacts
    const copy = JSON.parse(JSON.stringify(this.contactsOriginalCopy));
    this.contacts = copy;

    // this.contacts = this.contactsOriginalCopy;

    // do not keep changeParticipantsState
    this.card.changeParticipantsState = false;
    this.card.viewParticipantsState = false;
    this.card.changedParticipantListToggle = false;

    // clear badgelist on cancel
    // $ngRedux.dispatch(contactActions.setBadgeList([]));
  };
  // this function is here again because view participants header has a transition to get to changing participants
  this.changeParticipants = ($event) => {
    // expand the card if pressed from kebob
    // if($event.target &&
    //     $event.target.className &&
    //     $event.target.className.indexOf('no-expand') === -1
    // ) {
    //     $ngRedux.dispatch(cardActions.setExpanded(this.card.id));
    // }

    if (this.card.viewParticipantsState) this.card.viewParticipantsState = false;

    // change card state to session name editting
    if (!this.card.changeParticipantsState) this.card.changeParticipantsState = true;
    else this.card.changeParticipantsState = false;
  };

  /**
     * Will remove any contacts from the
     */
  this.updateContactList = () => {
    // if contacts is empty, then no need to do anything, or even if allContactsCopy is undefined or empty
    if (this.contacts == undefined || this.allContactsCopy == undefined || this.allContactsCopy.length == 0) {
      return;
    }

    // if the contacts array just became length 0, that's fine, but should just make it so that allcontacts are available
    if (this.contacts.length == 0) {
      const allContactListToUpdate = this.loadContacts(this.searchResultSet, this.session);
      this.allContactsCopy = allContactListToUpdate;
      this.allContacts = allContactListToUpdate;
      return;
    }

    const allContactListToUpdate = this.loadContacts(this.searchResultSet, this.session);

    // filter out contacts of a certain type
    if (this.contacts[0] !== undefined && this.contacts[0].type !== undefined) {
      if (this.contacts[0].type == 'extension') {
        // should get rid of all the contacts that are mms
        for (var j = allContactListToUpdate.length - 1; j >= 0; j--) {
          if (allContactListToUpdate[j].type !== 'extension') {
            allContactListToUpdate.splice(j, 1);
          }
        }
      } else {
        // should get rid of contacts that are chats
        for (var j = allContactListToUpdate.length - 1; j >= 0; j--) {
          if (allContactListToUpdate[j].type == 'extension') {
            allContactListToUpdate.splice(j, 1);
          }
        }
      }
    }

    for (let i = this.contacts.length - 1; i >= 0; i--) {
      for (var j = allContactListToUpdate.length - 1; j >= 0; j--) {
        if (this.contacts[i].number == allContactListToUpdate[j].number) {
          allContactListToUpdate.splice(j, 1);
        }
      }
    }

    this.allContactsCopy = allContactListToUpdate;
    this.allContacts = allContactListToUpdate;
  };

  /**
     *  Confirm name change for the session, change state to normal card state
     */
  this.confirmParticipantChange = () => {
    $ngRedux.connect((state) => {
      let participantStr = '';

      const badges = contactActions.getBadgeList(state);
      let type = 'groupchat';

      // if session is not there, then maybe check again
      if (this.session == undefined) {
        const sessionTryAgain = chatActions.getSession(this.card.meta.sessionId);
      }

      if (this.session.smsani == '') {
        // flag if it is for chat or group mms //this is for group chat
        // get all participants from contact_arr, see which are removed or stayed the same
        for (let i = 0; i < this.contacts.length; i += 1) {
          participantStr += `${this.contacts[i].uid},`; // should be like 0001@domain,0002@domain,0003@domain, (the original including self)
        }
      } else {
        type = 'groupmms';

        for (let i = 0; i < this.contacts.length; i += 1) {
          if (this.contacts[i].number.length == '10') {
            participantStr += `1${this.contacts[i].number},`;
          } else {
            participantStr += `${this.contacts[i].number},`;
          }
        }
      }

      // get out of participant edit state on confirm, also get out of view participants
      this.card.changeParticipantsState = false;
      this.card.changedParticipantListToggle = false;
      this.card.viewParticipantsState = false;
      // clear badgelist on confirm
      // $ngRedux.dispatch(contactActions.setBadgeList([]));

      // compile params for chatActions call
      const partParams = { participantStr, ...this.card.meta.sendMessageParams };

      // TODO overhaul will not be having the combination anymore, will only be taking from list of badges

      // just do logic here for opening new card if it is an sms number
      if (type == 'groupmms') {
        const item = {};
        item.type = 'groupmms';
        // new destination will be participantStr+smsani's of contact_arr
        // item.destination = participantStr+contactActions.getDestination(this.session.contact_arr, 'sms');
        item.destination = participantStr.replace(/,\s*$/, ''); // remove the last comma from the participants list

        item.fromNum = this.card.meta.sendMessageParams.fromNum;

        // create new hash for new contactId
        if (item.fromNum.length == 10) { // need from_num to be correct 12223334444 format
          item.fromNum = `1${item.fromNum}`;
        }
        let toHash = `${item.destination},${item.fromNum}`;
        const toHashArr = toHash.split(',');
        toHashArr.sort();
        toHash = toHashArr.join('');
        const hash = crypto.createHash('md5').update(toHash).digest('hex');

        // check if the session exists already or not based on sessionid
        const sessionsList = chatActions.getSessions(state);
        let found = false;
        let foundSession;
        for (let j = 0; j < sessionsList.length; j += 1) {
          if (sessionsList[j].id == hash) {
            found = true;
            foundSession = sessionsList[j];
            continue;
          }
        }

        if (found) { // session already exists just need to open card
          item.type = 'groupmms';
          item.session = foundSession;
          // item.remote = remote;
          item.sessionId = foundSession.id;

          var { sessionId } = item;
          var sendMessageParams = {
            destination: item.destination,
            fromNum: item.fromNum,
            sessionId: item.sessionId,
            type: 'sms',
          };

          // build card meta
          const cardMeta = {
            sendMessageParams,
            sessionId,
          };
          const hasCard = cardActions.hasCard(cardMeta);

          if (cardActions.hasCard(cardMeta)) {
            // get the card id and expand it
            const cardId = cardActions.getCardId(cardMeta);
            // $ngRedux.dispatch(cardActions.setExpanded(null));
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          } else {
            // start fetching the messages for the session
            $ngRedux.dispatch(chatActions.fetchMessages(sessionId));

            // add the new card
            $ngRedux.dispatch(cardActions.newCard(cardMeta));

            // get the new card id and expand it
            const cardId = cardActions.getCardId(cardMeta);
            $ngRedux.dispatch(cardActions.setExpanded(null));
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          }
        } else {
          // else make new card using new metadata
          item.sessionId = hash;
          item.numPart = item.destination.split(',').length;
          item.nameList = '';

          // get namelist from looking through current session contact_arr as well as badgelist
          const newBadgeList = [];
          for (let b = 0; b < this.contacts.length; b++) {
            item.nameList += `${this.contacts[b].name}, `;
            newBadgeList.push(this.contacts[b]); // for the new card
          }
          // item.nameList += contactActions.getParticipantNames(this.session.contact_arr);
          var { sessionId } = item;
          var sendMessageParams = {
            destination: item.destination,
            fromNum: item.fromNum,
            sessionId: item.sessionId,
            type: 'sms',
          };

          // // need to make the contact_arr too
          let contact;
          let searchStr;
          const contact_arr = [];
          const temp_contact_arr = [];
          const contact_uids = item.destination.split(',');
          for (let x = 0; x < contact_uids.length; x++) {
            const uidArr = contact_uids[x].split('@');
            searchStr = uidArr[0];
            searchStr = searchStr.replace(/['"\]\[]+/g, '');
            contact = contactActions.matchContact(searchStr);
            // if contact is not found, make temp one for this group
            if (contact == undefined) {
              contact = new Object();
              contact.name = searchStr;
              contact.cell_phone = searchStr;
            }
            contact_arr.push(contact);
            temp_contact_arr.push(contact);
          }
          item.contact_arr = contact_arr;
          item.temp_contact_arr = temp_contact_arr;

          // set namelist and number of participatns, usually done when making new session
          if (item.nameList != undefined) {
            sendMessageParams.nameList = item.nameList;
            sendMessageParams.numPart = item.numPart;
          }

          // make sendMessageParams have contact_arr
          if (item.temp_contact_arr != undefined) {
            sendMessageParams.contact_arr = item.contact_arr;
            sendMessageParams.temp_contact_arr = item.temp_contact_arr;
          }

          // build card meta
          const cardMeta = {
            sendMessageParams,
            sessionId,
          };

          // need to create a new session
          const sessionToCreate = {
            contact: null,
            contactId: null,
            contact_arr: item.contact_arr || newBadgeList,
            temp_contact_arr: item.temp_contact_arr || newBadgeList, // this will always be the original contact_arr
            partNames: item.nameList,
            nameList: item.nameList,
            numPart: item.contact_arr.length || newBadgeList.length,
            session_name: null,
            domain: this.user.domain,
            id: item.sessionId,
            lastMessage: null,
            lastStatus: 'read',
            lastSender: null,
            lastTimestamp: null,
            muted: false,
            remote: item.destination,
            smsani: item.fromNum,
            startTimestamp: null,
          };

          // seperate this else will error out when creating new session by adding to group mms
          if (item !== undefined && item.contact !== undefined && item.contact.contact_id !== undefined) {
            sessionToCreate.contact_id = item.contact.contact_id;
          }

          $ngRedux.dispatch(chatActions.addSession(sessionToCreate));

          let cardId;
          // this logic makes sure duplicate cards are not made
          if (cardActions.hasCard(cardMeta)) {
            // get the card id and expand it
            cardId = cardActions.getCardId(cardMeta);
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          } else {
            // start fetching the messages for the session
            $ngRedux.dispatch(chatActions.fetchMessages(sessionId));

            // add the new card
            $ngRedux.dispatch(cardActions.newCard(cardMeta));

            // get the new card id and expand it
            cardId = cardActions.getCardId(cardMeta);
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          }
        } // end of session not found
      } else {
        // else do actual session updating for a group chat scenario
        if (!(this.card.meta.sendMessageParams.destination.indexOf(',') > -1)) {
          this.openGroupConversation(this.contacts);
        } else {
          $ngRedux.dispatch(chatActions.changeParticipants(partParams));
        }
      }
    });
  };

  // this will always be a group chat 1:1 uplift
  this.openGroupConversation = (badges) => {
    $ngRedux.connect((state) => {
      const item = {};

      // get the list of remote and destination from the badgelist
      const remoteArr = []; // 1123@portal,25002@portal,etc. OR nothing for groupmms
      const destinationArr = []; // 1123,25002,etc. OR list of
      const contact_arr_badges = []; // 1123,25002,etc
      const itemType = '';
      for (let i = 0; i < badges.length; i += 1) {
        // could use the phone number, else check if the extension exists
        // add check whether or not it is group chat or group mms
        remoteArr.push(badges[i].uid);
        destinationArr.push(badges[i].number);

        const uidArr = badges[i].uid.split('@');
        var searchStr = uidArr[0];
        var searchStr = searchStr.replace(/['"\]\[]+/g, '');
        const contact = contactActions.matchContact(searchStr);

        if (contact !== undefined) {
          contact_arr_badges.push(contact);
        }
      }
      const remote = remoteArr.join(',');
      const destination = destinationArr.join(',');

      // get list of sessions
      // check whether and of sessions in sessionsList's contactlist matches with badges
      let found = false;
      let foundSession;
      const sessionsList = chatActions.getSessions(state);
      for (let j = 0; j < sessionsList.length; j += 1) {
        if (sessionsList[j].contact_arr) {
          if (this.arraysEqual(sessionsList[j].contact_arr, contact_arr_badges)) {
            found = true;
            foundSession = sessionsList[j];
          }
        }
      }

      if (found) {
        // if session exists already, check if card is open (hasCard), use the sessionId that was found
        // if (itemType == "groupChat") {
        item.type = 'groupChat';
        item.session = foundSession;
        item.destination = destination;
        item.remote = remote;
        item.sessionId = foundSession.id;

        this.openNewChat(item);
        // }
      } else {
        // if session DOES NOT exist already, create new sessionId, create new messagesMeta open new card
        item.type = 'groupChat';
        // item.session = foundSession;
        item.destination = destination;
        item.remote = remote;
        item.nameList = contactActions.getParticipantNames(contact_arr_badges);
        item.numPart = badges.length;
        // create new hash for new contactId
        const secret = new Date() + destination;
        const hash = crypto.createHmac('md5', secret)
          .update(destination)
          .digest('hex');
        item.sessionId = hash;

        this.openNewChat(item);
      }
    }); // end of ngRedux.connect
  };// end of openGroupConversation

  /**
     *
     * @param item, is a contact item
     */
  this.openNewChat = (item) => {
    // generate session id and message params
    let sendMessageParams;
    let sessionId;
    switch (item.type) {
      case 'groupChat':
        sessionId = item.sessionId;
        sendMessageParams = {
          destination: item.destination,
          remote: item.remote,
          fromUID: this.user.uid,
          sessionId,
          nameList: item.nameList,
          numPart: item.numPart,
        };
        break;
    }

    // build card meta
    const cardMeta = {
      sendMessageParams,
      sessionId,
    };

    // add contact id if contact is set
    if (item.contact) {
      cardMeta.contactId = item.contact.id;
    }

    // remove the current new-chat card
    // $ngRedux.dispatch(cardActions.removeCard(this.card.id));

    let cardId;
    // this logic makes sure duplicate cards are not made
    if (cardActions.hasCard(cardMeta)) {
      // get the card id and expand it
      cardId = cardActions.getCardId(cardMeta);
      $ngRedux.dispatch(cardActions.setExpanded(cardId));
    } else {
      // start fetching the messages for the session
      $ngRedux.dispatch(chatActions.fetchMessages(sessionId));

      // add the new card
      $ngRedux.dispatch(cardActions.newCard(cardMeta));

      // get the new card id and expand it
      cardId = cardActions.getCardId(cardMeta);
      $ngRedux.dispatch(cardActions.setExpanded(cardId));
    }

    if (this.screenSize.display === 'compact') {
      // remove the current state (new-chat) from stack
      // the nav stack will now have the previous state
      $ngRedux.dispatch(navActions.navStackBack());

      // get the last on the navStack to add to history
      const state = navActions.getLastOnStack($ngRedux.getState(), 'navStack');

      // get the card for state params
      const card = cardActions.getCard($ngRedux.getState(), cardId);
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.chat-session',
          params: { card },
          fromName: state.name,
          fromParams: state.params,
        }),
      );
      $state.go('wrapper.chat-session', { card });
    }
  };

  // check whether two arrays are equal, regardless of order
  this.arraysEqual = (_arr1, _arr2) => {
    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

    const arr1 = _arr1.sort((a, b) => ((a.id > b.id) ? 1 : -1));
    const arr2 = _arr2.sort((a, b) => ((a.id > b.id) ? 1 : -1));

    for (let i = 0; i < arr1.length; i += 1) {
      if ((arr1[i].id !== arr2[i].id) || (arr1[i].first_name !== arr2[i].first_name)) {
        return false;
      }
    }
    return true;
  };

  // end header functions
}

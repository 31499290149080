export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.contact-edit', {
      url: 'contact-edit',
      params: {
        contactID: null, // contact to edit
        type: null, // call detail number type (work, cell, home)
        number: null, // call detail phone number
        workflow: null, // call-details-add-to-contact
      },
      template: '<nets-contact-edit></nets-contact-edit>',
    });
};

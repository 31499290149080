import nsToken from '@netsapiens/netsapiens-js/dist/token';

const removeValue = (list, value, separator) => {
  separator = separator || ',';
  const values = list.split(separator);
  for (let i = 0; i < values.length; i += 1) {
    if (values[i] === value) {
      values.splice(i, 1);
      return values.join(separator);
    }
  }
  return list;
};

// input: mesg data, including list of uid's of participants
//       ie. ["9999@portal","1123@portal","5197@portal","1005@portal","2500@portal","2501@portal"]
// return: comma seperated list of destination user extensions
//       ie. 9999,1123,5197,1005,2501
export const getGroupDestination = ({ data }) => {
  const decodedToken = nsToken.getDecoded();
  const { user } = decodedToken;
  const { domain } = decodedToken;

  let destination = data.participants;
  destination = destination.replace(/['"\][]+/g, '');

  destination = removeValue(destination, `${user}@${domain}`, ',');
  destination = destination.replace(new RegExp(`@${domain}`, 'g'), '');
  destination = destination.replace(/domain/g, '');

  return destination;
};

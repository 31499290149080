import './styles.css';
import template from './template.html';
import * as cardActions from '../../../../core/card-management';
import {
  getHourStats,
  getDayStats,
  getLoading,
  fetchSumByHour,
  fetchSumByDay,
  fetchSumByUser,
  fetchAgentKPI,
} from '../../../../core/agent-center';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe = null;

  this.$onInit = () => {
    $ngRedux.dispatch(fetchSumByHour());
    $ngRedux.dispatch(fetchSumByDay());
    $ngRedux.dispatch(fetchSumByUser());
    $ngRedux.dispatch(fetchAgentKPI());
    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
      hourstats: getHourStats(state),
      daystats: getDayStats(state),
      loaded: !getLoading(state),
      configs: state.configs,
    }))((state) => {
      this.screenSize = state.screenSize;
      this.hourstats = state.hourstats;
      this.daystats = state.daystats;
      this.loaded = state.loaded;
      this.allowAgentStats = state.configs.get('callQueueAgentStats') === 'yes';
      this.allowStatsGraphs = state.configs.get('callQueueAgentStatGraphs') === 'yes';
    });

    this.statsUpdate = setInterval(() => {
      $ngRedux.dispatch(fetchSumByHour());
      $ngRedux.dispatch(fetchSumByDay());
      $ngRedux.dispatch(fetchSumByUser());
      $ngRedux.dispatch(fetchAgentKPI());
    }, 3600000);
  };

  this.focusPerHour = () => {
    let cardId = cardActions.getCardId({ type: 'perhour' });
    if (!cardId) {
      $ngRedux.dispatch(cardActions.newCard({ type: 'perhour' }));
      cardId = cardActions.getCardId({ type: 'perhour' });
    }
    $ngRedux.dispatch(cardActions.setExpanded(cardId));
  };

  this.focusPerDay = () => {
    let cardId = cardActions.getCardId({ type: 'perday' });
    if (!cardId) {
      $ngRedux.dispatch(cardActions.newCard({ type: 'perday' }));
      cardId = cardActions.getCardId({ type: 'perday' });
    }
    $ngRedux.dispatch(cardActions.setExpanded(cardId));
  };

  this.focusPerQueue = () => {
    let cardId = cardActions.getCardId({ type: 'perqueue' });
    if (!cardId) {
      $ngRedux.dispatch(cardActions.newCard({ type: 'perqueue' }));
      cardId = cardActions.getCardId({ type: 'perqueue' });
    }
    $ngRedux.dispatch(cardActions.setExpanded(cardId));
  };

  this.focusDayStats = () => {
    let cardId = cardActions.getCardId({ type: 'kpistats' });
    if (!cardId) {
      $ngRedux.dispatch(cardActions.newCard({ type: 'kpistats' }));
      cardId = cardActions.getCardId({ type: 'kpistats' });
    }
    $ngRedux.dispatch(cardActions.setExpanded(cardId));
  };

  this.$onDestroy = () => {
    unsubscribe();
    clearInterval(this.statsUpdate);
  };
}

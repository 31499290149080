import './styles.css';
import template from './template.html';
import * as contactActions from '../../core/contacts';
import * as navActions from '../../core/state-history';
import * as voicemailActions from '../../core/voicemail';

export default {
  template,
  controller,
  bindings: {
    context: '@', // modal
    type: '<', // phone type work, cell, home
    index: '<', // voicemail index
    number: '<', // phone number
    onContactDetails: '&', // call back fo navigating to contact phone numbers
    onPhone: '&', // call back for phone modal, navigates back to the phone
    onQueue: '&',
    workflow: '@', // in-call, in-call-transfer
  },
};

controller.$inject = [
  '$mdColors',
  '$mdToast',
  '$ngRedux',
  '$state',
  '$translate',
];
function controller(
  $mdColors,
  $mdToast,
  $ngRedux,
  $state,
  $translate,
) {
  const self = this;
  let filteredBy;
  let sortedBy;
  let sortedDirection;
  let unsubscribe;

  // used for displaying loader or list
  this.loaded = false;

  /**
     *
     */
  this.$onInit = () => {
    // get accent color, this is a fix for md-colors attribut not working
    this.accent = { color: $mdColors.getThemeColor('accent') };

    // because this component is used by routes
    // and a modal it needs to handle props and params
    // normalize bindings and state params
    this.type = this.type || $state.params.type;
    this.index = this.index || $state.params.index;
    this.number = this.number || $state.params.number;
    this.workflow = this.workflow || $state.params.workflow;

    // handle workflows
    switch (this.workflow) {
      case 'call-details-add-to-contact':
        this.showBackButton = true;
        this.filterDisabled = true;
        break;
      case 'in-call':
        this.showBackButton = true;
        this.inCallClass = 'in-call';
        break;
      case 'in-call-transfer':
        this.showBackButton = true;
        this.inCallClass = 'in-call';
        break;
      case 'queue-supr-transfer':
        this.showBackButton = true;
        this.inCallClass = 'in-call';
        break;
      case 'voicemail-forward':
        this.showBackButton = true;
        this.filterDisabled = true;

        // get current filter and sorting
        filteredBy = contactActions.getFilteredBy($ngRedux.getState());
        sortedBy = contactActions.getSortedBy($ngRedux.getState());
        sortedDirection = contactActions.getSortedDirection($ngRedux.getState());

        // dispatch if diff than whats in store
        if (filteredBy !== 'coworkers') {
          $ngRedux.dispatch(contactActions.filteredBy('coworkers'));
        }
        if (filteredBy !== 'last_name') {
          $ngRedux.dispatch(contactActions.sortedBy('last_name'));
        }
        if (filteredBy !== 'asc') {
          $ngRedux.dispatch(contactActions.sortedDirection('asc'));
        }

        break;
      default:
        this.showAddContact = true;
    }

    // bind redux to component
    unsubscribe = $ngRedux.connect((state) => {
      let contactsResultSet;
      let filteredBy;
      let searchedResultsSet;
      let sortedBy;
      let sortedDirection;
      if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
        contactsResultSet = contactActions.getContactsResultSet(state);
        searchedResultsSet = contactActions.getSearchedResultSet(state);
        filteredBy = contactActions.getFilteredBy(state);
        sortedBy = contactActions.getSortedBy(state);
        sortedDirection = contactActions.getSortedDirection(state);
      } else {
        contactsResultSet = contactActions.getInCallContactsSet(state);
        searchedResultsSet = contactActions.getInCallSearchedSet(state);
        filteredBy = contactActions.getInCallFilteredBy(state);
        sortedBy = contactActions.getInCallSortedBy(state);
        sortedDirection = contactActions.getInCallSortedDirection(state);
      }

      // map redux to component
      return {
        contactsResultSet,
        loaded: !contactActions.getLoading(state),
        filteredBy,
        screenSize: state.screenSize,
        searchedResultsSet,
        sortedBy,
        sortedDirection,
      };
    })((state) => {
      // update component state
      this.loaded = state.loaded;
      this.filteredBy = state.filteredBy;
      this.screenSize = state.screenSize;
      this.sortedBy = state.sortedBy;
      this.sortedDirection = state.sortedDirection;

      if (state.searchedResultsSet !== false) {
        this.dataset.refresh(state.searchedResultsSet);
      } else {
        this.dataset.refresh(state.contactsResultSet);
      }

      // determine virtual repeat height
      if (this.context === 'modal') {
        this.height = `${this.screenSize.height - 240}px`;
      } else if (this.screenSize.display === 'compact') {
        if (this.workflow) {
          this.height = `${this.screenSize.height - 90}px`;
        } else {
          this.height = `${this.screenSize.height - 130}px`;
        }
      } else {
        this.height = `${this.screenSize.height - 126}px`;
      }

      // set empty message text
      _emptyMessage();
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();

    // if the workflow is voicemail forward, set back to original filter and sorting
    if (filteredBy) {
      $ngRedux.dispatch(contactActions.filteredBy(filteredBy));
    }
    if (sortedBy) {
      $ngRedux.dispatch(contactActions.sortedBy(sortedBy));
    }
    if (sortedDirection) {
      $ngRedux.dispatch(contactActions.sortedDirection(sortedDirection));
    }
  };

  /**
     * virtual repeat
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.dataset = {
    items: [],
    refresh: (list) => {
      this.dataset.items = list;
    },
    getItemAtIndex: (index) => this.dataset.items[index],
    getLength: () => this.dataset.items.length,
  };

  /**
     *
     */
  this.navBack = () => {
    let lastOnStack;
    switch (this.workflow) {
      case 'in-call':
      case 'in-call-transfer':
        if (this.context === 'modal') {
          this.onPhone();
        } else {
          $ngRedux.dispatch(navActions.callStackBack());
          lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
          $state.go(lastOnStack.name, lastOnStack.params);
        }
        break;
      case 'queue-supr-transfer':
        if (this.context === 'modal') {
          this.onQueue();
        } else {
          $ngRedux.dispatch(navActions.callStackBack());
          lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
          $state.go(lastOnStack.name, lastOnStack.params);
        }
        break;
      default:
        $ngRedux.dispatch(navActions.navStackBack());
        lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
        $state.go(lastOnStack.name, lastOnStack.params);
    }
  };

  /**
     *
     * @param contactID
     */
  this.navPush = (contactID) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');

    switch (this.workflow) {
      case 'call-details-add-to-contact':
        // allow time for contact to be matched to the call
        setTimeout(() => {
          $ngRedux.dispatch(
            navActions.navStackPush({
              name: 'wrapper.contact-edit',
              params: {
                contactID,
                type: this.type,
                number: this.number,
                workflow: this.workflow,
              },
              fromName: currentState.name,
              fromParams: currentState.params,
            }),
          );
          $state.go('wrapper.contact-edit', {
            contactID,
            type: this.type,
            number: this.number,
            workflow: this.workflow,
          });
        }, 500);
        break;
      case 'in-call':
      case 'queue-supr-transfer':
      case 'in-call-transfer':
        if (this.context === 'modal') {
          this.onContactDetails({ contactID });
        } else {
          $ngRedux.dispatch(
            navActions.callStackPush({
              name: 'wrapper.contact-details',
              params: { contactID, workflow: this.workflow },
              fromName: currentState.name,
              fromParams: currentState.params,
            }),
          );
          $state.go('wrapper.contact-details', { contactID, workflow: this.workflow });
        }
        break;
      case 'voicemail-forward':
        $ngRedux.dispatch(voicemailActions.forwardVoicemail({
          forward: contactID,
          type: this.type,
          index: this.index,
        }));
        // allow time for post and voicemails to be updated
        setTimeout(() => {
          $mdToast.show(
            $mdToast.simple()
              .textContent($translate.instant('VOICEMAIL_FORWARDED'))
              .position('bottom left')
              .hideDelay(1500),
          );
          this.navBack();
        }, 500);
        break;
      default:
        $ngRedux.dispatch(
          navActions.navStackPush({
            name: 'wrapper.contact-details',
            params: { contactID },
            fromName: currentState.name,
            fromParams: currentState.params,
          }),
        );
        $state.go('wrapper.contact-details', { contactID });
    }
  };

  /**
     * display message for empty lists
     * @private
     */
  function _emptyMessage() {
    if (self.searchedResultsSet !== false) {
      self.title = $translate.instant('NO_CONTACTS_FOUND');
      self.content = $translate.instant('NO_CONTACTS_MATCHED_YOUR_SEARCH_FOR');
    } else {
      self.title = $translate.instant('NO_CONTACTS_FOUND');
      switch (self.filteredBy) {
        case 'all':
        case 'my_contacts':
          self.content = $translate.instant('NO_CONTACTS_FOUND');
          break;
        case 'favorites':
          self.content = $translate.instant('YOU_HAVE_NO_FAVORITE_CONTACTS.');
          break;
        case 'coworkers':
          self.content = $translate.instant('YOU_HAVE_NO_COWORKERS');
          break;
        case 'online':
          self.content = $translate.instant('YOU_HAVE_NO_ONLINE_CONTACTS');
          break;
        case 'busy':
          self.content = $translate.instant('YOU_HAVE_NO_BUSY_CONTACTS');
          break;
        case 'offline':
          self.content = $translate.instant('YOU_HAVE_NO_OFFLINE_CONTACTS');
          break;
        default:
          self.content = `You have no ${self.filteredBy} contacts.`;
      }
    }
  }
}

/* eslint-disable no-console */
import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsModels from '@netsapiens/netsapiens-js/dist/models';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import Push from 'push.js';
import { SessionState, UserAgent } from 'sip.js';

import * as audioActions from '../../audio';
import * as angularActions from '../../angular';
import { showWrapUpDialog } from '../../agent-center';
import * as sessionActions from '../redux';
import * as uaActions from '../../ua';
import { store } from '../../store';
import * as cardActions from '../../card-management';
import * as navActions from '../../state-history';
import * as userMedia from '../../user-media';
import { stripCodecs } from '../utils/stripCodecs';

export default class CallSession extends nsModels.sip.Session {
  /**
   *
   * @param params
   */
  constructor(params) {
    super({ ...params, type: 'call' });

    this.name = params.name || null;
    this.callId = null;
    this.parkId = null;
    this.callTime = 0;
    this.callerId = null;
    this.from_tag = params.from_tag || null;
    // this.holdTimer = null;
    this.number = params.number || null;
    this.push = null;
    this.auto_ans = false;
    this.stats = {
      runStatsMsDelay: 200,
      runStatsCounter: 0,
      runStatsLastByte: 0,
      runStatsInterval: null,
    };
    this.timer = null;
    this.isConference = params.isConference || false;
    this.conferenceList = [];

    this.formatedNumber = nsUtils.formatPhoneNumber(this.number);
    this.notifcationOptions = {
      body: this.formatedNumber,
      requireInteraction: true,
      icon: store.getState().configs.get('faviconURL'),
      tag: this.id,
      persistent: true,
      data: {
        options: {
          sessionId: this.id,
        },
      },
    };

    let remoteName = this.name || '';
    if (this.direction === 'inbound') {
      // get push notification remoteName
      const $translate = angularActions.getObject(store.getState(), '$translate');

      if (remoteName) {
        remoteName = `${$translate.instant('FROM')} ${remoteName}`;
      }

      const title = `${$translate.instant('INCOMING_CALL')} ${remoteName}`;
      const thisSession = this;

      Notification.requestPermission().then(function (result) {
        if (result === 'granted') {
          if (navigator.serviceWorker) {
            thisSession.addNotifcation(title, [
              { action: 'Answer', title: thisSession.jsUcfirst($translate.instant('ANSWER')) },
              { action: 'Reject', title: thisSession.jsUcfirst($translate.instant('REJECT')) },
            ]);
          } else {
            // open push notification (older style notification)
            this.push = Push.create(`Incoming call ${remoteName}`, {
              body: nsUtils.formatPhoneNumber(this.number),
              icon: store.getState().configs.get('faviconURL'),
              timeout: 45000,
              onClick: () => {
                window.focus();
                this.push.then((result) => {
                  result.close();
                });
              },
            }).catch(() => {});
          }
        }
      });

      const headerAlert = this.getHeader('Alert-Info');
      const autoanswer = ['Auto Answer', 'AutoAnswer', 'Answer'];
      const ringanswer = ['Ring Answer', 'RingAnswer'];
      if (headerAlert) {
        if (autoanswer.includes(headerAlert)) {
          if (store.getState().configs.get('portalWebPhoneAutoAnswerMicMuted') === 'yes') {
            this.isMuted = true;
          }
          this.auto_ans = true;
          store.dispatch(uaActions.acceptInvite(this));
        }
        if (ringanswer.includes(headerAlert)) {
          if (store.getState().configs.get('portalWebPhoneRingAnswerMicMuted') === 'yes') {
            this.isMuted = true;
          }
          this.ringdelay = store.getState().configs.get('portalWebPhoneRingAnswerDelay') || 1.5;
          if (this.ringdelay > 999) {
            this.ringdelay /= 1000;
          }

          setTimeout((self) => {
            store.dispatch(uaActions.acceptInvite(self));
          }, this.ringdelay * 1000, this);
        }
      }

      if (!this.auto_ans) {
        const activeId = sessionActions.getActiveId(store.getState());
        const callControl = store.getState().userMedia.get('hidDevice');
        if (activeId) {
          // This would be call waiting ringtone.
          audioActions.playCallWaiting();
        } else if (!callControl) {
          audioActions.playRinger();
        }
      }
    }

    if (this.direction === 'outbound') {
      if ((this.number && this.number.includes('adhoc')) || (this.name != null && this.name.includes('adhoc'))) {
        this.callerId = ' ';
      }
    }

    // Setup session state change handler
    this.session.stateChange.addListener((newState) => {
      switch (newState) {
        case SessionState.Establishing:
          if (this.direction === 'outbound') {
            this.status = 'outboundProgressing';
          }
          if (this.direction === 'inbound') {
            this.status = 'inboundProgressing';
          }
          audioActions.playRingback();
          store.dispatch(sessionActions.sessionsUpdated());
          if (typeof this.session.sessionDescriptionHandler !== 'undefined') {
            store.dispatch(userMedia.addStream(this.session.sessionDescriptionHandler.localMediaStream));
          }
          break;
        case SessionState.Established:
          this.setupLocalMedia();
          this.setupRemoteMedia();
          this.status = 'accepted';

          audioActions.stopRinger();
          audioActions.stopRingback();
          audioActions.stopCallwaiting();

          this.startTimer();
          if (typeof this.clearNotifcations === 'function') this.clearNotifcations(this.id);
          break;
        case SessionState.Terminated:
          if (this.status === 'inboundProgressing') {
            store.dispatch(uaActions.removeIc(this.id));
            const callControl = store.getState().userMedia.get('hidDevice');
            if (callControl) {
              callControl.rejectIncomingCall().catch(console.debug);
            }
          }

          if (this.getHeader('P-Served-Application')
            && this.getHeader('P-Served-Application') === 'CallQueue'
            && this.status === 'accepted'
          ) {
            store.dispatch(showWrapUpDialog({
              callTime: this.callTime,
              callerId: this.callerId,
              number: this.number,
              direction: this.direction,
              callId: this.callId,
            }));
          }

          this.status = 'ended';
          audioActions.stopRinger();
          audioActions.stopRingback();
          audioActions.stopCallwaiting();

          this.stopTimer();
          store.dispatch(sessionActions.remove(this.id));

          if (typeof this.clearNotifcations === 'function') this.clearNotifcations(this.id);

          if (!store.getState().sessions.get('activeCount')
            // ignore if on the settings page, this is when hark is enabled
            // && !_.get(store.getState().stateHistory.get('currentState'), 'name', '').includes('settings')
            && !_.get(store.getState().stateHistory.get('currentState'), 'name', '').includes('greetings-new')
            && this.session.sessionDescriptionHandler
            && this.session.sessionDescriptionHandler.localMediaStream
            && this.session.sessionDescriptionHandler.localMediaStream.active
          ) {
            store.dispatch(userMedia.stopStreams());
          }
          break;
      }
    });
  }

  addNotifcation(title, actions) {
    const thisSession = this;
    const options = JSON.parse(JSON.stringify(thisSession.notifcationOptions));
    options.actions = actions;

    navigator.serviceWorker.ready.then((registration) => {
      thisSession.push = registration.showNotification(title, options);
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.sessionId && event.data.action && event.data.sessionId == thisSession.id) {
          if (event.data.action === 'answer-action') {
            console.log('this is in in answer-action CallSession');
          }
          if (event.data.action === 'Answer') {
            store.dispatch(uaActions.removeIc(thisSession.id));
            if (thisSession.status === 'ended') return;

            thisSession.accept();

            // get or create call card and set card to expand
            let cardId = cardActions.getCardId({ type: 'call' });
            if (!cardId) {
              store.dispatch(cardActions.newCard({ type: 'call' }));
              cardId = cardActions.getCardId({ type: 'call' });
            }
            store.dispatch(cardActions.setExpanded(cardId));

            // if compact and not already in the phone state, go to phone state
            const currentState = navActions.getCurrentState(store.getState());

            if (store.getState().screenSize.display === 'compact' && currentState.name !== 'wrapper.phone') {
              const card = cardActions.getCard(store.getState(), cardId);
              store.dispatch(
                navActions.callStackRebase({
                  name: 'wrapper.phone',
                  params: { card },
                  fromName: null,
                  fromParams: null,
                }),
              );
              const $state = store.getState().angular.get('$state');
              $state.go('wrapper.phone', { card });
            }
          } else if (event.data.action === 'Reject') {
            thisSession.reject();
          } else if (event.data.action === 'Hold') {
            thisSession.hold();
          } else if (event.data.action === 'Un Hold') {
            thisSession.unhold();
          } else if (event.data.action === 'Hang Up') thisSession.bye(true);
        }
      });
    });
  }

  clearNotifcations(id) {
    const options = { tag: id };

    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.getNotifications(options).then((notifications) => {
          notifications.forEach((notification) => {
            notification.close();
          });

        // do something with your notifications
        });
      });
    }
  }

  setupLocalMedia() {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }

    const mediaElement = audioActions.getAudio('local');
    mediaElement.srcObject = this.session.sessionDescriptionHandler.localMediaStream;
  }

  setupRemoteMedia() {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }

    const mediaElement = audioActions.getAudio('remote');
    const remoteStream = this.session.sessionDescriptionHandler.remoteMediaStream;

    mediaElement.autoplay = true;
    mediaElement.srcObject = remoteStream;
  }

  enableSenderTracks(enable) {
    if (!this.session) { throw new Error('Session does not exist.'); }
    const pc = this.session.sessionDescriptionHandler.peerConnection;
    if (!pc) { throw new Error('Peer connection closed.'); }
    pc.getSenders().forEach((sender) => {
      if (sender.track) {
        sender.track.enabled = enable;
      }
    });
  }

  jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }



  getHeaders() {
    if (!this.session) {
      return null;
    }
    if (this.direction === 'inbound' && this.session.incomingInviteRequest) {
      return this.session.incomingInviteRequest.message.headers;
    } if ((this.direction === 'outbound' && this.session.outgoingInviteRequest)) {
      return this.session.outgoingInviteRequest.message.headers;
    }
    return null;
  }

  getHeader(header) {
    const headersList = this.getHeaders();
    if (headersList && headersList[header]) {
      return headersList[header][0].raw;
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  stripVideo(description) {
    let { sdp } = description;
    const descriptionRegExp = new RegExp('m=video' + '.*$', 'gm');
    const groupRegExp = new RegExp('^a=group:.*$', 'gm');
    if (descriptionRegExp.test(sdp)) {
      let midLineToRemove;
      sdp = sdp.split(/^m=/gm).filter((section) => {
        if (section.substr(0, 5) === 'video') {
          midLineToRemove = section.match(/^a=mid:.*$/gm);
          if (midLineToRemove) {
            const step = midLineToRemove[0].match(/:.+$/g);
            if (step) {
              midLineToRemove = step[0].substr(1);
            }
          }
          return false;
        }
        return true;
      }).join('m=');
      const groupLine = sdp.match(groupRegExp);
      if (groupLine && groupLine.length === 1) {
        let groupLinePortion = groupLine[0];
        // eslint-disable-next-line no-useless-escape
        const groupRegExpReplace = new RegExp(`\ *${midLineToRemove}[^\ ]*`, 'g');
        groupLinePortion = groupLinePortion.replace(groupRegExpReplace, '');
        sdp = sdp.split(groupRegExp).join(groupLinePortion);
      }
    }
    // eslint-disable-next-line no-param-reassign
    description.sdp = sdp;
    return Promise.resolve(description);
  }

  /**
     * Session method
     * see: http://sipjs.com/api/0.7.6/session/#acceptoptions
     */
  accept(signalHid = true) {
    // close the push notification if it is being displayed
    if (this.push) {
      this.push.then((res) => {
        if (res) res.close();
      });
    }

    // if there is already an active call, put the call on hold
    const activeId = sessionActions.getActiveId(store.getState());
    if (activeId
      && sessionActions.getActiveCall(store.getState())
      && !sessionActions.getActiveCall(store.getState()).isOnHold
    ) {
      sessionActions.getActiveCall(store.getState()).hold(false);

      setTimeout(() => {
        if (this.session) {
          this.session.mute();
          this.session.unmute();
        }
      }, 500); // force unmute on answer of second call.
    }

    // set call active
    store.dispatch(sessionActions.setActiveId(this.id));
    const devices = userMedia.getMediaDevices();
    const appName = store.getState().configs.get('appName');
    // accept call
    setTimeout(() => {
      const stream = store.getState().userMedia.get('stream');

      const decodedToken = nsToken.getDecoded();
      const inputDeviceId = userMedia.getDefaultAudioInput(appName, decodedToken.user, devices);

      if (this.session) {
        this.session.sessionDescriptionHandlerOptions = { iceGatheringTimeout: 500 };

        if (inputDeviceId)
        {
          this.session.sessionDescriptionHandlerOptions.constraints = {
            audio: { deviceId: inputDeviceId },
            video: false,
          };
        }


        const allowedCodecs = store.getState().configs.get('PORTAL_WEBRTC_CODEC_LIST_AUDIO');

        this.session.sessionDescriptionHandlerModifiers = [this.stripVideo, allowedCodecs && allowedCodecs.length > 0 ? stripCodecs(allowedCodecs) : null];
        this.session.accept({
          media: {
            stream,
            render: {
              remote: audioActions.getAudio('remote'),
              local: audioActions.getAudio('local'),
            },
          },
        });

        audioActions.updateElementSinkId();
        setTimeout(audioActions.updateElementSinkId,250);

      }
    }, 100);

    audioActions.stopRinger();
    audioActions.stopCallwaiting();
    this.startTimer();
    this.status = 'accepted';
    store.dispatch(sessionActions.sessionsUpdated());

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.acceptIncomingCall().catch(console.error);
    }
  }

  /**
   * Session method
   * Sends a BYE request on a confirmed session
   * see: http://sipjs.com/api/0.7.6/session/#byeoptions
   *
   * Triggers the terminate function
   * in inbound or outbound handlers depending on the direction
   */
  bye(signalHid = true) {
    console.log(this.session.state);
    if (this.session
      && this.session.state !== SessionState.Terminated
      && this.session.state !== SessionState.Terminating
    ) {
      this.session.bye();
    }

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.endCall().catch(console.error);
    }
  }

  /**
     * Session method
     * see: http://sipjs.com/api/0.7.6/session/#canceloptions
     *
     * Triggers the terminate function
     * in inbound or outbound handlers depending on the direction
     */
  cancel(signalHid = true) {
    console.log(this.session.state);
    if (this.session && (this.session.state === SessionState.Initial
      || this.session.state === SessionState.Establishing
    )) {
      this.session.cancel();
    }
    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.endCall().catch(console.error);
    }
  }

  /**
   *
   */
  ignore(signalHid = true) {
    store.dispatch(sessionActions.remove(this.id));

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.rejectIncomingCall().catch(console.debug);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  holdModifier(description) {
    if (!description.sdp || !description.type) {
      throw new Error('Invalid SDP');
    }
    let { sdp } = description;
    const { type } = description;
    if (sdp) {
      if (!/a=(sendrecv|sendonly|recvonly|inactive)/.test(sdp)) {
        sdp = sdp.replace(/(m=[^\r]*\r\n)/g, '$1a=sendonly\r\n');
      } else {
        sdp = sdp.replace(/a=sendrecv\r\n/g, 'a=sendonly\r\n');
        sdp = sdp.replace(/a=recvonly\r\n/g, 'a=inactive\r\n');
      }
    }
    return Promise.resolve({ sdp, type });
  }

  setHold(hold) {
    if (this.held === hold) {
      return;
    }

    const inviteOptions = {
      requestDelegate: {
        onAccept: (response) => {
          this.held = hold;
          console.log('Positive response = ', response);
        },
        onProgress: (response) => {
          console.log('Positive response = ', response);
        },
        onReject: (response) => {
          console.log('Negative response = ', response);
        },
        onTerminated: (response) => {
          console.log('Negative response = ', response);
        },
      },
    };

    inviteOptions.sessionDescriptionHandlerModifiers = hold ? [this.holdModifier] : [];
    this.enableSenderTracks(!hold);
    this.session.invite(inviteOptions).then(() => {
      this.enableSenderTracks(!hold);
    }).catch((error) => {
      console.error('Hold invite error:', error);
    });
  }

  /**
   * http://sipjs.com/api/0.7.6/session/#holdoptions-modifiers
   */
  hold(signalHid = true) {
    this.isOnHold = true;

    try {
      this.setHold(true);
    } catch (e) {
      console.error(e);
    }

    store.dispatch(sessionActions.sessionsUpdated());

    // const appName = store.getState().configs.get('appName');
    /* let timer = localStorage.getItem(`${appName}_holdtimer`);

        if (timer === null) {
            // initialize local storage var
            timer = true;
            localStorage.setItem(`${appName}_holdtimer`, 'true');
        } else if (timer === 'true') {
            timer = true;
        } else if (timer === 'false') {
            timer = false;
        }

        if (timer) {
            this.holdTimer = setInterval(() => {
                if (this.holdTimer) {
                    this.session.unhold();
                    setTimeout(() => {
                        if (this.holdTimer) {
                            this.session.hold();
                        }
                    }, 200);
                }
            }, 15000);
        } */

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.hold().catch(console.error);
    }
  }

  /**
   * http://sipjs.com/api/0.7.6/session/#unholdoptions-modifiers
   */
  unhold(signalHid = true) {
    this.isOnHold = false;

    // clearInterval(this.holdTimer);
    // this.holdTimer = null;

    // unhold the session
    try {
      this.setHold(false);
      this.enableSenderTracks(true);
      this.isMuted = false;
    } catch (e) {
      console.error(e);
    }

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.resume().catch(console.error);
    }

    store.dispatch(sessionActions.sessionsUpdated());
    this.setupRemoteMedia();
  }

  /**
   * @param signalHid
   */
  mute(signalHid = true) {
    this.enableSenderTracks(false);
    this.isMuted = true;

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.mute().catch(console.error);
    }
  }

  /**
   * @param signalHid
   */
  unmute(signalHid = true) {
    this.enableSenderTracks(true);
    this.isMuted = false;

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.unmute().catch(console.error);
    }
  }

  /**
   * Api call to record audio
   */
  record() {
    const { uid } = store.getState().user;
    const recordid = this.id.replace(this.from_tag, ''); // WP-781, remove from_tag from id
    return nsApi.post({
      object: 'call',
      action: 'record_on',
      format: 'json',
      callid: recordid,
      uid,
    });
  }

  /**
   *
   */
  reject(signalHid = true) {
    const { uid } = store.getState().user;
    store.dispatch(sessionActions.remove(this.id));

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.rejectIncomingCall().catch(console.debug);
    }

    return nsApi.post({
      object: 'call',
      action: 'reject',
      callid: this.callId,
      uid,
    });
  }

  /**
   * Starts the call duration timer
   */
  startTimer() {
    if (!this.callTime && !this.timer) {
      this.timer = setInterval(() => {
        this.callTime += 1;
        store.dispatch(sessionActions.sessionsUpdated());
      }, 1000);
    }
  }

  /**
   * Stops the call duration timer
   */
  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  /**
   * Api call to stop recording audio
   */
  stopRecording() {
    const { uid } = store.getState().user;
    const stopRecordId = this.id.replace(this.from_tag, ''); // WP-781, remove from_tag from id
    return nsApi.post({
      object: 'call',
      action: 'record_off',
      format: 'json',
      callid: stopRecordId,
      uid,
    });
  }

  /**
   * If blind transfer send number, for assisted send the session object
   * Session method
   * see: http://sipjs.com/api/0.7.6/session/#refertarget-options
   * @param target
   * @param blind
   * @param signalHid
   */
  transfer(target, blind, signalHid = true) {
    if (blind) {
      try {
        const targetUri = UserAgent.makeURI(`sip:${target.replace('#', '%23')}@portal`);
        this.session.refer(targetUri);
      } catch (e) {
        console.error('Fail to refer');
        console.error(e);
      }
    } else {
      try {
        this.session.refer(target);
      } catch (e) {
        console.error('Fail to assisted refer');
        console.error(e);
      }
    }

    const callControl = store.getState().userMedia.get('hidDevice');
    if (signalHid && callControl) {
      callControl.hold().catch(console.error);
    }
  }

  /**
     * todo
     */
  runStats() {
    if (typeof this.session !== 'undefined'
            && typeof this.session.sessionDescriptionHandler !== 'undefined'
            && typeof this.session.sessionDescriptionHandler.peerConnection !== 'undefined'
    ) {
      /**
             * firefox callback
             * @param stats
             */
      const mozCallBack = (stats) => {
        this.stats.runStatsCounter++;
        stats.forEach((result) => {
          const item = {};
          item.id = result.id;
          item.type = result.type;
          item.timestamp = result.timestamp;
        });
      };

      const webkitCallBack = (stats) => {
        this.stats.runStatsCounter++;
        stats.result().forEach((result) => {
          const item = {};
          result.names().forEach((name) => {
            item[name] = result.stat(name);
          });

          item.id = result.id;
          item.type = result.type;
          item.timestamp = result.timestamp;
          item.audioInputLevel = parseInt(item.audioInputLevel); // type cast to integer
          item.audioOutputLevel = parseInt(item.audioOutputLevel); // type cast to integer

          if (typeof item.id !== 'undefined') {
            if (item.id.indexOf('send') !== -1 && typeof item.audioInputLevel !== 'undefined') {
              this.stats.micVolume = item.audioInputLevel;

              // Gather peak values for dynamic range on meter in UI
              if (item.audioInputLevel > this.stats.micPeak) {
                this.stats.micPeak = item.audioInputLevel;
              }
            } else if (item.id.indexOf('Conn-audio') !== -1 && typeof item.audioOutputLevel !== 'undefined') {
              this.stats.speakVolume = item.audioOutputLevel;

              // calculate the packet loss
              this.stats.packetLoss = (parseInt(item.packetsLost) / parseInt(item.packetsReceived)).toFixed(0);

              // Gather peak values for dynamic range on meter in UI
              if (item.audioOutputLevel > this.stats.speakPeak) {
                this.stats.speakPeak = item.audioOutputLevel;
              }

              if (typeof item.googCodecName !== 'undefined') {
                this.stats.codecLabel = item.googCodecName;
              }

              if (typeof item.bytesReceived !== 'undefined') {
                if (this.stats.runStatsCounter % (1000 / this.stats.runStatsMsDelay) === 0) {
                  const kbps = (item.bytesReceived - this.stats.runStatsLastByte) * 8 / 1000;
                  if (this.stats.runStatsLastByte !== 0) {
                    this.stats.kbps = parseFloat(kbps).toFixed(0);
                  }
                  this.stats.runStatsLastByte = item.bytesReceived;
                }
              }
            }
          }
        });
      };

      // start collecting stats
      this.stats.runStatsInterval = setInterval(() => {
        if (navigator.mozGetUserMedia) {
          // deprecated method
          if (this.session.sessionDescriptionHandler.peerConnection.getReceivers
                        && this.session.sessionDescriptionHandler.peerConnection.getReceivers()[0]
          ) {
            this.session.sessionDescriptionHandler.peerConnection.getStats(
              this.session.sessionDescriptionHandler.peerConnection.getReceivers()[0].track,
              (res) => {
                mozCallBack(res);
              },
              mozCallBack,
            );
          } else if (this.session.sessionDescriptionHandler.peerConnection.getLocalStreams
                        && this.session.sessionDescriptionHandler.peerConnection.getLocalStreams().length
          ) {
            this.session.sessionDescriptionHandler.peerConnection.getStats(
              this.session.sessionDescriptionHandler.peerConnection.getLocalStreams()[0].getAudioTracks()[0],
              (res) => {
                mozCallBack(res);
              },
              mozCallBack,
            );
          }
        } else {
          this.session.sessionDescriptionHandler.peerConnection.getStats(webkitCallBack);
        }
      }, this.stats.runStatsMsDelay);
    }
  }
}

import moduleConfig from './module-config';
import netsAgentCenterDetails from './nets-agent-center-details';

export default angular
  .module('app.agent-detail-center', [])
  .config(['$stateProvider', moduleConfig])
  .component('netsAgentCenterDetails', netsAgentCenterDetails)
  .directive('startDateTime', ['$interval',
    function ($interval) {
      // return the directive link function. (compile function not needed)
      return function (scope, element, attrs) {
        let startTime; // date format
        let stopTime; // so that we can cancel the time updates

        function formatStoMS(s) {
          return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
        }

        // used to update the UI
        function updateTime() {
          const now = new Date();
          const start = new Date(`${startTime} UTC`);
          const duration = now.getTime() - start.getTime();
          element.text(formatStoMS(Math.trunc(duration / 1000)));
        }

        // watch the expression, and update the UI on change.
        scope.$watch(attrs.startDateTime, (value) => {
          startTime = value;
          updateTime();
        });

        stopTime = $interval(updateTime, 1000);

        // listen on DOM destroy (removal) event, and cancel the next UI update
        // to prevent updating time after the DOM element was removed.
        element.on('$destroy', () => {
          $interval.cancel(stopTime);
        });
      };
    }]);

export default ($stateProvider) => {
  $stateProvider.state('sso-enroll', {
    url: `${location.pathname}sso-enroll`,
    params: {
        	idtoken: null,
        	vendor: null,
    },
    template: '<nets-sso-enroll></nets-sso-enroll>',
  });
};

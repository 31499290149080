import { put, select, takeEvery } from 'redux-saga/effects';

import { activeCount, ACTIVE_COUNT } from '../sessions';
import { CONTACTS } from '../contacts';
import * as cardActions from '../card-management';

let isAppInit = false;

export default function* () {
  yield takeEvery(CONTACTS, _fetchCards);
  yield takeEvery(ACTIVE_COUNT, _removeCallCard);
}

/**
 * This is used during the initial load process
 * and handles contact updates
 * @private
 */
const _fetchCards = function* () {
  // [WP-624] Profile card pops up when editing contact favorites
  // Only allow fetch cards to be called once
  if (!isAppInit) {
    isAppInit = true;
    yield put(cardActions.fetchCards());
  } else {
    yield put(cardActions.updateChatContacts());
  }
};

const _removeCallCard = function* () {
  const count = yield select((state) => activeCount(state));
  if (!count) {
    const cardId = yield cardActions.getCardId({ type: 'call' });
    if (cardId) {
      yield put(cardActions.removeCard(cardId));
    }
  }
};

import './styles.css';
import template from './template.html';
import * as parkActions from '../../core/call-park';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];
function controller(
  $ngRedux,
) {
  let unsubscribe = null;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      parkedCount: parkActions.getParked(state).length,
      parkLot: parkActions.getParkingLot(state),
    }))((state) => {
      this.parkedCount = state.parkedCount;
      this.showParkMenu = !!state.parkLot;
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     * Creates dialer card, sets the card to be expanded and if compact changes state.
     */
  this.goToPark = () => {
    $ngRedux.dispatch(parkActions.createCard());
  };
}

import { Map } from 'immutable';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import { stopRingback, stopRinger, stopCallwaiting } from '../audio';
import { activeTypes, getActiveCount } from './utils/getActiveCount';
import { getIsConference } from './utils/getIsConference';

/** ********************************************
 * Redux constants
 ******************************************** */

export const ACTIVE_COUNT = 'core/sessions/ACTIVE_COUNT';
export const ACTIVE_ID = 'core/sessions/ACTIVE_ID';
export const CALL_ENDED = 'core/sessions/CALL_ENDED';
export const SESSIONS = 'core/sessions/SESSIONS';
export const SESSIONS_UPDATED = 'core/sessions/SESSIONS_UPDATED';
export const IS_CONFERENCE = 'core/sessions/IS_CONFERENCE';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  activeCount: 0,
  activeId: null,
  callEnded: null,
  sessions: nsUtils.normalizeList('id', []),
  isConference: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_COUNT:
      window.ga('send', 'event', 'active calls', action.payload);
      return state.set('activeCount', action.payload);
    case ACTIVE_ID:
      return state.set('activeId', action.payload);
    case CALL_ENDED:
      return state.set('callEnded', action.payload);
    case SESSIONS:
      return state.set('sessions', nsUtils.normalizeList('id', action.payload));
    case IS_CONFERENCE:
      return state.set('isConference', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @param payload
 * @returns {function(*, *)}
 */
export const add = (payload) => (dispatch, getState) => {
  const sessions = getState().sessions.get('sessions').toArray();
  sessions.push(payload);

  dispatch({
    type: SESSIONS,
    payload: sessions,
  });

  dispatch(updateActiveCount());
  dispatch(updateIsConference());
};

/**
 *
 * @return {function(*, *)}
 */
export const putSessionsOnHold = () => (dispatch, getState) => {
  const sessions = getState().sessions.get('sessions').toArray();
  if (sessions && sessions.length) {
    for (let i = 0; i < sessions.length; i += 1) {
      sessions[i].hold();
    }
    dispatch(sessionsUpdated());
  }
};

/**
 *
 * @param sessionId
 * @returns {function(*, *)}
 */
export const remove = (sessionId) => (dispatch, getState) => {
  // todo add selector function hasSession to determine if this function should be run

  const existingSessions = getState().sessions.get('sessions').toArray();

  let removedSession;
  const sessions = [];
  for (let i = 0; i < existingSessions.length; i += 1) {
    if (existingSessions[i].id !== sessionId) {
      sessions.push(existingSessions[i]);
    } else {
      removedSession = existingSessions[i];
    }
  }

  dispatch({
    type: SESSIONS,
    payload: sessions,
  });

  dispatch(updateActiveCount());
  dispatch(updateIsConference());
  dispatch(setLastActive());

  // Check if removedSession is set, some cases try to remove a session that no longer exists.
  // One case is when ignoring a call it is removed from sessions,
  // but the session still exists until the call is terminated.
  // When it is terminated it tries to delete itself from the list removedSession is not set.
  // todo move this comment to the top after hasSession function has been implimented
  if (removedSession) {
    // [WP-620] Ignoring or rejecting a call doesn't stop the ring tone
    if (['trying', 'inboundProgressing', 'outboundProgressing'].includes(removedSession.status)) {
      if (removedSession.direction === 'inbound') {
        stopRinger();
        stopCallwaiting();
      } else {
        stopRingback();
      }
    }

    // signal to the ui only if the status is not trying or progressing
    if (!['trying', 'inboundProgressing', 'outboundProgressing'].includes(removedSession.status)) {
      dispatch({
        type: CALL_ENDED,
        payload: removedSession.name || removedSession.number,
      });

      setTimeout(() => {
        dispatch({
          type: CALL_ENDED,
          payload: null,
        });
      }, 3000);
    }
  }
};

/**
 *
 * @param payload
 * @return {{type: string, payload: *}}
 */
export const setActiveId = (payload) => ({
  type: ACTIVE_ID,
  payload,
});

/**
 *
 * @returns {{type: string, payload: *}}
 */
export const sessionsUpdated = () => ({
  type: SESSIONS_UPDATED,
});

/**
 *
 * @return {function(*, *)}
 */
export const setLastActive = () => (dispatch, getState) => {
  const sessions = getState().sessions.get('sessions').toArray();

  sessions.reverse();

  let id = null;
  for (let i = 0; i < sessions.length; i += 1) {
    if (activeTypes.indexOf(sessions[i].status) !== -1) {
      id = sessions[i].id;
    }
  }

  dispatch(setActiveId(id));
};

/**
 *
 * @return {function(*, *)}
 */
export const updateActiveCount = () => (dispatch, getState) => {
  const sessions = getState().sessions.get('sessions').toArray();
  const count = getActiveCount(sessions);

  dispatch({
    type: ACTIVE_COUNT,
    payload: count,
  });
};

export const updateIsConference = () => (dispatch, getState) => {
  const sessions = getState().sessions.get('sessions').toArray();
  const isConf = getIsConference(sessions);

  dispatch({
    type: IS_CONFERENCE,
    payload: isConf,
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @return {*}
 */
export const getActiveCall = (state) => {
  const activeId = state.sessions.get('activeId');
  return state.sessions.get('sessions').entities[activeId];
};

/**
 *
 * @param state
 */
export const getActiveId = (state) => state.sessions.get('activeId');

/**
 *
 * @param state
 */
export const activeCount = (state) => state.sessions.get('activeCount');

/**
 *
 * @param state
 * @return {Array}
 */
export const getSessions = (state) => state.sessions.get('sessions').toArray();

/**
 *
 * @param state
 */
export const isConference = (state) => state.sessions.get('isConference');

/** ********************************************
 * Private Helper functions
 ******************************************** */

import color from 'onecolor';

/**
 *
 * @param clr
 * @returns {*}
 */
const colorToRgbaArray = (clr) => {
  if (Array.isArray(clr) && clr.length === 3) { return clr; }

  if (/^rgb/.test(clr)) {
    return clr.replace(/(^\s*rgba?\(|\)\s*$)/g, '').split(',').map((value, i) => (i === 3 ? parseFloat(value) : parseInt(value, 10)));
  }

  // eslint-disable-next-line no-param-reassign
  if (clr.charAt(0) === '#') { clr = clr.substring(1); }
  if (!/^([a-fA-F0-9]{3}){1,2}$/g.test(clr)) { return undefined; }

  const dig = clr.length / 3;
  let red = clr.substr(0, dig);
  let grn = clr.substr(dig, dig);
  let blu = clr.substr(dig * 2);
  if (dig === 1) {
    red += red;
    grn += grn;
    blu += blu;
  }
  return [parseInt(red, 16), parseInt(grn, 16), parseInt(blu, 16)];
};

/**
 *
 * @param palette
 */
const formatPalette = (palette) => {
  const DARK_CONTRAST_COLOR = colorToRgbaArray('rgba(0,0,0,0.87)');
  const LIGHT_CONTRAST_COLOR = colorToRgbaArray('rgba(255,255,255,0.87)');
  const STRONG_LIGHT_CONTRAST_COLOR = colorToRgbaArray('rgb(255,255,255)');

  const formattedPalette = {};
  const defaultContrast = palette.contrastDefaultColor;
  let lightColors = palette.contrastLightColors || [];
  let strongLightColors = palette.contrastStrongLightColors || [];
  let darkColors = palette.contrastDarkColors || [];

  // These colors are provided as space-separated lists
  if (typeof lightColors === 'string') { lightColors = lightColors.split(' '); }
  if (typeof strongLightColors === 'string') { strongLightColors = strongLightColors.split(' '); }
  if (typeof darkColors === 'string') { darkColors = darkColors.split(' '); }

  // Cleanup after ourselves
  delete palette.contrastDefaultColor; // eslint-disable-line no-param-reassign
  delete palette.contrastLightColors; // eslint-disable-line no-param-reassign
  delete palette.contrastStrongLightColors; // eslint-disable-line no-param-reassign
  delete palette.contrastDarkColors; // eslint-disable-line no-param-reassign

  angular.forEach(palette, (hueValue, hueName) => {
    function formatRgb() {
      return palette[hueName].replace(/[rgb()]/g, '').split(',').map((val) => parseInt(val, 10));
    }

    function getContrastColor() {
      if (defaultContrast === 'light') {
        if (darkColors.indexOf(hueName) > -1) {
          return DARK_CONTRAST_COLOR;
        }
        return strongLightColors.indexOf(hueName) > -1 ? STRONG_LIGHT_CONTRAST_COLOR
          : LIGHT_CONTRAST_COLOR;
      } if (lightColors.indexOf(hueName) > -1) {
        return strongLightColors.indexOf(hueName) > -1 ? STRONG_LIGHT_CONTRAST_COLOR
          : LIGHT_CONTRAST_COLOR;
      }
      return DARK_CONTRAST_COLOR;
    }

    formattedPalette[hueName] = {
      value: formatRgb(),
      contrast: getContrastColor(),
    };
  });

  return formattedPalette;
};

/**
 *
 * @param hex
 * @returns {*}
 */
const generator = (hex) => {
  if (!hex) {
    return false;
  }

  const myColor = color(hex);

  if (!(myColor instanceof color.RGB)) {
    return false;
  }

  return formatPalette({
    50: myColor.lighten(0.52).css(),
    100: myColor.lighten(0.37).css(),
    200: myColor.lighten(0.26).css(),
    300: myColor.lighten(0.12).css(),
    400: myColor.lighten(0.06).css(),
    500: myColor.css(),
    600: myColor.darken(0.06).css(),
    700: myColor.darken(0.12).css(),
    800: myColor.darken(0.18).css(),
    900: myColor.darken(0.24).css(),
    A100: myColor.lighten(0.52).css(),
    A200: myColor.lighten(0.37).css(),
    A400: myColor.lighten(0.06).css(),
    A700: myColor.darken(0.12).css(),
    contrastDefaultColor: 'light',
    contrastDarkColors: '50 100 200 A100 A200',
  });
};

export default {
  generator,
};

import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import DetectRTC from 'detectrtc';

import { store } from '../../store/index';
import * as ringtoneActions from '../../ringtones';

const audioElements = {
  local: document.createElement('audio'),
  remote: document.createElement('audio'),
  ringback: document.createElement('audio'),
  ringer: document.createElement('audio'),
  callwaiting: document.createElement('audio'),
  busy: document.createElement('audio'),
  fastbusy: document.createElement('audio'),
};

let repeatRinger = false;
let repeatRingback = false;
let repeatCallwaiting = false;

// setup local audio
audioElements.local.muted = true;
audioElements.ringback.src = 'https://upload.wikimedia.org/wikipedia/commons/c/cd/US_ringback_tone.ogg';
audioElements.callwaiting.src = '/webphone/assets/audio/ringtones/ringtone_ah_ha_moment.wav';
audioElements.busy.src = 'https://upload.wikimedia.org/wikipedia/commons/6/6c/NorthAmericanBusySignal.ogg';
audioElements.fastbusy.src = 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Reorder_tone.ogg';

/**
 * Repeats audio after the track is finished
 * The 'repeatRinger' flag is used to prevent the track from playing again
 */
audioElements.ringer.onended = () => {
  if (repeatRinger) {
    const selectedRingtone = ringtoneActions.getSelectedRingtone(store.getState());
    setTimeout(() => {
      if (repeatRinger) {
        audioElements.ringer.play();
      } else {
        audioElements.ringer.pause();
        audioElements.ringer.currentTime = 0;
      }
    }, selectedRingtone.delayRepeat);
  } else {
    audioElements.ringer.pause();
    audioElements.ringer.currentTime = 0;
  }
};

/**
* Repeats audio after the track is finished
* The 'repeatRingback' flag is used to prevent the track from playing again
*/
audioElements.ringback.onended = () => {
  if (repeatRingback) {
    setTimeout(() => {
      if (repeatRingback) {
        audioElements.ringback.play();
      } else {
        audioElements.ringback.pause();
        audioElements.ringback.currentTime = 0;
      }
    }, 1000);
  } else {
    audioElements.ringback.pause();
    audioElements.ringback.currentTime = 0;
  }
};

audioElements.callwaiting.onended = () => {
  if (repeatCallwaiting) {
    setTimeout(() => {
      if (repeatCallwaiting) {
        audioElements.callwaiting.play();
      } else {
        audioElements.callwaiting.pause();
        audioElements.callwaiting.currentTime = 0;
      }
    }, 4000);
  } else {
    audioElements.callwaiting.pause();
    audioElements.callwaiting.currentTime = 0;
  }
};

/**
 * sets the sink id for the audio element
 * supports
 * @param audioElement
 */
const setSink = (audioElement,isRinger) => {
  if (typeof isRinger == 'undefined') isRinger = false;

  if (DetectRTC.isSetSinkIdSupported && nsBrowser.name === 'Chrome') {
    let sinkId = store.getState().userMedia.get('outputDevice');
    if (isRinger && store.getState().userMedia.get('outputDeviceRinger') != null)
      sinkId = store.getState().userMedia.get('outputDeviceRinger');
    if (sinkId) {
      if (isRinger)
        console.log("updateElementSinkId ringid=" + sinkId);
      else
        console.log("updateElementSinkId audioId=" + sinkId);
      audioElement.setSinkId(sinkId);
    }
  }
};

export const updateElementSinkId = () => {
  console.log("updateElementSinkId called ")
  setSink(audioElements.local);
  setSink(audioElements.remote);
  setSink(audioElements.callwaiting);
  setSink(audioElements.ringback);
  setSink(audioElements.busy);
  setSink(audioElements.fastbusy);
  setSink(audioElements.ringer,true);
};

/**
 *
 * @param {string} type - Expects local, remote, ringback or ringer
 * @returns {*}
 */
export const getAudio = (type) => audioElements[type];

/**
 *
 */
export const playRinger = () => {
  try {
    repeatRinger = true;
    setSink(audioElements.ringer,true);
    audioElements.ringer.play();
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playCallWaiting = () => {
  try {
    const appName = store.getState().configs.get('appName');
    const userId = store.getState().configs.get('userId');
    const cValue = localStorage.getItem(`${appName}-${userId}_callwaiting`);
    setSink(audioElements.callwaiting);
    audioElements.callwaiting.volume = 0.5;
    if (cValue === null || cValue === true || cValue === 'true' || cValue === 'enabled') {
      repeatCallwaiting = true;
      audioElements.callwaiting.play();
    } else if (cValue === 'one') {
      repeatCallwaiting = false;
      audioElements.callwaiting.play();
    } else {
      // eslint-disable-next-line no-console
      console.log('incoming call, skipping audio');
    }
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playRingback = () => {
  try {
    repeatRingback = true;
    setSink(audioElements.ringback);
    audioElements.ringback.play();
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const stopRinger = () => {
  try {
    repeatRinger = false;
    audioElements.ringer.pause();
    audioElements.ringer.currentTime = 0;
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const stopRingback = () => {
  try {
    repeatRingback = false;
    audioElements.ringback.pause();
    audioElements.ringback.currentTime = 0;
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const stopCallwaiting = () => {
  try {
    repeatCallwaiting = false;
    audioElements.callwaiting.pause();
    audioElements.callwaiting.currentTime = 0;
  } catch (err) {
    // todo
  }
};

/**
 *
 * @param {string} path - path to audio file
 */
export const ringerSrc = (path) => {
  try {
    setSink(audioElements.ringer);
    audioElements.ringer.src = path;
    audioElements.ringer.pause();
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playBusy = () => {
  try {
    setSink(audioElements.busy);
    audioElements.busy.volume = 0.3;
    audioElements.busy.play();
    setTimeout(() => {
      audioElements.busy.pause();
      audioElements.busy.currentTime = 0;
    }, 3000);
  } catch (err) {
    // todo
  }
};

/**
 *
 */
export const playFastBusy = () => {
  try {
    setSink(audioElements.fastbusy);
    audioElements.fastbusy.volume = 0.3;
    audioElements.fastbusy.play();
    setTimeout(() => {
      audioElements.fastbusy.pause();
      audioElements.fastbusy.currentTime = 0;
    }, 3000);
  } catch (err) {
    // todo
  }
};

import './styles.css';
import template from './template.html';
import { filteredBy, getCallsFilteredBy } from '../../../../core/call-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$translate',
];
function controller(
  $ngRedux,
  $translate,
) {
  let unsubscribe = null;
  const self = this;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      filteredBy: getCallsFilteredBy(state),
    }))((state) => {
      this.filteredBy = state.filteredBy;
      _setCallHistoryLabel(this.filteredBy);
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.filterCallHistoryBy = (name) => {
    $ngRedux.dispatch(filteredBy(name));
  };

  function _setCallHistoryLabel(label) {
    switch (label) {
      case 'all': self.callHistoryMenuLabel = $translate.instant('CALL_HISTORY'); break;
      case 'missed': self.callHistoryMenuLabel = $translate.instant('MISSED'); break;
      case 'inbound': self.callHistoryMenuLabel = $translate.instant('INBOUND'); break;
      case 'outbound': self.callHistoryMenuLabel = $translate.instant('OUTBOUND'); break;
      default: self.callHistoryMenuLabel = label;
    }
  }
}

import Bugsnag from '@bugsnag/js';

const _bugsnagClient = Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY,
  appVersion: process.env.VERSION,
  releaseStage: process.env.NODE_ENV,
});

export const bugsnagClient = _bugsnagClient;

export default angular
  .module('exceptionOverride', [])
  .factory('$exceptionHandler', () => (exception, cause) => {
    _bugsnagClient.notify(exception, {
      beforeSend(report) {
        report.updateMetaData('angular', { cause });
      },
    });
  });

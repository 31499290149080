import template from './template.html';
import * as cardActions from '../../core/card-management';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$mdToast',
  '$state',
  '$translate',
];

function controller(
  $ngRedux,
  $mdDialog,
  $mdToast,
  $state,
  $translate,
) {
  let unsubscribe;
  const self = this;
  this.user;
  this.webphoneDevice;
  this.loaded = false;
  this.shownAlready = false;
  this.addressShow;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      loaded: !cardActions.getLoading(state),
      user: state.user,
      webphoneDevice: state.devices.get('audioDevice'),
      addressShow: state.configs.get('portalShowAddressBook'),
    }))((state) => {
      this.loaded = state.loaded;

      if (state.loaded) {
        this.showAddressModal(state.user, state.webphoneDevice, state.addressShow);
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  this.showAddressModal = (user, webphoneDevice, addressShow) => {
    if (this.shownAlready == false && user != null && webphoneDevice != null && addressShow == 'yes' && webphoneDevice.is_endpoint_callid) {
      // if the user does not want to show the notification ever again, then
      if (localStorage.getItem(`doNotShowEmergencyAddressNotification_${webphoneDevice.aor}`)) {
        return;
      }

      // if address_id is set and is not from any level, then the device address was set, should just return
      // and if the ip address is correct
      if (webphoneDevice.address_id != null
                && webphoneDevice.address_id_source == undefined
                && webphoneDevice.received_from.includes(webphoneDevice.address_ip)) {
        return;
      }

      // if the user was taking from a default, notify the user of the source of the emergency address
      let notifyMessage = '';
      if (webphoneDevice.address_id_source != null && webphoneDevice.address_id != 'add_address_id') {
        if (webphoneDevice.address_id_source == 'domain_level') {
          notifyMessage = $translate.instant('EMERGENCY_ADDRESS_FROM_DOMAIN_LEVEL');
        } else if (webphoneDevice.address_id_source == 'site_level') {
          notifyMessage = $translate.instant('EMERGENCY_ADDRESS_FROM_SITE_LEVEL');
        } else if (webphoneDevice.address_id_source == 'user_level') {
          notifyMessage = $translate.instant('EMERGENCY_ADDRESS_FROM_USER_LEVEL');
        }
      }

      // if the address_id is not set at all, then notify user that there is currently no emergency address for this device
      if (webphoneDevice.address_id == null || webphoneDevice.address_id == '' || webphoneDevice.address_id == 'add_address_id') {
        notifyMessage = $translate.instant('NO_EMERGENCY_ADDRESS_SET');
      }

      // if the pretty print string is set, then ask the user if this is the current address
      let currentAddressStringQuestion = '';
      let currentAddressString = '';
      if (webphoneDevice.address_pretty_print != null && webphoneDevice.address_pretty_print != '') {
        currentAddressStringQuestion = $translate.instant('IS_THIS_CURRENT_ADDRESS');
        currentAddressString = webphoneDevice.address_pretty_print;
      }

      // if ip address on file and where logged in are different, then show message
      let checkIPAddress = '';
      if ((webphoneDevice.address_ip == undefined
                            || webphoneDevice.address_ip == false
                            || webphoneDevice.address_ip == ''
                            || !webphoneDevice.received_from.includes(webphoneDevice.address_ip))
                && webphoneDevice.address_id != 'add_address_id') {
        checkIPAddress = $translate.instant('EMERGENCY_ADDRESS_IP_SET_DIFFERENT');
      }

      // set notify/request admin for changes
      const contactAdminToChangeMessage = $translate.instant('CONTACT_ADMIN_TO_CHANGE_EMERGENCY');

      $mdDialog.show({
        template: `${'<md-dialog aria-label="Emergency Address Notify" class="delete-modal">'
                            + '<md-dialog-content style="padding: 20px;">'
                            + '<p>'}${notifyMessage}</p>`
                            + `<p>${currentAddressStringQuestion} <span style="font-size: 18px;font-weight: 500;"> ${currentAddressString} </span>` + '</p>'
                            + `<p>${checkIPAddress}</p>`
                            + `<p>${contactAdminToChangeMessage}</p>`
                            + '</md-dialog-content>'
                            + '<md-dialog-actions layout="row">'
                                + `<md-button ng-click="$ctrl.cancel()" class="md-primary">${
                                  $translate.instant('CANCEL')
                                } </md-button>`
                                + `<md-button ng-click="$ctrl.doNotShowAgain()" class="md-primary">${
                                  $translate.instant('DO_NOT_SHOW_AGAIN')
                                } </md-button>`
                            + '</md-dialog-actions>'
                        + '</md-dialog>',
        controllerAs: '$ctrl',
        controller: [function () {
          this.cancel = () => {
            $mdDialog.hide();
          };
          this.doNotShowAgain = () => {
            localStorage.setItem(`doNotShowEmergencyAddressNotification_${webphoneDevice.aor}`, true);
            $mdDialog.hide();
          };
        }],
      });
      this.shownAlready = true;
    }
  };
}

import './styles.css';
import template from './template.html';
import * as cardActions from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
];
function controller(
  $ngRedux,
  $mdDialog,
) {
  this.show = 'phone';

  /**
     *
     */
  this.closeModal = () => {
    $mdDialog.hide();
    $ngRedux.dispatch(cardActions.setExpanded(null));
  };

  /**
     *
     * @param workflow
     */
  this.showPark = (workflow) => {
    this.parkWorkflow = workflow;
    this.show = 'call-park';
  };

  /**
     *
     * @param workflow
     */
  this.showContactList = (workflow) => {
    this.contactsWorkflow = workflow;
    this.show = 'contacts';
  };

  /**
     *
     * @param transferId
     */
  this.assistedTransfer = (transferId) => {
    this.contactsWorkflow = null;
    this.dialerWorkflow = null;
    this.transferId = transferId;
    this.show = 'phone';
  };

  /**
     *
     * @param contactId
     */
  this.showContactDetails = (contactId) => {
    this.contactID = contactId;
    this.show = 'contact-details';
  };

  /**
     *
     * @param workflow
     */
  this.showDialer = (workflow) => {
    this.dialerWorkflow = workflow;
    this.show = 'dialer';
  };
}

import template from './template.html';
import * as cardActions from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
];
function controller(
  $ngRedux,
  $mdDialog,
) {
  /**
     *
     */
  this.closeModal = () => {
    $mdDialog.hide();
    $ngRedux.dispatch(cardActions.setExpanded(null));
  };
}

import template from './template.html';
import * as cardActions from '../../../../core/card-management';
import { getDayStats } from '../../../../core/agent-center';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = ['$ngRedux'];
function controller($ngRedux) {
  this.$onInit = () => {
    $ngRedux.connect((state) => ({
      daystats: getDayStats(state),
    }))((state) => {
      this.daystats = state.daystats;
    });
  };

  /**
     *
     */
  this.closeModal = () => {
    $ngRedux.dispatch(cardActions.setExpanded(null));
    $ngRedux.dispatch(cardActions.removeCard(this.card.id));
  };
}

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.chat-new', {
      url: 'chat-new',
      params: {
        card: null,
      },
      template: '<nets-chat-new card="$ctrl.card"></nets-chat-new>',
      controller: ['$stateParams', function ($stateParams) {
        this.card = $stateParams.card;
      }],
      controllerAs: '$ctrl',
    });
};

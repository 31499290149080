import './styles.css';
import template from './template.html';
import {
  fetchInDispositions, fetchOutDispositions, getDispositions, postWrapUp,
} from '../../core/agent-center';
import { getCallCdrDetails, updateCdr } from '../../core/call-history';
import { getExpandedId, setExpanded, removeCard } from '../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@',
  },
};

controller.$inject = [
  '$ngRedux',
];

function controller(
  $ngRedux,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    if (this.card.meta.details.direction === 'inbound') {
      $ngRedux.dispatch(fetchInDispositions());
    } else {
      $ngRedux.dispatch(fetchOutDispositions());
    }
    unsubscribe = $ngRedux.connect((state) => ({
      dispositions: getDispositions(state, this.card.meta.details.direction),
      cdr: getCallCdrDetails(state, this.card.meta.details.callId),
      screenSize: state.screenSize,
      configs: state.configs,
      expandedId: getExpandedId(state),
      context: this.context,
    }))((state) => {
      this.dispositions = state.dispositions;
      this.dispMenu = this.dispositions.map((disp) => disp.disposition).filter((value, index, self) => self.indexOf(value) === index);
      this.cdr = state.cdr ? state.cdr.cdr[0] : false;
      this.context = state.context;
      this.screenSize = state.screenSize;
      this.configs = state.configs;
      this.showNotes = state.configs.get('portalCallPopupShowNotes') === 'yes';
      this.showDisps = state.configs.get('portalCallPopupShowDispNotes') === 'yes';
      this.expanded = this.card.id === state.expandedId;
      if (this.cdr) {
        this.selectedDisposition = this.cdr.CdrR.disposition;
        this.selectedReason = this.cdr.CdrR.reason;
        this.wrapupnotes = this.cdr.CdrR.notes;
      } else {
        this.selectedDisposition = '';
        this.selectedReason = '';
      }
    });
  };

  this.focusCard = () => {
    $ngRedux.dispatch(setExpanded(this.card.id));
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.selectDisposition = ($event, disp) => {
    $event.stopPropagation();
    this.selectedDisposition = disp;
    this.updateCdr();
  };

  this.getReasons = () => this.dispositions.filter((disp) => disp.disposition === this.selectedDisposition)
    .map((disp) => disp.reason);

  this.selectReason = ($event, reason) => {
    $event.stopPropagation();
    this.selectedReason = reason;
    this.updateCdr();
  };

  this.keyUpFunc = () => {
    clearTimeout(this.keyUpTime);
    this.keyUpTime = setTimeout((self) => {
      self.updateCdr();
    }, 1000, this);
  };

  this.updateCdr = () => {
    let tempcdr = {
      CdrR: {
        disposition: this.selectedDisposition,
        reason: this.selectedReason,
        notes: this.wrapupnotes,
      },
    };
    if (this.cdr) {
      tempcdr = this.cdr;
      tempcdr.CdrR.disposition = this.selectedDisposition;
      tempcdr.CdrR.reason = this.selectedReason;
      tempcdr.CdrR.notes = this.wrapupnotes;
    }
    $ngRedux.dispatch(updateCdr({
      cdr_id: this.card.meta.details.callId,
      cdr: [tempcdr],
    }));
  };

  this.submitWrapUp = () => {
    $ngRedux.dispatch(postWrapUp({
      disposition: this.selectedDisposition,
      reason: this.selectedReason,
      notes: this.wrapupnotes,
      callId: this.card.meta.details.callId,
    }));
    $ngRedux.dispatch(removeCard(this.card.id));
  };
}

import nsApi from '@netsapiens/netsapiens-js/dist/api';

import template from './template.html';
import { authenticated } from '../login';

function controller(
  $ngRedux,
  $mdDialog,
  $rootScope,
  $sce,
  $state,
) {
  const self = this;

  self.imageIsLoaded = false;
  self.displayImage = true;
  self.imageLoaded = imageLoaded;
  self.formMessage = '';
  self.spinner = false;
  self.imgUrl = null;
  self.mfaSubmit = mfaSubmit;
  self.username = null;
  self.mfa_type = null;
  self.mfa_vendor = null;

  self.$onInit = () => {
    $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
    }))(this);

    const hostname = $ngRedux.getState().configs.get('hostname');
    self.imgUrl = `https://${hostname}/ns-api/?object=image&action=read&server=${hostname}&filename=portal_landing.png`;
    self.username = $state.params.username;
    self.mfa_type = $state.params.mfa_type;
    self.mfa_vendor = $state.params.mfa_vendor;
  };

  function imageLoaded(display) {
    self.displayImage = display;
    self.imageIsLoaded = true;
    $rootScope.$digest();
  }

  function mfaSubmit(form) {
    self.formMessage = '';

    if (form.$valid) {
      self.spinner = true;
    }

    // check if $apply or $digest is already in progress
    if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') {
      $rootScope.$digest();
    }

    nsApi.mfaAuth({
      username: self.username,
      mfa_type: self.mfa_type,
      mfa_vendor: self.mfa_vendor,
      passcode: form.passcode.$viewValue,
      apiHash: $ngRedux.getState().configs.get('apiHash'),
    }).then(() => {
      $ngRedux.dispatch(authenticated());
    }).catch(() => {
      self.spinner = false;
      self.formMessage = $sce.trustAsHtml('Passcode is invalid.<br>Please try again.');
      $rootScope.$digest();
    });
  }
}

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$rootScope',
  '$sce',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
};

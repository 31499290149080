import './styles.css';
import template from './template.html';
import { getConfig } from '../../core/configs';
import { getEnabled } from '../../core/answering-rules';
import * as navActions from '../../core/state-history';
import { getParked } from '../../core/call-park';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdSidenav',
  '$ngRedux',
  '$state',
];

function controller(
  $mdSidenav,
  $ngRedux,
  $state,
) {
  let imgLoaded = false;
  const self = this;
  let unsubscribe;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      hostname: getConfig(state, 'hostname'),
      rule: getEnabled(state),
      user: state.user,
      parkedCount: getParked(state).length,
      // todo get user presence
    }))((state) => {
      this.rule = state.rule;
      this.user = state.user;
      this.parkedCount = state.parkedCount;
      if (!imgLoaded) {
        const imgUrl = `https://${state.hostname}/ns-api/?object=image&action=read&server=${state.hostname}&filename=drawer_default.png&domain=${state.domain}`;
        const elem = document.getElementById('drawer-header');
        elem.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imgUrl})`;
        elem.style.backgroundSize = 'cover';
        imgLoaded = true;
      }

      _setPresence();
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param route
     */
  this.navPush = (route) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(navActions.clearToBase('navStack'));
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: route.name,
        params: route.params || null,
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go(route.name, route.params || null);
    $mdSidenav('drawer').toggle();
  };

  /**
     *
     */
  this.navPushUserDetails = () => {
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    if (lastOnStack.name !== 'wrapper.user-details') {
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.user-details',
          params: null,
          fromName: lastOnStack.name,
          fromParams: lastOnStack.params,
        }),
      );
      $state.go('wrapper.user-details');
      $mdSidenav('drawer').toggle();
    }
  };

  function _setPresence() {
    if (!self.user) {
      return;
    }

    switch (self.user.presence) {
      case 'open':
      case 'progressing':
      case 'inuse':
      case 'alerting':
        self.presenceColor = '#3ed455';
        break;
      case 'inactive':
      case 'held-private':
        self.presenceColor = '#ff270e';
        break;
      default:
        self.presenceColor = '#ff270e';
    }
  }
}

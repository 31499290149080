import _ from 'lodash';
import naturalSort from 'javascript-natural-sort';

/**
 *
 * @param {Object|Array} contactList
 * @param {string} sortedBy 'first_name', 'last_name', 'online', 'extension'
 * @param {string} sortDirection 'asc', 'desc'
 * @returns {[]}
 */
export const addContactListDividers = (contactList, sortedBy, sortDirection) => {
  let first = true;
  let tempList = [];

  switch (sortedBy) {
    case 'last_name':
    case 'first_name':
      if (sortDirection === 'asc') {
        for (const char in contactList) {
          if (contactList.hasOwnProperty(char)) {
            tempList.push({ index: char, divider: true, first });
            tempList = tempList.concat(contactList[char]);

            if (first) {
              first = false;
            }
          }
        }
      } else {
        _.keys(contactList)
          .sort(naturalSort)
          .reverse()
          .forEach((char) => {
            tempList.push({ index: char, divider: true, first });
            tempList = tempList.concat(contactList[char]);

            if (first) {
              first = false;
            }
          });
      }
      return tempList;
    case 'online':
      if (contactList.hasOwnProperty('Online')) {
        tempList.push({ index: 'Online', divider: true, first });
        tempList = tempList.concat(contactList.Online);
      }
      if (contactList.hasOwnProperty('Offline')) {
        tempList.push({ index: 'Offline', divider: true, first });
        tempList = tempList.concat(contactList.Offline);
      }
      return tempList;
    default:
      return contactList;
  }
};

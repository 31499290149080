import React from 'react';
import Chart from 'react-google-charts'; // eslint-disable-line no-unused-vars

export default class CallStatsComponent extends React.Component {
  render() {
    const slices = {};
    this.props.queueColors.forEach((item, i) => {
      slices[i] = { color: item };
    });

    return (
      <div>
        <Chart
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={this.props.callStats}
          options={{
            chartArea: {
              width: '90%',
              height: '90%',
            },
            pieHole: 0.64,
            pieSliceText: 'none',
            height: '117px',
            width: '117px',
            legend: 'none',
            slices,
          }}
        />
      </div>
    );
  }
}

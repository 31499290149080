import './styles.css';
import template from './template.html';
import * as chatActions from '../../../../core/chat';
import * as cardActions from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      expandedId: cardActions.getExpandedId(state),
      screenSize: state.screenSize,
    }))((state) => {
      this.showCard = this.card.id !== state.expandedId;
      this.screenSize = state.screenSize;
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param $event
     */
  this.focusChat = ($event) => {
    this.scrollbottom = true;


    // check if session needs to be marked as read
    let session = chatActions.getSession($ngRedux.getState(), this.card.meta.sessionId);
    if (session != undefined && session.lastStatus === "unread") {
      $ngRedux.dispatch(chatActions.updateLastRead(this.card.meta.sessionId));
    }
    
    // check if the event is on an element without no-expand
    if ($event.target
            && $event.target.className
            && $event.target.className.indexOf('no-expand') === -1
    ) {
      $ngRedux.dispatch(cardActions.setExpanded(this.card.id));
    }
  };

  /**
     *
     * @param cardId
     */
  this.removeCard = (cardId) => {
    $ngRedux.dispatch(cardActions.removeCard(cardId));
  };

  /**
     *
     */
  this.sendMessage = () => {
    if (!this.messageInput || !this.messageInput.trim()) {
      this.messageInput = '';
      return;
    }
    const params = { message: this.messageInput, ...this.card.meta.sendMessageParams };
    $ngRedux.dispatch(chatActions.sendMessage(params));
    this.messageInput = '';
  };
}

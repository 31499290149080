import './styles.css';
import template from './template.html';

import * as navActions from '../../core/state-history';
import { getUser } from '../../core/user';
import { getShowAgentCenter } from '../../core/agent-center';
import { getParked, getParkingLot } from '../../core/call-park';
import * as chatActions from '../../core/chat';
import { unsubscribePush } from '../../core/push';

function controller(
  $mdColors,
  $ngRedux,
  $state,
  $translate,
) {
  let unsubscribe;

  // used to compare currentState to state updates
  const currentState = {};

  /**
     *
     */
  this.$onInit = () => {
    // get highlight color
    this.highlightColor = $mdColors.getThemeColor('primary');

    // get active border color
    this.activeBorder = `3px solid ${$mdColors.getThemeColor('accent')}`;
    this.inactiveBorder = '3px solid #fff';

    this.showVoicemail = true;
    // bind redux to component
    // map redux to component
    unsubscribe = $ngRedux.connect((state) => ({
      currentState: navActions.getCurrentState(state),
      user: getUser(state),
      screenSize: state.screenSize,
      unreadCount: chatActions.getUnreadCount(state),
      parkedCount: getParked(state).length,
      parkLot: getParkingLot(state),
      newVoicemail: state.voicemail.get('newVoicemail'),
      allowChat: state.configs.get('portalChatSMS') || "yes",
      showAgentCenterMenu: getShowAgentCenter(state),
      contactCenterName: state.configs.get('PORTAL_CONTACT_CENTER_FEATURE_NAME'),
      PORTAL_WEB_PHONE_SHOW_ANSWERRULES: state.configs.get('PORTAL_WEB_PHONE_SHOW_ANSWERRULES') || "yes",
      PORTAL_WEB_PHONE_SHOW_VOICEMAIL: state.configs.get('PORTAL_WEB_PHONE_SHOW_VOICEMAIL') || "yes",
      PORTAL_WEB_PHONE_SHOW_GREETINGS: state.configs.get('PORTAL_WEB_PHONE_SHOW_GREETINGS') || "yes",
      PORTAL_WEB_PHONE_SHOW_PARKS: state.configs.get('PORTAL_WEB_PHONE_SHOW_PARKS') || "yes",
      PORTAL_WEB_PHONE_SHOW_CALLHISTORY: state.configs.get('PORTAL_WEB_PHONE_SHOW_CALLHISTORY')|| "yes",
      PORTAL_WEB_PHONE_SHOW_CONTACTS: state.configs.get('PORTAL_WEB_PHONE_SHOW_CONTACTS') || "yes",
      iframeName: state.configs.get('PORTAL_WEB_PHONE_IFRAME_NAME'),
      iframeUrl: state.configs.get('PORTAL_WEB_PHONE_IFRAME_URL'),
      iframeIcon: state.configs.get('PORTAL_WEB_PHONE_IFRAME_ICON')

    }))((state) => {
      // update component state
      this.currentState = state.currentState;
      this.user = state.user;
      this.showVoicemail = (state.user && state.user.vmailEnabled == "yes" && state.PORTAL_WEB_PHONE_SHOW_VOICEMAIL.toLowerCase() !== "no");
      this.showAnserrules = state.PORTAL_WEB_PHONE_SHOW_ANSWERRULES.toLowerCase() !== "no";
      this.showGreetings = state.PORTAL_WEB_PHONE_SHOW_GREETINGS.toLowerCase() !== "no";
      this.showCallhistory = state.PORTAL_WEB_PHONE_SHOW_CALLHISTORY.toLowerCase() !== "no";
      this.showContacts = state.PORTAL_WEB_PHONE_SHOW_CONTACTS.toLowerCase() !== "no";
      this.screenSize = state.screenSize;
      this.unreadCount = state.unreadCount;
      this.parkedCount = state.parkedCount;
      this.newVoicemail = state.newVoicemail;
      this.allowChat = state.allowChat.toLowerCase() ;
      this.showAgentCenterMenu = state.showAgentCenterMenu;
      this.showParkMenu = !!state.parkLot && state.PORTAL_WEB_PHONE_SHOW_PARKS.toLowerCase() !== "no";
      this.contactCenterName = state.contactCenterName || $translate.instant('CALL_CENTER');
      this.iframeName = state.iframeName || "";
      this.iframeUrl = state.iframeUrl || "";
      //https://fonts.google.com/icons?selected=Material+Icons
      this.iframeIcon = state.iframeIcon || "open_in_new";
      this.showIframe= this.iframeName !== "" && this.iframeUrl !== "" && this.iframeIcon !== "";

      // update active button border and highlighting if the state has changed
      if (currentState.name !== this.currentState.name) {
        this.agentActive = this.currentState.name === 'wrapper.agent-center-list';
        this.contactsActive = this.currentState.name === 'wrapper.contact-list';
        this.callsActive = this.currentState.name === 'wrapper.call-history-list';
        this.voicemailActive = this.currentState.name === 'wrapper.voicemail-list';
        this.chatActive = this.currentState.name === 'wrapper.chat-list';
        this.callparkActive = this.currentState.name === 'wrapper.call-park-list';
        this.arActive = this.currentState.name === 'wrapper.answering-rule-list';
        this.greetingsActive = this.currentState.name === 'wrapper.greetings-list';
        this.iframeActive = this.currentState.name === 'wrapper.iframe-holder';
        this.settingsActive = this.currentState.name === 'wrapper.settings';
        this.logoutActive = this.currentState.name === 'wrapper.logout';
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param {string} name - link name
     * @param {boolean} flag - highlightColor button?
     */
  this.hover = (name, flag) => {
    switch (name) {
      case 'agent':
        this.agentIconHover = flag ? this.highlightColor : '#727272';
        this.agentTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'contacts':
        this.contactsIconHover = flag ? this.highlightColor : '#727272';
        this.contactsTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'calls':
        this.callsIconHover = flag ? this.highlightColor : '#727272';
        this.callsTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'voicemail':
        this.voicemailIconHover = flag ? this.highlightColor : '#727272';
        this.voicemailTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'chat':
        this.chatIconHover = flag ? this.highlightColor : '#727272';
        this.chatTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'park':
        this.parkIconHover = flag ? this.highlightColor : '#727272';
        this.parkTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'answering-rules':
        this.arIconHover = flag ? this.highlightColor : '#727272';
        this.arTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'greetings':
        this.greetingsIconHover = flag ? this.highlightColor : '#727272';
        this.greetingsTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'settings':
        this.settingsIconHover = flag ? this.highlightColor : '#727272';
        this.settingsTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'logout':
        this.logoutIconHover = flag ? this.highlightColor : '#727272';
        this.logoutTextHover = flag ? this.highlightColor : 'inherit';
        break;
      case 'iframe':
        this.iframeIconHover = flag ? this.highlightColor : '#727272';
        this.iframeTextHover = flag ? this.highlightColor : 'inherit';
        break;
      default:
    }
  };

  /**
     *
     * @param route
     */
  this.navPush = (route) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(navActions.clearToBase('navStack'));
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: route.name,
        params: route.params || null,
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go(route.name, route.params || null);
  };

  /**
     *
     */
  this.navPushUserDetails = () => {
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    if (lastOnStack.name !== 'wrapper.user-details') {
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.user-details',
          params: null,
          fromName: lastOnStack.name,
          fromParams: lastOnStack.params,
        }),
      );
      $state.go('wrapper.user-details');
    }
  };

  /**
     * Rebases the nav stack with the new route
     * @param {string} name - name of route
     */
  this.rebase = (name) => {
    $ngRedux.dispatch(
      navActions.navStackRebase({
        name,
        params: null,
        fromName: null,
        fromParams: null,
      }),
    );
  };

  this.menuDirection = 'left';
  this.collapseMenu = () => {
    if (this.navSize === undefined || this.navSize === 'uncollapsedMenu') {
      this.navSize = 'collapsedMenu';
      this.menuDirection = 'right';
    } else {
      this.navSize = 'uncollapsedMenu';
      this.menuDirection = 'left';
    }
  };

  // click listener for logout button to unregister push.
  // only call unsubscribePush on explicity button press
  this.logoutClick = () => {
    // when logout click, clear the badge
    navigator.clearAppBadge().catch(() => {});

    unsubscribePush();
  };
}

controller.$inject = [
  '$mdColors',
  '$ngRedux',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
};

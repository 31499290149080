import crypto from 'crypto-browserify';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

/**
 *
 * @param {object} contact
 * @return {*}
 * @private
 */
export const formatContact = (contact) => {
  contact.name = `${contact.first_name} ${contact.last_name}`;

  contact.initials = '';
  if (contact.first_name) {
    contact.initials += contact.first_name[0].toUpperCase();
  }
  if (contact.last_name) {
    contact.initials += contact.last_name[0].toUpperCase();
  }
  // create a unique id
  contact.id = contact.contact_id || nsUtils.generateContactId({
    firstName: contact.first_name,
    lastName: contact.last_name,
    user: contact.user,
  });

  // phone numbers
  if (contact.cell_phone) {
    contact.cell_phone = contact.cell_phone.replace(/[^0-9+]/g, '');
  }
  if (contact.home_phone) {
    contact.home_phone = contact.home_phone.replace(/[^0-9+]/g, '');
  }
  if (contact.work_phone) {
    contact.work_phone = contact.work_phone.replace(/[^0-9+]/g, '');
  }
  if (contact.user) {
    contact.extension = contact.user;
    contact.extension_name = contact.name;
  }
  if (contact.email && contact.email.trim()) {
    contact.email = contact.email.trim();
    contact.email = contact.email.toLowerCase();
    contact.email = contact.email.split(';');

    contact.gravatar = crypto.createHash('md5').update(contact.email[0]).digest('hex');
  }

  return contact;
};

import { Map } from 'immutable';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';

import pack from '../../../package.json';

const ns = pack.config.nsSocketURLs;
const ua = pack.config.uaSocketURLs;
const nsSocketURLs = [];
const uaSocketURLs = [];

/** ********************************************
 * Redux constants
 ******************************************** */

export const SET_CONFIG = 'core/config/SET_CONFIG';
export const SET_CONFIGS = 'core/config/SET_CONFIGS';

/** ********************************************
 * Reducer
 ******************************************** */

if (ns.length) {
  for (let i = 0; i < ns.length; i += 1) {
    nsSocketURLs.push(`wss://${ns[i].replace('*', global.location.hostname)}`);
  }
}

if (ua.length) {
  for (let i = 0; i < ua.length; i += 1) {
    uaSocketURLs.push(`wss://${ua[i].replace('*', global.location.hostname)}`);
  }
}

const configs = {
  appName: pack.name,
  apiURL: `https://${pack.config.apiURL.replace('*', global.location.hostname)}`,
  devicePostFix: pack.config.devicePostFix,
  hostname: global.location.hostname,
  nsSocketURLs,
  pathname: global.location.pathname,
  portalURL: `https://${pack.config.portalURL.replace('*', global.location.hostname)}`,
  protocol: global.location.protocol,
  supportedBrowsers: pack.config.supportedBrowsers,
  uaSocketURLs,
  userId: nsBrowser.getQuery('user'),
  version: pack.version,
};

const initialState = new Map(configs);

localStorage.setItem('portalURL', configs.portalURL);

export default (state = initialState, action) => {
  let configs;
  switch (action.type) {
    case SET_CONFIG:
      return state.set(action.payload.config_name, action.payload.config_value);
    case SET_CONFIGS:
      configs = state.toJS();
      for (let i = 0; i < action.payload.length; i += 1) {
        if (action.payload[i].config_value || (action.payload[i].config_name === 'PORTAL_WEB_PHONE_POSTFIX')) {
          if (action.payload[i].config_name === 'PORTAL_WEB_PHONE_POSTFIX') {
            configs.devicePostFix = action.payload[i].config_value;
          } else configs[action.payload[i].config_name] = action.payload[i].config_value;
        }
      }
      return new Map(configs);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @param config_name
 * @param config_value
 * @return {{type: string, payload: {config_name: *, config_value: *}}}
 */
export const setConfig = (config_name, config_value) => ({
  type: SET_CONFIG,
  payload: { config_name, config_value },
});

/**
 *
 * @param payload
 * @return {{type: string, payload: *}}
 */
export const setConfigs = (payload) => ({
  type: SET_CONFIGS,
  payload,
});

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @param name
 * @return {null}
 */
export const getConfig = (state, name) => state.configs.get(name) || null;

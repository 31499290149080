import { Map } from 'immutable';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import moment from 'moment';
import Autolinker from 'autolinker';
import { matchContact, getParticipantNames } from '../contacts';
import { getObject } from '../angular';

/** ********************************************
 * Redux constants
 ******************************************** */

export const LOADING = 'core/chat/LOADING';
export const MESSAGES = 'core/chat/MESSAGES';
export const MESSAGES_LIST = 'core/chat/MESSAGES_LIST';
export const SESSION = 'core/chat/SESSION';
export const SESSIONS = 'core/chat/SESSIONS';
export const UNREAD_COUNT = 'core/chat/UNREAD_COUNT';
export const MMS_CAPABLE_NUMBERS = 'core/chat/MMS_CAPABLE_NUMBERS';
export const GROUP_MMS_CAPABLE_NUMBERS = 'core/chat/GROUP_MMS_CAPABLE_NUMBERS';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  loading: true,
  messages: nsUtils.normalizeList('id', []),
  sessions: nsUtils.normalizeList('id', []),
  unreadCount: 0,
  mmsCapableNumbers: '',
  groupMmsCapableNumbers: '',
});

export default (state = initialState, action) => {
  let messages;
  let sessions;

  switch (action.type) {
    case LOADING:
      return state.set('loading', action.payload);
    case MESSAGES:
      messages = state.get('messages');
      if (!messages.entities[action.payload.id]) {
        messages = messages.toArray();
        messages.push(action.payload);
        return state.set('messages', nsUtils.normalizeList('id', messages));
      }
      messages.entities[action.payload.id] = action.payload;
      return state.set('messages', messages);

    case MESSAGES_LIST:
      return state.set('messages', nsUtils.normalizeList('id', action.payload));
    case SESSION:
      sessions = state.get('sessions');
      if (!sessions.entities[action.payload.id]) {
        sessions = sessions.toArray();
        sessions.push(action.payload);
        return state.set('sessions', nsUtils.normalizeList('id', sessions));
      }
      sessions.entities[action.payload.id] = action.payload;
      return state.set('sessions', sessions);

    case SESSIONS:
      return state.set('sessions', nsUtils.normalizeList('id', action.payload));
    case UNREAD_COUNT:
      return state.set('unreadCount', action.payload);
    case MMS_CAPABLE_NUMBERS:
      return state.set('mmsCapableNumbers', action.payload);
    case GROUP_MMS_CAPABLE_NUMBERS:
      return state.set('groupMmsCapableNumbers', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @param msg
 * @return {function(*, *)}
 */
export const addMessage = (msg) => (dispatch, getState) => {
  if (msg.type === 'chat-participant-remove'
    || msg.type === 'chat-participant-leave'
    || msg.type === 'chat-participant-add'
    || msg.type === 'chat-name-change'
    || msg.type === 'chat-leave'
    || msg.type === 'chat-video-invite'
  ) {
    msg.status_change = true;

    let uid;
    if (msg.text.split('@').length === 2) { // check if it's a uid
      uid = msg.text;
    } else if (msg.text.includes('<a href=')) {
      // work around for anchor element coming from somewhere
      const div = document.createElement('div');
      div.innerHTML = msg.text;
      uid = div.firstChild?.text;
    }

    console.log(msg.text);

    msg.term_contact = matchContact(uid);
    msg.from_contact = matchContact(msg.from_uid);
  }

  if (msg.type === 'chat-participant-remove-notification') {
    return;
  }

  // for newline insert showing correctly on the temp sent out message
  msg.text = msg.text.replace(/\<br\>/g, '\n');

  msg.text = linkifyText(msg.text);

  const messages = getState().chat.get('messages');
  let sessionMessages = messages.entities[msg.session_id];

  if (sessionMessages && sessionMessages.messages) {
    const todayTime = moment().format('YYYY-MM-DD HH:mm:ss');

    msg.timestamp = moment(todayTime).fromNow();
    msg.dateTime = moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss');

    msg.status = '';

    let last = (last = Object.keys(sessionMessages.messages))[last.length - 1];
    if (last) {
      if (last === msg.timestamp) {
        // add to same time key
        sessionMessages.messages[last].push(msg);
      } else {
        // create new key for new time when you have chat sesions already
        const newKey = msg.timestamp;
        sessionMessages.messages[newKey] = [msg];
      }
    } else {
      // empty no  chat messages
      const key = msg.timestamp;
      sessionMessages.messages = {
        [key]: [msg],
      };
    }
    if (typeof sessionMessages.messagesRaw === 'undefined') {
      sessionMessages.messagesRaw = [];
    }
    sessionMessages.messagesRaw.push(msg);
  } else {
    sessionMessages = {
      id: msg.session_id,
      init: true,
      loading: false,
      messages: [msg],
      messagesRaw: [msg],
    };
  }

  dispatch({
    type: MESSAGES,
    payload: sessionMessages,
  });
};

export const linkifyText = (textToAutoLink) => {
  const options = {
    sanitizeHtml: true,
  };
  const autolinker = new Autolinker();

  const linkedText = autolinker.link(textToAutoLink);
  return linkedText;
};

/**
 * Fetches session messages
 * @param sessionId
 * @returns {function(*, *)}
 */
export const fetchMessages = (sessionId) => (dispatch, getState) => {
  let sessionMessages = getState().chat.get('messages').entities[sessionId] || { init: false };

  if (sessionMessages.init) {
    return;
  }

  // update messages object
  sessionMessages.id = sessionId;
  sessionMessages.init = true;
  sessionMessages.loading = true;
  sessionMessages.messages = [];

  dispatch({
    type: MESSAGES,
    payload: sessionMessages,
  });

  const decodedToken = nsToken.getDecoded();

  nsApi.get({
    object: 'message',
    action: 'read',
    user: decodedToken.user,
    domain: decodedToken.domain,
    session_id: sessionId,
  }).then((res) => {
    sessionMessages.loading = false;
    sessionMessages.messages = res;

    // reverse order
    if (sessionMessages.messages && sessionMessages.messages.length) {
      sessionMessages.messages.reverse();
    }

    // filter out bad repeat messages here (go backwards in order to splice without breaking array)
    for (let i = sessionMessages.messages.length - 1; i >= 0; i -= 1) {
      if ((sessionMessages.messages[i].type === 'sms'
        || sessionMessages.messages[i].type === 'mms')
        && sessionMessages.messages[i].direction === 'orig'
        && (!sessionMessages.messages[i].term_uid.includes(`${decodedToken.user}@${decodedToken.domain}`))
      ) {
        sessionMessages.messages.splice(i, 1);
        continue;
      }
      // if the type is sms/mms and you didnt send the message, then remove
      if ((sessionMessages.messages[i].type === 'sms'
        || sessionMessages.messages[i].type === 'mms')
        && sessionMessages.messages[i].direction === 'term'
        && (!sessionMessages.messages[i].from_uid.includes(`${decodedToken.user}@${decodedToken.domain}`))
      ) {
        sessionMessages.messages.splice(i, 1);
        continue;
      }

      // remove unused props to save memory
      delete sessionMessages.messages[i].carrier;
      delete sessionMessages.messages[i].direction;
      delete sessionMessages.messages[i].from_ua;
      delete sessionMessages.messages[i].rx_hostname;
      delete sessionMessages.messages[i].session_id;

      // change status sending to null
      sessionMessages.messages[i].status = null;

      // get the to/from of a message if it is the certain type
      // also look for contact again using from_num if not found using from_uid
      sessionMessages.messages[i].from_contact = matchContact(sessionMessages.messages[i].from_uid);
      if (sessionMessages.messages[i].from_contact == null && typeof sessionMessages.messages[i].from_num !== 'undefined') {
        sessionMessages.messages[i].from_contact = matchContact(sessionMessages.messages[i].from_num);
      }
      if (sessionMessages.messages[i].type === 'chat-participant-remove'
                    || sessionMessages.messages[i].type === 'chat-participant-leave'
                    || sessionMessages.messages[i].type === 'chat-participant-add'
                    || sessionMessages.messages[i].type === 'chat-name-change'
                    || sessionMessages.messages[i].type === 'chat-leave'
                    || sessionMessages.messages[i].type === 'chat-video-invite') {
        sessionMessages.messages[i].status_change = true;
        sessionMessages.messages[i].term_contact = matchContact(sessionMessages.messages[i].text);
      } else {
        // replace <br> with /n to show new line breaks in chat
        sessionMessages.messages[i].text = sessionMessages.messages[i].text.replace(/\<br\>/g, '\n');
      }

      sessionMessages.messages[i].text = linkifyText(sessionMessages.messages[i].text);
    }

    const convertTo = (arr, key) => {
      const groups = {};
      for (let i = 0; i < arr.length; i += 1) {
        // normalize dates
        arr[i].dateTime = arr[i][key];
        arr[i][key] = moment.utc(arr[i][key]).fromNow();
        // group dates
        groups[arr[i][key]] = groups[arr[i][key]] || [];
        groups[arr[i][key]].push(arr[i]);
      }
      return groups;
    };

    const groupMessagesMeta = {};
    angular.copy(sessionMessages, groupMessagesMeta);
    groupMessagesMeta.messages = convertTo(groupMessagesMeta.messages, 'timestamp');
    sessionMessages = groupMessagesMeta;

    dispatch({
      type: MESSAGES,
      payload: sessionMessages,
    });
  });
};

export const addSession = (sessionToCreate) => (dispatch, getState) => {
  dispatch({
    type: SESSION,
    payload: sessionToCreate,
  });

  // concat session to the rest of the sessions too
  const currentSessions = getState().chat.get('sessions').toArray();

  // console.log('this is currentSessions: ', currentSessions);

  // currentSessions.push(sessionToCreate); // do not have this else you have repeat
  dispatch({
    type: SESSIONS,
    payload: currentSessions,
  });
  // let currentSessionsAfter = getState().chat.get('sessions').toArray();
  // console.log('after pushing currentSessions: ', currentSessionsAfter);
};

/**
 *
 * @param sessionId
 * @return {function(*)}
 */
export const fetchSession = (sessionId) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();

  nsApi.get({
    object: 'messagesession',
    action: 'read',
    user: decodedToken.user,
    domain: decodedToken.domain,
    session_id: sessionId,
  }).then((res) => {
    if (!res[0]) {
      return;
    }

    res = res[0];

    console.log('fetchSession', res);

    let searchStr = res.remote || '';
    const contact_arr = [];
    const temp_contact_arr = [];
    let names;
    let numPart = 0;
    let contact;

    if (searchStr != undefined && !searchStr.includes(',')) {
      // for single user/contact
      if (typeof res.remote !== 'undefined' && res.remote.indexOf('@') !== -1) {
        const uidArr = res.remote.split('@');
        searchStr = uidArr[0];
      }
      contact = matchContact(searchStr);
      contact_arr.push(contact);
      temp_contact_arr.push(contact);
    } else {
      // for group conversation
      const contact_uids = searchStr.split(',');
      for (let x = 0; x < contact_uids.length; x++) {
        const uidArr = contact_uids[x].split('@');
        searchStr = uidArr[0];
        searchStr = searchStr.replace(/['"\]\[]+/g, '');
        contact = matchContact(searchStr);
        // if contact is not found, make temp one for this group
        if (contact == undefined) {
          contact = new Object();
          // [WP-975]
          // if search string is 11 digits, then take off leading 1 for first_name to match portal
          if (searchStr.length > 10) {
            // remove leading 1 from phone number
            contact.first_name = searchStr.replace(/^1/, '');
            // console.log('replacing first 1 in searchstr2');
          }

          contact.name = searchStr;
          contact.cell_phone = searchStr;
        }
        contact_arr.push(contact);
        temp_contact_arr.push(contact);
      }
      names = getParticipantNames(contact_arr);
      numPart = contact_arr.length;
    }

    dispatch({
      type: SESSION,
      payload: {
        contact,
        contact_arr,
        temp_contact_arr, // this will always be the original contact_arr
        partNames: names,
        numPart,
        session_name: res.session_name,
        domain: res.domain,
        id: res.session_id,
        lastMessage: res.last_mesg,
        lastStatus: res.last_status,
        lastSender: res.last_sender,
        lastTimestamp: res.last_timestamp,
        muted: res.muted,
        remote: res.remote,
        smsani: res.smsani,
        startTimestamp: res.start_timestamp,
      },
    });

    console.log({
      type: SESSION,
      payload: {
        contact,
        contact_arr,
        temp_contact_arr, // this will always be the original contact_arr
        partNames: names,
        numPart,
        session_name: res.session_name,
        domain: res.domain,
        id: res.session_id,
        lastMessage: res.last_mesg,
        lastStatus: res.last_status,
        lastSender: res.last_sender,
        lastTimestamp: res.last_timestamp,
        muted: res.muted,
        remote: res.remote,
        smsani: res.smsani,
        startTimestamp: res.start_timestamp,
      },
    });

    dispatch(unreadCount());
  });
};

/**
 * Fetches last message for each contact
 * @returns {function(*, *)}
 */
export const fetchSessions = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });

  const decodedToken = nsToken.getDecoded();

  nsApi.get({
    object: 'messagesession',
    action: 'read',
    user: decodedToken.user,
    domain: decodedToken.domain,
  }).then((res) => {
    console.log('sessions', res);
    const sessions = [];
    if (res) {
      for (let i = 0; i < res.length; i += 1) {
        // filter out video chat sessions
        if (res[i].session_id && res[i].session_id.includes('meeting')) {
          continue;
        }
        let searchStr = res[i].remote || '';
        const contact_arr = [];
        const temp_contact_arr = [];
        var names;
        let numPart = 0;
        var contact;

        if (!searchStr.includes(',')) {
          // for single user/contact
          if (typeof res[i].remote !== 'undefined' && res[i].remote.indexOf('@') !== -1) {
            const uidArr = res[i].remote.split('@');
            searchStr = uidArr[0];
          }

          contact = matchContact(searchStr);
          contact_arr.push(contact);
          temp_contact_arr.push(contact);
        } else {
          // for group conversation
          const contact_uids = searchStr.split(',');
          for (let x = 0; x < contact_uids.length; x++) {
            const uidArr = contact_uids[x].split('@');
            searchStr = uidArr[0];
            searchStr = searchStr.replace(/['"\]\[]+/g, '');
            contact = matchContact(searchStr);
            // if contact is not found, make temp one for this conversation
            if (contact == undefined) {
              contact = new Object();
              // [WP-975]
              // if search string is 11 digits, then take off leading 1 for first_name to match portal
              if (searchStr.length > 10) {
                // remove leading 1 from phone number
                contact.first_name = searchStr.replace(/^1/, '');
                // console.log('replacing first 1 in searchstr');
              }
              contact.last_name = '';
              contact.name = searchStr;
              contact.cell_phone = searchStr;
            }
            contact_arr.push(contact);
            temp_contact_arr.push(contact);
          }
          names = getParticipantNames(contact_arr);
          numPart = contact_arr.length;
        }
        sessions.push({
          contact,
          contact_arr,
          temp_contact_arr, // this will always be the original contact_arr
          partNames: names,
          numPart,
          session_name: res.session_name,
          domain: res[i].domain,
          id: res[i].session_id,
          lastMessage: res[i].last_mesg,
          lastStatus: res[i].last_status,
          lastSender: res[i].last_sender,
          lastTimestamp: res[i].last_timestamp,
          muted: res[i].muted,
          remote: res[i].remote,
          smsani: res[i].smsani,
          startTimestamp: res[i].start_timestamp,
          session_name: res[i].session_name,
        });
      }
    }

    dispatch({
      type: SESSIONS,
      payload: sessions,
    });

    dispatch({
      type: LOADING,
      payload: false,
    });

    dispatch(unreadCount());
  });
};

/**
 *
 * @param sessionId
 * @return {function(*, *)}
 */
export const deleteSession = (sessionId) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();

  nsApi.post({
    object: 'messagesession',
    action: 'delete',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: sessionId,
  });

  // remove session from sessions array
  const sessions = getState().chat.get('sessions').toArray();
  const tmpSessions = [];
  for (let i = 0; i < sessions.length; i += 1) {
    if (sessions[i].id !== sessionId) {
      tmpSessions.push(sessions[i]);
    }
  }

  // remove session from sessions array
  const messages = getState().chat.get('messages').toArray();
  const tmpMessages = [];
  for (let i = 0; i < messages.length; i += 1) {
    if (messages[i].id !== sessionId) {
      tmpMessages.push(messages[i]);
    }
  }

  // console.log('this is tmpSessions in deleteSession: ', tmpSessions);

  dispatch({
    type: SESSIONS,
    payload: tmpSessions,
  });

  dispatch({
    type: MESSAGES_LIST,
    payload: tmpMessages,
  });

  dispatch(unreadCount());
};

/**
 *
 * @param sessionId
 * @param oldValue
 * @return {function(*, *)}
 */
export const toggleMute = (sessionId, oldValue) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();

  let value = 'yes';
  if (oldValue === 'no') {
    value = 'yes';
  } else if (oldValue === 'yes') {
    value = 'no';
  }

  nsApi.post({
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: sessionId,
    muted: value,
  }).then(() => {
    dispatch(fetchSession(sessionId));
  });
};

/**
 * leave session and send out the notification to the rest of the users
 * remove traces of the conversation from the webphone
 * @param sessionId
 * @param remote
 * @return N/A
 */
export const leaveSession = (sessionId, remote) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();

  // do not try when remote is null
  if (typeof remote === 'undefined' || remote === '') {
    return;
  }

  const leaveParams = {
    object: 'messagesession',
    action: 'leave',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: sessionId,
    send_update_message: 'yes',
    participants: remote,
  };

  nsApi.post(leaveParams);

  // remove session from sessions array
  const sessions = getState().chat.get('sessions').toArray();
  const tmpSessions = [];
  for (let i = 0; i < sessions.length; i += 1) {
    if (sessions[i].id !== sessionId) {
      tmpSessions.push(sessions[i]);
    }
  }

  // remove messages from messages array
  const messages = getState().chat.get('messages').toArray();
  const tmpMessages = [];
  for (let i = 0; i < messages.length; i += 1) {
    if (messages[i].id !== sessionId) {
      tmpMessages.push(messages[i]);
    }
  }

  dispatch({
    type: SESSIONS,
    payload: tmpSessions,
  });

  dispatch({
    type: MESSAGES_LIST,
    payload: tmpMessages,
  });

  dispatch(unreadCount());
};

/**
 *
 * @param payload
 * @return {{type: string, payload: *}}
 */
export const loading = (payload) => ({
  type: LOADING,
  payload,
});

/**
 *
 * @param state
 */
export const getSearchResultSet = (state) => state.contacts.get('searchResultsSet');

/**
 *
 * @param destination
 * @param fromNum
 * @param fromUID
 * @param message
 * @param sessionId
 * @param type
 * @param data
 * @param mime_type
 * @param size
 * @param file
 * @param trustedFile
 * @return {function(*, *)}
 */
export const sendMessage = ({
  destination,
  fromNum,
  fromUID,
  message,
  sessionId,
  type,
  data,
  mime_type,
  size,
  file,
  trustedFile,
}) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();

  destination = `${destination}`;
  if (destination.indexOf('@') !== -1) {
    destination = destination.split('@')[0];
  }

  if (destination.length === 10) {
    destination = `1${destination}`;
  }

  // replace br tags with proper new lines
  message = message.replace(/<br>/g, '\n');
  // will need to use upload id instead
  const payload = {
    object: 'message',
    action: 'create',
    domain: decodedToken.domain,
    user: decodedToken.user,
    destination,
    message,
    session_id: sessionId,
  };

  // set payload for media
  if (data != undefined) {
    payload.type = 'chat-media';
    payload.data = data;
    payload.mime_type = mime_type;
    payload.size = size;
  }

  if (type === 'chat-video-invite') {
    payload.type = type;
  }

  // checks if destination number length is less than 10
  // assumes extensions < 10 digits
  if (fromUID) {
    if (!payload.type || (payload.type !== 'chat-media' && payload.type !== 'chat-video-invite')) payload.type = 'chat';
    payload.from_uid = fromUID;
  } else {
    if (payload.type && payload.type === 'chat-media') payload.type = 'mms';
    else payload.type = 'sms';
    payload.from_num = fromNum;
  }

  const id = nsUtils.randomId();

  // might need to addMessage of a chat-media
  const dateTimeAPI = moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss');
  if (payload.type === 'chat-media' || payload.type === 'mms') {
    // add temp message to array
    dispatch(addMessage({
      id,
      type: payload.type,
      from_num: payload.from_num ? payload.from_num : null,
      from_uid: payload.from_uid ? payload.from_uid : null,
      text: message,
      status: 'sending',
      session_id: sessionId,
      // remote path will be the temp src/preview
      file,
      trustedFile,
      dateTimeAPI,
    }));
  } else {
    // add temp message to array
    dispatch(addMessage({
      id,
      type: payload.type,
      from_num: payload.from_num ? payload.from_num : null,
      from_uid: payload.from_uid ? payload.from_uid : null,
      text: message,
      status: 'sending',
      session_id: sessionId,
    }));
  }

  const params = {
    object: 'message',
    action: 'create',
    domain: decodedToken.domain,
    user: decodedToken.user,
    from_num: payload.from_num,
    type: payload.type,
    destination,
    message,
    session_id: sessionId,
  };

  // for WP-885, the id needs to be attached for non media messages to be matched so not added again
  // and timestamp needs to be added for same reason for media (id wouldnt work since id of message is made in
  // media allocation in API)
  if (payload.type !== 'chat-media' && payload.type !== 'mms') {
    params.id = id;
  } else {
    params.timestamp = dateTimeAPI;
  }

  // console.log('this is params sendMessage: ', params);
  // console.log('this is payload sendMessage: ', payload);

  // return;

  nsApi.post(params, payload).then((res) => {
    // console.log('actually posted');
    // update message
    const messages = getState().chat.get('messages');
    const sessionMessages = messages.entities[sessionId];

    for (let i = 0; i < sessionMessages.messages.length; i += 1) {
      if (sessionMessages.messages[i].id === id) {
        sessionMessages.messages[i] = res;
        break;
      }
    }

    // dispatch messages to trigger ui updates
    dispatch({
      type: MESSAGES,
      payload: sessionMessages,
    });

    dispatch(fetchSession(sessionId));
  }).catch((e) => {
    const messages = getState().chat.get('messages');
    const sessionMessages = messages.entities[sessionId];

    for (let i = 0; i < sessionMessages.messages.length; i += 1) {
      if (sessionMessages.messages[i].id === id) {
        sessionMessages.messages[i].status = 'failed';
        break;
      }
    }
  });
};

/**
 *
 * @param destination
 * @param fromNum
 * @param sessionId
 * @return {function(*, *)}
 * Push changes to the conversation session_name. AND send out a notification
 * to the rest of the participants
 */
export const changeSessionName = ({
  destination,
  session_name,
  sessionId,
}) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();

  destination = `${destination}`;
  if (destination.indexOf('@') !== -1) {
    destination = destination.split('@')[0];
  }

  if (destination.length === 10) {
    destination = `1${destination}`;
  }

  const params = {
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_name: encodeURIComponent(session_name),
    session_id: sessionId,
    send_update_message: 'yes',
  };

  const $mdDialog = getObject(getState(), '$mdDialog');

  nsApi.post(params).then(() => {
    $mdDialog.show($mdDialog.alert()
      .clickOutsideToClose(true)
      .textContent(
        'Conversation name has been changed.',
      )
      .ok('OK'));

    dispatch(fetchSession(sessionId));
  }).catch(() => {
    $mdDialog.show($mdDialog.alert()
      .clickOutsideToClose(true)
      .textContent(
        'An error occurred changing conversation name.',
      )
      .ok('OK'));
  });
};

/**
 *
 * @param destination
 * @param participantStr
 * @param sessionId
 * @return {function(*, *)}
 * Push changes to the conversation session_name. AND send out a notification
 * to the rest of the participants
 */
export const changeParticipants = ({
  participantStr,
  sessionId,
}) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  // append your own user to the participants
  const participants = `${participantStr}${decodedToken.user}@${decodedToken.domain}`;

  const params = {
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    participants,
    session_id: sessionId,
    send_update_message: 'yes',
  };

  nsApi.post(params).then(() => {
    dispatch(fetchSession(sessionId));
  }).catch(console.error);
};

/**
 *
 * @param sessionId
 * @return {function(*)}
 */
export const updateLastRead = (sessionId) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();

  const params = {
    object: 'messagesession',
    action: 'update',
    domain: decodedToken.domain,
    user: decodedToken.user,
    session_id: sessionId,
    last_status: 'read',
  };

  nsApi.post(params).then(() => {
    dispatch(fetchSession(sessionId));
  });

  dispatch(unreadCount());
};

/**
 *
 * @return {function(*, *)}
 */
export const updateSessionContacts = () => (dispatch, getState) => {
  const sessions = getState().chat.get('sessions').toArray();
  if (sessions) {
    for (let i = 0; i < sessions.length; i += 1) {
      const number = sessions[i].remote.split('@')[0];
      const contact = matchContact(number);

      if (contact) {
        sessions[i].contact = contact;
      } else {
        sessions[i].contact = null;
      }
    }

    dispatch({
      type: SESSIONS,
      payload: sessions,
    });
  }
};

/**
 *
 * @return {function(*, *)}
 */
export const unreadCount = () => (dispatch, getState) => {
  let unread = 0;
  const sessions = getState().chat.get('sessions').toArray();
  const decodedToken = nsToken.getDecoded();

  for (let i = 0; i < sessions.length; i += 1) {
    // Count unread messages
    const checkUnread = sessions[i].lastStatus;
    const checkSender = sessions[i].lastSender;
    if (checkUnread === 'unread' && checkSender !== decodedToken.sub) {
      unread += 1;
    }
  }

  navigator.setAppBadge(unread).catch((error) => {
    console.log('Set Badge error: ', error);
  });

  dispatch({
    type: UNREAD_COUNT,
    payload: unread,
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @return {boolean}
 */
export const getLoading = (state) => state.chat.get('loading');

/**
 *
 * @param state
 * @param sessionId
 * @returns {*}
 */
export const getMessages = (state, sessionId) => state.chat.get('messages').entities[sessionId] || null;

/**
 *
 * @param state
 * @param sessionId
 * @return {*|Function}
 */
export const getSession = (state, sessionId) => state.chat.get('sessions').entities[sessionId];

/**
 *
 * @param state
 * @return {[]} sessions
 */
export const getSessions = (state) => state.chat.get('sessions').toArray();

/**
 *
 * @param state
 * @return {[]} count
 */
export const getUnreadCount = (state) => state.chat.get('unreadCount');

/**
 *
 * @param state
 * @return {[]} count
 */
export const getMMSCapableNumbers = (state, number) => {
  let mmsCapable = false;
  const fetchedCapableNumbers = state.chat.get('mmsCapableNumbers');
  if (fetchedCapableNumbers.includes(number)) {
    mmsCapable = true;
  }

  return mmsCapable;
};

/**
 *
 * @return {function(*, *)}
 */
export const fetchMmsCapableNumbers = () => (dispatch) => {
  let mmsListString = '';
  let numbers;

  const decodedToken = nsToken.getDecoded();

  const params = {
    object: 'smsnumber',
    action: 'read',
    domain: decodedToken.domain,
    dest: decodedToken.user,
  };

  nsApi.post(params).then((res) => {
    numbers = res;

    for (let i = 0; i <= numbers.length; i += 1) {
      if (numbers[i] && numbers[i].mmsCapable) {
        mmsListString = mmsListString.concat(numbers[i].number);
      }
    }
    dispatch({
      type: MMS_CAPABLE_NUMBERS,
      payload: mmsListString,
    });
  }).catch(() => {

  });
};

/**
 *
 * @param state
 * @return {[]} count
 */
export const getGroupMMSCapableNumbers = (state, number) => {
  let groupMmsCapable = false;
  const fetchedCapableNumbers = state.chat.get('groupMmsCapableNumbers');
  if (fetchedCapableNumbers.includes(number)) {
    groupMmsCapable = true;
  }

  return groupMmsCapable;
};

/**
 * To get all the group mms capable numbers in a single string ex: 1647957212017247596347181436764931858224062618582834821
 * @param state
 * @return {[]} count
 */
export const getAllGroupMMSCapableNumbers = (state) => {
  const fetchedCapableNumbers = state.chat.get('groupMmsCapableNumbers');
  return fetchedCapableNumbers;
};

/**
 *
 * @return {function(*, *)}
 */
export const fetchGroupMmsCapableNumbers = () => (dispatch) => {
  let mmsListString = '';
  let numbers;

  const decodedToken = nsToken.getDecoded();

  const params = {
    object: 'smsnumber',
    action: 'read',
    domain: decodedToken.domain,
    dest: decodedToken.user,
  };

  nsApi.post(params).then((res) => {
    numbers = res;

    for (let i = 0; i <= numbers.length; i += 1) {
      if (numbers[i] && numbers[i].groupMMSCapable) {
        mmsListString = mmsListString.concat(numbers[i].number);
      }
    }
    dispatch({
      type: GROUP_MMS_CAPABLE_NUMBERS,
      payload: mmsListString,
    });
  }).catch(() => {

  });
};

/** ********************************************
 * Private Helper functions
 ******************************************** */

export default ($stateProvider) => {
  $stateProvider
    .state('generic-error', {
      url: `${location.pathname}error`,
      params: {
        title: null,
        body: null,
      },
      template: '<nets-generic-error></nets-generic-error>',
    });
};

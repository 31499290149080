import './styles.css';
import template from './template.html';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdColors',
  '$ngRedux',
];
function controller(
  $mdColors,
  $ngRedux,
) {
  let unsubscribe = null;
  this.background = $mdColors.getThemeColor('primary-600');

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      sip: state.ua.get('ua'),
    }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

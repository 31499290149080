import './styles.css';
import crypto from 'crypto-browserify';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import template from './template.html';
import * as chatActions from '../../core/chat';
import * as contactActions from '../../core/contacts';
import * as cardActions from '../../core/card-management';
import { getContact } from '../../core/contacts';
import { getExpandedId } from '../../core/card-management';
import { getConfig } from '../../core/configs';

function controller(
  $ngRedux,
  $scope,
  FileUploader,
  $sce,
) {
  let unsubscribe;
  let emojiInterval;

  /**
     *
     */
  this.$onInit = () => {
    // initialize mediaUploader for this card
    const mediaUploader = new FileUploader();
    mediaUploader.session_id = this.card.meta.sessionId;
    mediaUploader.onAfterAddingFile = (fileItem) => {
      this.sendMediaFile(fileItem, mediaUploader, this.card.meta);
    };
    this.mediaUploader = mediaUploader;
    this.messageInput = '';

    // set a unique random number for this input box
    const min = Math.ceil(1000);
    const max = Math.floor(100000);
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    unsubscribe = $ngRedux.connect((state) => ({
      cardMeta: this.card.meta,
      contact: getContact(state, this.card.meta.contactId),
      messagesMeta: chatActions.getMessages(state, this.card.meta.sessionId),
      screenSize: state.screenSize,
      session: chatActions.getSession(state, this.card.meta.sessionId),
      user: state.user,
      dateFormat: state.configs.get('portalLocalizationDateFormat'),
      timeFormat: state.configs.get('portalLocalizationTimeFormat'),
      mmsCapableNumber: chatActions.getMMSCapableNumbers(state, this.card.meta.sendMessageParams.fromNum),
      expandedId: getExpandedId(state),
      hostname: getConfig(state, 'hostname'),
      messageInput: undefined,
      uniqNum: randomNum,
    }))((state) => {
      // get sms number array
      const smsNumbers = [];
      if (state.user.sms) {
        for (let i = 0; i < state.user.sms.length; i += 1) {
          smsNumbers.push(state.user.sms[i].number);
        }
        /* smsNumbers = state.user.sms.map(num => {
                    if (num.number.indexOf('1') === 0) {
                        num.number = num.number.substring(1);
                    }
                    return num.number;
                }); */
      }

      // change height of chat scroll based on context
      let chatHeight;
      let loaderSize = 20;
      switch (this.context) {
        case 'compact':
          chatHeight = `${state.screenSize.height - 132}px`;
          loaderSize = 50;
          break;
        case 'card':
          chatHeight = '244px';
          break;
        case 'modal':
          chatHeight = '395px';
          break;
        default:
      }

      this.messageInput = undefined;
      this.cardMeta = state.cardMeta;
      this.chatHeight = chatHeight;
      this.contact = state.contact;
      this.loaderSize = loaderSize;
      this.screenSize = state.screenSize;
      this.session = state.session;
      this.smsNumbers = smsNumbers;
      this.user = state.user;
      this.groupMessagesMeta = state.messagesMeta;
      this.dateFormat = state.dateFormat;
      this.timeFormat = state.timeFormat;
      this.mmsCapableNumber = state.mmsCapableNumber;
      this.expanded = this.card.id === state.expandedId;
      this.hostname = state.hostname;
      this.allowSMSChange = this.cardMeta.sendMessageParams.fromNum
        && this.expanded
        && this.screenSize.display !== 'compact'
        && smsNumbers
        && (smsNumbers.length > 1);
      this.uniqNum = randomNum;
      this.initials = state.session?.contact_arr?.length || this.cardMeta.sendMessageParams.numPart;
    });

    // Fetch messages if there is a sessionId and the messages have not already been fetched.
    if (this.card.meta && this.card.meta.sessionId && !this.messagesMeta) {
      $ngRedux.dispatch(chatActions.fetchMessages(this.card.meta.sessionId));
    }

    // Fetch messages if there is a sessionId and the messages have not already been fetched.
    if (this.card.meta && this.card.meta.sendMessageParams.fromNum && !this.mmsCapableNumber) {
      $ngRedux.dispatch(chatActions.fetchMmsCapableNumbers(this.card.meta.sendMessageParams.fromNum));
    }

    // Set the input box in focus if the context is not a small card.
    // We only want expanded and full screen card inputs to get input focus
    if (this.context !== 'card') {
      let tries = 0;
      const interval = setInterval(() => {
        if (tries > 20) {
          clearInterval(interval);
        }

        const inputElem = document.getElementById(`chat-session-input_${this.card.id}_${this.context}`);
        if (inputElem) {
          const emojiId = inputElem.getAttribute('data-id');
          if (emojiId) {
            const emojiInputElem = document.getElementById(emojiId);
            if (emojiInputElem) {
              emojiInputElem.focus();
              clearInterval(interval);
            }
          }
        }
        tries += 1;
      }, 200);
    }

    // linkify messages
    for (let i = this.groupMessagesMeta.messages.length - 1; i >= 0; i -= 1) {
      this.groupMessagesMeta.messages[i].text = $sce.trustAsHtml(
        chatActions.linkifyText(this.groupMessagesMeta.messages[i].text),
      );
    }

    // onesignal emoji picker initialization
    // eslint-disable-next-line no-undef
    const emojiPickers = new EmojiPicker({
      emojiable_selector: '[data-emojiable=true]',
      assetsPath: '/webphone/assets/emoji/img/',
      popupButtonClasses: 'material-icons elipsis hover',
    });
    emojiPickers.discover();
    window.addEventListener('send', this.sendMessage);

    angular.element(() => {
      // eslint-disable-next-line no-undef
      new EmojiPicker().discover();
    });

    emojiInterval = setInterval(() => {
      angular.element(() => {
        // eslint-disable-next-line no-undef
        new EmojiPicker().discover();
      });
    }, 500);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
    if (emojiInterval) {
      clearInterval(emojiInterval);
    }
  };

  /**
     *
     * @param session
     * @return {boolean}
     */
  this.highlightHeader = (session) => {
    // test if the status is unread
    if (session && session.lastStatus === 'unread') {
      if (session.lastSender === this.user.uid) {
        return false;
      } if (this.user.sms) {
        // search for sms number match
        for (let i = 0; i < this.user.sms.length; i += 1) {
          if (session.lastSender === this.user.sms[i].number) {
            return false;
          }
        }
        return true;
      }
      return true;
    }
    return false;
  };

  /**
     *
     * @param $event
     * @return {boolean}
     */
  this.acceptVideoInvite = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.cardMeta.videoInvite = false;
    const url = `https://${this.hostname}/video/?id=${this.cardMeta.videoId}`;
    const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=1200,height=900';
    const openWin = window.open(url, '_blank', options);
    this.session.lastStatus = 'read';
  };

  /**
     *
     * @param $event
     * @return {boolean}
     */
  this.declineVideoInvite = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.cardMeta.videoInvite = false;
    this.session.lastStatus = 'read';
  };

  /**
     *
     * @param session
     * @return {boolean}
     */
  this.openImage = ($event, $remotePath, $file = null) => {
    // if the file is a preview image sent recently
    if ($file !== null && $file.includes('base64')) {
      const image = new Image();
      image.src = $file;
      const w = window.open($file);
      w.document.write(image.outerHTML);
    } else {
      const openWin = window.open($remotePath, '_blank');
    }
  };

  /**
     *
     */
  this.sendMessage = ($event) => {
    const text = $event.detail.text_value();
    this.messageInput = text;

    // check that conversation is correct one (else double sending)
    const textareaClass = $event.detail.emoji_textarea_class();
    if (!textareaClass.includes(this.uniqNum)) {
      return;
    }

    // added more cases for blank lines
    if (this.messageInput == undefined || this.messageInput == '' || !this.messageInput || !this.messageInput.trim() || this.messageInput == '<div><br></div>' || this.messageInput == '<br><div><br></div>' || this.messageInput == '<div><br></div><div><br></div>') {
      this.messageInput = '';
      if (document.getElementById(`chat-session-input_${this.card.id}_${this.context}`)) {
        document.getElementById(`chat-session-input_${this.card.id}_${this.context}`).value = '';
      }

      document.getElementById($event.detail.emoji_textarea_id()).innerHTML = '';

      return;
    }
    // remove last line break if needed
    if (this.messageInput.endsWith('<div><br></div>')) {
      const n = this.messageInput.lastIndexOf('<div><br></div>');
      this.messageInput = this.messageInput.slice(0, n) + this.messageInput.slice(n).replace('<div><br></div>', '');
    }

    const params = { message: this.messageInput, ...this.card.meta.sendMessageParams };

    $ngRedux.dispatch(chatActions.sendMessage(params));

    this.messageInput = '';
    if (document.getElementById(`chat-session-input_${this.card.id}_${this.context}`)) {
      document.getElementById(`chat-session-input_${this.card.id}_${this.context}`).value = '';
    }

    document.getElementById($event.detail.emoji_textarea_id()).innerHTML = '';
  };

  // selecting a different SMS number for the card
  // structured to first see if the session exists or not, and then if it does just open the known session card normally
  // if the session is not found, then need to make the session, but this is done in two ways, either as a group mms session or a 1:1 sms
  this.selectSMS = (index) => {
    $ngRedux.connect((state) => {
      // set from number in item with 11 number format
      const item = {};
      item.fromNum = this.user.sms[index].number;
      if (item.fromNum.length == 10) { // need from_num to be correct 12223334444 format
        item.fromNum = `1${item.fromNum}`;
      }

      // make sessionid numbers differently for 1:1 SMS vs group SMS
      // if the destination does not have a
      // then it is 1:1 and so should use generateChatId hash instead
      if (!this.card.meta.sendMessageParams.destination.includes(',')) {
        item.type = 'sms';
        item.destination = this.card.meta.sendMessageParams.destination;

        // use this only if the resulting session is 1:1 SMS
        const sessionidGenerate = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termNum: this.card.meta.sendMessageParams.destination,
          dialed: item.fromNum,
        });
        var hash = sessionidGenerate;

        // need to build the contact for 1:1 if the starting card didn't have contact
        if (this.contact === undefined || this.contact === null) {
          this.contact = new Object();
          this.contact.name = this.card.meta.sendMessageParams.destination;
          this.contact.cell_phone = this.card.meta.sendMessageParams.destination;
          this.contact.id = undefined;
        }
      } else {
        item.type = 'groupmms';
        let participantStr = '';

        const destArray = this.card.meta.sendMessageParams.destination.split(',');
        for (let i = 0; i < destArray.length; i += 1) {
          if (destArray[i].length == '10') {
            participantStr += `${destArray[i]},`;
          } else {
            participantStr += `${destArray[i]},`;
          }
        }
        item.destination = participantStr.replace(/,\s*$/, ''); // remove the last comma from the participants list

        // make the hash
        let toHash = `${item.destination},${item.fromNum}`;
        const toHashArr = toHash.split(',');
        toHashArr.sort();
        toHash = toHashArr.join('');
        var hash = crypto.createHash('md5').update(toHash).digest('hex');
      }

      // check if the session exists already or not based on sessionid
      const sessionsList = chatActions.getSessions(state);
      let found = false;
      let foundSession;
      for (let j = 0; j < sessionsList.length; j += 1) {
        if (sessionsList[j].id == hash) {
          found = true;
          foundSession = sessionsList[j];
          continue;
        }
      }

      // if session exists already, check if card is open (hasCard), use the sessionId that was found
      if (found) {
        item.session = foundSession;
        item.remote = item.fromNum;
        item.sessionId = foundSession.id;

        var sendMessageParams = {
          destination: item.destination,
          fromNum: item.fromNum,
          sessionId: foundSession.id,
        };
        var cardMeta = {
          sendMessageParams,
          sessionId: foundSession.id,
        };

        // if the original card had a contact, then set it here
        if (this.contact && this.contact != undefined && this.contact.id != undefined) {
          cardMeta.contactId = this.contact.id;
        }

        // open the card
        let cardId;
        // this logic makes sure duplicate cards are not made
        if (cardActions.hasCard(cardMeta)) {
          // get the card id and expand it
          cardId = cardActions.getCardId(cardMeta);
          $ngRedux.dispatch(cardActions.setExpanded(cardId));
        } else {
          // start fetching the messages for the session
          $ngRedux.dispatch(chatActions.fetchMessages(foundSession.id));

          // add the new card
          $ngRedux.dispatch(cardActions.newCard(cardMeta));

          // get the new card id and expand it
          cardId = cardActions.getCardId(cardMeta);
          $ngRedux.dispatch(cardActions.setExpanded(cardId));
        }

        // compact mode
        // if(this.screenSize.display === 'compact') {
        //     // remove the current state (new-chat) from stack
        //     // the nav stack will now have the previous state
        //     $ngRedux.dispatch(navActions.navStackBack());

        //     // get the last on the navStack to add to history
        //     const state = navActions.getLastOnStack($ngRedux.getState(), 'navStack');

        //     // get the card for state params
        //     const card = cardActions.getCard($ngRedux.getState(), cardId);
        //     $ngRedux.dispatch(
        //         navActions.navStackPush({
        //             name: 'wrapper.chat-session',
        //             params: {card},
        //             fromName: state.name,
        //             fromParams: state.params,
        //         })
        //     );
        //     $state.go('wrapper.chat-session', {card});
        // }
      } else {
        // else if not found, need to create the session
        if (item.type == 'groupmms') {
          // not found group mms
          // need to make contact arr of the destination numbers
          let contact;
          let searchStr;
          const contact_arr = [];
          const temp_contact_arr = [];
          const contact_uids = item.destination.split(',');
          for (let x = 0; x < contact_uids.length; x++) {
            const uidArr = contact_uids[x].split('@');
            searchStr = uidArr[0];
            searchStr = searchStr.replace(/['"\]\[]+/g, '');
            contact = contactActions.matchContact(searchStr);
            // if contact is not found, make temp one for this group
            if (contact == undefined) {
              contact = new Object();
              contact.name = searchStr;
              contact.cell_phone = searchStr;
              contact.id = undefined;
            }
            contact_arr.push(contact);
            temp_contact_arr.push(contact);
          }

          // update item so openning card works with all the participants list and such
          item.contact_arr = contact_arr;
          item.temp_contact_arr = temp_contact_arr;
          item.numPart = contact_arr.length;
          item.nameList = contactActions.getParticipantNames(contact_arr);

          // create new session
          const sessionToCreate = {
            contact: null,
            contact_arr: item.contact_arr,
            temp_contact_arr: item.temp_contact_arr, // this will always be the original contact_arr
            partNames: item.nameList,
            numPart: item.numPart,
            session_name: null,
            domain: this.user.domain,
            id: hash,
            lastMessage: null,
            lastStatus: 'read',
            lastSender: null,
            lastTimestamp: null,
            muted: false,
            remote: item.destination,
            smsani: item.fromNum,
            startTimestamp: null,
          };
          $ngRedux.dispatch(chatActions.addSession(sessionToCreate));

          // set card meta data
          var sendMessageParams = {
            destination: item.destination,
            fromNum: item.fromNum,
            sessionId: hash,
          };
          var cardMeta = {
            sendMessageParams,
            sessionId: hash,
          };

          // open the card
          var cardId;
          // this logic makes sure duplicate cards are not made
          if (cardActions.hasCard(cardMeta)) {
            // get the card id and expand it
            cardId = cardActions.getCardId(cardMeta);
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          } else {
            // start fetching the messages for the session
            $ngRedux.dispatch(chatActions.fetchMessages(hash));

            // add the new card
            $ngRedux.dispatch(cardActions.newCard(cardMeta));

            // get the new card id and expand it
            cardId = cardActions.getCardId(cardMeta);
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          }
        } else {
          // not found 1:1 sms session
          const contact_arr = [];
          contact_arr.push(this.contact);
          item.nameList = contactActions.getParticipantNames(contact_arr);

          // create the session
          const sessionToCreate = {
            contact: this.contact || null,
            contactId: this.contact.contact_id || null,
            contact_arr,
            temp_contact_arr: contact_arr, // this will always be the original contact_arr
            partNames: item.destination,
            nameList: item.nameList,
            numPart: '1',
            session_name: null,
            domain: this.user.domain,
            id: hash,
            lastMessage: null,
            lastStatus: 'read',
            lastSender: null,
            lastTimestamp: null,
            muted: false,
            remote: item.destination,
            smsani: item.fromNum,
            startTimestamp: null,
          };
          $ngRedux.dispatch(chatActions.addSession(sessionToCreate));

          // set card meta data
          var sendMessageParams = {
            destination: item.destination,
            fromNum: item.fromNum,
            sessionId: hash,
          };
          var cardMeta = {
            sendMessageParams,
            sessionId: hash,
          };

          // if the original card had a contact set, then set it again here for card meta
          if (this.contact) {
            cardMeta.contactId = this.contact.id;
          }

          // open the card
          var cardId;
          // this logic makes sure duplicate cards are not made
          if (cardActions.hasCard(cardMeta)) {
            // get the card id and expand it
            cardId = cardActions.getCardId(cardMeta);
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          } else {
            // start fetching the messages for the session
            $ngRedux.dispatch(chatActions.fetchMessages(hash));

            // add the new card
            $ngRedux.dispatch(cardActions.newCard(cardMeta));

            // get the new card id and expand it
            cardId = cardActions.getCardId(cardMeta);
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
          }
        }
      }
    });
  };

  /**
     *
     */
  this.sendMediaFile = (file, uploader, cardMeta) => {
    const f = file._file;
    const r = new FileReader();
    r.card = this.card;

    r.onload = function (e) {
      const data = e.target.result;
      // create base64 data for params (same as iOS)
      const base64data = window.btoa(data);

      const params = { message: this.messageInput, ...this.card.meta.sendMessageParams };

      params.type = 'chat-media';
      params.data = base64data;
      params.mime_type = f.type;
      params.size = f.size;
      params.message = 'media from wp';
      if (file._file.name) {
        params.message = file._file.name;
      }

      // need to format preview data this way, else wont show correct src
      const previewData = `data:${f.type};base64,${base64data}`;
      // put trust as src
      params.file = previewData;
      params.trustedFile = $sce.trustAsResourceUrl(previewData);

      $ngRedux.dispatch(chatActions.sendMessage(params));

      // clearqueue so that it doesnt show in DOM (plugin default)
      uploader.clearQueue();
    };

    if (f) {
      try {
        r.readAsBinaryString(f);
        r.readAsDataURL(f);
      } catch (e) {
        // console.log("readAsDataURL error: ",e);
      }
    }
  };
}

controller.$inject = [
  '$ngRedux',
  '$scope',
  'FileUploader',
  '$sce',
];

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@',
  },
};

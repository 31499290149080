import loglevel from 'loglevel';

import 'webrtc-adapter';
import 'animate.css/animate.min.css';
import 'angular-material/angular-material.css';
import 'normalize.css/normalize.css';
import 'v-accordion/dist/v-accordion.min.css';
import './assets/css/NSIcon-Material.css';
import './assets/css/webphone.css';
import './assets/images/wifioff.png';
import 'ng-sortable/dist/ng-sortable.min.css';
import 'angular';
import 'angular-animate/angular-animate.min';
import 'angular-sanitize/angular-sanitize.min';
import 'angular-aria/angular-aria.min';
import 'angular-translate/dist/angular-translate.min';
import 'angular-cookies/angular-cookies.min';
import 'angular-translate/dist/angular-translate-loader-static-files/angular-translate-loader-static-files.min';
import 'angular-translate/dist/angular-translate-storage-cookie/angular-translate-storage-cookie.min';
import 'angular-translate/dist/angular-translate-storage-local/angular-translate-storage-local.min';
import 'angular-material/angular-material.min';
import 'angular-messages/angular-messages.min';
import 'angular-resource/angular-resource.min';
import '@uirouter/angularjs';
import 'v-accordion/dist/v-accordion.min';
import 'ng-sortable/dist/ng-sortable.min';
import 'moment/moment';
import 'moment/locale/en-ie';
import 'moment/locale/es';
import 'moment/locale/fr-ca';
import 'angular-moment/angular-moment.min';
import 'angularjs-scroll-glue/src/scrollglue';
import 'angular-audio/app/angular.audio';
import 'angular-file-upload/dist/angular-file-upload.min';

import addressNotifyModule from './components/address-notify';
import appConfig from './app-config';
import appRun from './app-run';
import bugsnag from './bugsnag';
import agentCenterListModule from './components/agent-center-list';
import agentCenterDetailsModule from './components/agent-center-details';
import agentQueueDetailsModule from './components/agent-queue-details';
import agentStatusModule from './components/agent-status';
import agentStatusModuleCompact from './components/agent-status-compact';
import arDetailsModule from './components/answering-rule-details';
import arListModule from './components/answering-rule-list';
import browserSupportModule from './components/browser-support-msg';
import callDetailsModule from './components/call-details';
import callHistoryListModule from './components/call-history-list';
import callParkListModule from './components/call-park-list';
import callWrapupModule from './components/call-wrapup';
import cardsManagementModule from './components/cards-management';
import chatNewModule from './components/chat-new';
import chatSessionKabobModule from './components/chat-session-kabob';
import chatChangeNameModule from './components/chat-change-name';
import chatChangeParticipantsAutofillModule from './components/chat-change-participants-autofill';
import chatSmsModule from './components/chat-sms-list';
import chatSmsSessionModule from './components/chat-sms-session';
import hidConsentModule from './components/hid-consent-dialog';
import contactAddModule from './components/contact-add';
import contactDetailsModule from './components/contact-details';
import contactEditModule from './components/contact-edit';
import contactListModule from './components/contact-list';
import dialerModule from './components/dialer';
import directivesModule from './core/directives';
import extensionModule from './components/extension-nav';
import filtersModule from './core/filters';
import forgotPasswordModule from './components/forgot-password';
import forgotUsernameModule from './components/forgot-username';
import genericErrorModule from './components/generic-error';
import gravatarModule from './components/gravatar';
import greetingsAddModule from './components/greetings-add';
import greetingsListModule from './components/greetings-list';
import greetingsNewModule from './components/greetings-new';
import headerModule from './components/header';
import headerCompactModule from './components/header-compact';
import incomingCallsModule from './components/incoming-calls';
import initModule from './components/init';
import legacyResetModule from './components/legacy-reset';
import localizationModule from './components/localization';
import loaderModule from './components/loader';
import loginModule from './components/login';
import logoModule from './components/logo';
import logoutModule from './components/logout';
import mfaFormModule from './components/mfa-form';
import navModule from './components/nav';
import navCompactModule from './components/nav-compact';
import numPadModule from './components/num-pad';
import passwordResetModule from './components/password-reset';
import phoneModule from './components/phone';
import selectSubscriberModule from './components/select-subscriber';
import settingsModule from './components/settings';
import settingsCallfromModule from './components/settings-callfrom';
import settingsRingtonesModule from './components/settings-ringtones';
import settingsCallwaitingModule from './components/settings-callwaiting';
import sideDrawerModule from './components/side-drawer';
import sipModule from './components/sip';
import sipCompactModule from './components/sip-compact';
import speedDialFabModule from './components/speed-dial-fab';
import speedParkFabModule from './components/speed-park-fab';
import ssoEnrollModule from './components/sso-enroll';
import storeModule from './core/store';
import themePalettesModule from './core/theme-palettes';
import tutorialModule from './components/tutorial';
import ucLicenseModule from './components/uc-license-msg';
import userDetailsModule from './components/user-details';
import voicemailListModule from './components/voicemail-list';
import iframeModule from './components/iframe-holder';
import wrapperModule from './components/wrapper';

window.loglevel = loglevel;

angular.module('app', [
  'ngAnimate',
  'ngSanitize',
  'ngAria',
  'pascalprecht.translate',
  'ngMaterial',
  'ngMessages',
  'ngResource',
  'ui.router',
  'vAccordion',
  'as.sortable',
  'angularMoment',
  'luegg.directives',
  'ngAudio',
  'ngCookies',
  'angularFileUpload',
  addressNotifyModule.name,
  agentCenterListModule.name,
  agentCenterDetailsModule.name,
  agentQueueDetailsModule.name,
  agentStatusModule.name,
  agentStatusModuleCompact.name,
  arDetailsModule.name,
  arListModule.name,
  browserSupportModule.name,
  bugsnag.name,
  callDetailsModule.name,
  callHistoryListModule.name,
  callParkListModule.name,
  callWrapupModule.name,
  cardsManagementModule.name,
  chatNewModule.name,
  chatSessionKabobModule.name,
  chatChangeNameModule.name,
  chatChangeParticipantsAutofillModule.name,
  chatSmsModule.name,
  hidConsentModule.name,
  chatSmsSessionModule.name,
  contactDetailsModule.name,
  contactAddModule.name,
  contactEditModule.name,
  contactListModule.name,
  dialerModule.name,
  directivesModule.name,
  extensionModule.name,
  filtersModule.name,
  forgotPasswordModule.name,
  forgotUsernameModule.name,
  genericErrorModule.name,
  gravatarModule.name,
  greetingsAddModule.name,
  greetingsListModule.name,
  greetingsNewModule.name,
  headerModule.name,
  headerCompactModule.name,
  incomingCallsModule.name,
  initModule.name,
  legacyResetModule.name,
  localizationModule.name,
  loaderModule.name,
  loginModule.name,
  logoModule.name,
  logoutModule.name,
  mfaFormModule.name,
  navModule.name,
  navCompactModule.name,
  numPadModule.name,
  passwordResetModule.name,
  phoneModule.name,
  selectSubscriberModule.name,
  settingsModule.name,
  settingsCallfromModule.name,
  settingsRingtonesModule.name,
  settingsCallwaitingModule.name,
  sideDrawerModule.name,
  sipModule.name,
  sipCompactModule.name,
  speedDialFabModule.name,
  speedParkFabModule.name,
  ssoEnrollModule.name,
  storeModule.name,
  themePalettesModule.name,
  tutorialModule.name,
  ucLicenseModule.name,
  userDetailsModule.name,
  voicemailListModule.name,
  iframeModule.name,
  wrapperModule.name,
])
  .config([
    '$locationProvider',
    '$mdThemingProvider',
    '$urlRouterProvider',
    '$translateProvider',
    appConfig,
  ])
  .run([
    '$location',
    '$ngRedux',
    '$mdDialog',
    '$rootScope',
    '$state',
    '$transitions',
    appRun,
  ]);

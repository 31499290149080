export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.contact-add', {
      url: 'add-contact',
      params: {
        type: null, // call detail number type (work, cell, home)
        number: null, // call detail phone number
        workflow: null, // call-detail-new-contact
      },
      template: '<nets-contact-add></nets-contact-add>',
    });
};

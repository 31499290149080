/* eslint-disable no-console */
import './styles.css';
import template from './template.html';
import { getLoading, fetchAgentQueues } from '../../core/agent-center';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
];

function controller(
  $ngRedux,
) {
  let unsubscribe;

  this.$onInit = () => {
    $ngRedux.dispatch(fetchAgentQueues());
    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
      loaded: !getLoading(state),
      dateFormat: state.configs.get('portalLocalizationDateFormat'),
      timeFormat: state.configs.get('portalLocalizationTimeFormat'),
    }))((state) => {
      this.loaded = state.loaded;
      this.screenSize = state.screenSize;
      this.dateFormat = state.dateFormat;
      this.timeFormat = state.timeFormat;
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

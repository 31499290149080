import { Map } from 'immutable';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import * as cardActions from '../card-management';
import * as chatActions from '../chat';
import * as deviceActions from '../devices';
import { getObject } from '../angular';
import isPlainObject from '../utils/isPlainObject';
import { matchContact } from '../contacts';
import * as navActions from '../state-history';
import * as sessions from '../sessions';
import * as ua from '../ua';

/** ********************************************
 * Redux constants
 ******************************************** */

export const EXTENSION_APP = 'core/extension/EXTENSION_APP';
export const EXTENSION_MENU = 'core/extension/EXTENSION_MENU';
export const EXTENSION_ID = 'core/extension/EXTENSION_ID';
export const PORT = 'core/extension/PORT';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  extensionApp: null,
  id: null,
  menu: 'MENU_EXPANDED',
  port: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case EXTENSION_APP:
      return state.set('extensionApp', action.payload);
    case EXTENSION_MENU:
      return state.set('menu', action.payload);
    case EXTENSION_ID:
      return state.set('id', action.payload);
    case PORT:
      return state.set('port', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

export const messaging = () => (dispatch, getState) => {
  const backgroundPort = getState().extension.get('port');

  backgroundPort.onMessage.addListener((msg) => {
    if (msg && isPlainObject(msg) && msg.method) {
      switch (msg.method) {
        case 'APP':
          dispatch(setApp(msg.response));
          break;
        case 'CALL': {
          const state = getState();
          if (msg.number
                            && (Number(sessions.activeCount(state)) < Number(state.configs.get('maxActiveCalls')))
          ) {
            const callFromDevice = deviceActions.selectCallFrom(getState());

            if (!callFromDevice) {
              dispatch(ua.sendInvite(msg.number));
            } else {
              const $mdToast = getObject(getState(), '$mdToast');
              const $translate = getObject(getState(), '$translate');

              $mdToast.show(
                $mdToast.simple()
                  .textContent(`${$translate.instant('CALLING_FROM')} ${callFromDevice.display}`)
                  .position('bottom left')
                  .hideDelay(1500),
              );

              getState().dispatch(deviceActions.callFrom(this.call.number));
            }
          }
          break;
        }
        case 'SMS': {
          const { user } = getState();
          if (user && user.sms && msg.number) {
            let selectedSMS = null;
            for (let i = 0; i < user.sms.length; i += 1) {
              if (user.sms[i].default) {
                selectedSMS = i;
                break;
              }
            }

            if (selectedSMS !== null) {
              const contact = matchContact(msg.number);
              let sessionId;
              let sendMessageParams;
              if (contact) {
                sessionId = nsUtils.generateChatId({
                  domain: user.domain,
                  fromUID: user.uid,
                  termNum: msg.number,
                  dialed: user.sms[selectedSMS].number,
                });
                sendMessageParams = {
                  destination: msg.number,
                  fromNum: user.sms[selectedSMS].number,
                  sessionId,
                };
              } else {
                // todo if no contact and 5 digit string of numbers allow without valid phone check
                // todo the number is not 5 do a valid phone check
                sessionId = nsUtils.generateChatId({
                  domain: user.domain,
                  fromUID: user.uid,
                  termNum: msg.number,
                  dialed: user.sms[selectedSMS].number,
                });
                sendMessageParams = {
                  destination: msg.number,
                  fromNum: user.sms[selectedSMS].number,
                  sessionId,
                };
              }

              // build card meta
              const cardMeta = {
                sendMessageParams,
                sessionId,
              };

              // add contact id if contact is set
              if (contact) {
                cardMeta.contactId = contact.id;
              }

              let cardId;
              // this logic makes sure duplicate cards are not made
              if (cardActions.hasCard(cardMeta)) {
                // get the card id and expand it
                cardId = cardActions.getCardId(cardMeta);
                dispatch(cardActions.setExpanded(cardId));
              } else {
                // start fetching the messages for the session
                dispatch(chatActions.fetchMessages(sessionId));

                // add the new card
                dispatch(cardActions.newCard(cardMeta));

                // get the new card id and expand it
                cardId = cardActions.getCardId(cardMeta);
                dispatch(cardActions.setExpanded(cardId));
              }

              const state = navActions.getLastOnStack(getState(), 'navStack');

              // get the card for state params
              const card = cardActions.getCard(getState(), cardId);
              dispatch(
                navActions.navStackPush({
                  name: 'wrapper.chat-session',
                  params: { card },
                  fromName: state.name,
                  fromParams: state.params,
                }),
              );

              const $state = getObject(getState(), '$state');
              $state.go('wrapper.chat-session', { card });
            }
          }
          break;
        }
        default:
      }
    }
  });

  // get which app the extension is in
  backgroundPort.postMessage({ method: 'APP' });
};

export const expandMenu = () => (dispatch, getState) => {
  const backgroundPort = getState().extension.get('port');
  if (backgroundPort) {
    backgroundPort.postMessage({ method: 'MENU_EXPAND' });
    dispatch({ type: EXTENSION_MENU, payload: 'MENU_EXPANDED' });
  }
};

export const collapseMenu = () => (dispatch, getState) => {
  const backgroundPort = getState().extension.get('port');
  if (backgroundPort) {
    backgroundPort.postMessage({ method: 'MENU_COLLAPSE' });
    dispatch({ type: EXTENSION_MENU, payload: 'MENU_COLLAPSED' });
  }
};

export const setSMS = () => (dispatch, getState) => {
  const backgroundPort = getState().extension.get('port');
  if (backgroundPort) {
    const portalChatSMS = getState().configs.get('portalChatSMS') === 'yes';
    const enabled = portalChatSMS && getState().user && getState().user.sms && getState().user.sms.length;
    backgroundPort.postMessage({ method: 'USER_SMS', value: !!enabled });
  }
};

export const setDeviceRegistered = (registered) => (dispatch, getState) => {
  const backgroundPort = getState().extension.get('port');
  if (backgroundPort) {
    backgroundPort.postMessage({ method: 'DEVICE_REGISTERED', value: registered });
  }
};

export const setApp = (app) => ({
  type: EXTENSION_APP,
  payload: app,
});

export const setBackgroundPort = (port) => ({
  type: PORT,
  payload: port,
});

export const setExtensionId = (id) => ({
  type: EXTENSION_ID,
  payload: id,
});

export const selectExtensionApp = (state) => state.extension.get('extensionApp');

export const selectExtensionId = (state) => state.extension.get('id');

export const selectExtensionMenu = (state) => state.extension.get('menu');

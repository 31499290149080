import './styles.css';
import template from './template.html';
import * as uaActions from '../../core/ua';
import { getQueueName } from '../../core/agent-center';
import { getParkName } from '../../core/call-park';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];

function controller(
  $ngRedux,
) {
  let containerElem;
  let isShowing = false;
  let unsubscribe;
  let screenPopUrl;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      incomingCalls: state.ua.get('incomingCalls'),
      screenPopUrl: state.configs.get('PORTAL_AGENT_SCREEN_POP_URL'),
    }))((state) => {
      this.incomingCalls = state.incomingCalls;
      this.screenPopUrl = state.screenPopUrl;

      // only show after the socket update
      let show = false;
      this.viaqueue = null;
      this.viapark = null;
      this.vianormal = true;
      const d = new Date();
      if (this.incomingCalls.length) {
        for (let i = 0; i < this.incomingCalls.length; i += 1) {
          if (this.incomingCalls[i].display) {
            show = true;
            if (this.incomingCalls[i].getHeader('P-Served-Application')
                            && this.incomingCalls[i].getHeader('P-Served-Application') === 'CallQueue') {

              const queue = this.incomingCalls[i].getHeader('P-Served-Queue') || this.incomingCalls[i].getHeader('P-Served-User');
              this.viaqueue = getQueueName($ngRedux.getState(), queue.split('@')[0]);
              this.viapark = getParkName($ngRedux.getState(), queue.split('@')[0]);
              this.vianormal = false;
            }
            break;
          } else if (!this.incomingCalls[i].initTime) {
            this.incomingCalls[i].initTime = d.getTime();
          } else if (d.getTime() - this.incomingCalls[i].initTime > 1000) {
            show = true;
          }
        }
      }

      if (show && !isShowing) {
        this.show = show;
        isShowing = true;
        setTimeout(() => {
          containerElem = angular.element('.ic-container');
          containerElem.css({ opacity: 1 });
        }, 0);
      } else if (!show && isShowing) {
        isShowing = false;
        containerElem.css({ opacity: 0 });
        setTimeout(() => {
          this.show = show;
        }, 0);
      }
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param call
     */
  this.answer = (call) => {
    $ngRedux.dispatch(uaActions.acceptInvite(call));
    if (this.screenPopUrl && call.number.length >= 6) {
      const url = this.screenPopUrl + call.number;
      const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=800,height=800';
      window.open(url, '_blank', options);
    }
  };

  /**
     *
     * @param call
     */
  this.ignore = (call) => {
    call.ignore();
    removeFromIC(call.id);
  };

  /**
     *
     * @param call
     */
  this.reject = (call) => {
    call.reject();
    removeFromIC(call.id);
  };

  /**
     *
     * @param id
     */
  function removeFromIC(id) {
    $ngRedux.dispatch(uaActions.removeIc(id));
  }
}

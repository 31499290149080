import _ from 'lodash';
import Clipboard from 'clipboard';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import './styles.css';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import * as chatActions from '../../core/chat';
import * as contactActions from '../../core/contacts';
import * as deviceActions from '../../core/devices';
import * as navActions from '../../core/state-history';
import * as sessionActions from '../../core/sessions';
import { transferQueued } from '../../core/agent-center';
import * as uaActions from '../../core/ua';
import { getConfig } from '../../core/configs';

function controller(
  $mdDialog,
  $mdToast,
  $ngRedux,
  $state,
  $translate,
) {
  const self = this;
  let clipboard;
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    // because this component is used by routes
    // and a modal it needs to handle props and params
    // normalize bindings and state params
    this.workflow = this.workflow || $state.params.workflow;
    this.queueKey = this.queueKey || $state.params.queueKey || null;

    if (this.workflow) {
      this.inCallClass = 'in-call';
    }

    unsubscribe = $ngRedux.connect((state) => {
      const { user } = state;

      if (user.sms) {
        for (let i = 0; i < user.sms.length; i += 1) {
          if (user.sms[i].default) {
            self.selectedSMS = i;
          }
        }
      }

      return {
        allowAssisted: state.configs.get('allowAssistedTransfer') === 'yes' && sessionActions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-679]
        allowCall: sessionActions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-677]
        allowChat: state.configs.get('portalChatSMS'),
        allowVideo: state.configs.get('allowVideo'),
        contact: contactActions.getContact(state, this.contactId || $state.params.contactID),
        lastOnStack: navActions.getLastOnStack(state, 'navStack'),
        screenSize: state.screenSize,
        ua: state.ua.get('ua'),
        user,
        hostname: getConfig(state, 'hostname'),
      };
    })(this);

    const countryCode = $ngRedux.getState().configs.get('countryCode');

    clipboard = new Clipboard('#contact-copy', {
      text() {
        let str = '';

        if (self.contact.user) {
          str += `User: ${self.contact.user}\r`;
        }
        if (self.contact.name) {
          str += `Name: ${self.contact.name}\r`;
        }
        if (self.contact.extension) {
          str += `Extension: ${self.contact.extension}\r`;
        }
        if (self.contact.work_phone) {
          str += `Work: ${nsUtils.formatPhoneNumber(self.contact.work_phone, countryCode)}\r`;
        }
        if (self.contact.cell_phone) {
          str += `Mobile: ${nsUtils.formatPhoneNumber(self.contact.cell_phone, countryCode)}\r`;
        }
        if (self.contact.home_phone) {
          str += `Home: ${nsUtils.formatPhoneNumber(self.contact.home_phone, countryCode)}\r`;
        }
        if (self.contact.email) {
          str += `Email: ${self.contact.email.join(', ')}\r`;
        }
        if (self.contact.tags) {
          str += `Tags: ${self.contact.tags}\r`;
        }
        if (self.contact.group) {
          str += `Group: ${self.contact.group}\r`;
        }

        str += `Domain: ${self.contact.domain}`;

        return str;
      },
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    clipboard.destroy();
    unsubscribe();
  };

  /**
     *
     */
  this.assistedTransfer = (number) => {
    // get asistedTarget
    const activeCall = sessionActions.getActiveCall($ngRedux.getState());

    // call assisted number
    $ngRedux.dispatch(uaActions.sendInvite(number, false));

    // fullscreen use callback
    if (this.screenSize.display === 'fullscreen') {
      this.onAssisted({ transferId: activeCall.id });
    } else {
      $ngRedux.dispatch(navActions.clearToBase('callStack'));
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');

      // add assistedTarget param
      lastOnStack.params = lastOnStack.params || {};
      lastOnStack.params.transferId = activeCall.id;

      $state.go(lastOnStack.name, lastOnStack.params);
    }
  };

  /**
     *
     */
  this.blindTransfer = (number) => {
    const activeCall = sessionActions.getActiveCall($ngRedux.getState());
    activeCall.transfer(number, true);

    if (this.context === 'modal') {
      this.onPhone();
    } else {
      $ngRedux.dispatch(navActions.clearToBase('callStack'));
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
      $state.go(lastOnStack.name, lastOnStack.params);
    }
    $mdToast.show(
      $mdToast
        .simple()
        .textContent($translate.instant('TRANSFER_COMPLETED'))
        .position('bottom left'),
    );
  };

  /**
     *
     */
  this.queueTransfer = (number) => {
    $ngRedux.dispatch(transferQueued(this.queueKey, number));
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
    if (lastOnStack && lastOnStack.fromParams && lastOnStack.fromParams.queueKey)
    $ngRedux.dispatch(transferQueued(lastOnStack.fromParams.queueKey, "sip:"+number+"@"+lastOnStack.fromParams.domain));

    if (this.context === 'modal') {
      this.onQueue();
    } else {
      $ngRedux.dispatch(navActions.clearToBase('callStack'));
      if (lastOnStack && lastOnStack.fromName)
        $state.go(lastOnStack.fromName, lastOnStack.fromParams);
    }
    $mdToast.show(
      $mdToast
        .simple()
        .textContent($translate.instant('TRANSFER_COMPLETED'))
        .position('bottom left'),
    );
  };

  /**
     *
     * @param contactID
     */
  this.startVideo = (type = 'start', screenshare = false) => {
    // if start send a message
    if (type === 'start') {
      // create a meeting with getId=yes
      const decodedToken = nsToken.getDecoded();
      nsApi.post({
        object: 'meeting',
        action: 'create',
        user: decodedToken.user,
        domain: decodedToken.domain,
        getId: 'yes',
      }).then((res) => {
        if (typeof res.id === 'undefined') {
          console.log('ERROR CREATING MEETING');
          return;
        }
        const sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termUID: this.contact.uid,
        });
        const sendMessageParams = {
          destination: this.contact.user,
          fromUID: this.user.uid,
          sessionId,
        };
        // take that return to send a message with that id
        const params = { message: res.id.toString(), ...sendMessageParams };
        params.type = 'chat-video-invite';

        $ngRedux.dispatch(chatActions.sendMessage(params));

        // open the window /start&id=___&users=
        let url = `https://${this.hostname}/video/${type}?id=${res.id}`;

        const ext = this.contact.user;

        if (ext) {
          url += `?users=${ext}`;
          if (screenshare) url += '&s=1&v=0';
        }

        const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=1200,height=900';
        window.open(url, '_blank', options);
      });
      // I would need the video_id from here, so that i can send it through the message
    } else if (type === 'schedule') {
      // schedule logic, just open a window for scheduling,
      // separate because there is no message made
      let url = `https://${this.hostname}/video/${type}`;
      const ext = this.contact.user;
      if (ext) {
        url += `?users=${ext}`;
      }
      const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=1200,height=900';
      window.open(url, '_blank', options);
    }
  };

  /**
     *
     * @param smsIndex
     * @param type
     */
  this.chat = (smsIndex, type) => {
    if (type !== 'extension' && !this.user.sms) {
      return;
    }

    // generate session id and message params
    let sendMessageParams;
    let sessionId;
    switch (type) {
      case 'extension':
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termUID: this.contact.uid,
        });
        sendMessageParams = {
          destination: this.contact.user,
          fromUID: this.user.uid,
          sessionId,
        };
        break;
      case 'cell':
      case 'home':
      case 'work':
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termNum: this.contact[`${type}_phone`],
          dialed: this.user.sms[smsIndex].number,
        });
        sendMessageParams = {
          destination: this.contact[`${type}_phone`],
          fromNum: this.user.sms[smsIndex].number,
          sessionId,
        };
        break;
      default:
    }

    // build card meta
    const cardMeta = {
      sendMessageParams,
      sessionId,
    };

    // add contact id if contact is set
    if (this.contact) {
      cardMeta.contactId = this.contact.id;
    }

    let cardId = cardActions.getCardId(cardMeta);
    if (!cardId) {
      $ngRedux.dispatch(chatActions.fetchMessages(sessionId));
      $ngRedux.dispatch(cardActions.newCard(cardMeta));
      cardId = cardActions.getCardId(cardMeta);
    }

    $ngRedux.dispatch(cardActions.setExpanded(cardId));

    if (this.screenSize.display === 'compact') {
      const state = navActions.getCurrentState($ngRedux.getState());
      const card = cardActions.getCard($ngRedux.getState(), cardId);
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.chat-session',
          params: { card },
          fromName: state.name,
          fromParams: state.params,
        }),
      );
      $state.go('wrapper.chat-session', { card });
    }
  };

  /**
     *
     * @param number
     */
  this.call = (number) => {
    if (!this.allowCall) {
      return;
    }

    const callFromDevice = deviceActions.selectCallFrom($ngRedux.getState());

    if (this.workflow) {
      $ngRedux.dispatch(uaActions.sendInvite(number, false));
      if (this.context === 'modal') {
        this.onPhone();
      } else {
        $ngRedux.dispatch(navActions.clearToBase('callStack'));
        const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
        $state.go(lastOnStack.name, lastOnStack.params);
      }
    } else if (!callFromDevice) {
      $ngRedux.dispatch(uaActions.sendInvite(number));
    } else {
      $mdToast.show(
        $mdToast.simple()
          .textContent(`${$translate.instant('CALLING_FROM')} ${callFromDevice.display}`)
          .position('bottom left')
          .hideDelay(1500),
      );

      $ngRedux.dispatch(deviceActions.callFrom(number));
    }
  };

  /**
     *
     */
  this.deleteContact = () => {
    $mdDialog.show({
      template: `${'<md-dialog aria-label="Delete Contact" class="delete-modal">'
                        + '<md-dialog-content style="padding: 20px;">'
                        + '<p>'}${$translate.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE')}<span style="font-size: 18px;font-weight: 500;"> ${this.contact.name}</span>` + '?</p>'
                        + '</md-dialog-content>'
                        + '<md-dialog-actions layout="row">'
                            + `<md-button ng-click="$ctrl.cancel()" class="md-primary">${
                              $translate.instant('CANCEL')
                            } </md-button>`
                            + `<md-button ng-click="$ctrl.delete()" class="md-primary">${
                              $translate.instant('DELETE')
                            } </md-button>`
                        + '</md-dialog-actions>'
                    + '</md-dialog>',
      controllerAs: '$ctrl',
      controller: [function () {
        this.cancel = () => {
          $mdDialog.hide();
        };
        this.delete = () => {
          // show confirmation toast
          $mdToast.show(
            $mdToast
              .simple()
              .textContent($translate.instant('CONTACT_REMOVED!'))
              .position('bottom left')
              .hideDelay(1500),
          );
          // delete contact
          $ngRedux.dispatch(contactActions.deleteContact(self.contact.id));
          // close modal
          $mdDialog.hide();
          // transition to contacts
          self.navBack();
        };
      }],
    });
  };

  /**
     *
     */
  this.editContact = () => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-edit',
        params: { contactID: this.contact.id },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-edit', { contactID: this.contact.id });
  };

  /**
     *
     */
  this.navBack = () => {
    let lastOnStack;

    switch (this.workflow) {
      case 'in-call':
      case 'in-call-transfer':
      case 'queue-supr-transfer':
        this.onContactList();
        break;
      default:
        $ngRedux.dispatch(navActions.navStackBack());
        lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
        $state.go(lastOnStack.name, lastOnStack.params);
    }
  };

  /**
     *
     */
  this.toggleFav = () => {
    let tags;
    if (!this.contact.tags || this.contact.tags.indexOf('fav') === -1) {
      if (!this.contact.tags) {
        this.contact.tags = 'fav';
      } else {
        tags = this.contact.tags.split(',');
        tags.push('fav');
        tags = tags.join();
        this.contact.tags = tags;
      }
      $ngRedux.dispatch(contactActions.updateContact(this.contact));
      $mdToast.show(
        $mdToast
          .simple()
          .textContent($translate.instant('FAVORITE_ADDED'))
          .position('bottom left')
          .hideDelay(1500),
      );
    } else {
      tags = this.contact.tags.split(',');
      tags = _.reject(tags, (tag) => tag === 'fav');
      tags = tags.join();
      this.contact.tags = tags;
      $ngRedux.dispatch(contactActions.updateContact(this.contact));
      $mdToast.show(
        $mdToast
          .simple()
          .textContent($translate.instant('FAVORITE_REMOVED'))
          .position('bottom left')
          .hideDelay(1500),
      );
    }
  };
}

controller.$inject = [
  '$mdDialog',
  '$mdToast',
  '$ngRedux',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
  bindings: {
    contactId: '@', // target contact's id
    queueKey: '@',
    context: '@', // modal
    onAssisted: '&', // call back for phone modal, navigates back to the phone with the assisted id
    onContactList: '&', // call back for phone modal, navigates back to contacts list
    onPhone: '&', // call back for phone modal, navigates back to the phone
    onQueue: '&', // call back for phone modal, navigates back to the phone
    workflow: '@', // in-call, in-call-transfer
  },
};

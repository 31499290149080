import { Map } from 'immutable';

/** ********************************************
 * Redux constants
 ******************************************** */

export const SHOW_HID_CONSENT_DIALOG = 'core/hid-consent/SHOW_HID_CONSENT_DIALOG';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  showDialog: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HID_CONSENT_DIALOG:
      return state.set('showDialog', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 * @param show
 * @returns {(function(*): void)|*}
 */
export const showHidConsent = (show) => ({
  type: SHOW_HID_CONSENT_DIALOG,
  payload: show,
});

/** ********************************************
 * Selectors
 ******************************************** */

/**
 * @param state
 * @returns {*|null}
 */
export const selectShowHidConsent = (state) => state.hidConsent.get('showDialog') || null;

/** ********************************************
 * Private Helper functions
 ******************************************** */

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.call-details', {
      url: 'call-details',
      params: { callID: null },
      template: '<nets-call-details data-callid="$ctrl.id"></nets-call-details>',
      controller: ['$stateParams', function ($stateParams) {
        this.id = $stateParams.callID;
      }],
      controllerAs: '$ctrl',
    });
};

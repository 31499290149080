import './styles.css';
import template from './template.html';
import { getListType, listType } from '../../../../core/voicemail';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$translate',
];
function controller(
  $ngRedux,
  $translate,
) {
  let unsubscribe = null;
  const self = this;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      listType: getListType(state),
    }))((state) => {
      this.listType = state.listType;
      _setVoicemalLabel(this.listType);
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.filterVoicemailBy = (name) => {
    $ngRedux.dispatch(listType(name));
  };

  function _setVoicemalLabel(label) {
    switch (label) {
      case 'vmail/new': self.voicemailMenuLabel = $translate.instant('VOICEMAIL'); break;
      case 'vmail/edit': self.voicemailMenuLabel = $translate.instant('SAVED'); break;
      default: self.voicemailMenuLabel = label;
    }
  }
}

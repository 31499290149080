import nsToken from '@netsapiens/netsapiens-js/dist/token';
import './styles.css';
import template from './template.html';
import {
  getQueue,
  getResponders,
  getQueued,
  prioritizeQueued,
  transferQueued,
  updateQueueEntry,
  createAgentLog,
  postAgentStatus,
  getAgentType,
} from '../../core/agent-center';
import { getExpandedId, setExpanded } from '../../core/card-management';
import * as navActions from '../../core/state-history';

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@',
    onContacts: '&',
  },
};

controller.$inject = [
  '$ngRedux',
  '$state',
];

function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe;
  const decodedToken = nsToken.getDecoded();

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      responders: getResponders(state, this.card.meta.queueId),
      queued: getQueued(state, this.card.meta.queueId),
      queue: getQueue(state, this.card.meta.queueId),
      agenttype: getAgentType(state),
      screenSize: state.screenSize,
      configs: state.configs,
      expandedId: getExpandedId(state),
      context: this.context,
    }))((state) => {
      this.responders.refresh(state.responders);
      this.queued.refresh(state.queued);
      this.context = state.context;
      this.queue = state.queue;
      this.screenSize = state.screenSize;
      this.configs = state.configs;
      this.expanded = this.card.id === state.expandedId;
      this.loggedIn = false;
      this.allowGranularLogin = state.configs.get('callQueueGranularLogin') === 'yes';
      this.devicePostFix = `${state.configs.get('devicePostFix')}@`;
      this.agentDevice = state.agenttype.includes(this.devicePostFix) ? this.configs.get('userId') + this.configs.get('devicePostFix') : this.configs.get('userId');
      this.isSupervisor = state.configs.get('portalMenuShowCallCenterAgentSupervisor') === 'yes' || decodedToken.user_scope === 'Call Center Supervisor';
    });
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.responders = {
    items: [],
    refresh: (list) => {
      this.responders.items = list;
    },
    getItemAtIndex: (index) => this.responders.items[index],
    getLength: () => this.responders.items.length,
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.queued = {
    items: [],
    refresh: (list) => {
      this.queued.items = list;
    },
    getItemAtIndex: (index) => this.queued.items[index],
    getLength: () => this.queued.items.length,
  };

  this.queueLogOut = ($event, queue) => {
    $event.stopPropagation();
    // $ngRedux.dispatch(updateQueueEntry({
    //     queue_name: queue.queue_name,
    //     option: 'manual',
    //     action: 'update',
    // }));
    $ngRedux.dispatch(updateQueueEntry({
      queue_name: queue.queue_name,
      action: 'make_un',
      toAsQueue: 'yes',
    }));
    // $ngRedux.dispatch(createAgentLog('Auto')); //portal doesnt make agentlog || setstatus
    // $ngRedux.dispatch(postAgentStatus(''));

    // $mdToast.show(
    //     $mdToast.simple()
    //         .textContent($translate.instant('GREETING_DELETED'))
    //         .position('bottom left')
    //         .hideDelay(1500)
    // );
  };

  this.queueLogIn = ($event, queue) => {
    $event.stopPropagation();
    const entry = {
      queue_name: queue.queue_name,
      option: 'immediate',
      action: 'make_im',
      toAsQueue: 'yes',
    };
    $ngRedux.dispatch(updateQueueEntry(entry));
    $ngRedux.dispatch(createAgentLog('Auto'));
    $ngRedux.dispatch(postAgentStatus(''));
    // $mdToast.show(
    //     $mdToast.simple()
    //         .textContent($translate.instant('GREETING_DELETED'))
    //         .position('bottom left')
    //         .hideDelay(1500)
    // );
  };

  this.isQueuePrioritized = (que) => {
    const queued = this.queued.items;
    const specQ = queued.find(({ queue }) => queue.from_user === que.from_user);
    return specQ.prioritized === 'yes';
  };

  this.prioritizeQueued = (queue) => {
    $ngRedux.dispatch(prioritizeQueued(queue));
  };

  this.pickUpQueued = (queue) => {
    $ngRedux.dispatch(transferQueued(queue.session_key));
  };

  this.agentLoggedin = () => {
    const responders = this.responders.items;
    const responder = responders.find(({ device }) => device === this.agentDevice);
    return responder.entry_option === 'immediate' || responder.entry_status === 'available';
  };

  this.showContacts = (workflow, queue) => {
    if (this.context === 'modal') {
      this.onContacts({
        workflow,
        queueKey: queue.session_key,
        domain: queue.domain
      });
    } else {
      const currentState = navActions.getLastOnStack($ngRedux.getState(), 'callStack');

      let options = {
        name: 'wrapper.contact-list',
        params: {
          workflow,
          queueKey: queue.session_key,
          domain: queue.domain
        }
      };
      if (currentState){
        options.fromName = currentState.name;
        options.fromParams = currentState.params;
      }

      $ngRedux.dispatch(navActions.callStackPush());

      $state.go('wrapper.contact-list', options.params);
      };
  };

  this.focusCard = () => {
    $ngRedux.dispatch(setExpanded(this.card.id));
  };
  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };
}

import './styles.css';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import * as navActions from '../../core/state-history';
import * as sessionActions from '../../core/sessions';
import { collapseMenu, selectExtensionApp } from '../../core/extension';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdColors',
  '$ngRedux',
  '$rootScope',
  '$state',
];
function controller(
  $mdColors,
  $ngRedux,
  $rootScope,
  $state,
) {
  let unsubscribe = null;

  /**
     *
     */
  this.$onInit = () => {
    this.callEndedTimeout = false;
    this.callEndedValue = null;

    unsubscribe = $ngRedux.connect((state) => ({
      activeCount: sessionActions.getActiveCount(sessionActions.getSessions(state)),
      callEnded: state.sessions.get('callEnded'),
      currentState: navActions.getCurrentState(state),
      extensionApp: selectExtensionApp(state),
      screenSize: state.screenSize,
      sip: state.ua.get('ua'),
      user: state.user,
      showLocalization: state.configs.get('PORTAL_WEBPHONE_LOCALIZATION_LANGUAGE_LAYOUTS') || 'yes',
    }))((state) => {
      this.currentState = state.currentState;
      this.extensionApp = state.extensionApp;
      this.onCall = this.currentState.name !== 'wrapper.phone' && !!state.activeCount;
      this.screenSize = state.screenSize;
      this.sip = state.sip;
      this.user = state.user;
      this.showLocalization = state.showLocalization === 'yes';

      if (state.callEnded && !this.callEndedTimeout) {
        this.callEndedValue = state.callEnded;
        this.callEndedTimeout = true;
        setTimeout(() => {
          this.callEndedTimeout = false;
          this.callEndedValue = null;
          this.background = _getBackground(this.onCall, this.callEndedTimeout);
          $rootScope.$apply();
        }, 2000);
      }

      this.background = _getBackground(this.onCall, this.callEndedTimeout);
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.backToCall = () => {
    const cardId = cardActions.getCardId({ type: 'call' });
    $ngRedux.dispatch(cardActions.setExpanded(cardId));
    $ngRedux.dispatch(navActions.callStackRebase({
      name: 'wrapper.phone',
      params: null,
      fromName: null,
      fromParams: null,
    }));
    $state.go('wrapper.phone');
  };

  /**
     *
     */
  this.navPush = () => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(navActions.clearToBase('navStack'));
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.user-details',
        params: {},
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.user-details');
  };

  /**
     * Collapses extension container
     */
  this.collapseMenu = () => {
    $ngRedux.dispatch(collapseMenu());
  };

  /**
     *
     * @param onCall
     * @param callEndedTimeout
     * @return {*}
     * @private
     */
  function _getBackground(onCall, callEndedTimeout) {
    let background = $mdColors.getThemeColor('primary-600');
    if (callEndedTimeout) {
      background = '#ff270e';
    } else if (onCall) {
      background = '#3ed455';
    }
    return background;
  }
}

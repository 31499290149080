import { Map } from 'immutable';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

/** ********************************************
 * Redux constants
 ******************************************** */

export const AUDIO_DEVICE = 'core/devices/AUDIO_DEVICE';
export const CALL_FROM_DEVICE = 'core/devices/CALL_FROM_DEVICE';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  audioDevice: null,
  callFromDevice: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case AUDIO_DEVICE:
      return state.set('audioDevice', action.payload);
    case CALL_FROM_DEVICE:
      return state.set('callFromDevice', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {{payload: *, type: string}}
 */
export const setAudioDevice = (audioDevice) => ({
  type: AUDIO_DEVICE,
  payload: audioDevice,
});

/**
 *
 * @return {{payload: *, type: string}}
 */
export const setCallFromDevice = (device) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();
  const appName = getState().configs.get('appName');
  const isExtension = !!getState().extension.get('port');

  localStorage.setItem(
    `${appName}-${decodedToken.user}_callfrom${isExtension ? '_extension' : ''}`,
    JSON.stringify(device),
  );

  dispatch({
    type: CALL_FROM_DEVICE,
    payload: device,
  });
};

/**
 *
 * @param number
 * @returns {Function}
 */
export const callFrom = (number) => (dispatch, getState) => {
  const device = getState().devices.get('callFromDevice');

  const decodedToken = nsToken.getDecoded();
  nsApi.post({
    object: 'call',
    action: 'call',
    callid: Math.floor(Math.random() * (100000000000 - 1 + 1)) + 1,
    origination: device.aor,
    destination: number,
    uid: decodedToken.sub,
    format: 'json',
  });
};

/**
 *
 * @param state
 * @returns {*}
 */
export const selectCallFrom = (state) => {
  const callFromDevice = state.devices.get('callFromDevice');
  const webphoneDevice = state.devices.get('audioDevice');

  if (callFromDevice && webphoneDevice && callFromDevice.aor === webphoneDevice.aor) {
    return null;
  }
  return callFromDevice;
};

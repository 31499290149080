import { react2angular } from 'react2angular';
import netsAgentCenterList from './nets-agent-center-list';
import netsAgentQueueTab from './components/agent-queue-tab/nets-agent-queue-tab';
import netsAgentStatsTab from './components/agent-stats-tab/nets-agent-stats-tab';
import HourStatsComponent from './components/agent-stats-tab/hour-stats-component';
import DayStatsComponent from './components/agent-stats-tab/day-stats-component';
import CallStatsComponent from './components/agent-stats-tab/call-stats-component';
import netsAgentKpiStats from './components/agent-kpi-stats/nets-agent-kpi-stats';
import netsStatsPerQueue from './components/stats-per-queue/nets-stats-per-queue';
import moduleConfig from './module-config';

export default angular
  .module('app.agent', [])
  .config(['$stateProvider', moduleConfig])
  .component('netsAgentCenterList', netsAgentCenterList)
  .component('netsAgentQueueTab', netsAgentQueueTab)
  .component('netsAgentStatsTab', netsAgentStatsTab)
  .component('hourStatsComponent', react2angular(HourStatsComponent, ['hourStats', 'height', 'chartHeight', 'chartWidth']))
  .component('dayStatsComponent', react2angular(DayStatsComponent, ['dayStats', 'height', 'chartHeight', 'chartWidth']))
  .component('callStatsComponent', react2angular(CallStatsComponent, ['callStats', 'queueColors']))
  .component('netsAgentKpiStats', netsAgentKpiStats)
  .component('netsStatsPerQueue', netsStatsPerQueue);

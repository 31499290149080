import template from './template.html';

export default {
  template,
  controller,
};

controller.$inject = ['$ngRedux'];
function controller($ngRedux) {
  let unsubscribe = null;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
    }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

import './styles.css';
import template from './template.html';
import * as chatActions from '../../../../core/chat';
import { setExpanded } from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = ['$ngRedux'];
function controller($ngRedux) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({ screenSize: state.screenSize }))(this);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.closeModal = () => {
    $ngRedux.dispatch(setExpanded(null));
  };

  /**
     *
     */
  this.sendMessage = () => {
    if (!this.messageInput || !this.messageInput.trim()) {
      this.messageInput = '';
      return;
    }
    const params = { message: this.messageInput, ...this.card.meta.sendMessageParams };
    $ngRedux.dispatch(chatActions.sendMessage(params));
    this.messageInput = '';
  };
}

import Clipboard from 'clipboard';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import template from './template.html';
import { getContact, deleteContact } from '../../../../core/contacts';
import { navStackBack, navStackPush } from '../../../../core/state-history';

function controller(
  $mdToast,
  $mdDialog,
  $ngRedux,
  $state,
  $translate,
) {
  let clipboard;
  const self = this;
  const { contactID } = $state.params;
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      selectedContact: getContact(state, contactID),
    }))(this);

    // make a copy of the contact
    this.contact = { ...this.selectedContact };
    // this.isUser = !!state.contact.user;
    this.isUser = !!this.contact.user;

    const countryCode = $ngRedux.getState().configs.get('countryCode');
    clipboard = new Clipboard('#contact-copy', {
      text() {
        let str = '';

        if (self.contact.user) {
          str += `User: ${self.contact.user}\r`;
        }
        if (self.contact.name) {
          str += `Name: ${self.contact.name}\r`;
        }
        if (self.contact.extension) {
          str += `Extension: ${self.contact.extension}\r`;
        }
        if (self.contact.work_phone) {
          str += `Work: ${nsUtils.formatPhoneNumber(self.contact.work_phone, countryCode)}\r`;
        }
        if (self.contact.cell_phone) {
          str += `Mobile: ${nsUtils.formatPhoneNumber(self.contact.cell_phone, countryCode)}\r`;
        }
        if (self.contact.home_phone) {
          str += `Home: ${nsUtils.formatPhoneNumber(self.contact.home_phone, countryCode)}\r`;
        }
        if (self.contact.email) {
          str += `Email: ${self.contact.email.join(', ')}\r`;
        }
        if (self.contact.tags) {
          str += `Tags: ${self.contact.tags}\r`;
        }
        if (self.contact.group) {
          str += `Group: ${self.contact.group}\r`;
        }

        str += `Domain: ${self.contact.domain}`;

        return str;
      },
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    clipboard.destroy();
    unsubscribe();
  };

  /**
     *
     */
  this.deleteContact = () => {
    $mdDialog.show({
      template: `${'<md-dialog aria-label="Delete Contact" class="delete-modal">'
                        + '<md-dialog-content style="padding: 20px;">'
                        + '<p>'}${$translate.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE')}<span style="font-size: 18px;font-weight: 500;"> ${this.contact.name}</span>` + '?' + '</p>'
                        + '</md-dialog-content>'
                        + '<md-dialog-actions layout="row">'
                            + `<md-button ng-click="$ctrl.cancel()" class="md-primary">${
                              $translate.instant('CANCEL')
                            } </md-button>`
                            + `<md-button ng-click="$ctrl.delete()" class="md-primary">${
                              $translate.instant('DELETE')
                            } </md-button>`
                        + '</md-dialog-actions>'
                    + '</md-dialog>',
      controllerAs: '$ctrl',
      controller: [function () {
        this.cancel = () => {
          $mdDialog.hide();
        };
        this.delete = () => {
          // show confirmation toast
          $mdToast.show(
            $mdToast
              .simple()
              .textContent($translate.instant('CONTACT_REMOVED!'))
              .position('bottom left')
              .hideDelay(1500),
          );
          // delete contact
          $ngRedux.dispatch(deleteContact(contactID));
          $ngRedux.dispatch(navStackBack());
          // close modal
          $mdDialog.hide();
          // transition to contacts
          self.navBack();
        };
      }],
    });
  };

  /**
     *
     */
  this.navBack = () => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    if (currentState.params.callID) {
      $state.go(currentState.fromName, {
        callID: currentState.params.callID,
      });
    } else {
      $state.go('wrapper.contact-list');
    }
  };

  /**
     *
     */
  this.navPush = () => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navStackPush({
        name: 'wrapper.contact-edit',
        params: {
          contactID: $state.params.contactID,
        },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-edit', {
      contactID: $state.params.contactID,
    });
  };
}

controller.$inject = [
  '$mdToast',
  '$mdDialog',
  '$ngRedux',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
};

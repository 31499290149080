import { put, takeEvery } from 'redux-saga/effects';

import { fetchVoicemail } from '../voicemail';
import { CONTACTS } from '../contacts';

export default function* () {
  yield takeEvery(CONTACTS, _fetchVM);
}

const _fetchVM = function* () {
  // todo match voicemail if voicemail is already initialized
  yield put(fetchVoicemail());
};

import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsPortal from '@netsapiens/netsapiens-js/dist/portal';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import './styles.css';
import template from './template.html';
import { authenticated } from './redux';
import { getConfig } from '../../core/configs';

function controller($ngRedux) {
  const self = this;
  let unsubscribe;
  let decodedToken;

  self.formMessage = '';
  self.spinner = false;
  self.submit = submit;
  self.newPassword = '';
  self.confirm = '';
  self.pin = '';
  self.passReq = getPassReq();
  self.pinReq = getPinReq();
  self.testPassRegx = testPassRegx();
  self.testConfRegx = testConfRegx();
  self.testVmRegx = testVmRegx();
  self.passErr = '';
  self.confErr = '';
  self.pinErr = '';

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      appName: getConfig(state, 'appName'),
      portalAllowExtInPass: getConfig(state, 'portalAllowExtInPass'),
      portalForceNum: getConfig(state, 'portalForceNum'),
      portalMinPassLength: getConfig(state, 'portalMinPassLength'),
      portalPassStrong: getConfig(state, 'portalPassStrong'),
      screenSize: state.screenSize,
      securePassForceNum: getConfig(state, 'securePassForceNum'),
      securePassMinCapCnt: getConfig(state, 'securePassMinCapCnt'),
      securePassMinLength: getConfig(state, 'securePassMinLength'),
      securePassMinNumCnt: getConfig(state, 'securePassMinNumCnt'),
      securePassMinSpecialCharCnt: getConfig(state, 'securePassMinSpecialCharCnt'),
      securePassStrong: getConfig(state, 'securePassStrong'),
    }))(this);
    decodedToken = nsToken.getDecoded();
    localStorage.setItem(`${self.appName}-${decodedToken.user}_legacy`, 'true');
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  function getPassReq() {
    let str = `Minimum length of ${self.securePassMinLength} characters, `;
    if (self.securePassMinCapCnt
      && _.isNumber(self.securePassMinCapCnt)
      && _.toNumber(self.securePassMinCapCnt)
    ) {
      str += `minimum of ${self.securePassMinCapCnt} capital letter(s), `;
    }
    if (self.securePassMinNumCnt
      && _.isNumber(self.securePassMinNumCnt)
      && _.toNumber(self.securePassMinNumCnt)
    ) {
      str += `minimum of ${self.securePassMinNumCnt} number(s), `;
    }
    if (self.securePassMinSpecialCharCnt
      && _.isNumber(self.securePassMinSpecialCharCnt)
      && _.toNumber(self.securePassMinSpecialCharCnt)
    ) {
      str += `minimum of ${self.securePassMinSpecialCharCnt} special character(s), `;
    }
    if (self.securePassForceNum && self.securePassForceNum !== 'no') {
      str += 'numbers only, ';
    }
    if (self.securePassStrong !== 'no' && self.portalAllowExtInPass !== 'yes') {
      str += 'your extension cannot be part of your password, ';
    }
    return `${str.substring(0, str.length - 2)}.`;
  }

  function getPinReq() {
    let str = '';
    if (self.portalMinPassLength) {
      str += `Minimum length of ${self.portalMinPassLength} characters, `;
    }
    if (self.portalForceNum !== 'no') {
      str += 'numbers only, ';
    }
    if (self.portalPassStrong !== 'no' && self.portalAllowExtInPass !== 'yes') {
      str += 'your extension cannot be part of your password, ';
    }
    return `${str.substring(0, str.length - 2)}.`;
  }

  function testPassRegx() {
    return {
      test(value) {
        if (value.length < self.securePassMinLength) {
          self.passErr = `Minimum length of ${self.securePassMinLength} characters required`;
          return false;
        }

        if (self.securePassMinNumCnt) {
          const cnt = value.match(/\d/g);
          if (!cnt || cnt < self.securePassMinNumCnt) {
            self.passErr = `Minimum of ${self.securePassMinNumCnt} number(s) required`;
            return false;
          }
        }

        if (self.securePassMinSpecialCharCnt) {
          const cnt = new RegExp(`[!"#$%&'()*+,-.:;<=>?@[\\]^_\`{|}~]{${self.securePassMinSpecialCharCnt}}`, 'g');
          if (!cnt.test(value)) {
            self.passErr = `Minimum of ${self.securePassMinSpecialCharCnt} special character(s)`;
            return false;
          }
        }
        return true;
      },
    };
  }

  function testConfRegx() {
    return {
      test(value) {
        if (value !== self.newPassword) {
          self.confErr = 'The new password and password confirmation must match';
          return false;
        }
        return true;
      },
    };
  }

  function testVmRegx() {
    return {
      test(value) {
        if (value.length < self.portalMinPassLength) {
          self.pinErr = `Minimum length of ${self.portalMinPassLength} characters required`;
          return false;
        }
        if (value.length < self.portalForceNum) {
          const regx = new RegExp('^[0-9]*$');
          if (!regx.test(value)) {
            self.pinErr = 'Numbers only';
            return false;
          }
        }
        if (self.portalPassStrong && !self.portalAllowExtInPass) {
          // todo what extension?
          /* if(value.indexOf('') !== -1) {
                     self.pinErr = 'Your extension cannot be part of your password';
                     return false;
                     } */
        }
        return true;
      },
    };
  }

  function submit(form) {
    self.formMessage = '';

    if (form.$valid) {
      self.spinner = true;

      nsApi.post({
        object: 'subscriber',
        action: 'update',
        domain: decodedToken.domain,
        user: decodedToken.user,
        pwd_hash: form.password.$viewValue,
        subscriber_pin: form.pin.$viewValue,
      }).then(() => {
        nsApi.refreshToken().then(() => {
          localStorage.removeItem(`${self.appName}-${decodedToken.user}_legacy`);

          nsPortal.login(
            decodedToken.sub,
            form.password.$viewValue,
          ).then(() => {
            // todo
          }).catch(() => {
            // todo
          });

          $ngRedux.dispatch(authenticated());
        });
      }).catch(() => {
        // todo error message
        self.spinner = false;
      });
    }
  }
}

controller.$inject = ['$ngRedux'];

export default {
  template,
  controller,
};

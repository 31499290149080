/* eslint-disable no-console */
// import _ from 'lodash';
import { Map } from 'immutable';

import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import _ from 'lodash';
import { subscribe } from '../socket';
import { getObject } from '../angular';

import * as cardActions from '../card-management';
import * as navActions from '../state-history';

/** ********************************************
 * Redux constants
 ******************************************** */

export const FILTERED_BY = 'core/call-park/FILTERED_BY';
export const CALL_PARK_RESULT_SET = 'core/call-park/CALL_PARK_RESULT_SET';
export const CALL_PARK = 'core/call-park/CALL_PARK';
export const PARKED = 'core/call-park/PARKED';
export const REMOVED = 'core/call-park/REMOVED';
export const LOADING = 'core/call-park/LOADING';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  filteredBy: 'all',
  callPark: nsUtils.normalizeList('queue_name', []),
  callParkResultSet: [],
  parked: nsUtils.normalizeList('orig_callid', []),
  loading: true,
});

export default (state = initialState, action) => {
  let parked;
  let parktmp;

  switch (action.type) {
    case LOADING:
      return state.set('loading', action.payload);
    case FILTERED_BY:
      return state.set('filteredBy', action.payload);
    case CALL_PARK:
      return state.set('callPark', nsUtils.normalizeList('queue_name', action.payload));
    case CALL_PARK_RESULT_SET:
      return state.set('callParkResultSet', action.payload);
    case PARKED:
      parked = state.get('parked');
      if (!parked.entities[action.payload.orig_callid]) {
        parktmp = parked.toArray();
        parktmp.push(action.payload);
        return state.set('parked', nsUtils.normalizeList('orig_callid', parktmp));
      }
      parked.entities[action.payload.orig_callid] = action.payload;
      return state.set('parked', parked);

    case REMOVED:
      parked = state.get('parked');
      parktmp = _.filter(parked.toArray(), (waiter) => waiter.orig_callid !== action.payload.orig_callid);
      return state.set('parked', nsUtils.normalizeList('orig_callid', parktmp));
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {function(*)}
 */
export const fetchCallParks = () => (dispatch) => {
  console.log('fetchCallParks');

  dispatch({
    type: LOADING,
    payload: true,
  });
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'callqueue',
    action: 'read',
    type: 'park',
    user: decodedToken.user,
    domain: decodedToken.domain,
    format: 'json',
    addDeptSite: 'inclMatchesCurrentUser',
  }).then((res) => {
    dispatch({
      type: CALL_PARK,
      payload: res,
    });
    dispatch(processResultSet(true));
    res.forEach((park) => {
      dispatch(subscribe({
        type: 'call',
        filter: park.queue_name,
      }));
    });
  });
};

/**
 *
 * @param {string} payload 'all', 'outbound', 'inbound', 'missed'
 * @return {function(*, *)}
 */
export const filteredBy = (payload) => (dispatch, getState) => {
  dispatch({
    type: FILTERED_BY,
    payload,
  });

  const callParkList = getState().callPark.get('callPark').toArray();

  if (callParkList) {
    // process result set
    dispatch(processResultSet());
  }
};

/**
 *
 * @param updateLoading
 * @return {function(*, *)}
 */
export const processResultSet = (updateLoading = false) => (dispatch, getState) => {
  const callParkList = getState().callPark.get('callPark').toArray();
  const parkedList = getState().callPark.get('parked').toArray();
  const filteredBy = getState().callPark.get('filteredBy');
  let resultSet = [];

  switch (filteredBy) {
    case 'all':
      resultSet = callParkList;
      break;
    case 'site':
      resultSet = _.filter(callParkList, (park) => park.same_site_as_requesting_user === 'yes');
      break;
    case 'department':
      resultSet = _.filter(callParkList, (park) => park.same_dept_as_requesting_user === 'yes');
      break;
    default:
      resultSet = callParkList;
  }

  const expandedList = [];
  resultSet.forEach((res) => {
    const parked = _.filter(parkedList, (park) => park.term_user === res.queue_name);
    if (parked.length > 0) {
      parked.forEach((park) => {
        park.queue_name = park.term_user;
        expandedList.push(park);
      });
    } else {
      expandedList.push(res);
    }
  });

  dispatch({
    type: CALL_PARK_RESULT_SET,
    payload: expandedList,
  });

  if (updateLoading) {
    dispatch({
      type: LOADING,
      payload: false,
    });
  }
};

export const parkCall = (park) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();
  const prefix = getState().configs.get('portalTransferPrefixPark');

  const $mdToast = getObject(getState(), '$mdToast');
  const $translate = getObject(getState(), '$translate');
  $mdToast.show(
    $mdToast.simple()
      .textContent(`Call Info ${$translate.instant('PARKED_AT')} ${park.parkExt}`)
      .position('bottom left')
      .hideDelay(3000),
  );

  if (park.dynamic) {
    return nsApi.get({
      object: 'call',
      action: 'park',
      callid: park.parkId,
      prefix,
      valid: park.parkExt, // comma seperated list of all parkExt
      uid: decodedToken.sub,
      format: 'json',
    }).then((res) => { console.log('parkCall dynamic res:', res); });
  }
  return nsApi.get({
    object: 'call',
    action: 'xfer',
    callid: park.parkId,
    destination: `sip:${prefix}${park.parkExt}@${decodedToken.domain}`,
    uid: decodedToken.sub,
    format: 'json',
  }).then((res) => { console.log('parkCall specific res:', res); });
};

export const retrieveParked = (park) => {
  const decodedToken = nsToken.getDecoded();
  return nsApi.get({
    object: 'call',
    action: 'xfer',
    callid: park.parkId,
    destination: `sip:${decodedToken.sub}`,
    uid: decodedToken.sub,
    domain: decodedToken.domain,
  }).then((res) => { console.log('retrieveParked res:', res); });
};

/**
 *
 * @param data
 */
export const updateParked = (data) => (dispatch, getState) => {
  if (getState().callPark.get('callPark').entities[data.term_user]) {
    dispatch({
      type: PARKED,
      payload: data,
    });
    dispatch(processResultSet());
  }
};

/**
 *
 * @param data
 */
export const removeParked = (data) => (dispatch, getState) => {
  if (getState().callPark.get('callPark').entities[data.term_user]) {
    dispatch({
      type: REMOVED,
      payload: data,
    });
    dispatch(processResultSet());
  }
};

export const createCard = () => (dispatch, getState) => {
  const { screenSize } = getState();
  if (screenSize.display === 'compact') {
    const lastOnStack = navActions.getLastOnStack(getState(), 'navStack');

    dispatch(
      navActions.navStackPush({
        name: 'wrapper.call-park-list',
        params: {},
        fromName: lastOnStack.name,
        fromParams: lastOnStack.params,
      }),
    );
    const $state = getObject(getState(), '$state');
    $state.go('wrapper.call-park-list', {});
  } else {
    const cardMeta = {
      type: 'park',
    };

    let cardId = cardActions.getCardId(cardMeta);
    if (!cardId) {
      dispatch(cardActions.newCard(cardMeta));
      cardId = cardActions.getCardId(cardMeta);
    }
    dispatch(cardActions.setExpanded(cardId));
  }
};

/** ********************************************
 * Selectors
 ******************************************** */
/**
  *
  * @param state
  * @return {boolean}
  */
export const getLoading = (state) =>
// console.log('getLoading state:',state);
  state.callPark.get('loading');

/**
 *
 * @param state
 * @return {[]}
 */
export const getCallParkFilteredBy = (state) =>
// console.log('getDispositions state:',state);
  state.callPark.get('filteredBy');

/**
 *
 * @param state
 * @return {[]}
 */
export const getCallParkResultSet = (state) => state.callPark.get('callParkResultSet');

/**
 *
 * @param state
 * @param park
 * @return {[]}
 */
export const getParked = (state) => state.callPark.get('parked').toArray();

/**
 *
 * @param state
 * @param park
 * @return {[]}
 */
export const getParkingLot = (state) => state.callPark.get('callPark').ids;

/**
 *
 * @param state
 * @param park
 * @return {[]}
 */
export const validateParkId = (state, park) => state.callPark.get('callPark').entities[park];

export const getParkName = (state, park) => {
  if (state.callPark.get('callPark').entities[park]) {
    return state.callPark.get('callPark').entities[park].description;
  }
  return null;
};

/**
 *
 * @param state
 * @param park
 * @return {string}
 */
export const getParkDescription = (state, park) => {
  if (state.callPark.get('callPark').entities[park]) {
    return state.callPark.get('callPark').entities[park].description;
  }
  return null;
};

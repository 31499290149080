import formatDuration from './formatDuration';
import formatHourMin from './formatHourMin';
import formatPhoneNumber from './formatPhoneNumber';
import formatLongDateTime from './formatLongDateTime';
import formatTime from './formatTime';
import fromNow from './fromNow';
import humanizeDuration from './humanizeDuration';
import ruleTimeFrame from './ruleTimeFrame';
import localizationMap from './localizationMap';
import run from './run';

export default angular
  .module('app.filters', [])
  .run(['$filter', run])
  .filter('formatDuration', formatDuration)
  .filter('formatHourMin', formatHourMin)
  .filter('formatPhoneNumber', formatPhoneNumber)
  .filter('formatLongDateTime', formatLongDateTime)
  .filter('formatTime', formatTime)
  .filter('fromNow', fromNow)
  .filter('humanizeDuration', humanizeDuration)
  .filter('localizationMap', localizationMap)
  .filter('ruleTimeFrame', ruleTimeFrame);

import template from './template.html';
import './styles.css';
import * as sessions from '../../core/sessions';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$translate',
  '$timeout',
];

function controller(
  $ngRedux,
  $translate,
  $timeout,
) {
  let unsubscribe;
  const self = this;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      sip: state.ua.get('ua'),
      getActiveCall: sessions.getActiveCall(state),
    }))((state) => {
      this.animateCall = '';
      const WPmicrophone = localStorage.getItem('WPmicrophone') === 'true';

      // Registered
      if (state.sip) {
        this.WPmicrophone = true;
        this.sip = !!state.sip;

        this.NsPill = {
          'background-color': 'transparent',
        };
        this.NsStatus = {
          display: 'none',
        };
        this.NsIcon = {
          'background-color': '#00A404',
          color: '#fff',
        };
        this.sipIcon = 'dialer_sip';
        // On call
        if (state.getActiveCall) {
          this.NsPill = {
            'background-color': '#b9b9b9',
          };
          this.NsIcon = {
            'background-color': '#00A404',
            color: '#fff',
          };
          this.animateCall = 'oncall';
          $timeout(() => {
            self.NsStatus = {
              display: 'block',
            };
            self.sipMenuLabel = $translate.instant('ON_CALL');
          }, 800);
          this.sipIcon = 'dialer_sip';
        }
      }

      // No Microphone
      if (!state.sip && (!WPmicrophone || WPmicrophone === false)) {
        this.WPmicrophone = false;
      }

      if (!state.sip && (WPmicrophone || WPmicrophone === true)) {
        this.WPmicrophone = true;
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };
}

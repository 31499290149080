import template from './template.html';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];
function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe = null;

  this.title = $state.params.title || '';
  this.body = $state.params.body || '';

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({ screenSize: state.screenSize }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

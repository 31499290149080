import './styles.css';
import template from './template.html';
import * as contactActions from '../../../../core/contacts';

export default {
  template,
  controller,
  bindings: {
    workflow: '@', // in-call
  },
};

controller.$inject = [
  '$ngRedux',
  '$translate',
];
function controller(
  $ngRedux,
  $translate,
) {
  let unsubscribe = null;
  const self = this;

  /**
     *
     */
  this.$onInit = () => {
    // bind redux to component
    unsubscribe = $ngRedux.connect((state) =>
    // map redux to component
      ({
        departments: contactActions.getDepartments(state),
        filteredBy: !this.workflow || this.workflow.indexOf('in-call') === -1
          ? contactActions.getFilteredBy(state) : contactActions.getInCallFilteredBy(state),
      }))((state) => {
      // update component state
      this.departments = state.departments;
      this.filteredBy = state.filteredBy;
      _setContactLabel(this.filteredBy);
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param name
     */
  this.filterContactsBy = (name) => {
    if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
      $ngRedux.dispatch(contactActions.filteredBy(name));
    } else {
      $ngRedux.dispatch(contactActions.inCallFilteredBy(name));
    }
  };

  /**
     *
     * @param label
     * @private
     */
  function _setContactLabel(label) {
    switch (label) {
      case 'all': self.contactMenuLabel = $translate.instant('CONTACTS'); break;
      case 'favorites': self.contactMenuLabel = $translate.instant('FAVORITES'); break;
      case 'my_contacts': self.contactMenuLabel = $translate.instant('MY_CONTACTS'); break;
      case 'coworkers': self.contactMenuLabel = $translate.instant('COWORKERS'); break;
      case 'online': self.contactMenuLabel = $translate.instant('ONLINE'); break;
      case 'busy': self.contactMenuLabel = $translate.instant('BUSY'); break;
      case 'offline': self.contactMenuLabel = $translate.instant('OFFLINE'); break;
      default: self.contactMenuLabel = label;
    }
  }
}

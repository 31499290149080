import { store } from '../../store';

export const hasCard = ({ cardId, contactId, sessionId }) => {
  const cards = store.getState().cards.get('cards').toArray();

  if (cardId) {
    for (let i = 0; i < cards.length; i += 1) {
      if (cards[i].id === cardId) {
        return true;
      }
    }
  } else {
    for (let i = 0; i < cards.length; i += 1) {
      if (cards[i].meta
                && cards[i].meta.contactId === contactId
                && cards[i].meta.sessionId === sessionId
      ) {
        return true;
      } if (cards[i].meta
                && cards[i].meta.contactId == undefined
                && contactId == undefined
                && cards[i].meta.sessionId === sessionId
      ) {
        // if group chat, will not have contactId, but will still have sessionId
        return true;
      }
    }
  }

  return false;
};

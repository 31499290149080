export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.agent-center-details', {
      url: 'agent-center-details',
      params: {
        card: null,
        context: null,
      },
      template: '<nets-agent-center-details card="$ctrl.card" context="card"></nets-agent-center-details>',
      controller: ['$stateParams', function ($stateParams) {
        this.card = $stateParams.card;
        this.context = $stateParams.context;
      }],
      controllerAs: '$ctrl',
    });
};

import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import { getConfig } from '../../core/configs';
import { getExpandedId } from '../../core/card-management';

import * as chatActions from '../../core/chat';
import * as contactActions from '../../core/contacts';
import * as deviceActions from '../../core/devices';
import * as navActions from '../../core/state-history';
import { sendInvite } from '../../core/ua';
import * as sessionActions from '../../core/sessions';

function controller(
  $mdToast,
  $ngRedux,
  $state,
  $translate,
) {
  let unsubscribe;
  let contacts;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => {
      const res = {
        allowCall: sessionActions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-677]
        loaded: !chatActions.getLoading(state),
        screenSize: state.screenSize,
        ua: state.ua.get('ua'),
        hostname: getConfig(state, 'hostname'),
        allowVideo: getConfig(state, 'allowVideo'),
        expandedId: getExpandedId(state),
        expanded: false,
      };
      // if no card get expanded card
      // this is used for compact nav
      if (!this.card) {
        const expandedId = cardActions.getExpandedId(state);
        res.card = cardActions.getCard(state, expandedId);
      } else {
        // handle state and binding updates
        res.card = this.card;
        // Check if is a contact
        contacts = contactActions.getContactsResultSet(state);
        const userId = res.card.meta.contactId;
        res.isContact = _.some(contacts, (el) => el.id === userId);
      }

      if (this.card && this.card.meta.sessionId) {
        res.session = chatActions.getSession(state, this.card.meta.sessionId);
      }

      // set expanded for video icon option
      if (this.card
        && this.card.id
        && this.card.id === res.expandedId
      ) {
        res.expanded = true;
      }

      return res;
    })(this);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.call = () => {
    const callFromDevice = deviceActions.selectCallFrom($ngRedux.getState());

    if (!callFromDevice) {
      $ngRedux.dispatch(sendInvite(this.card.meta.sendMessageParams.destination));
    } else {
      $mdToast.show(
        $mdToast.simple()
          .textContent(`${$translate.instant('CALLING_FROM')} ${callFromDevice.display}`)
          .position('bottom left')
          .hideDelay(60000),
      );

      $ngRedux.dispatch(deviceActions.callFrom(this.card.meta.sendMessageParams.destination));
    }
  };

  /**
     *
     */
  this.delete = () => {
    $ngRedux.dispatch(cardActions.removeCard(this.card.id));
    $ngRedux.dispatch(chatActions.deleteSession(this.card.meta.sessionId));

    if (this.screenSize.display === 'compact') {
      // back navStack one state
      $ngRedux.dispatch(navActions.navStackBack());
      // get last on stack
      const state = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
      // go to ast on stack
      $state.go(state.name, state.params);
    }
  };

  /**
     *
     */
  this.toggleMute = () => {
    $ngRedux.dispatch(chatActions.toggleMute(this.card.meta.sessionId, this.session.muted));
  };

  /**
     *
     * @param type
     */
  this.addNewContact = (type) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-add',
        params: {
          workflow: 'chat-new-contact',
          type,
          number: this.card.meta.sendMessageParams.destination,
        },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-add', {
      workflow: 'chat-new-contact',
      type,
      number: this.card.meta.sendMessageParams.destination,
    });
  };

  /**
     *
     * @param type
     */
  this.addToContact = (type) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-list',
        params: {
          workflow: 'call-details-add-to-contact',
          type,
          number: this.card.meta.sendMessageParams.destination,
        },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-list', {
      workflow: 'call-details-add-to-contact',
      type,
      number: this.card.meta.sendMessageParams.destination,
    });
  };

  this.changeConversationName = ($event) => {
    // expand the card if pressed from kebob
    if ($event.target
            && $event.target.className
            && $event.target.className.indexOf('no-expand') === -1
    ) {
      $ngRedux.dispatch(cardActions.setExpanded(this.card.id));
    }

    // change card state to session name editting
    if (!this.card.changeSessionName) this.card.changeSessionName = true;
    else this.card.changeSessionName = false;
  };

  /**
     *  Leave Conversation confirmation from kebab
     */
  this.leaveConversation = () => {
    $ngRedux.dispatch(cardActions.removeCard(this.card.id));
    $ngRedux.dispatch(
      chatActions.leaveSession(this.card.meta.sessionId, this.card.meta.sendMessageParams.remote),
    );

    if (this.screenSize.display === 'compact') {
      // back navStack one state
      $ngRedux.dispatch(navActions.navStackBack());
      // get last on stack
      const state = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
      // go to ast on stack
      $state.go(state.name, state.params);
    }
  };

  this.changeParticipants = ($event) => {
    // expand the card if pressed from kebob
    if ($event.target
            && $event.target.className
            && $event.target.className.indexOf('no-expand') === -1
    ) {
      $ngRedux.dispatch(cardActions.setExpanded(this.card.id));
    }

    // change card state to session name editting
    if (!this.card.changeParticipantsState) this.card.changeParticipantsState = true;
    else this.card.changeParticipantsState = false;
  };

  // show only the participants page in a list
  this.viewParticipants = ($event) => {
    // expand the card if pressed from kebob
    if ($event.target
            && $event.target.className
            && $event.target.className.indexOf('no-expand') === -1
    ) {
      $ngRedux.dispatch(cardActions.setExpanded(this.card.id));
    }

    // change card state to session name editting
    if (!this.card.viewParticipantsState) this.card.viewParticipantsState = true;
    else this.card.viewParticipantsState = false;
  };

  /**
     *
     * @param contactID
     */
  this.navPush = (contactID) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-details',
        params: { contactID },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-details', { contactID });
  };

  /**
     *
     * @param contactID
     */
  this.startVideo = (type = 'start', screenshare = false) => {
    // if start send a message
    if (type === 'start') {
      // create a meeting with getId=yes
      const decodedToken = nsToken.getDecoded();
      nsApi.post({
        object: 'meeting',
        action: 'create',
        user: decodedToken.user,
        domain: decodedToken.domain,
        getId: 'yes',
      }).then((res) => {
        if (typeof res.id === 'undefined') {
          console.log('ERROR CREATING MEETING');
          return;
        }
        // take that return to send a message with that id
        const params = { message: res.id.toString(), ...this.card.meta.sendMessageParams };
        params.type = 'chat-video-invite';

        $ngRedux.dispatch(chatActions.sendMessage(params));

        // open the window /start&id=___&users=
        // let url = 'https://' + server_name + '/video/'+type;
        let url = `https://${this.hostname}/video/${type}?id=${res.id}`;

        const ext = this.card.meta.sendMessageParams.destination;

        if (ext) {
          url += `?users=${ext}`;
          if (screenshare) url += '&s=1&v=0';
        }

        const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=1200,height=900';
        window.open(url, '_blank', options);
      });
    } else if (type === 'schedule') {
      // schedule logic, just open a window for scheduling
      // separate because there is no message made
      let url = `https://${this.hostname}/video/${type}`;
      const ext = this.card.meta.sendMessageParams.destination;
      if (ext) {
        url += `?users=${ext}`;
      }
      const options = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,width=1200,height=900';
      window.open(url, '_blank', options);
    }
  };
}

controller.$inject = [
  '$mdToast',
  '$ngRedux',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

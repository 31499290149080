import './styles.css';
import _ from 'lodash';
import template from './template.html';
import { getConfig } from '../../../../core/configs/redux';
import { getEnabled } from '../../../../core/answering-rules';
import { setExpanded } from '../../../../core/card-management';
import { filteredBy } from '../../../../core/call-history';
import { navStackPush } from '../../../../core/state-history';
import * as chatActions from '../../../../core/chat';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = ['$ngRedux', '$state'];
function controller($ngRedux, $state) {
  let imgLoaded = false;
  let unsubscribe;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      enabledAr: getEnabled(state),
      hostname: getConfig(state, 'hostname'),
      newVoicemail: state.voicemail.get('newVoicemail'),
      callHistory: state.callHistory.get('callHistoryResultSet'),
      unreadCount: chatActions.getUnreadCount(state),
      user: state.user,
      voicemailLoaded: !state.voicemail.get('loading'),
      screenSize: state.screenSize,
      allowChat: state.configs.get('portalChatSMS') || "yes",
      PORTAL_WEB_PHONE_SHOW_VOICEMAIL: state.configs.get('PORTAL_WEB_PHONE_SHOW_VOICEMAIL') || "yes",
      PORTAL_WEB_PHONE_SHOW_CALLHISTORY: state.configs.get('PORTAL_WEB_PHONE_SHOW_CALLHISTORY')|| "yes",


    }))((state) => {
      this.enabledAr = state.enabledAr;
      this.newVoicemail = state.newVoicemail;
      this.showVoicemail = (state.user && state.user.vmailEnabled == "yes" && state.PORTAL_WEB_PHONE_SHOW_VOICEMAIL.toLowerCase() !== "no");
      this.allowChat = state.allowChat.toLowerCase() ;
      this.user = state.user;
      this.voicemailLoaded = state.voicemailLoaded;
      this.screenSize = state.screenSize;
      this.unreadCount = state.unreadCount;
      // Filter callHistory locally
      this.callHistory = _.filter(state.callHistory, (rec) => rec.type === '2');
      this.showCallhistory = state.PORTAL_WEB_PHONE_SHOW_CALLHISTORY.toLowerCase() !== "no";



      if (!imgLoaded) {
        setTimeout(() => {
          const imgUrl = `https://${state.hostname}/ns-api/?object=image&action=read&server=${state.hostname}&filename=drawer_default.png&domain=${state.domain}`;
          const elem = document.getElementById('user-card-image-modal');
          elem.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imgUrl})`;
          imgLoaded = true;
        }, 0);
      }
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.closeModal = () => {
    $ngRedux.dispatch(setExpanded(null));
  };

  this.goVoicemail = ($event) => {
    $event.stopPropagation();
    $state.go('wrapper.voicemail-list');
  };

  this.goMissedCall = ($event) => {
    $event.stopPropagation();
    // Apply missed filter and redirect
    $ngRedux.dispatch(filteredBy('missed'));
    $state.go('wrapper.call-history-list');
  };

  this.goRecentChat = ($event) => {
    $event.stopPropagation();
    $state.go('wrapper.chat-list');
  };

  this.goAnswerRules = ($event, ruleID) => {
    $event.stopPropagation();
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navStackPush({
        name: 'wrapper.answering-rule-details',
        params: { ruleID },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.answering-rule-details', { ruleID });
  };
}

export default ($stateProvider) => {
  $stateProvider
    .state('select-subscriber', {
      url: `${location.pathname}select-subscriber`,
      params: {
            	idtoken: null,
            	vendor: null,
        subscriber_list: [],
      },
      template: '<nets-select-subscriber></nets-select-subscriber>',
    });
};

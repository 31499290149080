import './styles.css';
import template from './template.html';
import { getKPIStats } from '../../../../core/agent-center';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe = null;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      kpistats: getKPIStats(state),
    }))((state) => {
      this.kpistats = state.kpistats;
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

import _ from 'lodash';
import loglevel from 'loglevel';
import {
  init as hidInit,
  EasyCallControlFactory,
  RequestedBrowserTransport,
} from '@gnaudio/jabra-js';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import { store } from '../../store';
import { bugsnagClient } from '../../../bugsnag';
import * as userMedia from '../index';
import { updateElementSinkId } from '../../audio';
import { activeCount, getActiveCall, getSessions } from '../../sessions';
import * as sessionActions from '../../sessions';
import { showHidConsent } from '../../hid-consent';
import { getObject } from '../../angular';

const MODULE_NAME = 'user-media/devices';
const logger = loglevel.getLogger(MODULE_NAME);
logger.setLevel('info');

/**
 * Returns all available devices
 * @returns {Promise<MediaDeviceInfo[]>}
 */
export const getMediaDevices = () => navigator.mediaDevices.enumerateDevices();

/**
 * Returns a devices audio stream
 * @param deviceId
 * @returns {Promise<MediaStream>}
 */
export const getAudioStream = (deviceId) => navigator.mediaDevices.getUserMedia({
  audio: { deviceId },
  video: false,
});

/**
 * Stop a device stream
 * @param stream
 */
export const stopStream = (stream) => {
  if (stream && stream.getTracks()) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }
};

/**
 * Filters the device list for audio input devices
 * @param devices
 * @returns {*}
 */
export const getFilteredAudioInput = (devices) => _.filter(
  devices,
  (device) => device.deviceId !== 'default' && device.kind === 'audioinput',
);

/**
 * Returns the last selected user input device if it's still available or selects the browsers default device
 * @param appName
 * @param user
 * @param devices
 * @returns {*|null}
 */
export const getDefaultAudioInput = (appName, user, devices) => {
  const previouslySelected = _.get(
    _.filter(devices, { deviceId: localStorage.getItem(`${appName}-${user}_inputAudioDevice`), kind: 'audioinput' }),
    '0',
  );
  const defaultDevice = _.get(_.filter(devices, { deviceId: 'default', kind: 'audioinput' }), '0');
  return previouslySelected || defaultDevice || _.get(getFilteredAudioInput(devices), '0') || null;
};

/**
 * Filters the device list for audio output devices
 * @param devices
 * @returns {*}
 */
export const getFilteredAudioOutput = (devices) => _.filter(
  devices,
  (device) => device.deviceId !== 'default' && device.kind === 'audiooutput',
);

/**
 * Returns the last selected user output device if it's still available or selects the browsers default device
 * @param appName
 * @param user
 * @param devices
 * @returns {*|null}
 */
export const getDefaultAudioOutput = (appName, user, devices) => {
  const previouslySelected = _.get(
    _.filter(devices, { deviceId: localStorage.getItem(`${appName}-${user}_outputAudioDevice`), kind: 'audiooutput' }),
    '0',
  );
  const defaultDevice = _.get(_.filter(devices, { deviceId: 'default', kind: 'audiooutput' }), '0');
  return previouslySelected || defaultDevice || _.get(getFilteredAudioOutput(devices), '0') || null;
};

export const getDefaultAudioOutputRinger = (appName, user, devices) => {
  const previouslySelected = _.get(
    _.filter(devices, { deviceId: localStorage.getItem(`${appName}-${user}_outputAudioDeviceRinger`), kind: 'audiooutput' }),
    '0',
  );
  const defaultDevice = _.get(_.filter(devices, { deviceId: 'default', kind: 'audiooutput' }), '0');
  return previouslySelected || defaultDevice || _.get(getFilteredAudioOutputRinger(devices), '0') || null;
};

/**
 * Returns the browser device label
 * @param deviceId
 * @returns {Promise<*>}
 */
const getDeviceLabel = async (deviceId) => {
  const devices = await userMedia.getMediaDevices();
  return _.find(devices, { deviceId })?.label;
};

/**
 * The browser device and the hid device don't share the same id
 * the two entities need to be matched by label
 * @param deviceLabel
 * @returns {*}
 */
const getHidDeviceByLabel = (deviceLabel) => {
  const hidDevices = store.getState().userMedia.get('hidDevices');
  return _.find(hidDevices, (callControlDevice) => {
    const { device } = callControlDevice;
    return deviceLabel.includes(device.name) && deviceLabel.includes(device.browserLabel);
  });
};

/**
 * Listens for hid device signals
 * and updates an active call session if there is one
 * @param callControl
 */
const hidSubscribe = (callControl) => {
  logger.info(`${MODULE_NAME} - hid subscribe call control`, callControl);

  callControl.muteState.subscribe((s) => {
    logger.info(`${MODULE_NAME} - hid mute state`, s);

    const activeCall = getActiveCall(store.getState());
    logger.info(`${MODULE_NAME} - hid mute state - active call`, activeCall);
    logger.info(`${MODULE_NAME} - hid mute state - active call isMuted`, activeCall?.isMuted);
    if (activeCall) {
      if (s === 'muted' && !activeCall.isMuted) {
        logger.info(`${MODULE_NAME} - hid - set active call as muted`);
        activeCall.mute(false); // false prevents loop
      } else if (s === 'unmuted' && activeCall.isMuted) {
        logger.info(`${MODULE_NAME} - hid - set active call as unmuted`);
        activeCall.unmute(false); // false prevents loop
      }
    }
  });

  /**
   * Emits the hold state of the device whenever that state changes.
   * This can happen due to interaction with the device (the hold command can differ between devices,
   * but is often triggered by long-pressing the start/end-call button) or
   * due to interaction with your softphone (e.g. pressing a hold button in the GUI).
   * true is emitted when the device is on hold, false is emitted when the device is not on hold e.g. resumed.
   */
  callControl.holdState.subscribe((s) => {
    logger.info(`${MODULE_NAME} - hid hold state`, s);

    const activeCall = getActiveCall(store.getState());
    logger.info(`${MODULE_NAME} - hid hold state - active call`, activeCall);
    logger.info(`${MODULE_NAME} - hid hold state - active call isOnHold`, activeCall?.isOnHold);

    if (activeCall) {
      if (s === 'on-hold' && !activeCall.isOnHold) {
        logger.info(`${MODULE_NAME} - hid - set active call on hold`);
        activeCall.hold(false); // false prevents loop
      } else if (s === 'not-on-hold' && activeCall.isOnHold) {
        logger.info(`${MODULE_NAME} - hid - set active call as unhold`);
        activeCall.unhold(false); // false prevents loop
      }
    }
  });

  /**
   * Emits whenever swap is triggered.
   * This can happen due to interaction with the device (most often the same button that triggers hold) or
   * due to interaction with your softphone (e.g. pressing a swap button in the GUI).
   * The observable does not emit any value, and it does not keep track of what call was swapped to or
   * from - this should be handled by the softphone application.
   * The most common implementation pattern would be to maintain a list of ongoing calls in the softphone application.
   * Then, whenever swap is triggered, the active call is moved to the next call in line.
   */
  callControl.swapRequest.subscribe(() => {
    const activeCall = getActiveCall(store.getState());
    const callSessions = getSessions(store.getState());

    // if there is an active call and has more than one call session
    logger.info(`${MODULE_NAME} - hid swap call`, activeCall, callSessions);
    if (activeCall && callSessions) {
      logger.info(`${MODULE_NAME} - hid swap call - hold active`, activeCall);
      activeCall.hold(false);
      for (let i = 0; i < callSessions.length; i += 1) {
        if (activeCall.id !== callSessions[i].id) {
          logger.info(`${MODULE_NAME} - hid swap - call unhold and set to active`, callSessions[i]);
          callSessions[i].unhold(false);
          store.dispatch(sessionActions.setActiveId(callSessions[i].id));
          break;
        }
      }
    }
  });

  /**
   * Emits the number of calls currently in progress.
   * 0 means that the device is idle with no calls in progress 1 or
   * more signifies the number of calls currently active
   * Starting a new call - or accepting an incoming call - will increment this counter.
   * Similarly, ending a call will decrement the count until it reaches 0.
   * If you wish to handle multiple calls scenarios make sure to keep your
   * application's list of ongoing calls in sync with this count.
   * If you only want to handle one active call at a time, treat this value as an on-off toggle.
   * Optionally throw an error if the count increases to more than 1,
   * which would mean something went wrong in the application logic.
   */
  callControl.ongoingCalls.subscribe((s) => {
    const activeCall = getActiveCall(store.getState());
    const count = activeCount(store.getState());

    logger.info(`${MODULE_NAME} - hid ongoing calls`, s);

    if (activeCall && count > s) {
      logger.info(`${MODULE_NAME} - hid ongoing calls - active call`, activeCall);
      _.times(count - s, () => {
        if (activeCall.status === 'trying'
          || activeCall.status === 'inboundProgressing'
          || activeCall.status === 'outboundProgressing'
        ) {
          activeCall.cancel(false);
        } else {
          activeCall.bye(false);
        }
      });
    }
  });

  callControl.onDisconnect.subscribe((s) => {
    logger.info(`${MODULE_NAME} - hid disconnect`, s);
  });
};

/**
 * Unsubscribes call control listeners and ends calls if there are any call sessions
 * @param callControl
 */
const hidUnsubscribe = (callControl) => {
  logger.info(`${MODULE_NAME} - hid unsubscribe`, callControl);
  callControl.muteState.unsubscribe(_.noop);
  callControl.holdState.unsubscribe(_.noop);
  callControl.swapRequest.unsubscribe(_.noop);
  callControl.ongoingCalls.unsubscribe(_.noop);
  callControl.onDisconnect.unsubscribe(_.noop);
};

/**
 * Handles setting and switching between hid devices
 * Removes any previous listeners
 * Adds subscription listeners to act on the active call session
 * @param deviceId
 * @param showSnackbar
 */
export const setCallControlByDeviceId = async (deviceId, showSnackbar = true) => {
  logger.info(`${MODULE_NAME} - setCallControlByDeviceId - device id`, deviceId);

  const deviceLabel = await getDeviceLabel(deviceId);
  logger.info(`${MODULE_NAME} - setCallControlByDeviceId - deviceLabel`, deviceLabel);

  if (deviceLabel) {
    const callControl = getHidDeviceByLabel(deviceLabel);
    logger.info(`${MODULE_NAME} - setCallControlByDeviceId - callControl`, callControl);

    const prevCallControl = store.getState().userMedia.get('hidDevice');

    // do nothing if the call control is already set
    if (prevCallControl
      && callControl
      && callControl?.device?.browserLabel === prevCallControl?.device?.browserLabel
    ) {
      return;
    }

    if (prevCallControl) {
      logger.info(`${MODULE_NAME} - setCallControlByDeviceId - unsubscribe to previous hid call control`, callControl);
      hidUnsubscribe(prevCallControl);
      const count = activeCount(store.getState());
      logger.info(`${MODULE_NAME} - setCallControlByDeviceId - remove previous calls`, count);
      _.times(count, prevCallControl.endCall);
    }

    if (callControl) {
      logger.info(`${MODULE_NAME} - setCallControlByDeviceId - switch to new call control`, callControl);

      if (showSnackbar) {
        logger.info(`${MODULE_NAME} - setCallControlByDeviceId - headset paired`);
        const $mdToast = getObject(store.getState(), '$mdToast');
        const $translate = getObject(store.getState(), '$translate');
        $mdToast.show(
          $mdToast.simple()
            .textContent($translate.instant('HEADSET_PAIRED'))
            .position('bottom left')
            .hideDelay(3000),
        );
      }

      store.dispatch(userMedia.hidDevice(callControl));
      hidSubscribe(callControl);
      const count = activeCount(store.getState());
      logger.info(`${MODULE_NAME} - setCallControlByDeviceId - start calls`, count);
      _.times(count, callControl.startCall);
    } else {
      logger.info(`${MODULE_NAME} - setCallControlByDeviceId - no matching hid device`);
      store.dispatch(userMedia.hidDevice(null));
    }
  }
};

/**
 * Adds a call to the hid call control
 * @returns {Promise<void>}
 */
export const addCall = async () => {
  const callControl = store.getState().userMedia.get('hidDevice');
  logger.info(`${MODULE_NAME} - addCall to`, callControl);

  if (callControl) {
    // Sets the device into call state.
    // Throws: If the device is locked by another softphone
    try {
      logger.info(`${MODULE_NAME} - addCall - start call`);
      await callControl.startCall();
    } catch (e) {
      logger.error(`${MODULE_NAME} - addCall - failed to start device call`);
      bugsnagClient.notify(e);
    }
  }
};

/**
 * Tests device to determines if the hid consent dialog should be shown and shows it
 * @param deviceId
 * @returns {Promise<void>}
 */
export const deviceNeedsConsent = async (deviceId) => {
  logger.info(`${MODULE_NAME} - deviceNeedsConsent - device id`, deviceId);

  const appName = store.getState().configs.get('appName');
  const token = nsToken.getDecoded();
  const firstLogin = localStorage.getItem(`${appName}-${token.user}_first-login`);

  logger.info(`${MODULE_NAME} - deviceNeedsConsent - first login`, firstLogin);

  // only ask for consent when the user has logged in before or finished the first time site tour
  if (firstLogin === 'false') {
    const devices = store.getState().userMedia.get('inputDevices');
    const device = _.find(devices, { deviceId });
    const deviceLabel = device?.label;
    logger.info(`${MODULE_NAME} - deviceNeedsConsent - device label`, deviceLabel);

    if (deviceLabel?.toLowerCase().includes('jabra')) {
      const callControl = getHidDeviceByLabel(deviceLabel);
      logger.info(`${MODULE_NAME} - deviceNeedsConsent - no call control triggers hid consent`, callControl);

      const dontAsk = localStorage.getItem(`${appName}-${token.user}_hid_dont_ask`);
      if (dontAsk !== 'true' && !callControl) {
        logger.info(`${MODULE_NAME} - deviceNeedsConsent - show hid consent`);
        store.dispatch(showHidConsent(true));
      }
    }
  }
};

/**
 * Tests for one or more HID devices to determines if the hid consent dialog should be shown and shows it
 * @returns {Promise<void>}
 */
export const devicesNeedConsent = async () => {
  logger.info(`${MODULE_NAME} - devicesNeedConsent`);

  const appName = store.getState().configs.get('appName');
  const token = nsToken.getDecoded();
  const firstLogin = localStorage.getItem(`${appName}-${token.user}_first-login`);

  logger.info(`${MODULE_NAME} - devicesNeedConsent - first login`, firstLogin);

  if (firstLogin === 'false') {
    const devices = await getMediaDevices();
    logger.info(`${MODULE_NAME} - devicesNeedConsent - devices`, devices);

    let res = await Promise.all(devices.map(async (device) => {
      const deviceLabel = device?.label;
      if (deviceLabel?.toLowerCase().includes('jabra')) {
        const callControl = getHidDeviceByLabel(deviceLabel);
        return !!callControl;
      }
      return null;
    }));

    logger.info(`${MODULE_NAME} - devicesNeedConsent - jabra devices`, res);

    // remove nulls (non hid devices)
    res = res.filter((e) => e != null);

    logger.info(`${MODULE_NAME} - devicesNeedConsent - every device has consent`, _.every(res));

    const dontAsk = localStorage.getItem(`${appName}-${token.user}_hid_dont_ask_again`);
    if (dontAsk !== 'true' && res.length && !_.every(res)) {
      logger.info(`${MODULE_NAME} - devicesNeedConsent - show hid consent`);
      store.dispatch(showHidConsent(true));
    }
  }
};

/**
 * Get HID devices and listen for changes
 * @returns {Promise<void>}
 */
export const initHid = async () => {
  logger.info(`${MODULE_NAME} - initHid`);

  try {
    const jabra = await hidInit({
      appId: 'webphone',
      appName: 'webphone',
      transport: RequestedBrowserTransport.CHROME_EXTENSION_WITH_WEB_HID_FALLBACK,
    });
    const ccFactory = new EasyCallControlFactory(jabra);

    jabra.deviceAdded.subscribe(async (addedDevice) => {
      logger.info(`${MODULE_NAME} - initHid - deviceAdded`, addedDevice);

      if (ccFactory.supportsEasyCallControl(addedDevice)) {
        // add device list to list in redux
        const callControl = await ccFactory.createMultiCallControl(addedDevice);
        const hidDevices = store.getState().userMedia.get('hidDevices');
        store.dispatch(userMedia.hidDevices([...hidDevices, callControl]));
        logger.info(`${MODULE_NAME} - initHid - devices`, [...hidDevices, callControl]);

        // get the selected input device
        const inputDeviceId = store.getState().userMedia.get('inputDevice');

        // if initDevices has been called this will be set
        // if the device matches a HID device then the hid device should be set
        if (inputDeviceId) {
          const deviceLabel = await getDeviceLabel(inputDeviceId);
          if (deviceLabel) {
            if (deviceLabel.includes(addedDevice.name) && deviceLabel.includes(addedDevice.browserLabel)) {
              logger.info(`${MODULE_NAME} - initHid - set call control by selected input device`, deviceLabel);
              setCallControlByDeviceId(inputDeviceId);
            }
          }
        }
      }
    });

    jabra.deviceRemoved.subscribe((removedDevice) => {
      let hidDevices = store.getState().userMedia.get('hidDevices');
      hidDevices = [...hidDevices];
      const index = hidDevices.findIndex(
        ({ device }) => device.id.equals(removedDevice.id),
      );

      if (index > -1) {
        hidUnsubscribe(hidDevices[index]);

        hidDevices.splice(index, 1);
        store.dispatch(userMedia.hidDevices(hidDevices));
        // unsubscribe if that was the active hid device
      }
    });
  } catch (e) {
    logger.error(`${MODULE_NAME} - initHid error`, e);
    bugsnagClient.notify(e);
    console.error(e);
  }
};

/**
 * Process devices to set redux and selected input output devices
 * @param appName
 * @param user
 * @returns {Promise<{inputDevices: *, outputDevice: (*|null), inputDevice: (*|null), outputDevices: *}>}
 */
const setDevices = async (appName, user) => {
  const devices = await userMedia.getMediaDevices();
  const inputDevice = userMedia.getDefaultAudioInput(appName, user, devices);
  const outputDevice = userMedia.getDefaultAudioOutput(appName, user, devices);
  const inputDevices = userMedia.getFilteredAudioInput(devices);
  const outputDevices = userMedia.getFilteredAudioOutput(devices);
  const outputDeviceRinger = userMedia.getDefaultAudioOutputRinger(appName, user, devices);


  store.dispatch(userMedia.inputDevice(inputDevice?.deviceId));
  store.dispatch(userMedia.inputDevices(inputDevices));
  store.dispatch(userMedia.outputDevice(outputDevice?.deviceId));
  store.dispatch(userMedia.outputDevices(outputDevices));
  store.dispatch(userMedia.outputDeviceRinger(outputDeviceRinger?.deviceId));
  updateElementSinkId();

  localStorage.setItem(`${appName}-${user}_inputAudioDevice`, inputDevice?.deviceId);
  localStorage.setItem(`${appName}-${user}_outputAudioDevice`, outputDevice?.deviceId);
  localStorage.setItem(`${appName}-${user}_outputAudioDeviceRinger`, outputDeviceRinger?.deviceId);


  logger.info(`${MODULE_NAME} - initDevices - devices`, devices);
  logger.info(`${MODULE_NAME} - initDevices - inputDevice`, inputDevice);
  logger.info(`${MODULE_NAME} - initDevices - outputDevice`, outputDevice);
  logger.info(`${MODULE_NAME} - initDevices - inputDevices`, inputDevices);
  logger.info(`${MODULE_NAME} - initDevices - outputDevices`, outputDevices);
  logger.info(`${MODULE_NAME} - initDevices - outputDeviceRinger`, outputDeviceRinger);


  return {
    inputDevice,
    inputDevices,
    outputDevice,
    outputDevices,
    outputDeviceRinger,
  };
};

/**
 * Adds a flag that indicates if the device is paired or not
 * @returns {Promise<void>}
 */
const updateDeviceLabels = async () => {
  const inputDevices = store.getState().userMedia.get('inputDevices');
  const outputDevices = store.getState().userMedia.get('outputDevices');

  const updatedInput = inputDevices.map((device) => {
    const callControl = getHidDeviceByLabel(device?.label);
    return {
      deviceId: device.deviceId,
      groupId: device.groupId,
      kind: device.kind,
      label: device.label,
      paired: !!callControl,
    };
  });

  const updatedOutput = outputDevices.map((device) => {
    const callControl = getHidDeviceByLabel(device?.label);
    return {
      deviceId: device.deviceId,
      groupId: device.groupId,
      kind: device.kind,
      label: device.label,
      paired: !!callControl,
    };
  });

  store.dispatch(userMedia.inputDevices(updatedInput));
  store.dispatch(userMedia.outputDevices(updatedOutput));
};

/**
 * handle device changes, this happens when there's a new device plugin or removed
 * Had to debounce this function, when disconnecting or connecting a device there are actually two devices
 * being added and removed, one input and one output
 */
const handleDeviceChanges = _.debounce(async (appName, user) => {
  logger.info(`${MODULE_NAME} - handleDeviceChanges`);
  const iDevices = store.getState().userMedia.get('inputDevices');
  const oDevices = store.getState().userMedia.get('outputDevices');

  const { inputDevice, inputDevices, outputDevices } = await setDevices(appName, user);

  const existingDevices = [...iDevices, ...oDevices];
  const newDevices = [...inputDevices, ...outputDevices];

  if ('hid' in navigator) {
    if (existingDevices.length !== newDevices.length) {
      if (newDevices.length < existingDevices.length) {
        await setCallControlByDeviceId(inputDevice?.deviceId);
      } else {
        // get the diff of the devices
        // this will return the input and output differences
        const diff = _.differenceBy(newDevices, existingDevices, 'deviceId');

        if (diff.length) {
          const deviceLabel = await getDeviceLabel(diff[0].deviceId);

          if (deviceLabel?.toLowerCase().includes('jabra')) {
            // delay getting the hid device/call control
            // max tries is 10 seconds
            let callControl;
            let triesLeft = 25;
            await new Promise((resolve) => {
              const interval = setInterval(() => {
                callControl = getHidDeviceByLabel(deviceLabel);
                if (callControl || !triesLeft) {
                  resolve();
                  clearInterval(interval);
                }
                triesLeft -= 1;
              }, 200);
            }); // wait for hid device to be added

            // auto select new hid device
            if (callControl) {
              const newInput = _.find(diff, { kind: 'audioinput' });
              const newOutput = _.find(diff, { kind: 'audiooutput' });
              store.dispatch(userMedia.inputDevice(_.get(newInput, 'deviceId')));
              store.dispatch(userMedia.outputDevice(_.get(newOutput, 'deviceId')));
              localStorage.setItem(`${appName}-${user}_inputAudioDevice`, _.get(newInput, 'deviceId'));
              localStorage.setItem(`${appName}-${user}_outputAudioDevice`, _.get(newOutput, 'deviceId'));
              updateElementSinkId();

              await setCallControlByDeviceId(_.get(newInput, 'deviceId'), false);

              const $mdToast = getObject(store.getState(), '$mdToast');
              const $translate = getObject(store.getState(), '$translate');
              $mdToast.show(
                $mdToast.simple()
                  .textContent($translate.instant('HEADSET_CONNECTED_AND_PAIRED'))
                  .position('bottom left')
                  .hideDelay(3000),
              );
              logger.info(`${MODULE_NAME} - handleDeviceChanges - headset connected and paired`);
            }

            await deviceNeedsConsent(diff[0].deviceId);
            await updateDeviceLabels();
          }
        }
      }
    }
  }
}, 1000);

/**
 * Get browser devices and sync with HID devices
 * @param appName
 * @param user
 * @returns {Promise<void>}
 */
export const initDevices = async (appName, user) => {
  logger.info(`${MODULE_NAME} - initDevices`);

  try {
    navigator.mediaDevices.ondevicechange = () => handleDeviceChanges(appName, user);

    const { inputDevice } = await setDevices(appName, user);

    if ('hid' in navigator) {
      const syncHid = async () => {
        await initHid();

        let triesLeft = 25;
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            const hidDevices = store.getState().userMedia.get('hidDevices');
            if (hidDevices?.length || !triesLeft) {
              clearInterval(interval);
              setTimeout(resolve, 500);
            }
            triesLeft -= 1;
          }, 200);
        }); // wait for hid device to be added

        const deviceLabel = await getDeviceLabel(inputDevice?.deviceId);
        if (deviceLabel?.toLowerCase().includes('jabra')) {
          await setCallControlByDeviceId(inputDevice?.deviceId);
        }

        await devicesNeedConsent();
        await updateDeviceLabels();
      };
      syncHid();
    }
  } catch (e) {
    logger.error(`${MODULE_NAME} - initDevices error`, e);
    bugsnagClient.notify(e);
    console.error(e);
  }
};

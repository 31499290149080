import React from 'react';
import Chart from 'react-google-charts'; // eslint-disable-line no-unused-vars

export default class DayStatsComponent extends React.Component {
  render() {
    if (this.props.dayStats.length === 0) {
      return null;
    }

    return (
      <div>
        <Chart
          chartType="AreaChart"
          loader={<div>Loading Chart</div>}
          data={this.props.dayStats}
          options={{
            chartArea: {
              width: this.props.chartWidth,
              height: this.props.chartHeight,
              top: '5',
              left: '5%',
            },
            width: '100%',
            height: this.props.height,
            legend: 'none',
            series: {
              0: { targetAxisIndex: 1 },
            },
            vAxis: {
              viewWindowMode: 'explicit',
              viewWindow: {
                min: 0,
              },
            },
            hAxis: {
              textStyle: {
                fontSize: 11,
              },
            },
          }}
        />
      </div>
    );
  }
}

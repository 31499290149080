import './styles.css';
import template from './template.html';
import { updateUser } from '../../core/user';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$translate',
  '$mdToast',
  '$location',
  'moment',
  '$state',
];

function controller(
  $ngRedux,
  $translate,
  $mdToast,
  $location,
  moment,
  $state,
) {
  let unsubscribe;
  /**
     *
     */
  this.$onInit = () => {
    // bind redux to component
    unsubscribe = $ngRedux.connect((state) =>
    // map redux to component
      ({
        user: state.user,
        local: state.configs.get('portalLocalizationMaster'),
      }))((state) => {
      // get localization ui_config
      if (state.local) {
        const ui_localized = state.local.toLowerCase();
        switch (ui_localized) {
          case 'yes':
            this.localized = 'yes';
            break;
          case 'y':
            this.localized = 'yes';
            break;
          case 'true':
            this.localized = 'yes';
            break;
          case '1':
            this.localized = 'yes';
            break;
          default:
            this.localized = 'no';
        }
      }
      // update component state
      if (state.user) {
        this.user = state.user;
        const userLang = this.user.language.toLowerCase();
        $translate.use(userLang);

        switch (userLang) {
          case 'en_us':
            moment.locale('en-ie');
            break;
          case 'es_mx':
            moment.locale('es');
            break;
          case 'fr_ca':
            moment.locale('fr-ca');
            break;
          default:
            moment.locale('en-ie');
        }
      }
    });

    // exclude Testing langauges
    const netDomain = $location.$$host.indexOf('netsapiens.com');
    if (netDomain === -1) {
      this.testLanguage = true;
    }
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param langKey
     */
  this.changeLanguage = function (langKey) {
    switch (langKey) {
      case 'en_us':
        moment.locale('en-ie');
        break;
      case 'es_mx':
        moment.locale('es');
        break;
      case 'fr_ca':
        moment.locale('fr-ca');
        break;
      default:
        moment.locale('en-ie');
    }
    $translate.use(langKey);
    this.user.language = langKey;
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('LANGUAGE_SAVED'))
        .position('bottom left')
        .hideDelay(1500),
    );
    $ngRedux.dispatch(updateUser(this.user));
    $state.reload();
  };

  this.checkLanguage = (userLanguage) => userLanguage;
}

import Touchtone from 'touchtone';
import baudio from 'webaudio';

import './styles.css';
import template from './template.html';
import * as sessionActions from '../../core/sessions';
import * as navActions from '../../core/state-history';
import { getKey } from '../../core/numpad';

export default {
  template,
  controller,
  bindings: {
    context: '@',
    onPhone: '&',
  },
};

controller.$inject = [
  '$ngRedux',
  '$mdColors',
  '$scope',
  '$state',
];

function controller(
  $ngRedux,
  $mdColors,
  $scope,
  $state,
) {
  // let audioContext;
  const self = this;
  let dtmfSender;
  let isZero = false;
  const touchtone = new Touchtone({ duration: 0.1, pause: 0.05, volume: 0.1 });
  let unsubscribe;
  let zeroTimeout = null;

  this.background = $mdColors.getThemeColor('primary');
  this.numbers = '';

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => {
      let minHeight;
      let maxHeight;
      let numHeight;
      switch (this.context) {
        case 'modal':
          minHeight = '450px';
          maxHeight = '450px';
          numHeight = `${state.screenSize.height - 330}px`;
          break;
        default:
          numHeight = `${state.screenSize.height - 28}px`;
      }

      return {
        activeCall: sessionActions.getActiveCall(state),
        activeCount: sessionActions.activeCount(state),
        minHeight,
        numHeight,
        maxHeight,
        screenSize: state.screenSize,
      };
    })(this);

    // setup dtmf
    window.addEventListener('keyup', keyUp);

    if (self.activeCall.session.sessionDescriptionHandler) {
      const selfPC = self.activeCall.session.sessionDescriptionHandler.peerConnection;
      if (selfPC.getSenders) {
        dtmfSender = selfPC.getSenders()[0].dtmf;
      } else if (selfPC.createDTMFSender) {
        const activeStream = self.activeCall.session.getLocalStreams()[0];
        dtmfSender = selfPC.createDTMFSender(activeStream.getAudioTracks()[0]);
      }
    }
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
    window.removeEventListener('keyup', keyUp);
  };

  /**
     *
     */
  this.end = () => {
    if (this.activeCall.status === 'inboundProgressing'
            || this.activeCall.status === 'outboundProgressing'
    ) {
      this.activeCall.cancel();
    } else {
      this.activeCall.bye();
    }
  };

  /**
     *
     */
  this.closeNumPad = () => {
    this.onPhone();
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.callStackBack());
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
    $state.go(lastOnStack.name, lastOnStack.params);
  };

  /**
     *
     * @param e
     */
  function keyUp(e) {
    const num = getKey(e);
    if (num !== null) {
      self.numbers += `${num}`;
      sendTone(num);
    }
    $scope.$apply();
  }

  /**
     *
     * @param key
     */
  this.mouseDown = (key) => {
    let num = key;

    if (isFinite(num)) {
      num = Number.parseInt(num);
    }

    if (num === 0) {
      isZero = true;
      zeroTimeout = setTimeout(() => {
        isZero = false;
        this.numbers += '+';
        sendTone('+');
      }, 1000);
    }
  };

  /**
     *
     * @param key
     */
  this.mouseUp = (key) => {
    let num = key;
    if (isFinite(num)) {
      num = Number.parseInt(num);
    }
    if (num === 0 && !isZero) {
      return;
    }
    clearTimeout(zeroTimeout);

    this.numbers += `${num}`;
    sendTone(num);
  };

  /**
     *
     * @param tone
     */
  function sendTone(tone) {
    if (dtmfSender && dtmfSender.insertDTMF) {
      // console.log("insertDTMF "+ tone);
      dtmfSender.insertDTMF(tone, 100, 70);
      touchtone.press(tone);
      baudio(touchtone.play()).play();
    }
  }
}

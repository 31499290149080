import { Map } from 'immutable';

/** ********************************************
 * Redux constants
 ******************************************** */

export const CALL_STACK = 'core/state-history/CALL_STACK';
export const CURRENT_STATE = 'core/state-history/CURRENT_STATE';
export const INIT = 'core/state-history/INIT';
export const NAV_STACK = 'core/state-history/NAV_STACK';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  callStack: [],
  currentState: {},
  init: false,
  navStack: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CALL_STACK:
      return state.set('callStack', action.payload);
    case CURRENT_STATE:
      return state.set('currentState', action.payload);
    case INIT:
      return state.set('init', action.payload);
    case NAV_STACK:
      return state.set('navStack', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {function(*, *)}
 */
export const callStackBack = () => (dispatch, getState) => {
  const stack = getState().stateHistory.get('callStack');
  dispatch({
    type: CALL_STACK,
    payload: stack.slice(0, -1),
  });
};

/**
 *
 * @param {{name: *, params: *, fromName: *, fromParams: *}} payload
 * @return {function(*, *)}
 */
export const callStackPush = (payload) => (dispatch, getState) => {
  const stack = getState().stateHistory.get('callStack');
  stack.push(payload);
  dispatch({
    type: CALL_STACK,
    payload: stack,
  });
};

/**
 *
 * @param {{name: *, params: *, fromName: *, fromParams: *}} payload
 * @return {function(*)}
 */
export const callStackRebase = (payload) => (dispatch) => {
  dispatch({
    type: CALL_STACK,
    payload: [payload],
  });
};

export const clearStack = (stackType) => ({
  type: stackType === 'navStack' ? NAV_STACK : CALL_STACK,
  payload: [],
});

/**
 *
 * @param {string} payload - expects 'callStack' or 'navStack'
 * @return {function(*, *)}
 */
export const clearToBase = (payload) => (dispatch, getState) => {
  if (payload === 'callStack') {
    const stack = getState().stateHistory.get('callStack');
    dispatch({
      type: CALL_STACK,
      payload: [stack[0]],
    });
  } else if (payload === 'navStack') {
    const stack = getState().stateHistory.get('navStack');
    dispatch({
      type: NAV_STACK,
      payload: [stack[0]],
    });
  }
};

/**
 *
 * @param {{name: *, params: *, fromName: *, fromParams: *}} payload
 * @return {{type: string, payload: *}}
 */
export const currentState = (payload) => ({
  type: CURRENT_STATE,
  payload,
});

/**
 *
 * @return {function(*, *)}
 */
export const navStackBack = () => (dispatch, getState) => {
  const stack = getState().stateHistory.get('navStack');
  dispatch({
    type: NAV_STACK,
    payload: stack.slice(0, -1),
  });
};

/**
 *
 * {{name: *, params: *, fromName: *, fromParams: *}} payload
 * @return {function(*, *)}
 */
export const navStackPush = (payload) => (dispatch, getState) => {
  const stack = getState().stateHistory.get('navStack');
  stack.push(payload);
  dispatch({
    type: NAV_STACK,
    payload: stack,
  });
};

/**
 *
 * @param payload
 * @return {{type: string, payload: [null]}}
 */
export const navStackRebase = (payload) => ({
  type: NAV_STACK,
  payload: [payload],
});

/**
 *
 * @return {function(*, *)}
 */
export const startTracking = () => ({
  type: INIT,
  payload: true,
});

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @return {{name: *, params: *, fromName: *, fromParams: *}}
 */
export const getCurrentState = (state) => state.stateHistory.get('currentState');

/**
 *
 * @param {object} state
 * @param {string} stackName - expects 'callStack' or 'navStack'
 */

export const getLastOnStack = (state, stackName) => {
  if (stackName === 'callStack') {
    const callStack = state.stateHistory.get('callStack');
    return callStack[callStack.length - 1];
  } if (stackName === 'navStack') {
    const navStack = state.stateHistory.get('navStack');
    return navStack[navStack.length - 1];
  }
};

/**
 *
 * @param state
 * @param stackName
 * @param {int} back
 */
export const getPreviousStackState = (state, stackName, back) => {
  if (stackName === 'callStack') {
    const callStack = state.stateHistory.get('callStack');
    return callStack[callStack.length - (back + 1)];
  } if (stackName === 'navStack') {
    const navStack = state.stateHistory.get('navStack');
    return navStack[navStack.length - (back + 1)];
  }
};

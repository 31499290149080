import moment from 'moment-timezone';

export default () => (time, dateFormat, timeFormat, zone) => {
  const tzTime = moment.tz(time, zone);

  if (!timeFormat) {
    return moment(tzTime._d).format('M/D/YY');
  }
  const formatTime = timeFormat === 'yes' ? 'h:mm' : 'H:mm';
  const keepAM = timeFormat === 'yes' ? 'a' : '';

  switch (dateFormat) {
    case 'MDY':
      return moment(tzTime._d).format(`M/D/YY ${formatTime} ${keepAM}`);
    case 'YMD':
      return moment(tzTime._d).format(`YY/M/D ${formatTime} ${keepAM}`);
    case 'DMY':
      return moment(tzTime._d).format(`D/M/YY ${formatTime} ${keepAM}`);
    default:
      return moment(tzTime._d).format(`M/D/YY ${formatTime} ${keepAM}`);
  }
};

import { put, select, takeEvery } from 'redux-saga/effects';

import { ACTIVE_COUNT } from '../sessions';
import { RESIZE } from '../screen-size';
import * as angularActions from '../angular';
import * as cardActions from '../card-management';
import * as navActions from '../state-history';

let lastDisplay;

export default function* () {
  yield takeEvery(ACTIVE_COUNT, _handleCallCount);
  yield takeEvery(RESIZE, _handleResize);
}

/**
 * This saga handles call counts
 * @private
 */
const _handleCallCount = function* () {
  const activeCount = yield select((state) => state.sessions.get('activeCount'));
  const isCompact = yield select((state) => state.screenSize.display === 'compact');

  if (activeCount === 0) {
    if (isCompact) {
      // clear the call stack
      yield put(navActions.clearStack('callStack'));
      // get last nav state
      const lastOnStack = yield select((state) => navActions.getLastOnStack(state, 'navStack'));

      // [WP-625] Transition from call to chat session loses reference to card data (compact)
      if (lastOnStack.name === 'wrapper.chat-session') {
        // set the expandedId
        yield put(cardActions.setExpanded(lastOnStack.params.card.id));
      }

      // go to last nav state
      const $state = yield select((state) => angularActions.getObject(state, '$state'));
      $state.go(lastOnStack.name, lastOnStack.params);
    }
  }
};

/**
 *
 * @private
 */
const _handleResize = function* () {
  const display = yield select((state) => state.screenSize.display);

  // update if the display has changed
  if (lastDisplay !== display) {
    lastDisplay = display;

    // get the current state
    const currentState = yield select((state) => navActions.getCurrentState(state));

    const $state = yield select((state) => angularActions.getObject(state, '$state'));

    // compact to fullscreen
    // this changes the state to a card for specific routes
    if (display === 'fullscreen') {
      let navLastOnStack;

      switch (currentState.name) {
        // CHAT
        case 'wrapper.chat-new':

          // go back to the state before the chat session
          yield put(navActions.navStackBack());

          // get last on stack after navStackBack action
          navLastOnStack = yield select(
            (state) => navActions.getLastOnStack(state, 'navStack'),
          );

          $state.go(navLastOnStack.name, navLastOnStack.params);
          break;
        case 'wrapper.chat-session':
          // go back to the state before the chat session
          yield put(navActions.navStackBack());

          // get last on stack after navStackBack action
          navLastOnStack = yield select(
            (state) => navActions.getLastOnStack(state, 'navStack'),
          );

          $state.go(navLastOnStack.name, navLastOnStack.params);
          break;
          // PHONE
        case 'wrapper.phone':
        case 'wrapper.num-pad':
        case 'wrapper.dialer':
          // test if the dialer (new-call) was opened in dialer
          if (currentState.name === 'wrapper.dialer'
                        && currentState.params.workflow === 'new-call'
          ) {
            yield put(navActions.navStackBack());
          } else {
            // always clear the call stack back to phone view
            yield put(navActions.clearToBase('callStack'));
          }

          // get the last on nav stack
          navLastOnStack = yield select(
            (state) => navActions.getLastOnStack(state, 'navStack'),
          );

          $state.go(navLastOnStack.name, navLastOnStack.params);
          break;
        case 'wrapper.contact-list':
        case 'wrapper.contact-details':
          if (currentState.params && currentState.params.workflow) {
            // always clear the call stack back to phone view
            yield put(navActions.clearToBase('callStack'));

            // get the last on nav stack
            navLastOnStack = yield select(
              (state) => navActions.getLastOnStack(state, 'navStack'),
            );

            $state.go(navLastOnStack.name, navLastOnStack.params);
          }
          break;
      }
    }
    // fullscreen to compact
    // this changes an expanded card to a state for specific routes
    else {
      // get expanded id
      const expandedId = yield select((state) => cardActions.getExpandedId(state));

      // no need to handle transition if no cards are expanded
      if (expandedId) {
        // get the exapnded card
        const card = yield select((state) => cardActions.getCard(state, expandedId));

        switch (card.type) {
          // CHAT
          case 'chat':
            // push chat session state to stack
            yield put(
              navActions.navStackPush({
                name: 'wrapper.chat-session',
                params: { card },
                fromName: currentState.name,
                fromParams: currentState.params,
              }),
            );

            // go to chat session
            $state.go('wrapper.chat-session', { card });
            break;
          case 'new-chat':
            // push chat session state to stack
            yield put(
              navActions.navStackPush({
                name: 'wrapper.chat-new',
                params: { card },
                fromName: currentState.name,
                fromParams: currentState.params,
              }),
            );

            // go to chat session
            $state.go('wrapper.chat-new', { card });
            break;
        }
      }
    }
  }
};

import './styles.css';
import template from './template.html';
import {
  getAgentQueues, getAllQueued, getAllResponders, getLoading, updateQueueEntry, createAgentLog, postAgentStatus, getAgentType,
} from '../../../../core/agent-center';
import { navStackPush, getCurrentState } from '../../../../core/state-history';
import * as cardActions from '../../../../core/card-management';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];
function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe = null;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      queues: getAgentQueues(state),
      queued: getAllQueued(state),
      responders: getAllResponders(state),
      agenttype: getAgentType(state),
      screenSize: state.screenSize,
      configs: state.configs,
      loaded: !getLoading(state),
    }))((state) => {
      this.queues = state.queues;
      this.responders = state.responders;
      this.screenSize = state.screenSize;
      this.configs = state.configs;
      this.dataset.refresh(state.queues);
      this.loaded = state.loaded;
      this.queued = state.queued;
      this.allowGranularLogin = state.configs.get('callQueueGranularLogin') === 'yes';
      this.devicePostFix = `${this.configs.get('devicePostFix')}@`;
      this.agentDevice = state.agenttype.includes(this.devicePostFix) ? this.configs.get('userId') + this.configs.get('devicePostFix') : this.configs.get('userId');
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.dataset = {
    items: [],
    refresh: (list) => {
      this.dataset.items = list;
    },
    getItemAtIndex: (index) => this.dataset.items[index],
    getLength: () => this.dataset.items.length,
  };

  this.navPush = (queue) => {
    const currentState = getCurrentState($ngRedux.getState());
    if (this.screenSize.display === 'compact') {
      console.log('navPush queue', queue); // eslint-disable-line no-console
      $ngRedux.dispatch(
        navStackPush({
          name: 'wrapper.agent-queue-details',
          params: { queue },
          fromName: currentState.name,
          fromParams: currentState.params,
        }),
      );
      $state.go('wrapper.agent-queue-details', { queue });
    } else {
      const cardMeta = {
        type: 'queue',
        queueId: queue.queue_name,
      };

      console.log('create Queue\'s card', queue); // eslint-disable-line no-console
      let cardId = cardActions.getCardId(cardMeta);
      if (!cardId) {
        $ngRedux.dispatch(cardActions.newCard(cardMeta));
        cardId = cardActions.getCardId(cardMeta);
      }
      $ngRedux.dispatch(cardActions.setExpanded(cardId));
    }
  };

  this.agentLoggedin = (queue) => {
    if (queue === undefined) { return false; }
    const specificresponders = this.responders.find((resp) => resp.queue_name === queue.queue_name);
    if (!specificresponders) { return false; }
    const responder = specificresponders.responders.find((responder) => responder.device === this.agentDevice);
    return responder.entry_option === 'immediate' || responder.entry_status === 'available';
  };

  this.waitingCount = (queue) => {
    if (this.queued[queue]) {
      return this.queued[queue].queued.length;
    }
    return 0;
  };

  this.loginQueue = ($event, queue) => {
    $event.stopPropagation();
    const entry = {
      queue_name: queue.queue_name,
      option: 'immediate',
      action: 'make_im',
      toAsQueue: 'yes',
    };
    $ngRedux.dispatch(updateQueueEntry(entry));
    $ngRedux.dispatch(createAgentLog('Auto'));
    $ngRedux.dispatch(postAgentStatus(''));
  };

  this.logoutQueue = ($event, queue) => {
    $event.stopPropagation();
    // $ngRedux.dispatch(updateQueueEntry({
    //     queue_name: queue.queue_name,
    //     option: 'manual',
    //     action: 'update',
    // }));
    $ngRedux.dispatch(updateQueueEntry({
      queue_name: queue.queue_name,
      action: 'make_un',
      toAsQueue: 'yes',
    }));
  };
}

import { eventChannel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';

import { bugsnagClient } from '../../bugsnag';
import { setNetworkConnected } from './redux';
import * as angularActions from '../angular';

const _sendError = (message) => {
  // the loop handles offline cases
  // the error will send after the browser is back online
  let interval;
  interval = setInterval(() => {
    if (navigator.onLine) {
      clearInterval(interval);
    }
  }, 10000);
};

export default function* networkConnection() {
  const channel = eventChannel((emitter) => {
    let lastState = null;

    const interval = setInterval(() => {
      if (lastState !== navigator.onLine) {
        lastState = navigator.onLine;
        emitter(lastState);
      }
    }, 1000);

    // unsubscribe method
    return () => {
      clearInterval(interval);
    };
  });

  while (true) {
    const res = yield take(channel);
    yield put(setNetworkConnected(res));
    if (res === false) {
      _sendError('Network connection error');

      // const $mdToast = yield select(state => angularActions.getObject(state, '$mdToast'));
      const $mdToast = yield select((state) => angularActions.getObject(state, '$mdToast'));
      const $translate = yield select((state) => angularActions.getObject(state, '$translate'));

      $mdToast.show(
        $mdToast.simple()
          .textContent($translate.instant('WEBPHONE_NETWORK_LOST_CONNECTION_TOAST'))
          .position('bottom left')
          .hideDelay(5000),
      );
    }
  }
}

import './styles.css';
import template from './template.html';
import * as navActions from '../../core/state-history';
import { expandMenu } from '../../core/extension';
import * as sessions from '../../core/sessions';
import * as cardActions from '../../core/card-management';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdColors',
  '$ngRedux',
  '$state',
];

function controller(
  $mdColors,
  $ngRedux,
  $state,
) {
  let unsubscribe = null;

  this.$onInit = () => {
    this.background = $mdColors.getThemeColor('primary-600');

    unsubscribe = $ngRedux.connect((state) => ({
      allowCall: sessions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-677]
      screenSize: state.screenSize,
      allowChat: state.configs.get('portalChatSMS'),
    }))(this);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     * Collapses extension container
     */
  this.expandMenu = () => {
    $ngRedux.dispatch(expandMenu());
  };

  /**
     * Creates dialer card, sets the card to be expanded and if compact changes state.
     */
  this.focusCall = () => {
    $ngRedux.dispatch(cardActions.newCard({ type: 'dialer' }));
    const cardId = cardActions.getCardId({ type: 'dialer' });
    $ngRedux.dispatch(cardActions.setExpanded(cardId));

    if (this.screenSize.display === 'compact') {
      const card = cardActions.getCard($ngRedux.getState(), cardId);
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');

      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.dialer',
          params: { card, workflow: 'new-call' },
          fromName: lastOnStack.fromName,
          fromParams: lastOnStack.fromParams,
        }),
      );
      $state.go('wrapper.dialer', { card, workflow: 'new-call' });
    }
    $ngRedux.dispatch(expandMenu());
  };

  /**
     * Rebases the nav stack with the new route
     * @param {string} name - name of route
     */
  this.rebase = (name) => {
    $ngRedux.dispatch(
      navActions.navStackRebase({
        name,
        params: null,
        fromName: null,
        fromParams: null,
      }),
    );
    $ngRedux.dispatch(expandMenu());
  };
}

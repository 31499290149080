import './styles.css';
import template from './template.html';
import {
  getRingtones, getToneName, toneName, getSelectedCallWaiting, setSelectedCallWaiting,
} from '../../core/ringtones';
import * as navActions from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
  '$mdToast',
  '$translate',
];
function controller(
  $ngRedux,
  $state,
  $mdToast,
  $translate,
) {
  const audioElements = [];
  let playingIndex;
  let unsubscribe;
  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
      ringtone: getToneName(state),
      ringtones: getRingtones(state),
      callwaiting: getSelectedCallWaiting(state),
    }))((state) => {
      this.screenSize = state.screenSize;
      this.ringtone = state.ringtone;
      this.ringtones = state.ringtones;
      this.callwaiting = state.callwaiting;
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackBack());
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    $state.go(lastOnStack.name, lastOnStack.params);
  };

  /**
     *
     */
  this.cancel = function () {
    this.navBack();
  };

  /**
     *
     */
  this.save = () => {
    if (this.callwaiting == 'undefined') this.callwaiting = 'one';
    $ngRedux.dispatch(setSelectedCallWaiting(this.callwaiting));

    this.navBack();
  };
}

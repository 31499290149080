/** ********************************************
 * Redux constants
 ******************************************** */

export const NETWORK_CONNECTED = 'network/IS_NETWORK_CONNECTED';

/** ********************************************
 * reducer
 ******************************************** */

const initialState = {
  networkConnected: null,
};

export default (state = initialState, action) => {
  if (action.type === NETWORK_CONNECTED) {
    return { ...state, networkConnected: action.payload };
  }
  return state;
};

/** ********************************************
 * actions
 ******************************************** */

/**
 *
 * @param isConnected
 * @return {{type, payload: *}}
 */
export const setNetworkConnected = (isConnected) => ({
  type: NETWORK_CONNECTED,
  payload: isConnected,
});

/** ********************************************
 * selectors
 ******************************************** */

export const selectNetworkConnected = (state) => state.network.networkConnected;

import './styles.css';
import template from './template.html';
import { getRingtones, getToneName, toneName } from '../../core/ringtones';
import * as navActions from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
  '$mdToast',
  '$translate',
];
function controller(
  $ngRedux,
  $state,
  $mdToast,
  $translate,
) {
  let audioElements = [];
  let playingIndex;
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      outputDevice: state.userMedia.get('outputDevice'),
      outputDeviceRinger: state.userMedia.get('outputDeviceRinger'),
      screenSize: state.screenSize,
      ringtone: getToneName(state),
      ringtones: getRingtones(state),
    }))((state) => {
      this.screenSize = state.screenSize;
      this.ringtone = state.ringtone;
      this.ringtones = state.ringtones;

      this.ringtones.forEach((tone) => {
        const elem = document.createElement('audio');
        elem.src = tone.path;
        elem.pause();
        if (state.outputDeviceRinger) {
          elem.setSinkId(state.outputDeviceRinger);
        }
        else if (state.outputDevice) {
          elem.setSinkId(state.outputDevice);
        }
        audioElements.push(elem);
      });
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    if (playingIndex !== undefined) {
      stopAudio(playingIndex);
    }
    unsubscribe();
    audioElements = undefined;
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackBack());
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    $state.go(lastOnStack.name, lastOnStack.params);
  };

  /**
     *
     */
  this.cancel = function () {
    // stop audio if any
    stopAudio(playingIndex);

    this.navBack();
  };

  /**
     *
     * @param i
     */
  this.play = (i) => {
    // stop previous audio if any
    stopAudio(playingIndex);

    // assign new index
    playingIndex = i;

    // play selected audio
    audioElements[i].play();

    // limit play time to 1 second
    setTimeout(() => {
      if (i !== undefined
              && audioElements
              && audioElements[i]
              && audioElements[i].pause
      ) {
        audioElements[i].pause();
        audioElements[i].currentTime = 0;
      }
    }, 1000);
  };

  /**
     *
     */
  this.save = () => {
    // stop audio if any
    stopAudio(playingIndex);

    // shows notification
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('RINGTONE_SAVED'))
        .position('bottom left')
        .hideDelay(1500),
    );

    $ngRedux.dispatch(toneName(this.ringtone));

    this.navBack();
  };

  /**
     *
     * @param i
     */
  function stopAudio(i) {
    if (i !== undefined
          && audioElements
          && audioElements[i]
          && audioElements[i].pause
    ) {
      audioElements[i].pause();
      audioElements[i].currentTime = 0;
      playingIndex = undefined;
    }
  }
}

import nsToken from '@netsapiens/netsapiens-js/dist/token';
import template from './template.html';
import * as navActions from '../../core/state-history';
import {
  getResponders, getQueued, prioritizeQueued, transferQueued,
} from '../../core/agent-center';

export default {
  template,
  controller,
  bindings: {
    queue: '@', // target contact's id
  },
};

controller.$inject = [
  '$mdDialog',
  '$mdToast',
  '$ngRedux',
  '$state',
  '$translate',
];

function controller(
  $mdDialog,
  $mdToast,
  $ngRedux,
  $state,
) {
  let unsubscribe;
  const decodedToken = nsToken.getDecoded();

  /**
     *
     */
  this.$onInit = () => {
    // because this component is used by routes
    // and a modal it needs to handle props and params
    // normalize bindings and state params
    this.queue = $state.params.queue;

    unsubscribe = $ngRedux.connect((state) => ({
      responders: getResponders(state, this.queue.queue_name),
      queued: getQueued(state, this.queue.queue_name),
      lastOnStack: navActions.getLastOnStack(state, 'navStack'),
      screenSize: state.screenSize,
      configs: state.configs,
    }))((state) => {
      this.responders.refresh(state.responders);
      this.queued.refresh(state.queued);
      this.lastOnStack = state.lastOnStack;
      this.screenSize = state.screenSize;
      this.configs = state.configs;
      this.isSupervisor = state.configs.get('portalMenuShowCallCenterAgentSupervisor') === 'yes' || decodedToken.user_scope === 'Call Center Supervisor';
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackBack());
    $state.go(this.lastOnStack.name, this.lastOnStack.params);
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.responders = {
    items: [],
    refresh: (list) => {
      this.responders.items = list;
    },
    getItemAtIndex: (index) => this.responders.items[index],
    getLength: () => this.responders.items.length,
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.queued = {
    items: [],
    refresh: (list) => {
      this.queued.items = list;
    },
    getItemAtIndex: (index) => this.queued.items[index],
    getLength: () => this.queued.items.length,
  };

  this.prioritizeQueued = (queue) => {
    $ngRedux.dispatch(prioritizeQueued(queue));
  };

  this.pickUpQueued = (queue) => {
    $ngRedux.dispatch(transferQueued(queue.session_key));
  };

  this.showContacts = (workflow, queue) => {
    if (this.context === 'modal') {
      this.onContacts({
        workflow,
        queueKey: queue.session_key,
        domain: queue.domain
      });
    } else {
      const currentState = navActions.getLastOnStack($ngRedux.getState(), 'callStack');

      let options = {
        name: 'wrapper.contact-list',
        params: {
          workflow,
          queueKey: queue.session_key,
          domain: queue.domain
        }
      };
      if (currentState){
        options.fromName = currentState.name;
        options.fromParams = currentState.params;
      }

      $ngRedux.dispatch(navActions.callStackPush());

      $state.go('wrapper.contact-list', options.params);
    }
  };
}

import template from './template.html';
import * as navActions from '../../core/state-history';
import { selectExtensionMenu } from '../../core/extension';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdColors',
  '$ngRedux',
];
function controller(
  $mdColors,
  $ngRedux,
) {
  let unsubscribe;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      currentState: navActions.getCurrentState(state),
      display: state.screenSize.display,
      extensionMenu: selectExtensionMenu(state),
      screenSize: state.screenSize,
    }))((state) => {
      switch (state.currentState.name) {

        case 'wrapper.chat-new':
        case 'wrapper.dialer':
        case 'wrapper.num-pad':
        case 'wrapper.phone':
        case 'wrapper.user-details':
          this.showCompactNav = false;
          break;
        default:
          this.showCompactNav = true;
      }

      this.currentState = state.currentState;
      this.display = state.display;
      this.extensionMenu = state.extensionMenu;
      this.screenSize = state.screenSize;

      // Hide fab speed dial on pages with user actions and compact view
      if (state.screenSize.display === 'compact') {
        switch (this.currentState.name) {
          case 'wrapper.agent-queue-details':
          case 'wrapper.answering-rule-list':
          case 'wrapper.answering-rule-details':
          case 'wrapper.call-details':
          case 'wrapper.call-park-list':
          case 'wrapper.chat-new':
          case 'wrapper.chat-session':
          case 'wrapper.contact-add':
          case 'wrapper.contact-edit':
          case 'wrapper.contact-details':
          case 'wrapper.dialer':
          case 'wrapper.greetings-list':
          case 'wrapper.greetings-new':
          case 'wrapper.phone':
          case 'wrapper.settings':
          case 'wrapper.settings-callfrom':
          case 'wrapper.settings-ringtones':
          case 'wrapper.settings-callwaiting':
          case 'wrapper.num-pad':
          case 'wrapper.user-details':
            this.showFab = false;
            break;
          default:
            this.showFab = true;
        }
      }

      if (state.currentState.name == "wrapper.iframe-holder") {
        this.showIframeLayout = true;
      }
      else {
        this.showIframeLayout = false;
      }

    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

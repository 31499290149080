import './styles.css';
import template from './template.html';
import gravatarSessionId from '../../core/utils/gravatarSessionId';

export default {
  template,
  controller,
  bindings: {
    gravatar: '<',
    initials: '<',
    user: '<',
  },
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe;

  this.gravatarSessionId = gravatarSessionId;

  this.$onChanges = () => {
    if (unsubscribe) {
      unsubscribe();
    }

    if (this.user) {
      unsubscribe = $ngRedux.connect((state) => {
        const presence = state.presence.get(this.user);

        let chatPresence = false;
        let presenceColor = '#ff270e';
        let presenceIcon;
        if (presence) {
          if (presence.chatPresence) {
            chatPresence = presence.chatPresence;
          }
          switch (presence.presence) {
            case 'open':
              presenceColor = '#3ed455';
              break;
            case 'inactive':
              presenceColor = '#cccccc';
              break;
            case 'closed':
              presenceIcon = 'do_not_disturb_on';
              presenceColor = '#ff270e';
              break;
            case 'inuse':
            case 'held-private':
            case 'held':
            case 'progressing':
            case 'alerting':
              presenceColor = '#ff270e';
              break;
          }
        }

        return {
          chatPresence,
          presenceColor,
          presenceIcon,
        };
      })(this);
    }
  };

  this.$onDestroy = () => {
    if (unsubscribe) {
      unsubscribe();
    }
  };
}

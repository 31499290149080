export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.logout', {
      url: 'logout',
      views: {
        '@': {
          template: '<nets-logout></nets-logout>',
        },
      },
    });
};

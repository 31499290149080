import debounce from 'debounce';

import { store } from '../store';

/** ********************************************
 * Redux constants
 ******************************************** */

export const RESIZE = 'core/screen-size/RESIZE';

/** ********************************************
 * Reducer
 ******************************************** */

const width = _getSize('Width');

let display;
let size;

if (width > 1100) {
  display = 'fullscreen';
  size = 'lg';
} else if (width > 715) {
  display = 'fullscreen';
  size = 'md';
} else {
  display = 'compact';
  size = 'sm';
}

const initialState = {
  display,
  height: _getSize('Height'),
  size,
  width,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESIZE:
      return Object.assign(state, action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Private Helper functions
 ******************************************** */

/**
 * Gets the window inner size
 * @param {string} name - expects Height or Width
 * @returns {*}
 * @private
 */
function _getSize(name) {
  return window[`inner${name}`];
}

/**
 * Handler for window resize events
 * @private
 */
function _resizeHandler() {
  const width = _getSize('Width');

  let display;
  let size;

  if (width > 1100) {
    display = 'fullscreen';
    size = 'lg';
  } else if (width > 715) {
    display = 'fullscreen';
    size = 'md';
  } else {
    display = 'compact';
    size = 'sm';
  }

  const payload = {
    type: RESIZE,
    payload: {
      display,
      height: _getSize('Height'),
      size,
      width,
    },
  };

  store.dispatch(payload);
}
window.addEventListener('resize', debounce(_resizeHandler, 50));

/**
 * Dispatch initial size on load
 */
document.addEventListener('load', () => {
  const width = _getSize('Width');

  let display;
  let size;

  if (width > 1100) {
    display = 'fullscreen';
    size = 'lg';
  } else if (width > 715) {
    display = 'fullscreen';
    size = 'md';
  } else {
    display = 'compact';
    size = 'sm';
  }

  const payload = {
    type: RESIZE,
    payload: {
      display,
      height: _getSize('Height'),
      size,
      width,
    },
  };

  store.dispatch(payload);
});

import './styles.css';
import _ from 'lodash';
import template from './template.html';
import { getEnabled } from '../../../../core/answering-rules';
import { getExpandedId, setExpanded, removeCard } from '../../../../core/card-management';
import { getConfig } from '../../../../core/configs';
import { filteredBy } from '../../../../core/call-history';
import { navStackPush } from '../../../../core/state-history';
import * as chatActions from '../../../../core/chat';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
  '$state',
];

function controller(
  $ngRedux,
  $state,
) {
  const self = this;
  let unsubscribe;

  self.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      enabledAr: getEnabled(state),
      expandedId: getExpandedId(state),
      hostname: getConfig(state, 'hostname'),
      newVoicemail: state.voicemail.get('newVoicemail'),
      callHistory: state.callHistory.get('callHistoryResultSet'),
      user: state.user,
      voicemailLoaded: !state.voicemail.get('loading'),
      unreadCount: chatActions.getUnreadCount(state),
      PORTAL_WEB_PHONE_SHOW_VOICEMAIL: state.configs.get('PORTAL_WEB_PHONE_SHOW_VOICEMAIL') || "yes",
      PORTAL_WEB_PHONE_SHOW_CALLHISTORY: state.configs.get('PORTAL_WEB_PHONE_SHOW_CALLHISTORY')|| "yes",

      allowChat: state.configs.get('portalChatSMS') || "yes",
    }))((state) => {
      this.enabledAr = state.enabledAr;
      this.newVoicemail = state.newVoicemail;
      this.showVoicemail = (state.user && state.user.vmailEnabled == "yes" && state.PORTAL_WEB_PHONE_SHOW_VOICEMAIL.toLowerCase() !== "no");
      this.allowChat = state.allowChat.toLowerCase() ;
      this.user = state.user;
      this.voicemailLoaded = state.voicemailLoaded;
      this.showCard = this.card.id !== state.expandedId;
      this.unreadCount = state.unreadCount;
      this.showCallhistory = state.PORTAL_WEB_PHONE_SHOW_CALLHISTORY.toLowerCase() !== "no";
      

      this.callHistory = _.filter(state.callHistory, (rec) => rec.type === '2');

      if (this.showCard) {
        setTimeout(() => {
          const imgUrl = `https://${state.hostname}/ns-api/?object=image&action=read&server=${state.hostname}&filename=drawer_default.png&domain=${this.user.domain}`;
          const elem = document.getElementById('user-card-image');
          if (elem) {
            elem.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imgUrl})`;
            elem.style.height = '120px';
            elem.style.backgroundSize = 'cover';
            elem.style.borderRadius = '2px 2px 0 0';
          }
        }, 0);
      }
    });
  };

  self.$onDestroy = () => {
    unsubscribe();
  };

  this.focusUser = () => {
    $ngRedux.dispatch(setExpanded(this.card.id));
  };

  this.removeCard = () => {
    $ngRedux.dispatch(removeCard(this.card.id));
  };

  this.goVoicemail = ($event) => {
    $event.stopPropagation();
    $state.go('wrapper.voicemail-list');
  };

  this.goMissedCall = ($event) => {
    $event.stopPropagation();
    // Apply missed filter and redirect
    $ngRedux.dispatch(filteredBy('missed'));
    $state.go('wrapper.call-history-list');
  };

  this.goRecentChat = ($event) => {
    $event.stopPropagation();
    $state.go('wrapper.chat-list');
  };

  this.goAnswerRules = ($event, ruleID) => {
    $event.stopPropagation();
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navStackPush({
        name: 'wrapper.answering-rule-details',
        params: { ruleID },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.answering-rule-details', { ruleID });
  };
}

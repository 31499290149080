import debounce from 'debounce';

import './styles.css';
import template from './template.html';
import * as contactActions from '../../../../core/contacts';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];
function controller(
  $ngRedux,
  $state,
) {
  const self = this;
  let inputElem;
  let unsubscribe = null;

  /**
     *
     */
  this.$onInit = () => {
    this.workflow = $state.params.workflow;
    unsubscribe = $ngRedux.connect((state) => ({
      id: `search-input-${this.workflow}`,
      screenSize: state.screenSize,
    }))(this);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();

    if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
      $ngRedux.dispatch(contactActions.search(''));
    } else {
      $ngRedux.dispatch(contactActions.inCallSearch(''));
    }

    if (inputElem) {
      inputElem.removeEventListener('keyup', self.search);
    }
  };

  /**
     *
     */
  this.searchOpen = () => {
    this.searchWindow = true;
    setTimeout(() => {
      inputElem = document.getElementById(`search-input-${this.workflow}`);
      inputElem.focus();
      inputElem.addEventListener('keyup', self.search);
    }, 0);
  };

  /**
     *
     */
  this.searchClose = () => {
    this.searchWindow = false;
    this.searchStr = '';

    if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
      $ngRedux.dispatch(contactActions.search(''));
    } else {
      $ngRedux.dispatch(contactActions.inCallSearch(''));
    }

    inputElem.removeEventListener('keyup', self.search);
  };

  /**
     *
     */
  this.search = debounce(
    () => {
      if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
        $ngRedux.dispatch(contactActions.search(this.searchStr));
      } else {
        $ngRedux.dispatch(contactActions.inCallSearch(this.searchStr));
      }
    },
    300,
  );
}

import './styles.css';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import * as navActions from '../../core/state-history';
import * as sessions from '../../core/sessions';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];
function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe = null;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      allowCall: sessions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-677]
      screenSize: state.screenSize,
      allowChat: state.configs.get('portalChatSMS'),
    }))(this);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     * Creates dialer card, sets the card to be expanded and if compact changes state.
     */
  this.focusCall = () => {
    $ngRedux.dispatch(cardActions.newCard({ type: 'dialer' }));
    const cardId = cardActions.getCardId({ type: 'dialer' });
    $ngRedux.dispatch(cardActions.setExpanded(cardId));

    if (this.screenSize.display === 'compact') {
      const card = cardActions.getCard($ngRedux.getState(), cardId);
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');

      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.dialer',
          params: { card, workflow: 'new-call' },
          fromName: lastOnStack.fromName,
          fromParams: lastOnStack.fromParams,
        }),
      );
      $state.go('wrapper.dialer', { card, workflow: 'new-call' });
    }
  };

  /**
     *
     */
  this.focusChat = () => {
    $ngRedux.dispatch(cardActions.newCard({ type: 'new-chat' }));
    const cardId = cardActions.getCardId({ type: 'new-chat' });
    $ngRedux.dispatch(cardActions.setExpanded(cardId));

    if (this.screenSize.display === 'compact') {
      const card = cardActions.getCard($ngRedux.getState(), cardId);
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.chat-new',
          params: { card },
          fromName: lastOnStack.name,
          fromParams: lastOnStack.params,
        }),
      );
      $state.go('wrapper.chat-new', { card });
    }
  };

  /**
     * creates user card and sets the card to be expanded
     * only used in fullscreen
     */
  this.focusUser = () => {
    let cardId = cardActions.getCardId({ type: 'user' });
    if (!cardId) {
      $ngRedux.dispatch(cardActions.newCard({ type: 'user' }));
      cardId = cardActions.getCardId({ type: 'user' });
    }
    $ngRedux.dispatch(cardActions.setExpanded(cardId));
  };
}

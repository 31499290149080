import './styles.css';
import template from './template.html';
import { getExpandedId, setExpanded, removeCard } from '../../../../core/card-management';
import { fetchAgentKPI, getKPIStats } from '../../../../core/agent-center';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
];

function controller(
  $ngRedux,
) {
  const self = this;
  let unsubscribe;

  self.$onInit = () => {
    $ngRedux.dispatch(fetchAgentKPI());
    unsubscribe = $ngRedux.connect((state) => ({
      expandedId: getExpandedId(state),
      kpistats: getKPIStats(state),
    }))((state) => {
      this.showCard = this.card.id !== state.expandedId;
      this.kpistats = state.kpistats;
    });
  };

  self.$onDestroy = () => {
    unsubscribe();
  };

  this.focusCard = () => {
    $ngRedux.dispatch(setExpanded(this.card.id));
  };

  this.removeCard = () => {
    $ngRedux.dispatch(removeCard(this.card.id));
  };
}

export default ($stateProvider) => {
  $stateProvider
    .state('password-reset', {
      url: `${location.pathname}password-reset?user`,
      template: '<nets-password-reset></nets-password-reset>',
      params: {
        prAuthCode: null,
      },
    });
};

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.num-pad', {
      url: 'num-pad',
      params: {
        type: null,
        number: null,
      },
      template: '<nets-num-pad></nets-num-pad>',
    });
};

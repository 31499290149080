import './styles.css';
import template from './template.html';
import { getCallHistoryResultSet, getLoading } from '../../core/call-history';
import { navStackPush } from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$state',
];

function controller(
  $ngRedux,
  $state,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    this.loaded = false;
    unsubscribe = $ngRedux.connect((state) => ({
      callHistory: getCallHistoryResultSet(state),
      loaded: !getLoading(state),
      screenSize: state.screenSize,
      dateFormat: state.configs.get('portalLocalizationDateFormat'),
      timeFormat: state.configs.get('portalLocalizationTimeFormat'),
    }))((state) => {
      this.loaded = state.loaded;
      this.screenSize = state.screenSize;
      this.dataset.refresh(state.callHistory);
      this.dateFormat = state.dateFormat;
      this.timeFormat = state.timeFormat;
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @type {{items: Array, refresh: (function(*)), getItemAtIndex: (function(*)), getLength: (function())}}
     */
  this.dataset = {
    items: [],
    refresh: (list) => {
      this.dataset.items = list;
    },
    getItemAtIndex: (index) => this.dataset.items[index],
    getLength: () => this.dataset.items.length,
  };

  /**
     *
     * @param callID
     */
  this.navPush = (callID) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navStackPush({
        name: 'wrapper.call-details',
        params: { callID },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.call-details', { callID });
  };
}

import moment from 'moment';
import 'moment-duration-format';

export default () => (time) => {
  time = Number
    .parseInt(time);
  return moment
    .duration(time, 'seconds')
    .format('m [minutes] s [seconds]', { trim: 'left' });
};

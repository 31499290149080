import './styles.css';
import _ from 'lodash';
import template from './template.html';
import * as greetingActions from '../../core/greetings';
import * as navActions from '../../core/state-history';
import { getSessions } from '../../core/sessions';
import * as userMedia from '../../core/user-media';

function controller(
  $interval,
  $mdToast,
  $ngRedux,
  $rootScope,
  $state,
  $translate,
) {
  const self = this;
  let unsubscribe;
  let audio;
  let audioURL;
  let chunks = [];
  let resultingBlob;
  let mediaRecorder;
  let timer;
  let audioStream;

  /**
     *
     */
  this.$onInit = async () => {
    this.greetingName = '';
    this.recording = false;
    this.message = false;
    this.playing = false;
    this.count = 0;
    this.progress = 0;

    unsubscribe = $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
      callSessions: getSessions(state),
      appName: state.configs.get('appName'),
      inputDevice: state.userMedia.get('inputDevice'),
    }))(this);

    const options = {
      mimeType: 'audio/webm',
    };

    if (this.inputDevice) {
      userMedia.getAudioStream(this.inputDevice).then((stream) => {
        audioStream = stream;

        mediaRecorder = new MediaRecorder(audioStream, options);
        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);

          mediaRecorder.onstop = () => {
            resultingBlob = new Blob(chunks, { type: 'video/webm' });
            audioURL = URL.createObjectURL(resultingBlob);
            audio = document.createElement('audio');
            audio.controls = true;
            const sourceEl = document.createElement('source');
            sourceEl.src = audioURL;
            sourceEl.type = 'audio/webm';
            audio.appendChild(sourceEl);

            audio.addEventListener('ended', () => {
              self.stopPlayback();
              $rootScope.$apply();
            }, false);
          };
        };
      }).catch(console.error);
    }
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
    if (!_.size(this.callSessions)) {
      if (audioStream) {
        userMedia.stopStream(audioStream);
      }
      $ngRedux.dispatch(userMedia.stopStreams());
    }

    if (audioStream) {
      audioStream = null;
    }
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackBack());
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    $state.go(lastOnStack.name, lastOnStack.params);
  };

  /**
     *
     */
  this.playback = () => {
    this.playing = true;
    audio.mute = false;
    audio.src = audioURL;
    audio.play();
  };

  /**
     *
     */
  this.redo = () => {
    chunks = [];
    audio.mute = true;
    this.recording = false;
    this.message = false;
    this.playing = false;
    this.count = 0;
    this.progress = 0;
    this.displaySaveOptions = false;
    $mdToast.hide();
  };

  /**
     *
     */
  this.startRecording = () => {
    chunks = [];
    mediaRecorder.start();
    this.recording = true;
    this.startTimer();
  };

  /**
     *
     */
  this.stopPlayback = () => {
    this.playing = false;
    audio.mute = true;
    audio.pause();
    audio.currentTime = 0;
  };

  /**
     *
     */
  this.stopRecording = () => {
    mediaRecorder.stop();
    this.recording = false;
    this.message = true;
    this.stopTimer();
    this.displaySaveOptions = true;
  };

  /**
     *
     */
  this.startTimer = () => {
    timer = $interval(() => {
      self.count += 100;
      self.progress = (self.count / 60000) * 100;
      if (self.count === 60000) {
        self.stopRecording();
      }
    }, 100);
  };

  /**
     *
     */
  this.stopTimer = () => {
    $interval.cancel(timer);
  };

  /**
     *
     */
  this.submit = () => {
    // test for greeting name
    if (this.greetingName && this.greetingName.trim()) {
      if (resultingBlob) {
        $ngRedux.dispatch(greetingActions.save(this.greetingName, resultingBlob));

        // init toast
        const toast = $mdToast.simple()
          .textContent($translate.instant('GREETING_SAVED'))
          .highlightAction(true)
          .highlightClass('theme-toast-action')
          .position('bottom');

        // show the toast
        $mdToast.show(toast);

        $ngRedux.dispatch(navActions.navStackBack());
        const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
        $state.go(lastOnStack.name, lastOnStack.params);
      }
    } else {
      let saveToast = false;
      angular.element('[name="gname"]').focus();

      const toast = $mdToast.simple()
        .textContent($translate.instant('GREETING_NAME_REQUIRED'))
        .action($translate.instant('OK'))
        .highlightAction(true)
        .highlightClass('theme-toast-action')
        .position('bottom')
        .hideDelay(5000);

      // show the toast
      $mdToast.show(toast).then((res) => {
        if (res === 'ok' && !saveToast) {
          saveToast = true;
        }
      });

      setTimeout(() => {
        if (!saveToast) {
          saveToast = true;
        }
      }, 6000);
    }
  };

  /**
     *
     * @param device
     */
  this.setInputDevice = async (device) => {
    this.selectedInputAudioDevice = device;
    const deviceId = _.get(device, 'deviceId');

    if (deviceId !== this.inputDevice) {
      // localStorage.setItem(`${this.appName}-${user}_inputAudioDevice`, deviceId);
      $ngRedux.dispatch(userMedia.inputDevice(deviceId));

      if (audioStream) {
        userMedia.stopStream(audioStream);
        audioStream = null;
      }

      audioStream = await userMedia.getAudioStream(deviceId);
      $ngRedux.dispatch(userMedia.addStream(audioStream));

      const newTrack = audioStream.getTracks()[0];

      newTrack.onended = () => {
        audioStream = null;
        newTrack.stop();
        // speechEvents.stop();
      };

      if (this.callSessions) {
        this.callSessions.forEach((callSession) => {
          const pc = callSession.session.sessionDescriptionHandler.peerConnection;
          if (pc.getSenders()) {
            pc.getSenders().forEach((sender) => {
              sender.replaceTrack(newTrack);
            });
          }
        });
      }
    }
  };
}

controller.$inject = [
  '$interval',
  '$mdToast',
  '$ngRedux',
  '$rootScope',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
};

import './styles.css';
import template from './template.html';

import * as contactActions from '../../../../core/contacts';

export default {
  template,
  controller,
  bindings: {
    workflow: '@', // in-call
  },
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe = null;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      direction: !this.workflow || this.workflow.indexOf('in-call') === -1
        ? contactActions.getSortedDirection(state) : contactActions.getInCallSortedDirection(state),
      sortedBy: !this.workflow || this.workflow.indexOf('in-call') === -1
        ? contactActions.getSortedBy(state) : contactActions.getInCallSortedBy(state),
    }))(this);
  };

  this.sortContactsBy = (name) => {
    if (this.sortedBy !== name) {
      if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
        $ngRedux.dispatch(contactActions.sortedBy(name));
        $ngRedux.dispatch(contactActions.sortedDirection('asc'));
      } else {
        $ngRedux.dispatch(contactActions.inCallSortedBy(name));
        $ngRedux.dispatch(contactActions.inCallSortedDirection('asc'));
      }
    } else {
      // ignore desc if 'online'
      if (this.direction === 'asc' && name !== 'online') {
        if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
          $ngRedux.dispatch(contactActions.sortedDirection('desc'));
        } else {
          $ngRedux.dispatch(contactActions.inCallSortedDirection('desc'));
        }
      } else if (!this.workflow || this.workflow.indexOf('in-call') === -1) {
        $ngRedux.dispatch(contactActions.sortedDirection('asc'));
      } else {
        $ngRedux.dispatch(contactActions.inCallSortedDirection('asc'));
      }
    }
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.contact-list', {
      url: 'contact-list',
      params: {
        type: null, // call detail number type (work, cell, home), voicemail type (new, save)
        index: null, // voicemail index
        number: null, // call detail phone number
        workflow: null, // call-details-add-to-contact, voicemail-forward
        queueKey: null
      },
      template: '<nets-contact-list></nets-contact-list>',
    });
};

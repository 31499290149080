import { put, takeEvery } from 'redux-saga/effects';

import { fetchCallHistory } from '../call-history';
import { CONTACTS } from '../contacts';

export default function* () {
  yield takeEvery(CONTACTS, _fetchHistory);
}

const _fetchHistory = function* () {
  yield put(fetchCallHistory());
};

export default ($stateProvider) => {
  $stateProvider
    .state('wrapper.call-wrapup', {
      url: 'call-wrapup',
      params: {
        card: null,
        context: null,
      },
      template: '<nets-call-wrapup card="$ctrl.card" context="card"></nets-call-wrapup>',
      controller: ['$stateParams', function ($stateParams) {
        this.card = $stateParams.card;
        this.context = $stateParams.context;
      }],
      controllerAs: '$ctrl',
    });
};

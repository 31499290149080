import nsUtils from '@netsapiens/netsapiens-js/dist/utils';

import './styles.css';
import template from './template.html';
import * as callActions from '../../core/call-history';
import * as cardActions from '../../core/card-management';
import * as chatActions from '../../core/chat';
import * as deviceActions from '../../core/devices';
import * as navActions from '../../core/state-history';
import * as sessionActions from '../../core/sessions';
import {
  getQueueName, showWrapUpDialog, getDispositions, getShowAgentCenter,
} from '../../core/agent-center';
import { sendInvite } from '../../core/ua';

export default {
  template,
  controller,
  bindings: {
    callid: '<',
  },
};

controller.$inject = [
  '$mdToast',
  '$ngRedux',
  '$state',
  '$filter',
  '$translate',
];

function controller(
  $mdToast,
  $ngRedux,
  $state,
  $filter,
  $translate,
) {
  let unsubscribe;
  let callFilter;

  /**
     *
     */
  this.$onInit = () => {
    $ngRedux.dispatch(callActions.fetchCallCDR(this.callid));
    unsubscribe = $ngRedux.connect((state) => ({
      allowCall: sessionActions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-677]
      dispositionin: getDispositions(state, 'inbound'),
      dispositionout: getDispositions(state, 'outbound'),
      call: callActions.getCallDetails(state, this.callid),
      cdr: callActions.getCallCdrDetails(state, this.callid),
      screenSize: state.screenSize,
      user: state.user,
      sip: state.ua.get('ua'),
      configs: state.configs,
      allowChat: state.configs.get('portalChatSMS'),
      showAgentCenter: getShowAgentCenter(state),
    }))((state) => {
      this.allowCall = state.allowCall; // [WP-677]
      this.allowChat = state.allowChat;
      this.call = state.call;
      this.cdr = state.cdr ? state.cdr.cdr[0] : false;
      this.screenSize = state.screenSize;
      this.notRegister = state.sip;
      this.user = state.user;
      this.userId = state.configs.get('userId');
      this.isQueueCall = (this.call.orig_to_uri !== this.userId)
                && state.showAgentCenter
                && (this.call.direction === 'inbound' ? state.dispositionin.length > 0 : state.dispositionout.length > 0);

      // format time for momentjs localization.
      const time = state.call.time_start;
      this.time_start = time._d;

      switch (this.user.language) {
        case 'es_mx':
          callFilter = $filter('humanizeDuration')(this.call.duration);
          this.duration = callFilter.replace('seconds', 'segundos').replace('minutes', 'minutos');
          break;
        case 'fr_ca':
          callFilter = $filter('humanizeDuration')(this.call.duration);
          this.duration = callFilter.replace('seconds', 'secondes').replace('minutes', 'minutes');
          break;
        default:
          this.duration = $filter('humanizeDuration')(this.call.duration);
      }

      // get call type
      if (!this.call.contact) {
        this.type = 'unknownSMS';
      } else {
        if (this.call.number === this.call.contact.extension) {
          this.type = 'extension';
        }
        if (this.call.number === this.call.contact.work_phone) {
          this.type = 'work';
        }
        if (this.call.number === this.call.contact.cell_phone) {
          this.type = 'cell';
        }
        if (this.call.number === this.call.contact.home_phone) {
          this.type = 'home';
        }
      }

      // is number
      this.isNumber = nsUtils.isPhoneNumber(
        this.call.number,
      );

      if (this.user.sms) {
        for (let i = 0; i < this.user.sms.length; i += 1) {
          if (this.user.sms[i].default) {
            this.selectedSMS = i;
          }
        }
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param type
     */
  this.addNewContact = (type) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-add',
        params: {
          workflow: 'call-detail-new-contact',
          type,
          number: this.call.number,
        },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-add', {
      workflow: 'call-detail-new-contact',
      type,
      number: this.call.number,
    });
  };

  /**
     *
     */
  this.callNumber = () => {
    const callFromDevice = deviceActions.selectCallFrom($ngRedux.getState());

    if (!callFromDevice) {
      $ngRedux.dispatch(sendInvite(this.call.number));
    } else {
      $mdToast.show(
        $mdToast.simple()
          .textContent(`${$translate.instant('CALLING_FROM')} ${callFromDevice.display}`)
          .position('bottom left')
          .hideDelay(60000),
      );

      $ngRedux.dispatch(deviceActions.callFrom(this.call.number));
    }
  };

  /**
     *
     * @param smsIndex
     */
  this.chat = (smsIndex) => {
    if (this.type !== 'extension' && !this.user.sms) {
      return;
    }

    // generate session id and message params
    let sendMessageParams;
    let sessionId;
    switch (this.type) {
      case 'extension':
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termUID: this.call.contact.uid,
        });
        sendMessageParams = {
          destination: this.call.contact.user,
          fromUID: this.user.uid,
          sessionId,
        };
        break;
      case 'work':
      case 'cell':
      case 'home':
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termNum: this.call.contact[`${this.type}_phone`],
          dialed: this.user.sms[smsIndex].number,
        });
        sendMessageParams = {
          destination: this.call.contact[`${this.type}_phone`],
          fromNum: this.user.sms[smsIndex].number,
          sessionId,
        };
        break;
      case 'unknownSMS':
        // todo if no contact and 5 digit string of numbers allow without valid phone check
        // todo the number is not 5 do a valid phone check
        sessionId = nsUtils.generateChatId({
          domain: this.user.domain,
          fromUID: this.user.uid,
          termNum: this.call.number,
          dialed: this.user.sms[smsIndex].number,
        });

        sendMessageParams = {
          destination: this.call.number,
          fromNum: this.user.sms[smsIndex].number,
          sessionId,
        };
        break;
    }

    // build card meta
    const cardMeta = {
      sendMessageParams,
      sessionId,
    };

    // add contact id if contact is set
    if (this.call.contact) {
      cardMeta.contactId = this.call.contact.id;
    }

    let cardId = cardActions.getCardId(cardMeta);
    if (!cardId) {
      $ngRedux.dispatch(chatActions.fetchMessages(sessionId));
      $ngRedux.dispatch(cardActions.newCard(cardMeta));
      cardId = cardActions.getCardId(cardMeta);
    }

    $ngRedux.dispatch(cardActions.setExpanded(cardId));

    // if compact add to stack and go to state
    if (this.screenSize.display === 'compact') {
      const state = navActions.getCurrentState($ngRedux.getState());
      const card = cardActions.getCard($ngRedux.getState(), cardId);
      $ngRedux.dispatch(
        navActions.navStackPush({
          name: 'wrapper.chat-session',
          params: {
            card,
          },
          fromName: state.name,
          fromParams: state.params,
        }),
      );
      $state.go('wrapper.chat-session', {
        card,
      });
    }
  };

  this.getQueueName = (queueId) => getQueueName($ngRedux.getState(), queueId);

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackRebase({
      name: 'wrapper.call-history-list',
      params: null,
      fromName: null,
      fromParams: null,
    }));
    $state.go('wrapper.call-history-list');
  };

  /**
     *
     */
  this.toContact = () => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-detail',
        params: {
          contactID: this.call.contact.id,
        },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-details', {
      contactID: this.call.contact.id,
    });
  };

  /**
     *
     * @param type
     */
  this.addToContact = (type) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: 'wrapper.contact-list',
        params: {
          workflow: 'call-details-add-to-contact',
          type,
          number: this.call.number,
        },
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go('wrapper.contact-list', {
      workflow: 'call-details-add-to-contact',
      type,
      number: this.call.number,
    });
  };

  this.toDisposition = () => {
    $ngRedux.dispatch(showWrapUpDialog({
      callTime: this.cdr.duration,
      callerId: this.cdr.name,
      number: this.cdr.number,
      direction: this.cdr.type === '1' ? 'inbound' : 'outbound',
      callId: this.cdr.cdr_id,
    }));
  };
}

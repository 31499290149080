import moment from 'moment';

export default () => (milliseconds) => {
  if (milliseconds < 3600000) {
    return moment
      .duration(milliseconds)
      .format('mm:ss', { trim: false });
  }
  return moment
    .duration(milliseconds)
    .format('h:mm:ss', { trim: 'left' });
};

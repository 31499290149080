import nsApi from '@netsapiens/netsapiens-js/dist/api';

import './styles.css';
import template from './template.html';

import { unRegister } from '../../core/ua';

function controller(
  $ngRedux,
) {
  const apiHash = $ngRedux.getState().configs.get('apiHash');
  nsApi.logout(apiHash).then(() => {
    // logout button was pressed
    $ngRedux.dispatch(unRegister());
    global.location.reload();
  }).catch(() => {
    // reload even if logout fails.
    global.location.reload();
  });
}

controller.$inject = [
  '$ngRedux',
];

export default {
  template,
  controller,
};

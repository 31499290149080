import './styles.css';
import template from './template.html';

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdColors',
  '$translate',
  '$ngRedux',
];
function controller(
  $mdColors,
  $translate,
  $ngRedux,
) {
  this.background = $mdColors.getThemeColor('primary-500');
  this.changeLanguage = function (langKey) {
    $translate.use(langKey);
  };

  let unsubscribe;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      showLocalization: state.configs.get('PORTAL_WEBPHONE_LOCALIZATION_LANGUAGE_LAYOUTS') || 'yes',
    }))((state) => {
      this.showLocalization = state.showLocalization === 'yes';
    });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

import template from './template.html';
import { getExpandedId, setExpanded, removeCard } from '../../../../core/card-management';

export default {
  template,
  controller,
  bindings: {
    card: '<',
  },
};

controller.$inject = [
  '$ngRedux',
];
function controller(
  $ngRedux,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({ expandedId: getExpandedId(state) }))((state) => { this.showCard = this.card.id !== state.expandedId; });
  };

  this.$onDestroy = () => {
    unsubscribe();
  };

  this.focusCard = () => {
    $ngRedux.dispatch(setExpanded(this.card.id));
  };
  /**
     *
     * @param cardId
     */
  this.removeCard = (cardId) => {
    $ngRedux.dispatch(removeCard(cardId));
  };
}

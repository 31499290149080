import _ from 'lodash';
import crypto from 'crypto-browserify';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsModels from '@netsapiens/netsapiens-js/dist/models';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import { setSMS } from '../extension';

/** ********************************************
 * Redux constants
 ******************************************** */

export const SET_USER = 'core/user/SET_USER';

/** ********************************************
 * Reducer
 ******************************************** */

export default (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {{type}}
 */
export const fetchUser = () => (dispatch, getState) => {
  const token = nsToken.getDecoded();

  const userFields = [
    'domain',
    'first_name',
    'email',
    'last_name',
    'language',
    'scope',
    'subscriber_login',
    'user',
    'vmail_enabled',
    'vmail_greeting',
    'subscriber_pin',
    'address_id',
    'address_id_source',
    'time_zone',
    'message',
  ];

  Promise.all([
    nsApi.get({
      user: token.user,
      domain: token.domain,
      object: 'subscriber',
      action: 'read',
      fields: userFields.join(','),
      format: 'json',
      includeDomain: 'yes',
    }),
    nsApi.get({
      object: 'smsnumber',
      action: 'read',
      domain: token.domain,
      dest: token.user,
    }),
  ]).then((res) => {
    const user = res[0][0];
    const sms = res[1];
    user.firstName = user.first_name;
    user.lastName = user.last_name;
    user.displayName = token.displayName;
    user.uid = `${user.user}@${user.domain}`;
    user.userId = user.user;
    user.subscriberLogin = user.subscriber_login;
    user.vmailEnabled = user.vmail_enabled;
    user.vmailGreeting = user.vmail_greeting;
    user.subscriberPin = user.subscriber_pin;
    user.address_id = user.address_id;
    user.address_id_source = user.address_id_source;
    user.timeZone = user.time_zone;
    user.portal_status = user.message || '';
    // check for first login
    const appName = getState().configs.get('appName');
    const firstLogin = localStorage.getItem(`${appName}-${token.user}_first-login`);
    if (!firstLogin) {
      localStorage.setItem(`${appName}-${token.user}_first-login`, 'true');
      user.isFirstLogin = true;
    } else {
      localStorage.setItem(`${appName}-${token.user}_first-login`, 'false');
      user.isFirstLogin = false;
    }

    if (sms && sms.length) {
      const defaultSMS = localStorage.getItem(`${appName}-${token.user}_sms-default`);
      if (defaultSMS) {
        let found = false;
        for (let i = 0; i < sms.length; i += 1) {
          if (defaultSMS === sms[i].number) {
            sms[i].default = true;
            found = true;
          }
        }
        // handle if the sms number has been deleted from the user
        if (!found) {
          sms[0].default = true;
          localStorage.setItem(`${appName}-${token.user}_sms-default`, sms[0].number);
        }
      } else {
        sms[0].default = true;
        localStorage.setItem(`${appName}-${token.user}_sms-default`, sms[0].number);
      }
      user.sms = sms;
    }

    user.initials = '';
    if (user.displayName && user.displayName.trim()) {
      user.displayName = user.displayName.trim();
      const nameArr = user.displayName.split(' ');
      user.initials += nameArr[0][0].toUpperCase();
      if (nameArr.length > 1) {
        // get the last name, this skips middle initials etc.
        user.initials += nameArr[nameArr.length - 1][0].toUpperCase();
      }
    }

    if (user.email && user.email.trim()) {
      user.email = user.email.trim();
      user.email = user.email.toLowerCase();
      user.email = user.email.split(';');

      user.gravatar = crypto.createHash('md5').update(user.email[0]).digest('hex');

      dispatch({ type: SET_USER, payload: new nsModels.user.User(user) });
      dispatch(setSMS());
    } else {
      dispatch({ type: SET_USER, payload: new nsModels.user.User(user) });
      dispatch(setSMS());
    }
  }).catch(() => {
    // console.log(err);
    dispatch({ type: SET_USER, payload: false });
    dispatch(setSMS());
  });
};

/**
 *
 * @param user
 */
export const updateUser = (user) => (dispatch) => {
  const decodedToken = nsToken.getDecoded();
  const params = {
    uid: decodedToken.sub,
    user: decodedToken.user,
    domain: decodedToken.domain,
    object: 'subscriber',
    action: 'update',
    format: 'json',
    current_password: user.pwd || '',
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    language: user.language,
    vmail_greeting: user.vmailGreeting,
  };

  if (params.email) params.email = _.chain(params.email).compact().join(';').value();

  nsApi.post(params).then(() => {
    dispatch({ type: SET_USER, payload: new nsModels.user.User(user) });
  });
};

/**
 *
 * @param number
 * @return {function(*, *)}
 */
export const updateSMSPref = (number) => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const token = nsToken.getDecoded();

  localStorage.setItem(`${appName}-${token.user}_sms-default`, number);

  const { user } = getState();
  for (let i = 0; user.sms.length; i += 1) {
    user.sms[i].default = user.sms[i].number === number;
  }

  dispatch({
    type: SET_USER,
    payload: user,
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @returns {[]|null}
 */
export const getSMS = (state) => state.user.sms;

/**
 *
 * @param state
 * @return {*}
 */
export const getUser = (state) => state.user;

import nsToken from '@netsapiens/netsapiens-js/dist/token';
import './styles.css';
import template from './template.html';
import * as arActions from '../../core/answering-rules';
import * as extensionActions from '../../core/extension';
import * as userActions from '../../core/user';
import { devicesNeedConsent } from '../../core/user-media';

function Ball(x, y, vx, vy, color) {
  this.x = x;
  this.y = y;
  this.vx = vx;
  this.vy = vy;
  this.color = color;
  this.origX = x;
  this.origY = y;
  this.radius = 3.5;
}

function getMousePos(canvas, evt) {
  // get canvas position
  let obj = canvas;
  let top = 0;
  let left = 0;
  while (obj.tagName !== 'BODY') {
    top += obj.offsetTop;
    left += obj.offsetLeft;
    obj = obj.offsetParent;
  }

  // return relative mouse position
  const mouseX = evt.clientX - left + window.pageXOffset;
  const mouseY = evt.clientY - top + window.pageYOffset;
  return {
    x: mouseX,
    y: mouseY,
  };
}

function initBalls() {
  const balls = [];

  const blue = '#0168B5';
  const yellow = '#F39605';

  balls.push(new Ball(25, 38, 0, 0, yellow));
  balls.push(new Ball(25, 38, 0, 0, yellow));
  balls.push(new Ball(25, 39, 0, 0, yellow));
  balls.push(new Ball(25, 40, 0, 0, yellow));
  balls.push(new Ball(25, 41, 0, 0, yellow));
  balls.push(new Ball(25, 43, 0, 0, yellow));
  balls.push(new Ball(25, 46, 0, 0, yellow));
  balls.push(new Ball(25, 48, 0, 0, yellow));
  balls.push(new Ball(25, 53, 0, 0, yellow));
  balls.push(new Ball(26, 56, 0, 0, yellow));
  balls.push(new Ball(26, 58, 0, 0, yellow));
  balls.push(new Ball(26, 58, 0, 0, yellow));
  balls.push(new Ball(26, 59, 0, 0, yellow));
  balls.push(new Ball(26, 59, 0, 0, yellow));
  balls.push(new Ball(26, 59, 0, 0, yellow));
  balls.push(new Ball(26, 59, 0, 0, yellow));
  balls.push(new Ball(26, 59, 0, 0, yellow));
  balls.push(new Ball(26, 61, 0, 0, yellow));
  balls.push(new Ball(26, 65, 0, 0, yellow));
  balls.push(new Ball(26, 68, 0, 0, yellow));
  balls.push(new Ball(25, 75, 0, 0, yellow));
  balls.push(new Ball(25, 85, 0, 0, yellow));
  balls.push(new Ball(25, 88, 0, 0, yellow));
  balls.push(new Ball(26, 93, 0, 0, yellow));
  balls.push(new Ball(26, 95, 0, 0, yellow));
  balls.push(new Ball(26, 97, 0, 0, yellow));
  balls.push(new Ball(26, 97, 0, 0, yellow));
  balls.push(new Ball(26, 98, 0, 0, yellow));
  balls.push(new Ball(26, 98, 0, 0, yellow));
  balls.push(new Ball(24, 38, 0, 0, yellow));
  balls.push(new Ball(24, 38, 0, 0, yellow));
  balls.push(new Ball(24, 38, 0, 0, yellow));
  balls.push(new Ball(24, 38, 0, 0, yellow));
  balls.push(new Ball(26, 41, 0, 0, yellow));
  balls.push(new Ball(26, 41, 0, 0, yellow));
  balls.push(new Ball(26, 40, 0, 0, yellow));
  balls.push(new Ball(26, 40, 0, 0, yellow));
  balls.push(new Ball(26, 40, 0, 0, yellow));
  balls.push(new Ball(26, 40, 0, 0, yellow));
  balls.push(new Ball(26, 40, 0, 0, yellow));
  balls.push(new Ball(26, 41, 0, 0, yellow));
  balls.push(new Ball(27, 43, 0, 0, yellow));
  balls.push(new Ball(28, 45, 0, 0, yellow));
  balls.push(new Ball(31, 51, 0, 0, yellow));
  balls.push(new Ball(32, 54, 0, 0, yellow));
  balls.push(new Ball(36, 63, 0, 0, yellow));
  balls.push(new Ball(39, 68, 0, 0, yellow));
  balls.push(new Ball(43, 78, 0, 0, yellow));
  balls.push(new Ball(45, 81, 0, 0, yellow));
  balls.push(new Ball(47, 86, 0, 0, yellow));
  balls.push(new Ball(47, 88, 0, 0, yellow));
  balls.push(new Ball(49, 93, 0, 0, yellow));
  balls.push(new Ball(49, 94, 0, 0, yellow));
  balls.push(new Ball(50, 96, 0, 0, yellow));
  balls.push(new Ball(50, 96, 0, 0, yellow));
  balls.push(new Ball(50, 96, 0, 0, yellow));
  balls.push(new Ball(50, 95, 0, 0, yellow));
  balls.push(new Ball(50, 94, 0, 0, yellow));
  balls.push(new Ball(50, 90, 0, 0, yellow));
  balls.push(new Ball(50, 87, 0, 0, yellow));
  balls.push(new Ball(50, 83, 0, 0, yellow));
  balls.push(new Ball(50, 80, 0, 0, yellow));
  balls.push(new Ball(51, 73, 0, 0, yellow));
  balls.push(new Ball(51, 69, 0, 0, yellow));
  balls.push(new Ball(51, 62, 0, 0, yellow));
  balls.push(new Ball(51, 60, 0, 0, yellow));
  balls.push(new Ball(51, 55, 0, 0, yellow));
  balls.push(new Ball(51, 54, 0, 0, yellow));
  balls.push(new Ball(51, 51, 0, 0, yellow));
  balls.push(new Ball(51, 49, 0, 0, yellow));
  balls.push(new Ball(51, 46, 0, 0, yellow));
  balls.push(new Ball(51, 45, 0, 0, yellow));
  balls.push(new Ball(51, 41, 0, 0, yellow));
  balls.push(new Ball(51, 40, 0, 0, yellow));
  balls.push(new Ball(50, 39, 0, 0, yellow));
  balls.push(new Ball(50, 38, 0, 0, yellow));
  balls.push(new Ball(50, 36, 0, 0, yellow));
  balls.push(new Ball(49, 35, 0, 0, yellow));
  balls.push(new Ball(49, 34, 0, 0, yellow));
  balls.push(new Ball(49, 34, 0, 0, yellow));
  balls.push(new Ball(49, 32, 0, 0, yellow));
  balls.push(new Ball(48, 32, 0, 0, yellow));
  balls.push(new Ball(48, 31, 0, 0, yellow));
  balls.push(new Ball(48, 30, 0, 0, yellow));
  balls.push(new Ball(48, 29, 0, 0, yellow));
  balls.push(new Ball(48, 29, 0, 0, yellow));
  balls.push(new Ball(48, 29, 0, 0, yellow));
  balls.push(new Ball(48, 29, 0, 0, yellow));
  balls.push(new Ball(47, 30, 0, 0, yellow));

  balls.push(new Ball(60, 84, 0, 0, yellow));
  balls.push(new Ball(60, 84, 0, 0, yellow));
  balls.push(new Ball(60, 84, 0, 0, yellow));
  balls.push(new Ball(60, 84, 0, 0, yellow));
  balls.push(new Ball(61, 84, 0, 0, yellow));
  balls.push(new Ball(62, 84, 0, 0, yellow));
  balls.push(new Ball(66, 83, 0, 0, yellow));
  balls.push(new Ball(69, 83, 0, 0, yellow));
  balls.push(new Ball(73, 81, 0, 0, yellow));
  balls.push(new Ball(75, 81, 0, 0, yellow));
  balls.push(new Ball(79, 79, 0, 0, yellow));
  balls.push(new Ball(81, 77, 0, 0, yellow));
  balls.push(new Ball(81, 77, 0, 0, yellow));
  balls.push(new Ball(82, 75, 0, 0, yellow));
  balls.push(new Ball(82, 74, 0, 0, yellow));
  balls.push(new Ball(82, 72, 0, 0, yellow));
  balls.push(new Ball(82, 71, 0, 0, yellow));
  balls.push(new Ball(81, 68, 0, 0, yellow));
  balls.push(new Ball(81, 67, 0, 0, yellow));
  balls.push(new Ball(79, 65, 0, 0, yellow));
  balls.push(new Ball(79, 65, 0, 0, yellow));
  balls.push(new Ball(78, 65, 0, 0, yellow));
  balls.push(new Ball(78, 64, 0, 0, yellow));
  balls.push(new Ball(77, 64, 0, 0, yellow));
  balls.push(new Ball(76, 64, 0, 0, yellow));
  balls.push(new Ball(76, 65, 0, 0, yellow));
  balls.push(new Ball(75, 65, 0, 0, yellow));
  balls.push(new Ball(75, 65, 0, 0, yellow));
  balls.push(new Ball(74, 66, 0, 0, yellow));
  balls.push(new Ball(73, 66, 0, 0, yellow));
  balls.push(new Ball(71, 67, 0, 0, yellow));
  balls.push(new Ball(70, 68, 0, 0, yellow));
  balls.push(new Ball(68, 69, 0, 0, yellow));
  balls.push(new Ball(68, 70, 0, 0, yellow));
  balls.push(new Ball(67, 70, 0, 0, yellow));
  balls.push(new Ball(66, 71, 0, 0, yellow));
  balls.push(new Ball(66, 71, 0, 0, yellow));
  balls.push(new Ball(65, 72, 0, 0, yellow));
  balls.push(new Ball(65, 72, 0, 0, yellow));
  balls.push(new Ball(64, 73, 0, 0, yellow));
  balls.push(new Ball(64, 74, 0, 0, yellow));
  balls.push(new Ball(63, 76, 0, 0, yellow));
  balls.push(new Ball(63, 78, 0, 0, yellow));
  balls.push(new Ball(63, 79, 0, 0, yellow));
  balls.push(new Ball(63, 80, 0, 0, yellow));
  balls.push(new Ball(63, 81, 0, 0, yellow));
  balls.push(new Ball(63, 82, 0, 0, yellow));
  balls.push(new Ball(63, 82, 0, 0, yellow));
  balls.push(new Ball(63, 83, 0, 0, yellow));
  balls.push(new Ball(63, 84, 0, 0, yellow));
  balls.push(new Ball(63, 85, 0, 0, yellow));
  balls.push(new Ball(64, 86, 0, 0, yellow));
  balls.push(new Ball(64, 86, 0, 0, yellow));
  balls.push(new Ball(65, 87, 0, 0, yellow));
  balls.push(new Ball(65, 87, 0, 0, yellow));
  balls.push(new Ball(66, 88, 0, 0, yellow));
  balls.push(new Ball(66, 88, 0, 0, yellow));
  balls.push(new Ball(67, 88, 0, 0, yellow));
  balls.push(new Ball(69, 88, 0, 0, yellow));
  balls.push(new Ball(72, 89, 0, 0, yellow));
  balls.push(new Ball(74, 89, 0, 0, yellow));
  balls.push(new Ball(77, 89, 0, 0, yellow));
  balls.push(new Ball(79, 89, 0, 0, yellow));
  balls.push(new Ball(81, 89, 0, 0, yellow));
  balls.push(new Ball(81, 89, 0, 0, yellow));
  balls.push(new Ball(82, 89, 0, 0, yellow));
  balls.push(new Ball(83, 89, 0, 0, yellow));
  balls.push(new Ball(83, 89, 0, 0, yellow));
  balls.push(new Ball(83, 89, 0, 0, yellow));
  balls.push(new Ball(83, 89, 0, 0, yellow));
  balls.push(new Ball(83, 89, 0, 0, yellow));
  balls.push(new Ball(83, 89, 0, 0, yellow));
  balls.push(new Ball(83, 88, 0, 0, yellow));

  balls.push(new Ball(92, 41, 0, 0, yellow));
  balls.push(new Ball(92, 41, 0, 0, yellow));
  balls.push(new Ball(92, 41, 0, 0, yellow));
  balls.push(new Ball(92, 42, 0, 0, yellow));
  balls.push(new Ball(91, 44, 0, 0, yellow));
  balls.push(new Ball(91, 46, 0, 0, yellow));
  balls.push(new Ball(91, 51, 0, 0, yellow));
  balls.push(new Ball(91, 56, 0, 0, yellow));
  balls.push(new Ball(91, 62, 0, 0, yellow));
  balls.push(new Ball(91, 66, 0, 0, yellow));
  balls.push(new Ball(91, 71, 0, 0, yellow));
  balls.push(new Ball(91, 74, 0, 0, yellow));
  balls.push(new Ball(92, 78, 0, 0, yellow));
  balls.push(new Ball(92, 80, 0, 0, yellow));
  balls.push(new Ball(92, 81, 0, 0, yellow));
  balls.push(new Ball(92, 81, 0, 0, yellow));
  balls.push(new Ball(92, 82, 0, 0, yellow));
  balls.push(new Ball(92, 82, 0, 0, yellow));
  balls.push(new Ball(92, 83, 0, 0, yellow));
  balls.push(new Ball(92, 83, 0, 0, yellow));
  balls.push(new Ball(92, 84, 0, 0, yellow));
  balls.push(new Ball(92, 84, 0, 0, yellow));
  balls.push(new Ball(92, 84, 0, 0, yellow));
  balls.push(new Ball(92, 84, 0, 0, yellow));

  balls.push(new Ball(84, 60, 0, 0, yellow));
  balls.push(new Ball(84, 60, 0, 0, yellow));
  balls.push(new Ball(84, 60, 0, 0, yellow));
  balls.push(new Ball(85, 60, 0, 0, yellow));
  balls.push(new Ball(89, 60, 0, 0, yellow));
  balls.push(new Ball(90, 60, 0, 0, yellow));
  balls.push(new Ball(95, 60, 0, 0, yellow));
  balls.push(new Ball(96, 60, 0, 0, yellow));
  balls.push(new Ball(100, 60, 0, 0, yellow));
  balls.push(new Ball(100, 60, 0, 0, yellow));
  balls.push(new Ball(101, 60, 0, 0, yellow));
  balls.push(new Ball(101, 60, 0, 0, yellow));
  balls.push(new Ball(101, 60, 0, 0, yellow));

  balls.push(new Ball(114, 66, 0, 0, blue));
  balls.push(new Ball(114, 66, 0, 0, blue));
  balls.push(new Ball(114, 66, 0, 0, blue));
  balls.push(new Ball(113, 66, 0, 0, blue));
  balls.push(new Ball(113, 66, 0, 0, blue));
  balls.push(new Ball(113, 66, 0, 0, blue));
  balls.push(new Ball(112, 66, 0, 0, blue));
  balls.push(new Ball(111, 66, 0, 0, blue));
  balls.push(new Ball(111, 66, 0, 0, blue));
  balls.push(new Ball(110, 67, 0, 0, blue));
  balls.push(new Ball(109, 67, 0, 0, blue));
  balls.push(new Ball(108, 67, 0, 0, blue));
  balls.push(new Ball(108, 68, 0, 0, blue));
  balls.push(new Ball(108, 68, 0, 0, blue));
  balls.push(new Ball(108, 68, 0, 0, blue));
  balls.push(new Ball(108, 68, 0, 0, blue));
  balls.push(new Ball(108, 68, 0, 0, blue));
  balls.push(new Ball(108, 68, 0, 0, blue));
  balls.push(new Ball(108, 69, 0, 0, blue));
  balls.push(new Ball(108, 69, 0, 0, blue));
  balls.push(new Ball(108, 69, 0, 0, blue));
  balls.push(new Ball(108, 70, 0, 0, blue));
  balls.push(new Ball(110, 71, 0, 0, blue));
  balls.push(new Ball(110, 71, 0, 0, blue));
  balls.push(new Ball(111, 72, 0, 0, blue));
  balls.push(new Ball(111, 73, 0, 0, blue));
  balls.push(new Ball(111, 73, 0, 0, blue));
  balls.push(new Ball(112, 73, 0, 0, blue));
  balls.push(new Ball(112, 74, 0, 0, blue));
  balls.push(new Ball(112, 74, 0, 0, blue));
  balls.push(new Ball(112, 75, 0, 0, blue));
  balls.push(new Ball(113, 76, 0, 0, blue));
  balls.push(new Ball(113, 76, 0, 0, blue));
  balls.push(new Ball(113, 77, 0, 0, blue));
  balls.push(new Ball(113, 77, 0, 0, blue));
  balls.push(new Ball(113, 78, 0, 0, blue));
  balls.push(new Ball(113, 79, 0, 0, blue));
  balls.push(new Ball(113, 80, 0, 0, blue));
  balls.push(new Ball(113, 80, 0, 0, blue));
  balls.push(new Ball(113, 81, 0, 0, blue));
  balls.push(new Ball(112, 81, 0, 0, blue));
  balls.push(new Ball(112, 81, 0, 0, blue));
  balls.push(new Ball(111, 82, 0, 0, blue));
  balls.push(new Ball(110, 83, 0, 0, blue));
  balls.push(new Ball(109, 83, 0, 0, blue));
  balls.push(new Ball(108, 84, 0, 0, blue));
  balls.push(new Ball(108, 84, 0, 0, blue));
  balls.push(new Ball(108, 84, 0, 0, blue));
  balls.push(new Ball(108, 84, 0, 0, blue));
  balls.push(new Ball(107, 84, 0, 0, blue));
  balls.push(new Ball(106, 84, 0, 0, blue));
  balls.push(new Ball(106, 84, 0, 0, blue));
  balls.push(new Ball(106, 84, 0, 0, blue));
  balls.push(new Ball(105, 84, 0, 0, blue));
  balls.push(new Ball(105, 83, 0, 0, blue));
  balls.push(new Ball(104, 83, 0, 0, blue));
  balls.push(new Ball(103, 83, 0, 0, blue));
  balls.push(new Ball(103, 83, 0, 0, blue));
  balls.push(new Ball(103, 83, 0, 0, blue));
  balls.push(new Ball(103, 83, 0, 0, blue));
  balls.push(new Ball(103, 83, 0, 0, blue));
  balls.push(new Ball(102, 83, 0, 0, blue));
  balls.push(new Ball(102, 83, 0, 0, blue));
  balls.push(new Ball(101, 83, 0, 0, blue));

  balls.push(new Ball(132, 70, 0, 0, blue));
  balls.push(new Ball(131, 70, 0, 0, blue));
  balls.push(new Ball(131, 70, 0, 0, blue));
  balls.push(new Ball(131, 70, 0, 0, blue));
  balls.push(new Ball(129, 70, 0, 0, blue));
  balls.push(new Ball(129, 70, 0, 0, blue));
  balls.push(new Ball(128, 70, 0, 0, blue));
  balls.push(new Ball(127, 69, 0, 0, blue));
  balls.push(new Ball(126, 69, 0, 0, blue));
  balls.push(new Ball(125, 69, 0, 0, blue));
  balls.push(new Ball(123, 70, 0, 0, blue));
  balls.push(new Ball(123, 70, 0, 0, blue));
  balls.push(new Ball(122, 70, 0, 0, blue));
  balls.push(new Ball(122, 70, 0, 0, blue));
  balls.push(new Ball(122, 71, 0, 0, blue));
  balls.push(new Ball(121, 71, 0, 0, blue));
  balls.push(new Ball(121, 72, 0, 0, blue));
  balls.push(new Ball(121, 72, 0, 0, blue));
  balls.push(new Ball(121, 73, 0, 0, blue));
  balls.push(new Ball(120, 74, 0, 0, blue));
  balls.push(new Ball(120, 75, 0, 0, blue));
  balls.push(new Ball(120, 76, 0, 0, blue));
  balls.push(new Ball(120, 76, 0, 0, blue));
  balls.push(new Ball(120, 77, 0, 0, blue));
  balls.push(new Ball(120, 77, 0, 0, blue));
  balls.push(new Ball(120, 78, 0, 0, blue));
  balls.push(new Ball(120, 78, 0, 0, blue));
  balls.push(new Ball(120, 78, 0, 0, blue));
  balls.push(new Ball(120, 78, 0, 0, blue));
  balls.push(new Ball(120, 78, 0, 0, blue));
  balls.push(new Ball(121, 78, 0, 0, blue));
  balls.push(new Ball(121, 79, 0, 0, blue));
  balls.push(new Ball(121, 79, 0, 0, blue));
  balls.push(new Ball(121, 79, 0, 0, blue));
  balls.push(new Ball(121, 79, 0, 0, blue));
  balls.push(new Ball(121, 79, 0, 0, blue));
  balls.push(new Ball(122, 79, 0, 0, blue));
  balls.push(new Ball(123, 79, 0, 0, blue));
  balls.push(new Ball(123, 78, 0, 0, blue));
  balls.push(new Ball(124, 78, 0, 0, blue));
  balls.push(new Ball(125, 78, 0, 0, blue));
  balls.push(new Ball(125, 78, 0, 0, blue));
  balls.push(new Ball(125, 78, 0, 0, blue));
  balls.push(new Ball(126, 78, 0, 0, blue));
  balls.push(new Ball(126, 78, 0, 0, blue));
  balls.push(new Ball(127, 77, 0, 0, blue));
  balls.push(new Ball(127, 77, 0, 0, blue));
  balls.push(new Ball(128, 77, 0, 0, blue));
  balls.push(new Ball(128, 76, 0, 0, blue));
  balls.push(new Ball(128, 76, 0, 0, blue));
  balls.push(new Ball(129, 76, 0, 0, blue));
  balls.push(new Ball(129, 75, 0, 0, blue));
  balls.push(new Ball(129, 75, 0, 0, blue));
  balls.push(new Ball(130, 75, 0, 0, blue));
  balls.push(new Ball(130, 74, 0, 0, blue));
  balls.push(new Ball(130, 73, 0, 0, blue));
  balls.push(new Ball(131, 73, 0, 0, blue));
  balls.push(new Ball(131, 73, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 71, 0, 0, blue));
  balls.push(new Ball(131, 71, 0, 0, blue));
  balls.push(new Ball(131, 71, 0, 0, blue));
  balls.push(new Ball(131, 71, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 72, 0, 0, blue));
  balls.push(new Ball(131, 73, 0, 0, blue));
  balls.push(new Ball(131, 74, 0, 0, blue));
  balls.push(new Ball(131, 74, 0, 0, blue));
  balls.push(new Ball(132, 77, 0, 0, blue));
  balls.push(new Ball(133, 77, 0, 0, blue));
  balls.push(new Ball(133, 78, 0, 0, blue));
  balls.push(new Ball(134, 78, 0, 0, blue));
  balls.push(new Ball(134, 78, 0, 0, blue));
  balls.push(new Ball(134, 79, 0, 0, blue));
  balls.push(new Ball(134, 79, 0, 0, blue));
  balls.push(new Ball(134, 79, 0, 0, blue));
  balls.push(new Ball(134, 79, 0, 0, blue));
  balls.push(new Ball(134, 79, 0, 0, blue));

  balls.push(new Ball(141, 66, 0, 0, blue));
  balls.push(new Ball(141, 67, 0, 0, blue));
  balls.push(new Ball(140, 67, 0, 0, blue));
  balls.push(new Ball(140, 68, 0, 0, blue));
  balls.push(new Ball(140, 70, 0, 0, blue));
  balls.push(new Ball(140, 71, 0, 0, blue));
  balls.push(new Ball(140, 75, 0, 0, blue));
  balls.push(new Ball(140, 77, 0, 0, blue));
  balls.push(new Ball(140, 81, 0, 0, blue));
  balls.push(new Ball(140, 85, 0, 0, blue));
  balls.push(new Ball(141, 92, 0, 0, blue));
  balls.push(new Ball(141, 94, 0, 0, blue));
  balls.push(new Ball(141, 98, 0, 0, blue));
  balls.push(new Ball(141, 99, 0, 0, blue));
  balls.push(new Ball(141, 99, 0, 0, blue));
  balls.push(new Ball(141, 100, 0, 0, blue));
  balls.push(new Ball(141, 102, 0, 0, blue));
  balls.push(new Ball(141, 103, 0, 0, blue));
  balls.push(new Ball(141, 104, 0, 0, blue));
  balls.push(new Ball(141, 104, 0, 0, blue));
  balls.push(new Ball(141, 104, 0, 0, blue));
  balls.push(new Ball(141, 104, 0, 0, blue));
  balls.push(new Ball(141, 102, 0, 0, blue));
  balls.push(new Ball(141, 100, 0, 0, blue));

  balls.push(new Ball(139, 69, 0, 0, blue));
  balls.push(new Ball(140, 69, 0, 0, blue));
  balls.push(new Ball(140, 68, 0, 0, blue));
  balls.push(new Ball(141, 67, 0, 0, blue));
  balls.push(new Ball(143, 66, 0, 0, blue));
  balls.push(new Ball(143, 65, 0, 0, blue));
  balls.push(new Ball(145, 65, 0, 0, blue));
  balls.push(new Ball(147, 64, 0, 0, blue));
  balls.push(new Ball(150, 64, 0, 0, blue));
  balls.push(new Ball(152, 64, 0, 0, blue));
  balls.push(new Ball(154, 64, 0, 0, blue));
  balls.push(new Ball(157, 64, 0, 0, blue));
  balls.push(new Ball(157, 64, 0, 0, blue));
  balls.push(new Ball(158, 65, 0, 0, blue));
  balls.push(new Ball(159, 67, 0, 0, blue));
  balls.push(new Ball(159, 68, 0, 0, blue));
  balls.push(new Ball(159, 70, 0, 0, blue));
  balls.push(new Ball(159, 72, 0, 0, blue));
  balls.push(new Ball(158, 73, 0, 0, blue));
  balls.push(new Ball(158, 74, 0, 0, blue));
  balls.push(new Ball(157, 75, 0, 0, blue));
  balls.push(new Ball(156, 76, 0, 0, blue));
  balls.push(new Ball(154, 77, 0, 0, blue));
  balls.push(new Ball(153, 79, 0, 0, blue));
  balls.push(new Ball(150, 80, 0, 0, blue));
  balls.push(new Ball(149, 81, 0, 0, blue));
  balls.push(new Ball(147, 81, 0, 0, blue));
  balls.push(new Ball(146, 82, 0, 0, blue));
  balls.push(new Ball(144, 82, 0, 0, blue));
  balls.push(new Ball(144, 82, 0, 0, blue));
  balls.push(new Ball(141, 82, 0, 0, blue));
  balls.push(new Ball(141, 82, 0, 0, blue));
  balls.push(new Ball(140, 82, 0, 0, blue));
  balls.push(new Ball(140, 82, 0, 0, blue));
  balls.push(new Ball(139, 82, 0, 0, blue));
  balls.push(new Ball(139, 82, 0, 0, blue));
  balls.push(new Ball(138, 82, 0, 0, blue));
  balls.push(new Ball(138, 81, 0, 0, blue));

  balls.push(new Ball(169, 65, 0, 0, blue));
  balls.push(new Ball(169, 65, 0, 0, blue));
  balls.push(new Ball(169, 65, 0, 0, blue));
  balls.push(new Ball(168, 66, 0, 0, blue));
  balls.push(new Ball(168, 67, 0, 0, blue));
  balls.push(new Ball(169, 70, 0, 0, blue));
  balls.push(new Ball(169, 72, 0, 0, blue));
  balls.push(new Ball(170, 77, 0, 0, blue));
  balls.push(new Ball(170, 79, 0, 0, blue));
  balls.push(new Ball(171, 82, 0, 0, blue));
  balls.push(new Ball(171, 83, 0, 0, blue));
  balls.push(new Ball(171, 83, 0, 0, blue));
  balls.push(new Ball(171, 83, 0, 0, blue));

  balls.push(new Ball(181, 77, 0, 0, blue));
  balls.push(new Ball(181, 77, 0, 0, blue));
  balls.push(new Ball(182, 77, 0, 0, blue));
  balls.push(new Ball(182, 76, 0, 0, blue));
  balls.push(new Ball(185, 75, 0, 0, blue));
  balls.push(new Ball(186, 75, 0, 0, blue));
  balls.push(new Ball(189, 73, 0, 0, blue));
  balls.push(new Ball(190, 73, 0, 0, blue));
  balls.push(new Ball(191, 72, 0, 0, blue));
  balls.push(new Ball(192, 71, 0, 0, blue));
  balls.push(new Ball(192, 71, 0, 0, blue));
  balls.push(new Ball(192, 69, 0, 0, blue));
  balls.push(new Ball(193, 68, 0, 0, blue));
  balls.push(new Ball(193, 66, 0, 0, blue));
  balls.push(new Ball(192, 65, 0, 0, blue));
  balls.push(new Ball(191, 63, 0, 0, blue));
  balls.push(new Ball(191, 62, 0, 0, blue));
  balls.push(new Ball(189, 60, 0, 0, blue));
  balls.push(new Ball(188, 59, 0, 0, blue));
  balls.push(new Ball(186, 58, 0, 0, blue));
  balls.push(new Ball(185, 57, 0, 0, blue));
  balls.push(new Ball(183, 56, 0, 0, blue));
  balls.push(new Ball(182, 56, 0, 0, blue));
  balls.push(new Ball(181, 56, 0, 0, blue));
  balls.push(new Ball(181, 56, 0, 0, blue));
  balls.push(new Ball(181, 57, 0, 0, blue));
  balls.push(new Ball(181, 57, 0, 0, blue));
  balls.push(new Ball(180, 58, 0, 0, blue));
  balls.push(new Ball(180, 58, 0, 0, blue));
  balls.push(new Ball(179, 60, 0, 0, blue));
  balls.push(new Ball(178, 61, 0, 0, blue));
  balls.push(new Ball(177, 62, 0, 0, blue));
  balls.push(new Ball(177, 64, 0, 0, blue));
  balls.push(new Ball(177, 65, 0, 0, blue));
  balls.push(new Ball(177, 67, 0, 0, blue));
  balls.push(new Ball(177, 68, 0, 0, blue));
  balls.push(new Ball(176, 69, 0, 0, blue));
  balls.push(new Ball(176, 71, 0, 0, blue));
  balls.push(new Ball(176, 72, 0, 0, blue));
  balls.push(new Ball(176, 73, 0, 0, blue));
  balls.push(new Ball(176, 74, 0, 0, blue));
  balls.push(new Ball(176, 75, 0, 0, blue));
  balls.push(new Ball(176, 76, 0, 0, blue));
  balls.push(new Ball(176, 76, 0, 0, blue));
  balls.push(new Ball(177, 77, 0, 0, blue));
  balls.push(new Ball(177, 78, 0, 0, blue));
  balls.push(new Ball(178, 79, 0, 0, blue));
  balls.push(new Ball(178, 79, 0, 0, blue));
  balls.push(new Ball(179, 80, 0, 0, blue));
  balls.push(new Ball(179, 80, 0, 0, blue));
  balls.push(new Ball(180, 81, 0, 0, blue));
  balls.push(new Ball(181, 81, 0, 0, blue));
  balls.push(new Ball(182, 81, 0, 0, blue));
  balls.push(new Ball(184, 82, 0, 0, blue));
  balls.push(new Ball(187, 82, 0, 0, blue));
  balls.push(new Ball(189, 82, 0, 0, blue));
  balls.push(new Ball(191, 83, 0, 0, blue));
  balls.push(new Ball(192, 83, 0, 0, blue));
  balls.push(new Ball(193, 83, 0, 0, blue));
  balls.push(new Ball(193, 83, 0, 0, blue));
  balls.push(new Ball(193, 83, 0, 0, blue));
  balls.push(new Ball(194, 83, 0, 0, blue));
  balls.push(new Ball(194, 83, 0, 0, blue));
  balls.push(new Ball(194, 83, 0, 0, blue));
  balls.push(new Ball(194, 83, 0, 0, blue));
  balls.push(new Ball(194, 83, 0, 0, blue));
  balls.push(new Ball(195, 82, 0, 0, blue));

  balls.push(new Ball(200, 60, 0, 0, blue));
  balls.push(new Ball(200, 60, 0, 0, blue));
  balls.push(new Ball(200, 60, 0, 0, blue));
  balls.push(new Ball(200, 61, 0, 0, blue));
  balls.push(new Ball(200, 62, 0, 0, blue));
  balls.push(new Ball(200, 66, 0, 0, blue));
  balls.push(new Ball(200, 69, 0, 0, blue));
  balls.push(new Ball(200, 72, 0, 0, blue));
  balls.push(new Ball(201, 77, 0, 0, blue));
  balls.push(new Ball(201, 79, 0, 0, blue));
  balls.push(new Ball(201, 81, 0, 0, blue));
  balls.push(new Ball(201, 81, 0, 0, blue));
  balls.push(new Ball(201, 81, 0, 0, blue));
  balls.push(new Ball(201, 81, 0, 0, blue));
  balls.push(new Ball(201, 81, 0, 0, blue));
  balls.push(new Ball(201, 80, 0, 0, blue));
  balls.push(new Ball(201, 79, 0, 0, blue));
  balls.push(new Ball(201, 78, 0, 0, blue));
  balls.push(new Ball(201, 75, 0, 0, blue));
  balls.push(new Ball(201, 73, 0, 0, blue));
  balls.push(new Ball(201, 71, 0, 0, blue));
  balls.push(new Ball(201, 69, 0, 0, blue));
  balls.push(new Ball(201, 67, 0, 0, blue));
  balls.push(new Ball(202, 66, 0, 0, blue));
  balls.push(new Ball(202, 64, 0, 0, blue));
  balls.push(new Ball(202, 64, 0, 0, blue));
  balls.push(new Ball(203, 63, 0, 0, blue));
  balls.push(new Ball(203, 63, 0, 0, blue));
  balls.push(new Ball(204, 62, 0, 0, blue));
  balls.push(new Ball(204, 62, 0, 0, blue));
  balls.push(new Ball(205, 62, 0, 0, blue));
  balls.push(new Ball(206, 62, 0, 0, blue));
  balls.push(new Ball(207, 62, 0, 0, blue));
  balls.push(new Ball(208, 62, 0, 0, blue));
  balls.push(new Ball(209, 62, 0, 0, blue));
  balls.push(new Ball(210, 63, 0, 0, blue));
  balls.push(new Ball(211, 63, 0, 0, blue));
  balls.push(new Ball(212, 64, 0, 0, blue));
  balls.push(new Ball(212, 66, 0, 0, blue));
  balls.push(new Ball(214, 68, 0, 0, blue));
  balls.push(new Ball(214, 69, 0, 0, blue));
  balls.push(new Ball(214, 71, 0, 0, blue));
  balls.push(new Ball(215, 72, 0, 0, blue));
  balls.push(new Ball(215, 75, 0, 0, blue));
  balls.push(new Ball(215, 76, 0, 0, blue));
  balls.push(new Ball(215, 76, 0, 0, blue));
  balls.push(new Ball(215, 76, 0, 0, blue));
  balls.push(new Ball(215, 76, 0, 0, blue));
  balls.push(new Ball(215, 76, 0, 0, blue));

  balls.push(new Ball(236, 57, 0, 0, blue));
  balls.push(new Ball(236, 57, 0, 0, blue));
  balls.push(new Ball(235, 56, 0, 0, blue));
  balls.push(new Ball(234, 56, 0, 0, blue));
  balls.push(new Ball(232, 56, 0, 0, blue));
  balls.push(new Ball(231, 56, 0, 0, blue));
  balls.push(new Ball(229, 56, 0, 0, blue));
  balls.push(new Ball(228, 56, 0, 0, blue));
  balls.push(new Ball(228, 56, 0, 0, blue));
  balls.push(new Ball(226, 56, 0, 0, blue));
  balls.push(new Ball(226, 56, 0, 0, blue));
  balls.push(new Ball(224, 57, 0, 0, blue));
  balls.push(new Ball(224, 57, 0, 0, blue));
  balls.push(new Ball(224, 57, 0, 0, blue));
  balls.push(new Ball(223, 57, 0, 0, blue));
  balls.push(new Ball(223, 57, 0, 0, blue));
  balls.push(new Ball(223, 57, 0, 0, blue));
  balls.push(new Ball(223, 57, 0, 0, blue));
  balls.push(new Ball(223, 58, 0, 0, blue));
  balls.push(new Ball(223, 58, 0, 0, blue));
  balls.push(new Ball(223, 58, 0, 0, blue));
  balls.push(new Ball(223, 60, 0, 0, blue));
  balls.push(new Ball(223, 60, 0, 0, blue));
  balls.push(new Ball(222, 61, 0, 0, blue));
  balls.push(new Ball(222, 62, 0, 0, blue));
  balls.push(new Ball(222, 63, 0, 0, blue));
  balls.push(new Ball(222, 64, 0, 0, blue));
  balls.push(new Ball(222, 65, 0, 0, blue));
  balls.push(new Ball(222, 65, 0, 0, blue));
  balls.push(new Ball(222, 66, 0, 0, blue));
  balls.push(new Ball(222, 66, 0, 0, blue));
  balls.push(new Ball(222, 66, 0, 0, blue));
  balls.push(new Ball(222, 66, 0, 0, blue));
  balls.push(new Ball(223, 66, 0, 0, blue));
  balls.push(new Ball(223, 67, 0, 0, blue));
  balls.push(new Ball(226, 67, 0, 0, blue));
  balls.push(new Ball(227, 67, 0, 0, blue));
  balls.push(new Ball(229, 68, 0, 0, blue));
  balls.push(new Ball(233, 68, 0, 0, blue));
  balls.push(new Ball(236, 68, 0, 0, blue));
  balls.push(new Ball(240, 69, 0, 0, blue));
  balls.push(new Ball(242, 70, 0, 0, blue));
  balls.push(new Ball(244, 70, 0, 0, blue));
  balls.push(new Ball(244, 71, 0, 0, blue));
  balls.push(new Ball(245, 71, 0, 0, blue));
  balls.push(new Ball(245, 72, 0, 0, blue));
  balls.push(new Ball(246, 73, 0, 0, blue));
  balls.push(new Ball(246, 73, 0, 0, blue));
  balls.push(new Ball(246, 74, 0, 0, blue));
  balls.push(new Ball(246, 75, 0, 0, blue));
  balls.push(new Ball(245, 76, 0, 0, blue));
  balls.push(new Ball(245, 77, 0, 0, blue));
  balls.push(new Ball(243, 79, 0, 0, blue));
  balls.push(new Ball(242, 80, 0, 0, blue));
  balls.push(new Ball(239, 83, 0, 0, blue));
  balls.push(new Ball(237, 84, 0, 0, blue));
  balls.push(new Ball(233, 87, 0, 0, blue));
  balls.push(new Ball(232, 88, 0, 0, blue));
  balls.push(new Ball(228, 89, 0, 0, blue));
  balls.push(new Ball(227, 89, 0, 0, blue));
  balls.push(new Ball(224, 90, 0, 0, blue));
  balls.push(new Ball(224, 90, 0, 0, blue));
  balls.push(new Ball(224, 90, 0, 0, blue));
  return balls;
}

function updateBalls(canvas, balls, timeDiff, mousePos) {
  // var context = canvas.getContext('2d');
  if (canvas) {
    const collisionDamper = 0.3;
    const floorFriction = 0.0005 * timeDiff;
    const mouseForceMultiplier = 1 * timeDiff;
    const restoreForce = 0.002 * timeDiff;

    for (let n = 0; n < balls.length; n++) {
      const ball = balls[n];
      // set ball position based on velocity
      ball.y += ball.vy;
      ball.x += ball.vx;

      // restore forces
      if (ball.x > ball.origX) {
        ball.vx -= restoreForce;
      } else {
        ball.vx += restoreForce;
      }
      if (ball.y > ball.origY) {
        ball.vy -= restoreForce;
      } else {
        ball.vy += restoreForce;
      }

      // mouse forces
      const mouseX = mousePos.x;
      const mouseY = mousePos.y;

      const distX = ball.x - mouseX;
      const distY = ball.y - mouseY;

      const radius = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2));

      const totalDist = Math.abs(distX) + Math.abs(distY);

      const forceX = (Math.abs(distX) / totalDist) * (1 / radius) * mouseForceMultiplier;
      const forceY = (Math.abs(distY) / totalDist) * (1 / radius) * mouseForceMultiplier;

      if (distX > 0) { // mouse is left of ball
        ball.vx += forceX;
      } else {
        ball.vx -= forceX;
      }
      if (distY > 0) { // mouse is on top of ball
        ball.vy += forceY;
      } else {
        ball.vy -= forceY;
      }

      // floor friction
      if (ball.vx > 0) {
        ball.vx -= floorFriction;
      } else if (ball.vx < 0) {
        ball.vx += floorFriction;
      }
      if (ball.vy > 0) {
        ball.vy -= floorFriction;
      } else if (ball.vy < 0) {
        ball.vy += floorFriction;
      }

      // floor condition
      if (ball.y > (canvas.height - ball.radius)) {
        ball.y = canvas.height - ball.radius - 2;
        ball.vy *= -1;
        ball.vy *= (1 - collisionDamper);
      }

      // ceiling condition
      if (ball.y < (ball.radius)) {
        ball.y = ball.radius + 2;
        ball.vy *= -1;
        ball.vy *= (1 - collisionDamper);
      }

      // right wall condition
      if (ball.x > (canvas.width - ball.radius)) {
        ball.x = canvas.width - ball.radius - 2;
        ball.vx *= -1;
        ball.vx *= (1 - collisionDamper);
      }

      // left wall condition
      if (ball.x < (ball.radius)) {
        ball.x = ball.radius + 2;
        ball.vx *= -1;
        ball.vx *= (1 - collisionDamper);
      }
    }
  }
}

function animate(canvas, balls, lastTime, mousePos) {
  if (canvas) {
    var context = canvas.getContext('2d');
  }

  // update
  const date = new Date();
  const time = date.getTime();
  const timeDiff = time - lastTime;
  updateBalls(canvas, balls, timeDiff, mousePos);
  lastTime = time;

  // clear
  if (context) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    // render
    for (let n = 0; n < balls.length; n++) {
      const ball = balls[n];
      context.beginPath();
      context.arc(ball.x, ball.y, ball.radius, 0, 2 * Math.PI, false);
      context.fillStyle = ball.color;
      context.fill();
    }
  }

  // request new frame
  window.requestAnimFrame(() => {
    animate(canvas, balls, lastTime, mousePos);
  });
}

export default {
  template,
  controller,
};

controller.$inject = [
  '$mdColors',
  '$mdDialog',
  '$ngRedux',
  '$translate',
];

function controller(
  $mdColors,
  $mdDialog,
  $ngRedux,
  $translate,
) {
  const self = this;
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      extensionApp: extensionActions.selectExtensionApp(state),
      wpName: state.configs.get('wpName'),
      user: userActions.getUser(state),
      screenSize: state.screenSize,
    }))(this);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    if (unsubscribe) {
      unsubscribe();
    }
  };

  this.fab = true;
  this.next = () => {
    this.fab = false;
    this.profile = true;
    this.loadegg = false;
  };

  /**
     *
     */
  this.dismiss = async () => {
    const appName = $ngRedux.getState().configs.get('appName');
    const token = nsToken.getDecoded();
    localStorage.setItem(`${appName}-${token.user}_first-login`, 'false');
    this.user.isFirstLogin = false;
    this.showSimRingDialog();
  };

  /**
     *
     */
  this.showSimRingDialog = () => {
    const arList = arActions.getAnsweringRules($ngRedux.getState());
    if (arList) {
      let rule;
      let isDefault = false;
      let simEnabled = false;

      // find active rule
      for (let i = 0; i < arList.length; i += 1) {
        if (arList[i].active === true) {
          rule = arList[i];
          isDefault = rule.time_frame === '*';
          simEnabled = arList[i].sim_control === 'e';
          break;
        }
      }

      const accent = { color: $mdColors.getThemeColor('accent') };

      if (rule) {
        // test if the rule i the default and simring is not enabled
        if (isDefault && !simEnabled) {
          $mdDialog.show({
            clickOutsideToClose: false,
            template: `${'<md-dialog>'
                            + '   <md-dialog-content>'
                            + '       <div class="md-dialog-content p-b-0">'
                            + '           <p>'}${
              $translate.instant('WOULD_YOU_LIKE_TO_ENABLE')} ${self.wpName} ${$translate.instant('TO_RECEIVE_INBOUND_CALLS')}?`
                            + '           </p>'
                            + '       </div>'
                            + '   </md-dialog-content>'
                            + '   <md-dialog-actions layout="row">'
                            + '       <span flex></span>'
                            + `       <md-button class="md-primary" ng-click="$ctrl.yes();" md-autofocus>${$translate.instant('YES')}</md-button>`
                            + `       <md-button ng-click="$ctrl.close();" style="color:${accent}">${$translate.instant('NO_THANKS')}</md-button>`
                            + '   </md-dialog-actions>'
                            + '</md-dialog>',
            controllerAs: '$ctrl',
            controller() {
              this.yes = () => {
                $ngRedux.dispatch(arActions.updateSimring(rule));
                $mdDialog.hide();
                devicesNeedConsent();
              };
              this.close = () => {
                $mdDialog.hide();
                devicesNeedConsent();
              };
            },
          });
        } else if (!isDefault && !simEnabled) {
          const message = $ngRedux.getState().configs.get('simringNotice');
          $mdDialog.show({
            clickOutsideToClose: false,
            template: `${'<md-dialog>'
                            + '   <md-dialog-content>'
                            + '       <div class="md-dialog-content p-b-0">'
                            + '        <p>'
                            + '               '}${message
            }        </p>`
                            + '       </div>'
                            + '   </md-dialog-content>'
                            + '   <md-dialog-actions layout="row">'
                            + '       <span flex></span>'
                            + '       <md-button class="md-primary" ng-click="$ctrl.close();" md-autofocus>OK</md-button>'
                            + '   </md-dialog-actions>'
                            + '</md-dialog>',
            controllerAs: '$ctrl',
            controller() {
              this.close = () => {
                $mdDialog.hide();
                devicesNeedConsent();
              };
            },
          });
        } else {
          devicesNeedConsent();
        }
      } else {
        devicesNeedConsent();
      }
    } else {
      devicesNeedConsent();
    }
  };

  let count = 1;
  this.show = () => {
    if (count < 10) {
      count++;
    } else {
      this.loadegg = true;

      window.requestAnimFrame = (function () {
        return window.requestAnimationFrame;
      }());

      const canvas = document.getElementById('myCanvas');
      const balls = initBalls();
      const date = new Date();
      const time = date.getTime();

      /*
             * set mouse position really far away
             * so the mouse forces are nearly obsolete
             */
      const mousePos = {
        x: 9999,
        y: 9999,
      };

      if (canvas) {
        canvas.addEventListener('mousemove', (evt) => {
          const pos = getMousePos(canvas, evt);
          mousePos.x = pos.x;
          mousePos.y = pos.y;
        });

        canvas.addEventListener('mouseout', () => {
          mousePos.x = 9999;
          mousePos.y = 9999;
        });
      }

      animate(canvas, balls, time, mousePos);
    }
  };
}

import { put, select, takeEvery } from 'redux-saga/effects';

import { fetchContacts } from '../contacts';
import { SET_USER } from '../user';

export default function* () {
  yield takeEvery(SET_USER, _fetchContacts);
}

const _fetchContacts = function* () {
  const length = yield select((state) => state.contacts.get('contacts').ids.length);
  if (!length) {
    yield put(fetchContacts());
  }
};

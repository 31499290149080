import { Map } from 'immutable';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

/** ********************************************
 * Redux constants
 ******************************************** */

export const GREETINGS = 'core/greetings/GREETINGS';
export const LOADING = 'core/greetings/LOADING';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  greetings: null,
  loading: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return state.set('loading', action.payload);
    case GREETINGS:
      state = state.set('loading', false);
      return state.set('greetings', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {function(*,*)}
 */
export const fetchGreetings = () => (dispatch) => {
  const token = nsToken.getDecoded();

  dispatch({
    type: LOADING,
    payload: true,
  });

  return nsApi.get({
    object: 'audio',
    action: 'read',
    user: token.user,
    domain: token.domain,
    format: 'json',
    intArray: 'true',
    type: 'greeting',
  }).then((res) => {
    const greetings = [];
    for (let i = 0; i < res.length; i += 1) {
      res[i].remotepath = res[i].remotepath.replace(/&amp;/g, '&');
      res[i].remotepath = res[i].remotepath.replace(/%40/g, '@');
      greetings.push(res[i]);
    }

    dispatch({
      type: GREETINGS,
      payload: greetings,
    });
  }).catch(() => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @param greeting
 */
export const updateGreeting = (greeting) => () => {
  const decodedToken = nsToken.getDecoded();
  const params = {
    vmail_greeting: greeting.index,
    domain: decodedToken.domain,
    user: decodedToken.user,
    object: 'subscriber',
    action: 'update',
    format: 'json',
  };

  nsApi.post(params);
};

/**
 *
 * @param greeting
 */
export const deleteGreeting = (greeting) => (dispatch, getState) => {
  const decodedToken = nsToken.getDecoded();
  const params = {
    type: 'greeting',
    owner_domain: decodedToken.domain,
    owner: decodedToken.user,
    object: 'audio',
    action: 'delete',
    format: 'json',
    index: greeting.index,
    intArray: true,
  };

  nsApi.post(params).then(() => {
    const greetings = getState().greetings.get('greetings');

    const newList = [];
    for (let i = 0; i < greetings.length; i += 1) {
      if (greetings[i].index !== greeting.index) {
        newList.push(greetings[i]);
      }
    }

    dispatch({
      type: GREETINGS,
      payload: newList,
    });
  });
};

/**
 * @param name
 * @param greeting
 * @returns {function(*, *)}
 */
export const save = (name, greeting) => (dispatch, getState) => {
  const apiURL = getState().configs.get('apiURL');
  const decodedToken = nsToken.getDecoded();
  const greetings = getState().greetings.get('greetings');

  let index;
  if (greetings.length) {
    index = (parseInt(greetings[greetings.length - 1].index) + 1);
  } else {
    index = 1;
  }

  let url = apiURL;
  url += '?object=audio';
  url += '&action=upload';
  url += '&format=json';
  url += `&owner_domain=${decodedToken.domain}`;
  url += `&owner=${decodedToken.user}`;
  url += '&type=greeting';
  url += `&script=${name}`;
  url += '&convert=yes';
  url += `&index=${index}`;

  const formData = new FormData();
  formData.append('file', greeting);

  const request = new XMLHttpRequest();
  request.open('POST', url);
  request.setRequestHeader('Authorization', `Bearer ${nsToken.get()}`);
  request.onreadystatechange = stateChange;
  request.send(formData);

  function stateChange() {
    if (request.readyState === 4 && (request.status === 200 || request.status === 202)) {
      dispatch(fetchGreetings());
    } else if (request.readyState === 4 && (request.status !== 200 && request.status !== 202)) {
      // todo error handling
    }
  }
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @return {boolean}
 */
export const getLoading = (state) => state.greetings.get('loading');

/**
 *
 * @param state
 * @return {[]}
 */
export const getGreetings = (state) => state.greetings.get('greetings');

import { Map } from 'immutable';
import nsModels from '@netsapiens/netsapiens-js/dist/models';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { matchContact } from '../contacts';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

/** ********************************************
 * Redux constants
 ******************************************** */

export const CARDS = 'core/card-management/CARDS';
export const EXPANDED = 'core/card-management/EXPANDED';
export const LOADING = 'core/card-management/LOADING';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  cards: nsUtils.normalizeList('id', []),
  expandedId: null,
  loading: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CARDS:
      return state.set('cards', nsUtils.normalizeList('id', action.payload));
    case EXPANDED:
      return state.set('expandedId', action.payload);
    case LOADING:
      return state.set('loading', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 * Gets cards stored in local storage
 * Creates default cards if no local storage
 * @return {function(*, *)}
 */
export const fetchCards = () => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');

  // get and parse local storage cards
  const storageCards = JSON.parse(
    localStorage.getItem(`${appName}-${userId}_cards`) || '[]',
  );

  const cards = [];
  let chatSessionRequests = [];
  const decodedToken = nsToken.getDecoded();

  if (storageCards.length) {
    // convert json to Card classes
    for (let i = 0; i < storageCards.length; i += 1) {
      // if the browser was refreshed or closed omit call and new-chat cards
      if (storageCards[i].type !== 'call'
                    && storageCards[i].type !== 'new-chat'
                    && storageCards[i].type !== 'dialer'
      ) {
        const card = new nsModels.card.Card(storageCards[i]);

        // update contact for chat cards
        if (card.type === 'chat') {
          const contact = matchContact(card.meta.sendMessageParams.destination);
          if (contact) {
            card.meta.contactId = contact.id;
          } else {
            card.meta.contactId = null;
          }
          chatSessionRequests.push(Promise.all([
            Promise.resolve(card),
            nsApi.get({
              object: 'messagesession',
              action: 'read',
              user: decodedToken.user,
              domain: decodedToken.domain,
              session_id: card.meta.sessionId,
            }).then((res) => {
              if (!res[0]) {
                return;
              }
              return res[0];
            })
          ]));
        } else cards.push(card);
      }
    }

    Promise.all(chatSessionRequests).then((res) => {
      res.forEach(item => {
        if (item[1]) {
          let start_ts = (new Date(item[1].start_timestamp)).getTime();
          let last_ts = (new Date(item[1].last_timestamp)).getTime();
          if (start_ts < last_ts) {
            cards.unshift(item[0]);
          } // else do nothing, invalid session
        }
      });

      // update local storage after possible removal of call and new-chat cards
      localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(cards));

      // dispatch cards
      dispatch({
        type: CARDS,
        payload: cards,
      });

      dispatch({
        type: LOADING,
        payload: false,
      });
    });

  } else {
    // create user card
    const userCard = {
      id: nsUtils.randomId(),
      type: 'user',
    };
    const card = new nsModels.card.Card(userCard);
    cards.push(card);

    // dispatch cards
    dispatch({
      type: CARDS,
      payload: cards,
    });

    // dispatch expanded
    dispatch({
      type: EXPANDED,
      payload: card.id,
    });

    // update local storage
    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(cards));

    dispatch({
      type: LOADING,
      payload: false,
    });
  }
};

/**
 *
 * @param contactId
 * @param sendMessageParams
 * @param sessionId
 * @param queueId
 * @param type
 * @return {function(*, *)}
 */
export const newCard = ({
  contactId = null,
  sendMessageParams = null,
  sessionId = null,
  queueId = null,
  type = 'chat',
  videoInvite = false,
  videoId = null,
  videoInviteFrom = null,
  details = {},
}) => (dispatch, getState) => {
  const card = new nsModels.card.Card({
    id: nsUtils.randomId(),
    meta: {
      contactId,
      sessionId,
      queueId,
      sendMessageParams,
      videoInvite,
      videoId,
      videoInviteFrom,
      details,
    },
    type,
  });

  const newOrder = [];
  const cards = getState().cards.get('cards').toArray();
  if (cards.length) {
    for (let i = 0; i < cards.length; i += 1) {
      newOrder.push(cards[i]);
    }
    newOrder.unshift(card);
  } else {
    newOrder.push(card);
  }

  // update local storage
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');
  localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(newOrder));

  dispatch({
    type: CARDS,
    payload: newOrder,
  });
};

/**
 *
 * @return {function(*, *)}
 */
export const updateChatContacts = () => (dispatch, getState) => {
  const cards = getState().cards.get('cards').toArray();
  if (cards.length) {
    for (let i = 0; i < cards.length; i += 1) {
      if (cards[i].type === 'chat') {
        if (cards[i].meta.sendMessageParams.fromNum) {
          const contact = matchContact(cards[i].meta.sendMessageParams.destination);
          if (contact) {
            cards[i].meta.contactId = contact.id;
          } else {
            cards[i].meta.contactId = null;
          }
        }
      }
    }

    dispatch({
      type: CARDS,
      payload: cards,
    });

    // update local storage
    const appName = getState().configs.get('appName');
    const userId = getState().configs.get('userId');
    localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(cards));
  }
};

/**
 *
 * @param cardId
 * @return {function(*, *)}
 */
export const removeCard = (cardId) => (dispatch, getState) => {
  const cards = getState().cards.get('cards').toArray();

  const newOrder = [];
  for (let i = 0; i < cards.length; i += 1) {
    if (cards[i].id !== cardId) {
      newOrder.push(cards[i]);
    }
  }

  // update local storage
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');
  localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(newOrder));

  // remove expanded if the card ids match
  const expandedId = getState().cards.get('expandedId');

  if (expandedId === cardId) {
    dispatch(setExpanded(null));
  }

  dispatch({
    type: CARDS,
    payload: newOrder,
  });
};

/**
 *
 * @param payload
 * @return {{type: *, payload: *}}
 */
export const setExpanded = (payload) => ({
  type: EXPANDED,
  payload,
});

/**
 * Updates local storage card order and dispatches the updated list
 * @param payload
 * @return {function(*, *)}
 */
export const updateOrder = (payload) => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');
  localStorage.setItem(`${appName}-${userId}_cards`, JSON.stringify(payload));

  dispatch({
    type: CARDS,
    payload,
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 */
export const getCards = (state) => state.cards.get('cards').toArray();

/**
 *
 * @param state
 * @param id
 * @return {*}
 */
export const getCard = (state, id) => state.cards.get('cards').entities[id] || null;

/**
 *
 * @param state
 */
export const getExpandedId = (state) => state.cards.get('expandedId');

/**
 *
 * @param state
 */
export const getLoading = (state) => state.cards.get('loading');

import { Map } from 'immutable';

/** ********************************************
 * Redux constants
 ******************************************** */

export const PUSH_SUBSCRIPTION = 'core/push/PUSH_SUBSCRIPTION';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  pushSubscription: 'hello world sample',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case PUSH_SUBSCRIPTION:
      return state.set('pushSubscription', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {{payload: *, type: string}}
 */
export const setPushSubscription = (pushSubscription) => {
  console.log('in setPushSubscription in push redux: ', pushSubscription);
  subscription = JSON.stringify(pushSubscription);

  return {
    type: PUSH_SUBSCRIPTION,
    payload: pushSubscription,
  };
};

/**
 *
 * @return {{payload: *, type: string}}
 */
// export const get = () => {
//     console.log('in get in push redux: ', subscription);
//     return
// };

export const getSubscription = (state) => state.push.get('pushSubscription');

/**
 *
 * @param N/A
 * @return N/A
 * will unsubscribe the webphone's service worker from listening to any more push notifications
 */
export const unsubscribePush = () => {
  navigator.serviceWorker.register('/webphone/root-service-worker.js').then((serviceWorker) => {
    serviceWorker.pushManager.getSubscription().then((sub) => {
      if (sub !== null) {
        sub.unsubscribe().then((successful) => {
          // You've successfully unsubscribed
          console.log('unsub succeed');
        }).catch((e) => {
          // Unsubscription failed
          console.log('unsub fail');
        });
      } else {
        console.log('sub did not exist to unsub');
      }
    });
  });
};

import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { router } from 'redux-ui-router';

import { redux as agent } from '../agent-center';
import { redux as angular } from '../angular';
import { redux as answeringRules } from '../answering-rules';
import { redux as callHistory } from '../call-history';
import { redux as callPark } from '../call-park';
import { redux as cards } from '../card-management';
import { redux as chat } from '../chat';
import { redux as configs } from '../configs';
import { redux as contacts } from '../contacts';
import { redux as devices } from '../devices';
import { redux as extension } from '../extension';
import { redux as greetings } from '../greetings';
import { redux as hidConsent } from '../hid-consent';
import { redux as network } from '../network';
import { redux as presence } from '../presence';
import { redux as ringtones } from '../ringtones';
import { redux as screenSize } from '../screen-size';
import { redux as sessions } from '../sessions';
import { redux as socket } from '../socket';
import { redux as stateHistory } from '../state-history';
import { redux as ua } from '../ua';
import { redux as user } from '../user';
import { redux as userMedia } from '../user-media';
import { redux as voicemail } from '../voicemail';
import { redux as push } from '../push';

export const sagaMiddleware = createSagaMiddleware();

export default ($ngReduxProvider) => {
  const reducers = combineReducers({
    agent,
    angular,
    answeringRules,
    callHistory,
    callPark,
    cards,
    chat,
    configs,
    contacts,
    devices,
    extension,
    greetings,
    hidConsent,
    network,
    presence,
    ringtones,
    router,
    screenSize,
    sessions,
    socket,
    stateHistory,
    ua,
    user,
    userMedia,
    voicemail,
    push,
  });

  const enhancers = [];

  // eslint-disable-next-line no-underscore-dangle
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    // eslint-disable-next-line no-underscore-dangle
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  $ngReduxProvider.createStoreWith(
    reducers,
    [thunk, sagaMiddleware],
    enhancers,
  );
};

import './styles.css';
import template from './template.html';
import * as cardActions from '../../core/card-management';

export default {
  template,
  controller,
};

controller.$inject = ['$ngRedux', '$scope'];
function controller($ngRedux, $scope) {
  const self = this;
  let animatedId = null;
  let containerElem;
  let modalElem;
  let unsubscribe;
  this.cards = [];
  this.expandedId = null;
  this.loaded = false;
  this.screenSize = {};

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      cards: cardActions.getCards(state),
      expandedId: cardActions.getExpandedId(state),
      loaded: !cardActions.getLoading(state),
      screenSize: state.screenSize,
    }))((state) => {
      this.cards = state.cards;
      this.loaded = state.loaded;
      this.screenSize = state.screenSize;

      // do nothing
      if (!animatedId && !state.expandedId) {
        _showModalContainer(false);
        _setExpandedCard(null);
        _setAnimatedId(null);
      }
      // animate the modal
      // test for an expandedId and compare to animatedId to determine if an animation is needed
      // this will prevent an expanded card from being animated again
      else if (state.expandedId && !animatedId) {
        _setAnimatedId(state.expandedId);
        const expandedCard = cardActions.getCard($ngRedux.getState(), state.expandedId);
        const targetPos = _getTargetPos(expandedCard);

        // test if the target element is avalable
        if (targetPos) {
          _showModalContainer(true);
          _setExpandedCard(expandedCard);
          _expandModal(targetPos);
        } else {
          // allow the cards to be rendered before getting the target position
          setTimeout(() => {
            _showModalContainer(true);
            _setExpandedCard(expandedCard);
            $scope.$apply(); // force component update
            setTimeout(() => {
              _expandModal(_getTargetPos(expandedCard));
            }, 0);
          }, 0);
        }
      }
      // close the modal
      // test for a null expandedId and an animatedId
      else if (animatedId && !state.expandedId) {
        _hideGroupKebabStates(state.cards);
        _closeModal(containerElem, modalElem);
      }
      // switch modals
      else if (state.expandedId && animatedId && state.expandedId !== animatedId) {
        _setAnimatedId(state.expandedId);
        _animateModalClose(modalElem);
        setTimeout(() => {
          _setExpandedCard(null);
          $scope.$apply(); // force component update
          setTimeout(() => {
            const expandedCard = cardActions.getCard($ngRedux.getState(), animatedId);
            _setExpandedCard(expandedCard);
            $scope.$apply(); // force component update
            setTimeout(() => {
              _expandModal(_getTargetPos(expandedCard));
            }, 0);
          }, 0);
        }, 200);
      }
    });

    window.addEventListener('keyup', _keyUp);
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
    window.removeEventListener('keyup', _keyUp);
  };

  /**
     *
     */
  this.closeModal = (event) => {
    if (!event || (event && event.target.className.indexOf('modal-container') !== -1)) {
      $ngRedux.dispatch(cardActions.setExpanded(null));
    }
  };

  /**
     * Drag and drop event handler
     * @type {{containment: string, containerPositioning: string, accept: controller.sortableRules.accept, orderChanged: (function(*))}}
     */
  this.sortableRules = {
    containment: '#card-containment',
    containerPositioning: 'relative',
    accept(sourceItemHandleScope, destSortableScope) {
      return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
    },
    orderChanged: (event) => {
      // get cards json in order of drop
      const _cards = event.dest.sortableScope.modelValue;
      const arr = [];
      for (let i = 0; i < _cards.length; i += 1) {
        arr.push(_cards[i]);
      }
      // send array to update local storage
      $ngRedux.dispatch(cardActions.updateOrder(arr));
    },
  };

  /**
     * Transforms the modal to its initial starting position and scale
     * @private
     */
  function _animateModalClose(elem) {
    if (typeof elem !== 'undefined') {
      elem.css({
        opacity: 0,
        transform: 'translate(0,0) scale(.65)',
      });
    }
  }

  /**
     * Update the modal css triggering the animation
     * @param elem
     * @param {{top: number, left: number}} destPos
     * @private
     */
  function _animateModalOpen(elem, destPos) {
    elem.css({
      opacity: 1,
      transform: `translate(${destPos.left}px, ${destPos.top}px) scale(1)`,
    });
  }

  /**
     *
     * @param elem
     * @private
     */
  function _animateContainerClose(elem) {
    elem.css({ opacity: 0 });
  }

  /**
     * Update the modal container css triggering the animation
     * @param elem
     * @private
     */
  function _animateContainerOpen(elem) {
    elem.css({ opacity: 1 });
  }

  /**
     * Calculate the destination postion
     * by reversing the subtraction order
     * we can get the distance to translate back to the center
     * 12 in the calculation offsets the padding
     * Round it so that the modal does not appear blurry because of sub-pixel alignment
     * @param targetPos
     * @param modalPos
     * @return {{top: number, left: number}}
     * @private
     */
  function _calcDestinationPosition(targetPos, modalPos) {
    return {
      top: Math.round(modalPos.top - targetPos.top - 12),
      left: Math.round(modalPos.left - targetPos.left - 12),
    };
  }

  /**
     * Calculate the animation start position
     * The modal starts out in the center
     * By subtracting the modal position from the target postion gets the difference
     * and positions the modal over the hidden small card
     * 12 in the calculation offsets the padding
     * @param targetPos
     * @param modalPos
     * @return {{top: number, left: number}}
     * @private
     */
  function _calcStartingPosition(targetPos, modalPos) {
    return {
      top: (targetPos.top - modalPos.top) + 12,
      left: (targetPos.left - modalPos.left) + 12,
    };
  }

  /**
     *
     * @param cElem short for containerElem
     * @param mElem short for modalElem
     * @private
     */
  function _closeModal(cElem, mElem) {
    _animateContainerClose(cElem);
    _animateModalClose(mElem);
    _setAnimatedId(null);
    // [WP-612] When defering (timeout) the DOM from removing the modal a race condition was created
    // and would remove the modal immediatly after expanding the modal
    _showModalContainer(false);
    _setExpandedCard(null);
    if (mElem) {
      mElem.remove();
      mElem = null;
    }
  }

  /**
     * Expand the modal
     * allows the ui to update before running code inside the setTimeout (next tick) function
     * @param targetPos
     * @private
     */
  function _expandModal(targetPos) {
    setTimeout(() => {
      // select the modal container element for css updates
      containerElem = _getModalContainerElem();
      _animateContainerOpen(containerElem);

      // select the modal element for calculating postions and css updates
      modalElem = _getModalElem();
      const modalPos = _getModalPosition(modalElem);

      // get staring endinng positions for animations
      const startingPos = _calcStartingPosition(targetPos, modalPos);
      const destPos = _calcDestinationPosition(targetPos, modalPos);

      // apply the modal starting position
      _initModalCss(modalElem, startingPos);

      // allow the ui to finish updating the modals new position
      setTimeout(() => {
        // animate modal to destination position
        _animateModalOpen(modalElem, destPos);
      }, 0);
    }, 0);
  }

  /**
     *
     * @return {*|Object}
     * @private
     */
  function _getCardContainerElem() {
    return angular.element('#card-containment');
  }

  /**
     *
     * @return {*|Object}
     * @private
     */
  function _getModalContainerElem() {
    return angular.element('.modal-container');
  }

  /**
     *
     * @return {*|Object}
     * @private
     */
  function _getModalElem() {
    return angular.element('.modal');
  }

  /**
     * Returns the modals top left position
     * @param elem
     * @return {*}
     * @private
     */
  function _getModalPosition(elem) {
    if (!elem) {
      elem = _getModalElem();
    }
    return elem.position();
  }

  /**
     * Captures the postion of the target (small card or other element on the page)
     * and returns the top, left position.
     * This is used as the starting point for expansion and
     * is also used for the ending point while closing the modal
     * @param expandedCard
     * @return {*}
     * @private
     */
  function _getTargetPos(expandedCard) {
    let cardCont;
    switch (expandedCard.type) {
      case 'dialer':
      case 'new-chat':
        cardCont = _getCardContainerElem();
        // when the innerHeigth is less than 100 this means the ui has not been updated with the cards
        // return null to wait till the next tick to get the position
        if (cardCont.innerHeight() < 100) {
          return null;
        }
        return {
          top: cardCont.innerHeight() - 240,
          left: cardCont.innerWidth() - 180,
        };
      case 'call':
      case 'chat':
      case 'user':
      case 'queue':
      case 'park':
      case 'perhour':
      case 'perday':
      case 'perqueue':
      case 'kpistats':
      case 'wrapup':
        return angular.element(`#card_${expandedCard.id}`).position();
    }
  }

  /**
     * Update the ui centering the modal over the target
     * @param elem
     * @param startingPos
     * @private
     */
  function _initModalCss(elem, startingPos) {
    elem.css({
      top: startingPos.top,
      left: startingPos.left,
    });
  }

  /**
     *
     * @param event
     * @private
     */
  function _keyUp(event) {
    // code 27 is the 'esc' key
    if (event.keyCode === 27) {
      self.closeModal();
    }
  }

  /**
     * Assign the id to prevent re-animating the card on redux updates
     * @param expandedId
     * @private
     */
  function _setAnimatedId(expandedId) {
    animatedId = expandedId;
  }

  /**
     * Render the modal
     * The initial opacity is 0 so it is hidden from the user
     * Allows the destination position to be captured
     * @param expandedCard
     * @private
     */
  function _setExpandedCard(expandedCard) {
    self.expandedCard = expandedCard;
  }

  /**
     * Render the modal container
     * @param showModal
     * @private
     */
  function _showModalContainer(showModal) {
    self.showModalContainer = showModal;
  }

  /**
     * Closes all session name change states for cards
     * @param cards
     * @private
     */
  function _hideGroupKebabStates(cards) {
    for (let i = 0; i < cards.length; i += 1) {
      if (cards[i].changeSessionName) cards[i].changeSessionName = false;
      if (cards[i].changeParticipantsState) cards[i].changeParticipantsState = false;
      if (cards[i].viewParticipantsState) cards[i].viewParticipantsState = false;
      if (cards[i].changedParticipantListToggle) cards[i].changedParticipantListToggle = false;
    }
  }
}

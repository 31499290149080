import template from './template.html';
import { getConfig } from '../../core/configs/redux';

export default {
  template,
  controller,
};

controller.$inject = ['$ngRedux'];
function controller($ngRedux) {
  let unsubscribe = null;

  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      logo: getConfig(state, 'logo'),
    }))(this);
  };

  this.$onDestroy = () => {
    unsubscribe();
  };
}

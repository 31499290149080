import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUiConfig from '@netsapiens/netsapiens-js/dist/ui-config';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import crypto from 'crypto-browserify';

import './styles.css';
import template from './template.html';
import { authenticated } from './redux';
import configFilter from '../../core/configs/configsFilter.json';
import { getConfig, setConfigs } from '../../core/configs';
import { unsubscribePush } from '../../core/push';

function controller(
  $ngRedux,
  $mdDialog,
  $rootScope,
  $sce,
  $state,
  $translate,
) {
  let unsubscribe = null;

  const self = this;

  self.imageIsLoaded = false;
  self.displayImage = true;
  self.spinner = false;
  self.spinner3 = false;
  self.spinner4 = false;

  self.formMessage = '';

  self.login = login;
  self.imageLoaded = imageLoaded;

  self.appleSso = appleSso;
  self.appleSsoEnabled = false;
  self.googleSsoEnabled = false;
  self.officeSso = officeSso;
  self.officeSsoEnabled = false;

  self.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      apiHash: getConfig(state, 'apiHash'),
      pathname: getConfig(state, 'pathname'),
      portalLegacyBehavior: getConfig(state, 'portalLegacyBehavior'),
      screenSize: state.screenSize,
      wpName: getConfig(state, 'wpName'),
      wpPassRecoverFail: getConfig(state, 'wpPassRecoverFail'),
      wpPassResetMsg: getConfig(state, 'wpPassResetMsg'),
    }))(this);

    const hostname = $ngRedux.getState().configs.get('hostname');
    self.imgUrl = `https://${hostname}/ns-api/?object=image&action=read&server=${hostname}&filename=portal_landing.png`;

    let localStorageLanguage = localStorage.getItem('NG_TRANSLATE_LANG_KEY'); // last language selected from Webphone
    localStorageLanguage = localStorageLanguage.toLowerCase();
    $translate.use(localStorageLanguage);

    // get apple sso uiconfigs
    let appleClientId = $ngRedux.getState().configs.get(`portalLoginAppleSsoClientId_${hostname}`);
    if (!appleClientId) appleClientId = $ngRedux.getState().configs.get('portalLoginAppleSsoClientId');

    if (appleClientId) {
      self.appleSsoEnabled = true;

      // load in apple js api for sso
      const aScript = document.createElement('script');
      aScript.type = 'text/javascript';
      aScript.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      document.head.appendChild(aScript);

      // when apple api is loaded, initialize it
      aScript.onload = () => {
        AppleID.auth.init({
          clientId: appleClientId, // 'com.netsapiens.local',
          scope: 'name email',
          redirectURI: `${window.location.origin}/webphone`,
          usePopup: true,
        });

        document.addEventListener('AppleIDSignInOnSuccess', (data) => {
          const idToken = data.detail.authorization.id_token;
          _ssoLogin(idToken, 'apple', self.spinner4);
        });

        document.addEventListener('AppleIDSignInOnFailure', (error) => {
          console.error('AppleIDSignInOnFailure', error);
        });
      };
    }

    // get google sso uiconfigs
    let googleClientId = $ngRedux.getState().configs.get(`portalLoginGoogleSsoClientId_${hostname}`);
    if (!googleClientId) googleClientId = $ngRedux.getState().configs.get('portalLoginGoogleSsoClientId');

    if (googleClientId) {
      self.googleSsoEnabled = true;

      // load in google js api for sso
      const aScript = document.createElement('script');
      aScript.type = 'text/javascript';
      aScript.src = 'https://accounts.google.com/gsi/client';
      document.head.appendChild(aScript);

      // when google api is loaded, initialize it
      aScript.onload = () => {
        window?.google?.accounts?.id?.initialize({
          client_id: googleClientId,
          callback: (res) => {
            if (res?.credential) {
              // eslint-disable-next-line no-use-before-define
              _ssoLogin(res?.credential, 'google');
            }
          },
        });
        window?.google?.accounts?.id?.renderButton(
          document.getElementById('google-sso'),
          {
            theme: 'outline',
            size: 'large',
            shape: 'rectangle',
            width: 316,
            logo_alignment: 'left',
            locale: localStorageLanguage || 'en_US',
          },
        );
      };
    }

    // get office sso uiconfigs
    let officeClientId = $ngRedux.getState().configs.get(`portalLoginOfficeSsoClientId_${hostname}`);
    if (!officeClientId) officeClientId = $ngRedux.getState().configs.get('portalLoginOfficeSsoClientId');

    if (officeClientId && $ngRedux.getState().configs.get('portalLoginOfficeSsoEnabled') === 'yes') {
      self.officeSsoEnabled = true;

      // push officeClientId to storage so it is accessible by index.html
      // on the return trip from MSAL
      localStorage.setItem('officeClientId', officeClientId);

      // load in MSAL api for sso
      // eslint-disable-next-line no-undef
      if (msalInstance === null) {
        const bScript = document.createElement('script');
        bScript.type = 'text/javascript';
        bScript.src = 'https://secure.aadcdn.microsoftonline-p.com/lib/1.0.0/js/msal.js';
        document.head.appendChild(bScript);

        // when MSAL is loaded, initialize it
        bScript.onload = () => {
          const msalConfig = {
            auth: {
              clientId: officeClientId,
              redirectUri: `${location.origin}/webphone?hash=${new Date().getTime()}`,
            },
            cache: { cacheLocation: 'localStorage' },
          };

          msalInstance = new Msal.UserAgentApplication(msalConfig);
        };
      }
    } else {
      localStorage.setItem('officeClientId', 'not_enabled');
    }
  };

  self.$onDestroy = () => {
    unsubscribe();
  };

  function imageLoaded(display) {
    self.displayImage = display;
    self.imageIsLoaded = true;
    $rootScope.$digest();
  }

  function login(form) {
    // unsubscribe from current push,
    // clearing the push capabilities before resubscribing in initSaga
    unsubscribePush();

    self.formMessage = '';

    if (form.$valid) {
      self.spinner = true;
    }

    // check if $apply or $digest is already in progress
    if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') {
      $rootScope.$digest();
    }

    nsApi.authenticate({
      username: form.username.$viewValue,
      password: form.password.$viewValue,
      scope: 'webphone',
      apiHash: $ngRedux.getState().configs.get('apiHash'),
    }).then(() => {
      const decodedToken = nsToken.getDecoded();

      /** legacy-reset? * */
      if (decodedToken.legacy) {
        const decodedApiHash = nsUtils.encoding.base64Decode(self.apiHash);
        const [client_id] = decodedApiHash.split(':');

        _configsToSettings(decodedToken).then(() => {
          switch (self.portalLegacyBehavior) {
            case 'reject_and_email':

              nsApi.post({
                object: 'email',
                action: 'create',
                subject: `Your Login ${self.wpName} Recovery`,
                template: 'password_reset_email.php',
                app_uri: `${location.origin}${self.pathname}?username=<USERNAME>&auth_code=<AUTH_CODE>`,
                recipient: decodedToken.user_email,
                user: decodedToken.user,
                client_id,
              }).then((res) => {
                if (res.sent) {
                  self.spinner = false;
                  $mdDialog.show($mdDialog.alert()
                    .clickOutsideToClose(true)
                    .textContent(self.wpPassResetMsg)
                    .ok('OK'));
                } else {
                  $mdDialog.show($mdDialog.alert()
                    .clickOutsideToClose(true)
                    .textContent(
                      self.wpPassRecoverFail,
                    )
                    .ok('OK'));
                }

                self.spinner = false;
              }).catch(() => {
                $mdDialog.show($mdDialog.alert()
                  .clickOutsideToClose(true)
                  .textContent(
                    self.wpPassRecoverFail,
                  )
                  .ok('OK'));
                self.spinner = false;
              });
              break;
            case 'warn':
            case 'silent':
              $ngRedux.dispatch(authenticated());
              break;
            default:
              $state.go('legacy-reset', { user: decodedToken.user });
          }
        });
      }
      /** force reset? * */
      else if (decodedToken.user_scope === '') {
        self.spinner = false;

        if (decodedToken.recover) {
          $mdDialog.show($mdDialog.alert()
            .clickOutsideToClose(true)
            .textContent(self.wpPassResetMsg)
            .ok('OK'));
        } else {
          $mdDialog.show($mdDialog.alert()
            .clickOutsideToClose(true)
            .textContent(self.wpPassRecoverFail)
            .ok('OK'));
        }
      } else {
        $ngRedux.dispatch(authenticated());
      }
    }).catch((err) => {
      if (err.reason === 'MFA_REQUIRED') {
        $state.go('mfa-form', {
          username: form.username.$viewValue,
          mfa_type: err.mfa_type,
          mfa_vendor: err.mfa_vendor,
        });
      } else {
        self.spinner = false;
        self.formMessage = $sce.trustAsHtml('Username or password is invalid.<br>Please try again.');
        $rootScope.$digest();
      }
    });
  }

  function appleSso(form) {
    self.formMessage = '';

    if (form.$valid) {
      self.spinner4 = true;
    }

    // check if $apply or $digest is already in progress
    if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') {
      $rootScope.$digest();
    }

    document.getElementById('appleid-signin').click();
  }

  function officeSso(form) {
    self.formMessage = '';

    if (form.$valid) {
      self.spinner3 = true;
    }

    // check if $apply or $digest is already in progress
    if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') {
      $rootScope.$digest();
    }

    const authenticationParameters = {
      scopes: ['user.read'], // optional Array<string>
      prompt: 'select_account',
    };

    window.history.pushState('portal', 'Login', '/webphone/'); // force base path so url can match allowed redirect urls

    msalInstance.loginPopup(authenticationParameters)
      .then((response) => {
        msalInstance.acquireTokenSilent(authenticationParameters)
          .then((response) => {
            const officeIdToken = response.accessToken;

            _ssoLogin(officeIdToken, 'office', self.spinner3);
          })
          .catch((err) => {
            console.log('acquireTokenSilent fresh err', err);
          });
      })
      .catch((err) => {
        console.log('loginPopup err', err);
      });
  }

  function _ssoLogin(token, vendor, spinner) {
    nsApi.ssoLogin({
      idtoken: token,
      vendor,
      scope: 'webphone',
      apiHash: $ngRedux.getState().configs.get('apiHash'),
    }).then(() => {
      $ngRedux.dispatch(authenticated());
    }).catch((err) => {
      if (err === 'NO_SUBSCRIBER') {
        $state.go('sso-enroll', {
          idtoken: token,
          vendor,
        });
      } else if (err.reason === 'SELECT_SUBSCRIBER') {
        const { subscriber_list } = err;

        subscriber_list.forEach((subscriber) => {
          if (subscriber.name) subscriber.initials = subscriber.name.split(' ').map((n) => n[0]).join('');
          subscriber.gravatar = crypto.createHash('md5').update(subscriber.email_address.split(';')[0]).digest('hex');
        });

        $state.go('select-subscriber', {
          idtoken: token,
          vendor,
          subscriber_list,
        });
      } else {
        console.error('ssoLogin error', err);
      }

      if (spinner !== undefined) {
        spinner = false;
      }

      self.formMessage = $sce.trustAsHtml(`Sign in via ${vendor} failed.<br>Please try again.`);
      $rootScope.$digest();
    });
  }

  function _configsToSettings(_decodedToken) {
    let configs = [];

    return Promise.all([
      nsUiConfig.get({
        configName: 'WS_SERVERS',
        domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
        user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
        role: _decodedToken && _decodedToken.user_scope ? _decodedToken.user_scope : '*',
        hostname: _decodedToken && _decodedToken.iss ? _decodedToken.iss : '*',
        reseller: _decodedToken && _decodedToken.territory ? _decodedToken.territory : '*',
        returnFirst: true,
      }),
      nsUiConfig.get({
        configName: 'PORTAL_*',
        domain: _decodedToken && _decodedToken.domain ? _decodedToken.domain : '*',
        user: _decodedToken && _decodedToken.user ? _decodedToken.user : '*',
        role: _decodedToken && _decodedToken.user_scope ? _decodedToken.user_scope : '*',
        hostname: _decodedToken && _decodedToken.iss ? _decodedToken.iss : '*',
        reseller: _decodedToken && _decodedToken.territory ? _decodedToken.territory : '*',
        filter: configFilter, // see configsFilter.json
      }),
    ]).then((res) => {
      // process socket response
      if (res[0]
                && res[0].length
                && res[0].config_value
                && res[0].config_value.trim()
      ) {
        const tempWs = res[0].config_value.split(',');
        if (tempWs) {
          const wsSockets = [];
          for (let i = 0; i < tempWs.length; i += 1) {
            if (tempWs[i] && tempWs[i].trim()) {
              tempWs[i] = tempWs[i].trim();
              if (tempWs[i].indexOf(':') === -1) {
                wsSockets.push(`wss://${tempWs[i]}:9002`);
              } else {
                wsSockets.push(`wss://${tempWs[i]}`);
              }
            }
          }
          if (wsSockets) {
            configs.push({
              config_name: 'nsSocketURLs',
              config_value: wsSockets,
            });
          }
        }
      }

      if (
        res[1]
                && res[1].length
      ) {
        configs = [...configs, ...res[1]];
      }

      $ngRedux.dispatch(setConfigs(configs));
    });
  }
}

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$rootScope',
  '$sce',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
};

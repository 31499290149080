import _ from 'lodash';
import { Map } from 'immutable';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import { matchContact } from '../contacts';

/** ********************************************
 * Redux constants
 ******************************************** */

export const LIST_TYPE = 'core/voicemail/LIST_TYPE';
export const LOADING = 'core/voicemail/LOADING';
export const NEW_VOICEMAIL = 'core/voicemail/NEW_VOICEMAIL';
export const SAVE_VOICEMAIL = 'core/voicemail/SAVE_VOICEMAIL';
export const SAVED_VOICEMAIL = 'core/voicemail/SAVED_VOICEMAIL';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  listType: 'vmail/new',
  loading: true,
  newVoicemail: null,
  savedVoicemail: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_TYPE:
      return state.set('listType', action.payload);
    case LOADING:
      return state.set('loading', action.payload);
    case NEW_VOICEMAIL:
      return state.set('newVoicemail', action.payload);
    case SAVED_VOICEMAIL:
      return state.set('savedVoicemail', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @param payload
 * @return {function(*, *)}
 */
export const deleteVoicemail = (payload) => (dispatch, getState) => {
  const token = nsToken.getDecoded();
  const type = getState().voicemail.get('listType');

  nsApi.post({
    object: 'audio',
    action: 'delete',
    format: 'json',
    owner_domain: token.domain,
    owner: token.user,
    index: `${payload.index}.wav`,
    type: getState().voicemail.get('listType'),
    intArray: 'true',
  }).then(() => {
    const typeName = type === 'vmail/new' ? 'newVoicemail' : 'savedVoicemail';

    let voicemail = getState().voicemail.get(typeName);

    voicemail = _.filter(voicemail, (v) => v.index !== payload.index);

    dispatch({
      type: typeName === 'newVoicemail' ? NEW_VOICEMAIL : SAVED_VOICEMAIL,
      payload: voicemail,
    });
  }).catch(() => {
    // todo
  });
};

/**
 *
 * @return {function(*)}
 */
export const fetchVoicemail = () => (dispatch) => {
  const token = nsToken.getDecoded();

  dispatch({
    type: LOADING,
    payload: true,
  });

  return Promise.all([
    nsApi.get({
      object: 'audio',
      action: 'read',
      user: token.user,
      domain: token.domain,
      format: 'json',
      count: 200,
      limit: 200,
      intArray: 'true',
      type: 'vmail/new',
    }).then((res) => {
      const voicemail = [];
      for (let i = 0; i < res.length; i += 1) {
        res[i].last = res.length - 1 === i;
        res[i].expanded = false; // this is used in the view for accordion animations
        res[i].remotepath = res[i].remotepath.replace('local.netsapiens.com', 'corp.netsapiens.com');
        res[i].remotepath = res[i].remotepath.replace(/&amp;/g, '&');
        res[i].remotepath = res[i].remotepath.replace(/%40/g, '@');
        // res[i].remotepath = res[i].remotepath + '&convert=signed';
        res[i].vmail_type = 'vmail/new';

        // get contact by name
        res[i].contact = matchContact(res[i].FromUser);
        res[i].callDetails = { name: res[i].FromName, numLabel: null };

        if (res[i].contact) {
          // get name
          res[i].callDetails.name = res[i].contact.name || res[i].FromName;

          // get number label
          if (res[i].contact.user && res[i].contact.user === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Extension';
          }
          if (res[i].contact.work_phone && res[i].contact.work_phone === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Work';
          }
          if (res[i].contact.cell_phone && res[i].contact.cell_phone === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Mobile';
          }
          if (res[i].contact.home_phone && res[i].contact.home_phone === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Home';
          }
        }

        voicemail.push(res[i]);
      }

      dispatch({
        type: NEW_VOICEMAIL,
        payload: voicemail,
      });
    }).catch(() => {
      dispatch({
        type: NEW_VOICEMAIL,
        payload: [],
      });
    }),
    nsApi.get({
      object: 'audio',
      action: 'read',
      user: token.user,
      domain: token.domain,
      format: 'json',
      limit: 200,
      intArray: 'true',
      type: 'vmail/save',
    }).then((res) => {
      const voicemail = [];
      for (let i = 0; i < res.length; i += 1) {
        res[i].last = res.length - 1 === i;
        res[i].expanded = false; // this is used in the view for accordion animations
        res[i].remotepath = res[i].remotepath.replace('local.netsapiens.com', 'corp.netsapiens.com');
        res[i].remotepath = res[i].remotepath.replace(/&amp;/g, '&');
        res[i].remotepath = res[i].remotepath.replace(/%40/g, '@');
        // res[i].remotepath = res[i].remotepath + '&convert=signed';
        res[i].vmail_type = 'vmail/save';

        // get contact by name
        res[i].contact = matchContact(res[i].FromUser);
        res[i].callDetails = { name: res[i].FromName, numLabel: null };

        if (res[i].contact) {
          // get name
          res[i].callDetails.name = res[i].contact.name || res[i].FromName;

          // get number label
          if (res[i].contact.user && res[i].contact.user === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Extension';
          }
          if (res[i].contact.work_phone && res[i].contact.work_phone === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Work';
          }
          if (res[i].contact.cell_phone && res[i].contact.cell_phone === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Mobile';
          }
          if (res[i].contact.home_phone && res[i].contact.home_phone === res[i].FromUser) {
            res[i].callDetails.numLabel = 'Home';
          }
        }

        voicemail.push(res[i]);
      }

      dispatch({
        type: SAVED_VOICEMAIL,
        payload: voicemail,
      });
    }).catch(() => {
      dispatch({
        type: SAVED_VOICEMAIL,
        payload: [],
      });
    }),
  ]).then(() => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

/**
 *
 * @param payload
 * @returns {function(*, *)}
 */
export const forwardVoicemail = (payload) => (dispatch, getState) => {
  const contact = getState().contacts.get('contacts').entities[payload.forward];

  const decodedToken = nsToken.getDecoded();
  const params = {
    action: 'update',
    format: 'json',
    object: 'audio',
    intArray: true,
    owner_domain: decodedToken.domain,
    index: `${payload.index}.wav`,
    type: payload.type,
    forward: contact.user,
    owner: decodedToken.user,
  };

  nsApi.post(params).then(() => {
    dispatch(fetchVoicemail());
  }).catch(() => {
    // todo, handle error
    // todo pass $mdDialog in payload
  });
};

/**
 *
 * @param payload
 * @return {{type: string, payload: *}}
 */
export const listType = (payload) => ({
  type: LIST_TYPE,
  payload,
});

/**
 *
 * @param payload
 * @return {function(*, *)}
 */
export const saveVoicemail = (payload) => (dispatch, getState) => {
  const token = nsToken.getDecoded();
  const type = getState().voicemail.get('listType');

  nsApi.post({
    object: 'audio',
    action: 'update',
    format: 'json',
    owner_domain: token.domain,
    owner: token.user,
    index: `${payload.index}.wav`,
    type: getState().voicemail.get('listType'),
    intArray: 'true',
  }).then(() => {
    const typeName = type === 'vmail/new' ? 'newVoicemail' : 'savedVoicemail';
    let voicemail = getState().voicemail.get(typeName);

    voicemail = _.filter(voicemail, (v) => v.index !== payload.index);

    dispatch({
      type: NEW_VOICEMAIL,
      payload: voicemail,
    });

    const voicemail_save = getState().voicemail.get('savedVoicemail');
    voicemail_save.unshift(payload);
    dispatch({
      type: SAVE_VOICEMAIL,
      payload: voicemail_save,
    });
  }).catch(() => {
    // todo
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 */
export const getListType = (state) => state.voicemail.get('listType');

/**
 *
 * @param state
 */
export const getLoading = (state) => state.voicemail.get('loading');

/**
 *
 * @param state
 */
export const getResultSet = (state) => {
  const lt = getListType(state);
  if (lt === 'vmail/new') {
    return state.voicemail.get('newVoicemail');
  }
  return state.voicemail.get('savedVoicemail');
};

import paletteUtils from './palettesUtils';

export default (
  $mdTheming,
  $ngRedux,
  ThemeProvider,
) => {
  let _count = 0;
  let _primaryHex;
  let _accentHex;

  const tabsPalette = paletteUtils.generator('#FAFAFA');
  const tabsAccent = paletteUtils.generator('#F49F00');
  ThemeProvider.definePalette('Primary', tabsPalette);
  ThemeProvider.definePalette('Accent', tabsAccent);
  ThemeProvider.theme('tabsPalette')
    .primaryPalette('Primary')
    .accentPalette('Accent', {
      default: '600',
    });
  $mdTheming.generateTheme('tabsPalette');
  ThemeProvider.setDefaultTheme('tabsPalette');

  // listen for theme changes
  $ngRedux.subscribe(() => {
    const primaryHex = $ngRedux.getState().configs.get('themePrimary');
    const accentHex = $ngRedux.getState().configs.get('themeAccent');

    // only update the theme has changed
    if (primaryHex && _primaryHex !== primaryHex
            || accentHex && _accentHex !== accentHex
    ) {
      _count++;
      _primaryHex = primaryHex;
      _accentHex = accentHex;

      const primary = paletteUtils.generator(_primaryHex);
      const accent = paletteUtils.generator(_accentHex);

      ThemeProvider.definePalette('Primary', primary);
      ThemeProvider.definePalette('Accent', accent);

      ThemeProvider.theme(`default${_count}`)
        .primaryPalette('Primary')
        .accentPalette('Accent', {
          default: '600',
        });

      $mdTheming.generateTheme(`default${_count}`);
      ThemeProvider.setDefaultTheme(`default${_count}`);
    }
  });
};

import { store } from '../../store';

export const matchContact = (str) => {
  if (!str) {
    return null;
  }

  const contacts = store.getState().contacts.get('contacts');

  if (!contacts.ids || !contacts.ids.length) {
    return null;
  }

  str = `${str}`;
  let searchString = str.toLowerCase();

  // search by name
  if (contacts.names.hasOwnProperty(searchString)) {
    return contacts.entities[contacts.names[searchString]];
  }

  // match by user
  if (contacts.users.hasOwnProperty(searchString)) {
    const contactId = contacts.users[searchString];
    const entity = contacts.entities[contactId];
    if (entity) {
      return entity;
    }
  }

  // match by uid
  const uid = searchString.split('@');
  if (uid && uid.length && contacts.users.hasOwnProperty(uid[0])) {
    const contactId = contacts.users[uid[0]];
    const entity = contacts.entities[contactId];
    if (entity) {
      return entity;
    }
  }

  // search by phone number
  searchString = str.replace(/\D/g, '');
  // save with leading 1 check
  const searchStringOriginal = searchString;
  if (searchString.length > 6) {
    // remove leading 1 from phone number
    // without leading 1 check
    searchString = str.replace(/^1+/, '');
  }
  if (contacts.phones.hasOwnProperty(searchString)) {
    return contacts.entities[contacts.phones[searchString]];
  }

  // check with original, with leading 1
  if (contacts.phones.hasOwnProperty(searchStringOriginal)) {
    return contacts.entities[contacts.phones[searchStringOriginal]];
  }
};

import { addContactListDividers } from './addContactListDividers';
import { filterContactList } from './filterContactList';
import { groupContactList } from './groupContactList';
import { sortContactList } from './sortContactList';

/**
 *
 * @param {[]} contactsArr
 * @param {string} filterBy
 * @param {boolean} filteredUser
 * @param {string} sortedBy
 * @param {string} sortedDirection
 * @return {[]}
 * @private
 */
export const getContactResultSet = (
  contactsArr,
  filterBy,
  filteredUser,
  sortedBy,
  sortedDirection,
) => {
  // filter array
  let contactsList = filterContactList(contactsArr, filterBy, filteredUser);

  // sort array
  contactsList = sortContactList(contactsList, sortedBy);

  // reverse array if sortDirection is 'desc'
  if (sortedDirection === 'desc') {
    contactsList.reverse();
  }

  // group the array
  contactsList = groupContactList(contactsList, sortedBy);

  // add dividers
  return addContactListDividers(contactsList, sortedBy, sortedDirection);
};

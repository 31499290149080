import _ from 'lodash';
import { Map } from 'immutable';
import { stopStream } from './utils/devices';

/** ********************************************
 * Redux constants
 ******************************************** */

export const STREAM = 'core/user-media/STREAM';
export const STREAMS = 'core/user-media/STREAMS';
export const HID_DEVICE = 'core/user-media/hidDevice';
export const HID_DEVICES = 'core/user-media/hidDevices';
export const INPUT_DEVICE = 'core/user-media/inputDevice';
export const INPUT_DEVICES = 'core/user-media/inputDevices';
export const OUTPUT_DEVICE = 'core/user-media/outputDevice';
export const OUTPUT_DEVICE_RINGER = 'core/user-media/outputDeviceRinger';
export const OUTPUT_DEVICES = 'core/user-media/outputDevices';
export const HAS_PERMISSION = 'core/user-media/hasPermission';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  stream: null,
  streams: [],
  hidDevice: null,
  hidDevices: [],
  inputDevice: null,
  inputDevices: [],
  outputDevice: null,
  outputDeviceRinger: null,
  outputDevices: [],
  hasPermission: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case STREAM:
      return state.set('stream', action.payload);
    case STREAMS:
      return state.set('streams', action.payload);
    case HID_DEVICE:
      return state.set('hidDevice', action.payload);
    case HID_DEVICES:
      return state.set('hidDevices', action.payload);
    case INPUT_DEVICE:
      return state.set('inputDevice', action.payload);
    case INPUT_DEVICES:
      return state.set('inputDevices', action.payload);
    case OUTPUT_DEVICE:
      return state.set('outputDevice', action.payload);
    case OUTPUT_DEVICE_RINGER:
      return state.set('outputDeviceRinger', action.payload);
    case OUTPUT_DEVICES:
      return state.set('outputDevices', action.payload);
    case HAS_PERMISSION:
      return state.set('hasPermission', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

export const stream = (payload) => ({
  type: STREAM,
  payload,
});

export const addStream = (payload) => (dispatch, getState) => {
  const streams = getState().userMedia.get('streams');
  streams.push(payload);
  dispatch({
    type: STREAMS,
    payload: streams,
  });
};

export const stopStreams = () => (dispatch, getState) => {
  const streams = getState().userMedia.get('streams');
  if (_.size(streams)) {
    streams.forEach((s) => {
      if (s.active) {
        stopStream(s);
      }
    });
  }

  dispatch({
    type: STREAMS,
    payload: [],
  });
};

export const hidDevice = (payload) => ({
  type: HID_DEVICE,
  payload,
});

export const hidDevices = (payload) => ({
  type: HID_DEVICES,
  payload,
});

export const inputDevice = (payload) => ({
  type: INPUT_DEVICE,
  payload,
});

export const inputDevices = (payload) => ({
  type: INPUT_DEVICES,
  payload,
});

export const outputDevice = (payload) => ({
  type: OUTPUT_DEVICE,
  payload,
});

export const outputDeviceRinger = (payload) => ({
  type: OUTPUT_DEVICE_RINGER,
  payload,
});

export const outputDevices = (payload) => ({
  type: OUTPUT_DEVICES,
  payload,
});

export const hasPermission = (payload) => ({
  type: HAS_PERMISSION,
  payload,
});

import { store } from '../store';

export default ($filter) => {
  let countryCode;
  const interval = setInterval(() => {
    if (store) {
      store.subscribe(() => {
        if (countryCode !== store.getState().configs.get('countryCode')) {
          countryCode = store.getState().configs.get('countryCode');
          $filter('formatPhoneNumber')('init');
        }
      });
      clearInterval(interval);
    }
  }, 200);
};

import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import './styles.css';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import * as navActions from '../../core/state-history';
import * as sessionActions from '../../core/sessions';
import { sendInvite } from '../../core/ua';

function controller(
  $mdColors,
  $mdDialog,
  $mdToast,
  $ngRedux,
  $state,
  $translate,
) {
  const self = this;
  let unsubscribe;
  let confBridgeId = null;

  /**
     *
     */
  this.$onInit = () => {
    this.background = $mdColors.getThemeColor('primary');

    unsubscribe = $ngRedux.connect((state) => ({
      activeCall: sessionActions.getActiveCall(state),
      activeCount: sessionActions.activeCount(state),
      activeId: sessionActions.getActiveId(state),
      allowCall: sessionActions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-677]
      callSessions: sessionActions.getSessions(state),
      currentState: navActions.getCurrentState(state),
      isConference: sessionActions.isConference(state),
      screenSize: state.screenSize,
      recordingAllowed: state.configs.get('callRecording').toLowerCase() === 'yes'
        || state.configs.get('callRecording').toLowerCase() === 'true',
    }))((state) => {
      this.activeCall = state.activeCall;
      this.activeCount = state.activeCount;
      this.activeId = state.activeId;
      this.recordingAllowed = state.recordingAllowed;
      this.allowCall = state.allowCall;
      this.callSessions = state.callSessions;
      this.isConference = state.isConference;
      this.screenSize = state.screenSize;

      // initilize buttons to disabled or hiddnen.
      this.muteButtonClass = 'disabled';
      this.holdClass = 'disabled';
      this.contactsClass = 'disabled';
      this.addCallClass = 'disabled';
      this.transferClass = 'disabled';
      this.parkCallClass = 'disabled';
      this.switchPhoneClass = 'disabled';

      this.swapClass = 'hide';
      this.completeTransferClass = 'hide';
      this.cancelTransferClass = 'hide';
      this.moreDropdownClass = 'hide';
      this.moreCardClass = 'hide';

      // calculate component height
      switch (this.context) {
        case 'card':
          this.phoneHeight = '300px';
          this.minHeight = null;
          this.phonePadding = '3px 6px';
          break;
        case 'modal':
          this.phoneHeight = `${state.screenSize.height - 330}px`;
          this.minHeight = '450px';
          this.maxHeight = '450px';
          this.phonePadding = '3px 20px';
          break;
        default:
          this.phoneHeight = `${state.screenSize.height - 28}px`;
      }
      if (typeof this.transferId === 'undefined') {
        this.transferId = this.transferId || $state.params.transferId;
      }

      this.isTransfer = !!this.transferId && this.activeCount > 1;

      // get assisted transfer target
      if (this.isTransfer) {
        // set assisted transfer variables if not set
        if (!this.activeCall) {
          const { ids } = $ngRedux.getState().sessions.get('sessions');
          let activeId = ids[0];
          if (ids[1]) activeId = ids[1] === this.transferId ? ids[0] : ids[1];
          this.activeCall = $ngRedux.getState().sessions.get('sessions').entities[activeId];
        }

        if (!this.assistedTarget) {
          // get the assisted target
          this.assistedTarget = $ngRedux.getState().sessions.get('sessions').entities[this.transferId];

          // keep track of calls so if one hangs up we can change the button display
          if (this.activeCall) {
            this.assistedIds = {
              activeCallId: this.activeCall.id,
              targetId: this.transferId,
            };
          } else {
            this.assistedIds = {
              targetId: this.transferId,
            };
          }
        } else {
          // check if either session is not in the sessions array
          if (!$ngRedux.getState().sessions.get('sessions').entities[this.assistedIds.activeCallId]
                        || !$ngRedux.getState().sessions.get('sessions').entities[this.assistedIds.targetId]
          ) {
            // one of the assisted transfer calls hung up
            // clear the variables so the buttons are displayed in the correct state
            _clearAssisted();
          }
        }
      }

      // outbound call failed listener
      if (this.activeCall) {
        // if trying attach handler for call failed
        if (this.activeCall.status === 'trying' && this.activeCall.direction === 'outbound') {
          this.activeCall.onFailed = () => {
            /* Only call toast if status is still trying.
                         * If the status is not equal to trying than the
                         * call is sucessfull */
            if (this.activeCall.status === 'trying') {
              $mdToast.show(
                $mdToast.simple()
                  .textContent($translate.instant('CALL_ENDED'))
                  .position('bottom left')
                  .hideDelay(1500)
                  .theme('error-toast'),
              );
            }
          };
        }
      }

      if (this.activeCall && this.activeCount === 1 && !this.isConference) {
        _singleHeader();

        if (confBridgeId !== null
          && this.activeCall.conferenceList != null
          && this.activeCall.conferenceList.lenth === 0
        ) {
          confBridgeId = null;
        }
      } else {
        _multipleHeader();
      }

      if (this.isConference && confBridgeId === null) {
        // This is logic to reset the confBridgeId on new call addition.
        const sessions = $ngRedux.getState().sessions.get('sessions');
        for (let i = 0; i < sessions.ids.length; i += 1) {
          const tmp_sess = sessions.entities[sessions.ids[i]];
          if (tmp_sess.conferenceList.length > 0) {
            confBridgeId = tmp_sess.name;
          }
        }
      }

      // button display classes
      if (this.activeCall) {
        _buttonDisplay();
      }
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.cancelTransfer = () => {
    if (this.screenSize.display === 'fullscreen') {
      this.onTransferCancel();
    }

    if (this.activeCall.status === 'outboundProgressing') {
      this.activeCall.cancel();
    } else {
      this.activeCall.bye();
    }
  };

  /**
     *
     */
  this.closeModal = () => {
    $mdDialog.hide();
    $ngRedux.dispatch(cardActions.setExpanded(null));
  };

  /**
     *
     */
  this.completeTransfer = () => {
    // todo determine direction
    this.activeCall.transfer(this.assistedTarget.session, false);
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('TRANSFER_COMPLETE'))
        .position('bottom')
        .hideDelay(3000)
        .highlightAction(true),
    );
  };

  /**
     *
     * @param workflow
     */
  this.showPark = (workflow) => {
    if (this.context === 'modal') {
      this.onPark({
        workflow,
        callId: this.activeCall.id,
      });
    } else {
      const currentState = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
      $ngRedux.dispatch(
        navActions.callStackPush({
          name: 'wrapper.call-park-list',
          params: {
            workflow,
            callId: this.activeCall.id,
          },
          fromName: currentState.name,
          fromParams: currentState.params,
        }),
      );
      $state.go('wrapper.call-park-list', {
        workflow,
        callId: this.activeCall.id,
      });
    }
  };

  /**
     *
     */
  this.end = () => {
    if (this.activeCall && this.activeCall.status) {
      if (this.activeCall.status === 'trying'
              || this.activeCall.status === 'inboundProgressing'
              || this.activeCall.status === 'outboundProgressing'
      ) {
        this.activeCall.cancel();
      } else {
        this.activeCall.bye();
      }
    }
  };

  /**
     *
     */
  this.hold = () => {
    if (this.activeCall.status === 'accepted') {
      if (this.activeCall.isOnHold) {
        this.activeCall.unhold();
      } else {
        this.activeCall.hold();
      }
    }
  };

  /**
     *
     */
  this.hideCall = () => {
    if (this.context !== 'modal') {
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
      $state.go(lastOnStack.name, lastOnStack.params);
    }
    this.closeModal();
  };

  /**
     *
     * @param sessionId
     */
  this.makeActive = (sessionId) => {
    // ignore if the call is already active or is a transfer
    if (this.activeId !== sessionId && !this.assistedTarget) {
      this.activeCall.hold();
      $ngRedux.dispatch(sessionActions.setActiveId(sessionId));
      for (let i = 0; i < this.callSessions.length; i += 1) {
        if (this.callSessions[i].id === sessionId) {
          this.callSessions[i].unhold();
          break;
        }
      }
    }
  };

  /**
     *
     */
  this.more = () => {
    $ngRedux.dispatch(cardActions.setExpanded(this.card.id));
  };

  /**
     *
     */
  this.mute = () => {
    if (this.activeCall.isMuted) {
      this.activeCall.unmute();
    } else {
      this.activeCall.mute();
    }
  };

  /**
     *
     */
  this.numPad = () => {
    if (this.activeCall.status === 'accepted') {
      // card numpad
      if (this.context === 'card') {
        $ngRedux.dispatch(cardActions.setExpanded(this.card.id));
      }
      // phone modal
      else if (this.context === 'modal') {
        this.onNumPad();
      }
      // compact state
      else {
        const currentState = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
        $ngRedux.dispatch(
          navActions.callStackPush({
            name: 'wrapper.num-pad',
            params: null,
            fromName: currentState.name,
            fromParams: currentState.params,
          }),
        );
        $state.go('wrapper.num-pad');
      }
    }
  };

  /**
     *
     */
  this.record = () => {
    this.activeCall.record().then(() => {
      this.isRecording = true;
    });
  };

  /**
     *
     */
  this.showContacts = (workflow) => {
    if (this.context === 'modal') {
      this.onContacts({
        workflow,
        callId: this.activeCall.id,
      });
    } else {
      const currentState = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
      $ngRedux.dispatch(
        navActions.callStackPush({
          name: 'wrapper.contact-list',
          params: {
            workflow,
            callId: this.activeCall.id,
          },
          fromName: currentState.name,
          fromParams: currentState.params,
        }),
      );
      $state.go('wrapper.contact-list', {
        workflow,
        callId: this.activeCall.id,
      });
    }
  };

  /**
     *
     */
  this.showDialer = (workflow) => {
    if (this.screenSize.display === 'fullscreen') {
      this.onDialer({
        workflow,
      });
    } else {
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
      $ngRedux.dispatch(
        navActions.callStackPush({
          name: 'wrapper.dialer',
          params: {
            workflow,
          },
          fromName: lastOnStack.name,
          fromParams: lastOnStack.params,
        }),
      );
      $state.go('wrapper.dialer', {
        workflow,
      });
    }
  };

  /**
     *
     */
  this.stopRecording = () => {
    this.activeCall.stopRecording().then(() => {
      this.isRecording = false;
    });
  };

  /**
     *
     */
  this.swap = () => {
    this.activeCall.hold();
    for (let i = 0; i < this.callSessions.length; i += 1) {
      if (this.activeCall.id !== this.callSessions[i].id) {
        this.callSessions[i].unhold();
        $ngRedux.dispatch(sessionActions.setActiveId(this.callSessions[i].id));
        break;
      }
    }
  };

  /**
     *
     */
  this.merge = () => {
    const token = nsToken.getDecoded();
    let callids = '';
    for (let i = this.callSessions.length - 1; i >= 0; i--) {
      if (this.callSessions[i].number.includes('adhoc')) // do not transfer this call as its the merged call
      { continue; }
      if (this.callSessions[i].name != null && this.callSessions[i].name.includes('adhoc')) // do not transfer this call as its the merged call
      { continue; }
      if (this.callSessions[i].isConference) // do not transfer this call as its the merged call
      { continue; }
      if (this.callSessions[i].isConference) // do not transfer this call as its the merged call
      { continue; }
      if (this.callSessions[i].direction === 'outbound') callids += `${this.callSessions[i].session.outgoingRequestMessage.callId}xfer_term,`;
      else if (this.callSessions[i].direction === 'inbound') callids += `${this.callSessions[i].session.incomingInviteRequest.message.callId}xfer_orig,`;
      else if (this.callSessions[i].callId) callids += `${this.callSessions[i].callId},`;
      else callids += `${this.callSessions[i].id.replace(this.callSessions[i].from_tag, '')},`;
    }

    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('MERGING_CALLS'))
        .position('top')
        .hideDelay(2000)
        .highlightAction(true),
    );

    if (confBridgeId != null) {
      const activeCall = sessionActions.getActiveCall($ngRedux.getState());
      activeCall.transfer(confBridgeId, true);
      // //A conference call is already up, no need to initiate a merge, just transfer.
      // nsApi.get({
      //     object: 'call',
      //     action: 'xfer',
      //     destination:  confBridgeId,
      //     uid: token.user + "@"+ token.domain,
      //     callid: callids.replace(/\,/g,"")
      // }).then(res => {
      //     debugger;
      // });
    } else {
      // we need to create a bridge and merge
      nsApi.get({
        object: 'call',
        action: 'merge',
        // origination:  `sip:${token.user}${devicePostFix}@${token.domain}`,
        uid: `${token.user}@${token.domain}`,
        callids,
        format: 'json',
      }).then((res) => {
        if (res[0].destination) {
          confBridgeId = res[0].destination;

          for (let i = this.callSessions.length - 1; i >= 0; i--) {
            if (this.callSessions[i].number.includes('adhoc')) // do not transfer this call as its the merged call
            { continue; }
            if (this.callSessions[i].name != null && this.callSessions[i].name.includes('adhoc')) // do not transfer this call as its the merged call
            { continue; }
            if (this.callSessions[i].isConference) // do not transfer this call as its the merged call
            { continue; }
            $ngRedux.dispatch(sessionActions.remove(this.callSessions[i].id));
          }

          $ngRedux.dispatch(sendInvite(confBridgeId));
        }
      });
    }
  };

  /**
     *
     */
  this.fetchDevices = () => {
    this.devices = null;

    const token = nsToken.getDecoded();

    nsApi.get({
      object: 'device',
      action: 'read',
      domain: token.domain,
      user: token.user,
      format: 'json',
      mode: 'registered_endpoint',
      noNDP: true,
    }).then((res) => {
      const registeredDevice = $ngRedux.getState().devices.get('audioDevice');

      const devices = [];
      for (let i = 0; i < res.length; i += 1) {
        if (registeredDevice.aor !== res[i].aor
                    && res[i].user_agent.indexOf('NetSapiens NCS') === -1
        ) {
          devices.push(res[i]);
        }
      }

      if (devices && devices.length) {
        this.devices = devices;
      } else {
        this.devices = false;
      }
    });
  };

  /**
     *
     */
  this.switch = (number) => {
    const decodedToken = nsToken.getDecoded();

    nsApi.post({
      object: 'call',
      action: 'call',
      format: 'json',
      callid: Math.floor(Math.random() * (100000000000 - 1 + 1)) + 1,
      uid: decodedToken.sub,
      destination: `sip:MoveMyCall@${decodedToken.domain}`,
      origination: number,
    }).then(() => {
      const cardId = this.card.id;
      $ngRedux.dispatch(cardActions.setExpanded(null));

      // start timer
      const timer = setTimeout(() => {
        $mdDialog.hide();
        $ngRedux.dispatch(cardActions.setExpanded(cardId));
        // todo if compact go to phone
      }, 10000);

      // show dialog
      $mdDialog.show({
        controller: ['$scope', function ($scope) {
          $scope.cancel = function () {
            clearTimeout(timer);
            $mdDialog.hide();
            $ngRedux.dispatch(cardActions.setExpanded(cardId));
            // todo if compact go to phone
          };
        }],
        template: '<md-dialog class="p-r-10 p-l-10">'
                    + '   <md-dialog-content class="m-t-20 m-r-10 m-l-10">'
                    + '       <h3 class="m-b-0">Please answer the selected device</h3>'
                    + '   </md-dialog-content>'
                    + '   <md-dialog-actions>'
                    + '        <md-button class="md-accent" ng-click="cancel()">'
                    + '           CANCEL'
                    + '       </md-button>'
                    + '   </md-dialog-actions>'
                    + '</md-dialog>',
        clickOutsideToClose: false,
      });
    });
  };

  /**
     *
     * @private
     */
  function _buttonDisplay() {
    const callAccepted = self.activeCall.status === 'accepted';

    // mute
    self.muteButtonClass = self.activeCall.isMuted ? 'active' : '';

    // hold
    self.holdClass = callAccepted ? '' : 'disabled';
    self.holdButtonClass = self.activeCall.isOnHold ? 'active' : '';

    // dial pad, aka numpad
    self.numpadClass = callAccepted ? '' : 'disabled';

    // transfer
    const transferClass = [];
    if (!callAccepted) {
      transferClass.push('disabled');
    }
    if (self.isTransfer || self.context === 'card') {
      transferClass.push('hide');
    }
    self.transferClass = transferClass.join(' ');

    // complete transfer
    const completeTransferClass = [];
    if (!callAccepted) {
      completeTransferClass.push('disabled');
    }
    if (!self.isTransfer || self.context === 'card') {
      completeTransferClass.push('hide');
    }
    self.completeTransferClass = completeTransferClass.join(' ');

    // add call
    const addCallClass = [];
    if (!callAccepted) {
      addCallClass.push('disabled');
    }
    if (self.context === 'card' || self.activeCount > 1 || self.isTransfer) {
      addCallClass.push('hide');
    }
    self.addCallClass = addCallClass.join(' ');

    // cancel transfer
    self.cancelTransferClass = !self.isTransfer || self.context === 'card' ? 'hide' : '';

    // swap calls
    const swapClass = [];
    if (!callAccepted) {
      swapClass.push('disabled');
    }
    if (self.context === 'card' || self.isTransfer || self.activeCount !== 2) {
      swapClass.push('hide');
    }
    self.swapClass = swapClass.join(' ');

    const parkCallClass = [];
    if (!callAccepted) {
      parkCallClass.push('disabled');
    }
    if (self.context === 'card' || self.isTransfer) {
      parkCallClass.push('hide');
    }
    self.parkCallClass = parkCallClass.join(' ');

    // switch phone
    const switchPhoneClass = [];
    if (!callAccepted) {
      switchPhoneClass.push('disabled');
    }
    if (self.context === 'card' || self.activeCount > 1) {
      switchPhoneClass.push('hide');
    }
    self.switchPhoneClass = switchPhoneClass.join(' ');

    // contacts
    const contactsClass = [];
    if (!callAccepted || self.isTransfer) {
      contactsClass.push('disabled');
    }
    if (self.context === 'card') {
      contactsClass.push('hide');
    }
    self.contactsClass = contactsClass.join(' ');

    // more dropdown
    const moreDropdownClass = [];
    if (!callAccepted || self.isTransfer) {
      moreDropdownClass.push('disabled');
    }
    if (self.context === 'card') {
      moreDropdownClass.push('hide');
    }
    self.moreDropdownClass = moreDropdownClass.join(' ');

    // more card
    self.moreCardClass = self.context !== 'card' ? 'hide' : '';
  }

  /**
     *
     * @private
     */
  function _clearAssisted() {
    self.assistedIds = null;
    self.assistedTarget = null;
    self.isTransfer = null;
    self.transferId = null;
  }

  /**
     *
     * @private
     */
  function _multipleHeader() {
    self.headerType = 'multiple';
  }

  /**
     *
     * @private
     */
  function _singleHeader() {
    self.headerType = 'single';
    self.headerClass = self.context === 'card' ? 'p-t-10' : 'p-t-20';

    // calling
    self.calling = self.activeCall.direction === 'outbound'
      && ['trying', 'outboundProgressing'].indexOf(self.activeCall.status) !== -1;

    // number
    self.numberClass = !self.activeCall.name && self.context !== 'card' ? 'font-24' : '';

    // show call time
    self.showCallTime = self.activeCall.callTime && !self.activeCall.isOnHold;

    // call time class
    const callTimeClass = [];
    if (self.context === 'card') {
      callTimeClass.push('font-14 p-t-3');
    }
    if (!self.activeCall.name && self.context !== 'card') {
      callTimeClass.push('p-t-5');
    }
    self.callTimeClass = callTimeClass.join(' ');

    // holdClass
    self.holdTextClass = !self.activeCall.name && self.context !== 'card' ? 'p-t-5' : '';
  }
}

controller.$inject = [
  '$mdColors',
  '$mdDialog',
  '$mdToast',
  '$ngRedux',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@',
    onContacts: '&',
    onPark: '&',
    onDialer: '&',
    onNumPad: '&',
    onTransferCancel: '&',
    transferId: '@',
  },
};

import netsCallCard from './components/call-card/nets-call-card';
import netsCallModal from './components/call-modal/nets-call-modal';
import netsCallParkCard from './components/call-park-card/nets-call-park-card';
import netsCallParkModal from './components/call-park-modal/nets-call-park-modal';
import netsChatCard from './components/chat-card/nets-chat-card';
import netsChatModal from './components/chat-modal/nets-chat-modal';
import netsCardsContainer from './nets-cards-container';
import netsDialerModal from './components/dialer-modal/nets-dialer-modal';
import netsNewChatModal from './components/new-chat-modal/nets-new-chat-modal';
import netsUserCard from './components/user-card/nets-user-card';
import netsUserModal from './components/user-modal/nets-user-modal';
import netsQueueCard from './components/queue-card/nets-queue-card';
import netsQueueModal from './components/queue-modal/nets-queue-modal';
import netsPerHourCard from './components/per-hour-card/nets-per-hour-card';
import netsPerHourModal from './components/per-hour-modal/nets-per-hour-modal';
import netsPerDayCard from './components/per-day-card/nets-per-day-card';
import netsPerDayModal from './components/per-day-modal/nets-per-day-modal';
import netsPerQueueCard from './components/per-queue-card/nets-per-queue-card';
import netsPerQueueModal from './components/per-queue-modal/nets-per-queue-modal';
import netsKpiStatsCard from './components/kpi-stats-card/nets-kpi-stats-card';
import netsKpiStatsModal from './components/kpi-stats-modal/nets-kpi-stats-modal';
import netsCallWrapupCard from './components/call-wrapup-card/nets-call-wrapup-card';
import netsCallWrapupModal from './components/call-wrapup-modal/nets-call-wrapup-modal';

export default angular
  .module('app.cardsManagement', [])
  .component('netsCallCard', netsCallCard)
  .component('netsCallModal', netsCallModal)
  .component('netsCallParkCard', netsCallParkCard)
  .component('netsCallParkModal', netsCallParkModal)
  .component('netsChatCard', netsChatCard)
  .component('netsChatModal', netsChatModal)
  .component('netsCardsContainer', netsCardsContainer)
  .component('netsDialerModal', netsDialerModal)
  .component('netsNewChatModal', netsNewChatModal)
  .component('netsUserCard', netsUserCard)
  .component('netsUserModal', netsUserModal)
  .component('netsQueueCard', netsQueueCard)
  .component('netsQueueModal', netsQueueModal)
  .component('netsPerHourCard', netsPerHourCard)
  .component('netsPerHourModal', netsPerHourModal)
  .component('netsPerDayCard', netsPerDayCard)
  .component('netsPerDayModal', netsPerDayModal)
  .component('netsPerQueueCard', netsPerQueueCard)
  .component('netsPerQueueModal', netsPerQueueModal)
  .component('netsKpiStatsCard', netsKpiStatsCard)
  .component('netsKpiStatsModal', netsKpiStatsModal)
  .component('netsCallWrapupCard', netsCallWrapupCard)
  .component('netsCallWrapupModal', netsCallWrapupModal);

import nsApi from '@netsapiens/netsapiens-js/dist/api';

import './styles.css';
import template from './template.html';
import { authenticated } from '../login';

function controller(
  $ngRedux,
  $mdDialog,
  $rootScope,
  $sce,
  $state,
) {
  const self = this;

  self.spinner = false;
  self.displayImage = true;
  self.imageLoaded = imageLoaded;
  self.ssoSubmit = ssoSubmit;
  self.ssoEnrollRoute = ssoEnrollRoute;
  self.formMessage = '';

  self.idtoken = null;
  self.vendor = null;
  self.selectedSubscriber = null;
  self.selectSub = selectSub;
  self.subscriber_list = [];
  self.most_recent = false;

  self.$onInit = () => {
    $ngRedux.connect((state) => ({
      screenSize: state.screenSize,
    }))(this);

    const hostname = $ngRedux.getState().configs.get('hostname');
    self.imgUrl = `https://${hostname}/ns-api/?object=image&action=read&server=${hostname}&filename=portal_landing.png`;
    self.idtoken = $state.params.idtoken;
    self.vendor = $state.params.vendor;
    self.subscriber_list = $state.params.subscriber_list;

    self.subscriber_list.forEach((subscriber) => {
      if (!self.most_recent
        || self.most_recent.modified < subscriber.modified
      ) {
        self.most_recent = subscriber;
      }
    });
  };

  function imageLoaded(display) {
    self.displayImage = display;
    self.imageIsLoaded = true;
    $rootScope.$digest();
  }

  function ssoSubmit(form) {
    self.formMessage = '';

    if (form.$valid) {
      self.spinner = true;
    }

    // check if $apply or $digest is already in progress
    if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') {
      $rootScope.$digest();
    }

    nsApi.ssoLogin({
      username: self.selectedSubscriber,
      idtoken: self.idtoken,
      vendor: self.vendor,
      scope: 'webphone',
      apiHash: $ngRedux.getState().configs.get('apiHash'),
    }).then(() => {
      $ngRedux.dispatch(authenticated());
    }).catch(() => {
      self.spinner = false;
      self.formMessage = $sce.trustAsHtml('Login attempt failed.<br>Please try again.');
      $rootScope.$digest();
    });
  }

  function selectSub(uid) {
    self.selectedSubscriber = uid;
    $rootScope.$digest();
  }

  function ssoEnrollRoute() {
    console.log('ssoEnrollRoute func');
    $state.go('sso-enroll', {
      idtoken: self.idtoken,
      vendor: self.vendor,
    });
  }
}

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$rootScope',
  '$sce',
  '$state',
];

export default {
  template,
  controller,
};

import './styles.css';
import template from './template.html';
import * as cardActions from '../../core/card-management';
import * as contactActions from '../../core/contacts';
import * as deviceActions from '../../core/devices';
import * as navActions from '../../core/state-history';
import * as numpadActions from '../../core/numpad';
import * as sessionActions from '../../core/sessions';
import * as uaActions from '../../core/ua';
import { getCallHistoryResultSet } from '../../core/call-history';

function controller(
  $mdColors,
  $mdDialog,
  $mdToast,
  $ngRedux,
  $scope,
  $state,
  $translate,
) {
  const self = this;

  let isZero = false;
  let unsubscribe;
  let zeroTimeout = null;

  /**
     *
     */
  this.$onInit = () => {
    this.input = '';

    // search result index
    this.highlightIndex = false;

    // default value for call button true == disabled
    this.isDisabled = true;

    // get accent color, this is a fix for md-colors attribut not working
    this.accent = $mdColors.getThemeColor('accent');

    unsubscribe = $ngRedux.connect((state) => ({
      allowAssisted: state.configs.get('allowAssistedTransfer')
        && sessionActions.activeCount(state) < state.configs.get('maxActiveCalls'), // [WP-679]
      searchResultSet: contactActions.getSearchResultSet(state),
      searchResultSetFull: contactActions.getSearchResultSet(state),
      callHistory: getCallHistoryResultSet(state),
      countryCode: state.configs.get('countryCode'),
      screenSize: state.screenSize,
      sip: state.ua.get('ua'),
    }))((state) => {
      this.allowAssisted = state.allowAssisted;
      this.countryCode = state.countryCode;
      this.dataset.refresh(state.callHistory);
      this.notRegister = state.sip;
      this.searchResultSet = state.searchResultSet;

      if (this.searchResultSet.length > 3000)
        this.searchResultSet = [];
      this.searchResultSetFull = state.searchResultSetFull;

      this.screenSize = state.screenSize;

      // set dialer height
      if (this.context === 'modal') {
        if (this.workflow === 'new-call') {
          this.dialerHeight = '450px';
        } else {
          this.dialerHeight = `${state.screenSize.height - 180}px`;
        }
      } else {
        this.dialerHeight = `${state.screenSize.height - 28}px`;
      }

      // set navigation
      if (!this.context && this.workflow === 'new-call') {
        this.showRemoveButton = true;
      } else if (this.workflow !== 'new-call') {
        this.showBackArrow = true;
      }
    });

    const interval = setInterval(() => {
      const inputElem = document.getElementById('dialer-input');
      if (inputElem) {
        inputElem.focus();
        clearInterval(interval);
      }
    }, 200);

    window.addEventListener('keyup', keyUp);
  };

  /**
     *
     */
  this.dataset = {
    items: [],
    refresh: (list) => {
      this.dataset.items = list;
    },
    getItemAtIndex: (index) => this.dataset.items[index],
    getLength: () => this.dataset.items.length,
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
    window.removeEventListener('keyup', keyUp);
  };

  /**
     *
     */
  this.assistedTransfer = (number) => {
    // get assisted target
    const activeCall = sessionActions.getActiveCall($ngRedux.getState());

    if (number) {
      // call assisted number
      $ngRedux.dispatch(uaActions.sendInvite(number, false));
    } else {
      // call assisted number
      $ngRedux.dispatch(uaActions.sendInvite(this.input, false));
    }

    // fullscreen use callback
    if (this.screenSize.display === 'fullscreen') {
      this.onAssisted({
        transferId: activeCall.id,
      });
    } else {
      $ngRedux.dispatch(navActions.clearToBase('callStack'));
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');

      // add assistedTarget param
      lastOnStack.params = lastOnStack.params || {};
      lastOnStack.params.transferId = activeCall.id;

      $state.go(lastOnStack.name, lastOnStack.params);
    }
  };

  /**
     *
     */
  this.backspace = () => {
    if (this.selectedItem) {
      this.selectedItem = null;
      document.getElementById('dialer-input').focus();
    } else if (!this.inputFocus && this.input && this.input.length) {
      this.input = this.input.substring(0, this.input.length - 1);
      // time out is so the keyUp function has time to finish
      setTimeout(() => {
        // validate that the number meets the requirement to dial
        const canDial = this.validateNumber(this.input);

        switch (canDial) {
          case true:
            this.isDisabled = false;
            this.highlightIndex = false;
            break;
          case false:
            this.isDisabled = true;
            this.highlightIndex = false;
            break;
          default:
            this.isDisabled = true;
            this.highlightIndex = false;
        }
        $scope.$apply();
      }, 0);
    }
  };

  /**
     *
     */
  this.blindTransfer = (number) => {
    const activeCall = sessionActions.getActiveCall($ngRedux.getState());
    if (number) {
      activeCall.transfer(number, true);
    } else {
      activeCall.transfer(this.input, true);
    }

    if (this.screenSize.display === 'fullscreen') {
      this.onPhone();
    } else {
      $ngRedux.dispatch(navActions.clearToBase('callStack'));
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
      $state.go(lastOnStack.name, lastOnStack.params);
    }
    $mdToast.show(
      $mdToast
        .simple()
        .textContent($translate.instant('TRANSFER_COMPLETED'))
        .position('bottom left'),
    );
  };

  /**
     *
     * @param number
     */
  this.call = (number) => {
    // check if number is valid
    const checkNumber = number ? self.validateNumber(number) : self.validateNumber(self.input);
    if (checkNumber === false && !number) {
      return;
    }

    let numberInput;

    // if clicked from contact search
    if (number) {
      numberInput = number;
    } else if (this.input.length
            && angular.element(`#index_${this.highlightIndex}`).length
            && angular.element(`#index_${this.highlightIndex}`)[0].dataset.number
    ) {
      // if contact search highlighted use the number from data attribute
      numberInput = angular.element(`#index_${this.highlightIndex}`)[0].dataset.number;
    } else if (this.input.length) {
      // else try from input
      numberInput = this.input.replace(/[^0-9*#+]/g, '');
    }

    // test for a number to dial
    if (!numberInput) {
      return;
    }

    if (this.workflow === 'new-call') {
      const callFromDevice = deviceActions.selectCallFrom($ngRedux.getState());
      if (!callFromDevice) {
        this.notRegister = $ngRedux.getState().ua.get('ua');
        $ngRedux.dispatch(uaActions.sendInvite(numberInput));
        $ngRedux.dispatch(cardActions.removeCard(this.card.id));
      } else {
        $ngRedux.dispatch(cardActions.removeCard(this.card.id));
        if (this.screenSize.display === 'compact') {
          $ngRedux.dispatch(navActions.navStackBack());
          const navLastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
          $state.go(navLastOnStack.name, navLastOnStack.params);
        }

        $mdToast.show(
          $mdToast.simple()
            .textContent(`${$translate.instant('CALLING_FROM')} ${callFromDevice.display}`)
            .position('bottom left')
            .hideDelay(1500),
        );

        $ngRedux.dispatch(deviceActions.callFrom(numberInput));
      }
    } else {
      // this route assumes the user is already on a call and the device is registered
      $ngRedux.dispatch(uaActions.sendInvite(numberInput, false));

      if (this.screenSize.display === 'fullscreen') {
        this.onPhone();
      } else {
        $ngRedux.dispatch(navActions.clearToBase('callStack'));
        const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
        $state.go(lastOnStack.name, lastOnStack.params);
      }
    }
  };

  /**
     *
     */
  this.inputChange = () => {
    // allow numbers only
    this.input = this.input.replace(/[^0-9*#+]/g, '');

    if (this.searchResultSetFull.length>3000)
    {
      if (this.input.length>2)
        this.searchResultSet = this.searchResultSetFull;
      else {
        this.searchResultSet = [];
      }
    }
    // time out is so the keyUp function has time to finish
    setTimeout(() => {
      // validate that the number meets the requirement to dial
      const canDial = this.validateNumber(this.input);

      switch (canDial) {
        case true:
          this.isDisabled = false;
          this.highlightIndex = false;
          break;
        case false:
          this.isDisabled = true;
          this.highlightIndex = false;
          break;
        default:
          this.isDisabled = true;
          this.highlightIndex = false;
      }
      $scope.$apply();
    }, 50);
  };

  /**
     *
     * @param num
     * @returns {boolean}
     */
  this.validateNumber = (num) => {
    const inputLength = num.length;
    return inputLength >= 1;

    // Remove number validation to avoid custom number issues
    // // allow * before number
    // let isStar = num.replace(/(^\*.{1,10}$)/g, 'true');
    // let startOne = num.replace(/(^[0,1])/g, 'true').indexOf('true');
    // // input lenght
    // let inputLength = num.length;

    // // check if number its found on a contact
    // // let isContact = angular.element('#index_0').length === 1 ? true : false;

    // // validate the input is equal to 3 or 4(911 - extensions)
    // if (inputLength === 2 || inputLength === 3 || inputLength === 4) {
    //     return true;
    // }
    // // validate the input is greater than 5
    // else if (inputLength > 5) {

    //     if (inputLength == 7 && startOne == -1) {
    //         return true;
    //     }

    //     // validate the input is a valid phone number
    //     let isValid = nsSDK.utils.isPhoneNumber(num, this.countryCode);
    //     if (isValid) {
    //         return true;
    //     }
    // }
    // // special case where a number has a '*' at the begining
    // else if (isStar == 'true') {
    //     return true;
    // } else if (num == '') {
    //     return false;
    // } else {
    //     return false;
    // }
  };

  /**
     *
     * @param key
     */
  this.mouseDown = (key) => {
    let num = key;

    if (isFinite(num)) {
      num = Number.parseInt(num);
    }

    if (num === 0) {
      isZero = true;
      zeroTimeout = setTimeout(() => {
        isZero = false;
        this.input += '+';
        // sendTone('+');
      }, 500);
    }
  };

  /**
     *
     * @param key
     */
  this.mouseUp = (key) => {
    let num = key;

    if (isFinite(num)) {
      num = Number.parseInt(num);
    }

    if (num === 0 && !isZero) {
      return;
    }
    clearTimeout(zeroTimeout);

    this.input += `${num}`;
    // sendTone(num);
    this.inputChange();
  };

  /**
     *
     */
  this.navBack = () => {
    if (this.screenSize.display === 'fullscreen') {
      this.onPhone();
    } else if (this.workflow === 'new-call') {
      $ngRedux.dispatch(cardActions.removeCard(this.card.id));
      $ngRedux.dispatch(navActions.clearToBase('navStack'));
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
      $state.go(lastOnStack.name, lastOnStack.params);
    } else {
      $ngRedux.dispatch(navActions.clearToBase('callStack'));
      const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'callStack');
      $state.go(lastOnStack.name, lastOnStack.params);
    }
  };

  /**
     *
     * @param e
     */
  function keyUp(e) {
    const num = numpadActions.getKey(e);

    if (num !== null) {
      // sendTone(num);
      switch (num) {
        case 'enter': {
          if (self.workflow !== 'in-call-transfer') {
            self.call();
          } else {
            document.getElementById('transfer-dropdown').click();
          }
          break;
        }
        case 'backspace': {
          // disable numeric keys if one of the input fields is in focus
          self.backspace();
          break;
        }
        case 'up': {
          if (self.input && !!angular.element(`#index_${self.highlightIndex - 1}`).length) {
            self.highlightIndex -= 1;

            angular.element('#contact-list').animate({
              scrollTop: angular.element(`#index_${self.highlightIndex}`)[0].offsetTop,
            }, 100);
          }
          break;
        }
        case 'down': {
          if (self.input && !!angular.element(`#index_${self.highlightIndex + 1}`).length) {
            self.highlightIndex += 1;

            angular.element('#contact-list').animate({
              scrollTop: angular.element(`#index_${self.highlightIndex}`)[0].offsetTop,
            }, 100);
          }
          break;
        }
        default: {
          // disable numeric keys if one of the input fields is in focus
          if (self.inputFocus) {
            return;
          }
          self.input += num;
          // extra validation for default behavior
          const checkNumber = self.validateNumber(self.input);
          self.isDisabled = checkNumber !== true;
        }
      }

      $scope.$apply();
    }
  }
}

controller.$inject = [
  '$mdColors',
  '$mdDialog',
  '$mdToast',
  '$ngRedux',
  '$scope',
  '$state',
  '$translate',
];

export default {
  template,
  controller,
  bindings: {
    card: '<',
    context: '@', // modal
    onAssisted: '&',
    onPhone: '&',
    workflow: '@', // new-call (opened from fab), in-call, in-call-transfer
  },
};

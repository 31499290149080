import _ from 'lodash';
import crypto from 'crypto-browserify';
import { Map } from 'immutable';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { setPresence } from '../presence';

import { connect } from '../socket';
import { formatContact } from './utils/formatContact';
import { getContactListDepartments } from './utils/getContactListDepartments';
import { getContactResultSet } from './utils/getContactResultSet';

/** ********************************************
 * Redux constants
 ******************************************** */

export const CONTACTS = 'core/contacts/CONTACTS';
export const CONTACTS_RESULT_SET = 'core/contacts/CONTACTS_RESULT_SET';
export const DEPARTMENTS = 'core/contacts/DEPARTMENTS';
export const FILTERED_BY = 'core/contacts/FILTERED_BY';
export const FILTERED_USER = 'core/contacts/FILTERED_USER';
export const IN_CALL_CONTACTS_SET = 'core/contacts/IN_CALL_CONTACTS_SET';
export const IN_CALL_FILTERED_BY = 'core/contacts/IN_CALL_FILTERED_BY';
export const IN_CALL_SEARCH_SET = 'core/contacts/IN_CALL_SEARCH_SET';
export const IN_CALL_SEARCHED_SET = 'core/contacts/IN_CALL_SEARCHED_SET';
export const IN_CALL_SORTED_BY = 'core/contacts/IN_CALL_SORTED_BY';
export const IN_CALL_SORTED_DIRECTION = 'core/contacts/IN_CALL_SORTED_DIRECTION';
export const LOADING = 'core/contacts/LOADING';
export const SEARCH_RESULTS_SET = 'core/contacts/SEARCH_RESULTS_SET';
export const SEARCHED_RESULTS_SET = 'core/contacts/SEARCHED_RESULTS_SET';
export const SORTED_BY = 'core/contacts/SORTED_BY';
export const SORTED_DIRECTION = 'core/contacts/SORTED_DIRECTION';
export const BADGE_LIST = 'core/contacts/BADGE_LIST';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  contacts: nsUtils.normalizeList('id', []), // master contacts list
  contactsResultSet: [], // sorted, filtered list used for the contact-list component
  departments: [],
  filteredBy: 'all',
  filteredUser: true,
  inCallContactsSet: [],
  inCallFilteredBy: 'all',
  inCallSearchSet: [],
  inCallSearchedSet: false,
  inCallSortedBy: 'last_name',
  inCallSortedDirection: 'asc',
  loading: true,
  searchResultsSet: [], // optimized contact list used for searching
  searchedResultsSet: false, // processed search result set
  sortedBy: 'last_name',
  sortedDirection: 'asc',
  badgeList: [],
});

export default (state = initialState, action) => {
  let contacts;

  switch (action.type) {
    case CONTACTS:
      contacts = nsUtils.normalizeList(
        'id',
        action.payload,
        [
          { name: 'names', path: 'name', toLower: true },
          { name: 'users', path: 'user' },
          { name: 'phones', path: 'user' },
          { name: 'phones', path: 'cell_phone' },
          { name: 'phones', path: 'home_phone' },
          { name: 'phones', path: 'work_phone' },
        ],
      );
      return state.set('contacts', contacts);
    case CONTACTS_RESULT_SET:
      return state.set('contactsResultSet', action.payload);
    case DEPARTMENTS:
      return state.set('departments', action.payload);
    case FILTERED_BY:
      return state.set('filteredBy', action.payload);
    case FILTERED_USER:
      return state.set('filteredUser', action.payload);
    case IN_CALL_CONTACTS_SET:
      return state.set('inCallContactsSet', action.payload);
    case IN_CALL_FILTERED_BY:
      return state.set('inCallFilteredBy', action.payload);
    case IN_CALL_SEARCH_SET:
      return state.set('inCallSearchSet', action.payload);
    case IN_CALL_SEARCHED_SET:
      return state.set('inCallSearchedSet', action.payload);
    case IN_CALL_SORTED_BY:
      return state.set('inCallSortedBy', action.payload);
    case IN_CALL_SORTED_DIRECTION:
      return state.set('inCallSortedDirection', action.payload);
    case LOADING:
      return state.set('loading', action.payload);
    case SEARCH_RESULTS_SET:
      return state.set('searchResultsSet', action.payload);
    case SEARCHED_RESULTS_SET:
      return state.set('searchedResultsSet', action.payload);
    case SORTED_BY:
      return state.set('sortedBy', action.payload);
    case SORTED_DIRECTION:
      return state.set('sortedDirection', action.payload);
    case BADGE_LIST:
      return state.set('badgeList', action.payload);

    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @param contact
 * @return {function(*=, *=)}
 */
export const addContact = (contact) => (dispatch, getState) => {
  // clean phone numbers and turn email array into string
  contact.cell_phone = nsUtils.cleanPhoneNumber(contact.cell_phone);
  contact.home_phone = nsUtils.cleanPhoneNumber(contact.home_phone);
  contact.work_phone = nsUtils.cleanPhoneNumber(contact.work_phone);
  if (contact.email) {
    contact.email = _.chain(contact.email).compact().join(';').value();
  }

  // make a copy of the contact
  // so the object isn't altered before saving the changes in redux
  const tmpContact = { ...contact };

  // prep post params
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'contact',
    action: 'create',
    domain: decodedToken.domain,
    cell_phone: contact.cell_phone || '',
    email: contact.email,
    first_name: contact.first_name,
    home_phone: contact.home_phone || '',
    last_name: contact.last_name,
    tags: contact.tags,
    user: decodedToken.user,
    work_phone: contact.work_phone || '',
  };
  // post contact
  nsApi.post(params).then(() => {
    // get contact
    // this gets additional props that are added by the api
    nsApi.get({
      object: 'contact',
      action: 'read',
      domain: decodedToken.domain,
      first_name: contact.first_name,
      format: 'json',
      includeDomain: 'yes',
      last_name: contact.last_name,
      limit: 1,
      user: decodedToken.user,
    }).then((res) => {
      _addContact(dispatch, getState, res[0]);
    });
  }).catch(() => {
    // todo
  });
};

/**
 *
 * @param payload
 * @return {function(*, *)}
 */
export const deleteContact = (payload) => (dispatch, getState) => {
  const token = nsToken.getDecoded();
  const contacts = getState().contacts.get('contacts');
  const contact = contacts.entities[payload];

  const delParams = {
    object: 'contact',
    action: 'delete',
    user: token.user,
    domain: token.domain,
    first_name: contact.first_name,
    last_name: contact.last_name,
  };

  nsApi.post(delParams).then(() => {
    // after api delete update local contacts
    const arr = contacts.toArray();
    const arrLength = arr.length;
    const contactsArr = [];
    for (let i = 0; i < arrLength; i += 1) {
      if (arr[i].id !== payload) {
        contactsArr.push(arr[i]);
      }
    }

    // update state
    dispatch({
      type: CONTACTS,
      payload: contactsArr,
    });

    if (contactsArr) {
      // process result set
      dispatch(processResultSet());
      dispatch(processSearchResultSet());
      dispatch(inCallProcessResultSet());
      dispatch(inCallProcessSearchResultSet());
    }
  });
};

/**
 *
 * @returns {function(*, *)}
 */
export const fetchContacts = () => (dispatch) => {
  dispatch(loading(true));

  const token = nsToken.getDecoded();
  const reqParams = {
    object: 'contact',
    action: 'read',
    user: token.user,
    domain: token.domain,
    format: 'json',
    includeDomain: 'yes',
  };

  return nsApi.get(reqParams).then((contactsArr) => {
    // format contacts
    const l = contactsArr.length;
    for (let i = 0; i < l; i += 1) {
      contactsArr[i] = formatContact(contactsArr[i]);
      // set presence
      if (contactsArr[i].user) {
        dispatch(setPresence(contactsArr[i].user, contactsArr[i].presence));
      }
    }

    // connect to socket
    dispatch(connect());

    dispatch({
      type: CONTACTS,
      payload: contactsArr,
    });

    if (contactsArr) {
      // process result set
      dispatch(processResultSet(true));
      dispatch(processSearchResultSet());
      dispatch(inCallProcessResultSet());
      dispatch(inCallProcessSearchResultSet());
    } else {
      dispatch(loading(false));
    }
  }).catch(() => {
    dispatch(loading(false));
  });
};

/**
 *
 * @param {string} payload 'all', 'favorites', 'my_contacts', 'coworkers', 'online', 'busy', 'offline', custom e.g 'department 1'
 * @return {function(*, *)}
 */
export const filteredBy = (payload) => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');

  localStorage.setItem(
    `${appName}-${userId}_contacts-filtered-by`,
    payload,
  );

  dispatch({
    type: FILTERED_BY,
    payload,
  });

  const contactsArr = getState().contacts.get('contacts').toArray();

  if (contactsArr) {
    // process result set
    dispatch(processResultSet());
    dispatch(processSearchResultSet());
  }
};

/**
 * filterUser excludes the user from the contact list, used by voicemail forward
 * @param {boolean} payload
 * @returns {function(*, *)}
 */
export const filteredUser = (payload) => (dispatch, getState) => {
  dispatch({
    type: FILTERED_USER,
    payload,
  });

  const contactsArr = getState().contacts.get('contacts').toArray();

  if (contactsArr) {
    // process result set
    dispatch(processResultSet());
    dispatch(processSearchResultSet());
    dispatch(inCallProcessResultSet());
    dispatch(inCallProcessSearchResultSet());
  }
};

/**
 *
 * @param {string} payload 'all', 'favorites', 'my_contacts', 'coworkers', 'online', 'busy', 'offline', custom e.g 'department 1'
 * @return {function(*, *)}
 */
export const inCallFilteredBy = (payload) => (dispatch, getState) => {
  dispatch({
    type: IN_CALL_FILTERED_BY,
    payload,
  });

  const hasContacts = !!getState().contacts.get('contacts').ids.length;

  if (hasContacts) {
    // process result set
    dispatch(inCallProcessResultSet());
    dispatch(inCallProcessSearchResultSet());
  }
};

/**
 *
 * @return {function(*, *)}
 */
export const inCallProcessResultSet = () => (dispatch, getState) => {
  // process contacts for contact result set
  const contactsList = getContactResultSet(
    getState().contacts.get('contacts').toArray(),
    getState().contacts.get('inCallFilteredBy'),
    getState().contacts.get('filteredUser'),
    getState().contacts.get('inCallSortedBy'),
    getState().contacts.get('inCallSortedDirection'),
  );

  // dispatch result set
  dispatch({
    type: IN_CALL_CONTACTS_SET,
    payload: contactsList,
  });
};

/**
 *
 * @return {function(*, *)}
 */
export const inCallProcessSearchResultSet = () => (dispatch, getState) => {
  const searchResultsSet = getState().contacts.get('searchResultsSet');
  dispatch({
    type: IN_CALL_SEARCH_SET,
    payload: searchResultsSet,
  });
};

/**
 *
 * @param searchStr
 * @returns {function(*, *)}
 */
export const inCallSearch = (searchStr) => (dispatch, getState) => {
  if (!searchStr) {
    dispatch({
      type: IN_CALL_SEARCHED_SET,
      payload: false,
    });
  } else {
    const contactsResultSet = getState().contacts.get('inCallContactsSet');
    const searchResults = _search(searchStr, contactsResultSet);

    dispatch({
      type: IN_CALL_SEARCHED_SET,
      payload: searchResults,
    });
  }
};

/**
 *
 * @param {string} payload 'first_name', 'last_name', 'online', 'extension'
 * @returns {function(*, *)}
 */
export const inCallSortedBy = (payload) => (dispatch, getState) => {
  dispatch({
    type: IN_CALL_SORTED_BY,
    payload,
  });

  const hasContacts = !!getState().contacts.get('contacts').ids.length;

  if (hasContacts) {
    // process result set
    dispatch(inCallProcessResultSet());
    dispatch(inCallProcessSearchResultSet());
  }
};

/**
 *
 * @param {string} payload 'asc', 'desc'
 * @returns {function(*, *)}
 */
export const inCallSortedDirection = (payload) => (dispatch, getState) => {
  dispatch({
    type: IN_CALL_SORTED_DIRECTION,
    payload,
  });

  const hasContacts = !!getState().contacts.get('contacts').ids.length;

  if (hasContacts) {
    // process result set
    dispatch(inCallProcessResultSet());
    dispatch(inCallProcessSearchResultSet());
  }
};

/**
 *
 * @param payload
 * @returns {{type: string, payload: *}}
 */
export const loading = (payload) => ({
  type: LOADING,
  payload,
});

/**
 *
 * @return {function(*, *)}
 */
export const processResultSet = (updateLoading = false) => (dispatch, getState) => {
  const contactsArr = getState().contacts.get('contacts').toArray();

  // process contacts for contact result set
  const contactsList = getContactResultSet(
    getState().contacts.get('contacts').toArray(),
    getState().contacts.get('filteredBy'),
    getState().contacts.get('filteredUser'),
    getState().contacts.get('sortedBy'),
    getState().contacts.get('sortedDirection'),
  );

  // get list of departments
  const depts = getContactListDepartments(contactsArr);

  // dispatch department list
  dispatch({
    type: DEPARTMENTS,
    payload: depts,
  });

  // dispatch result set
  dispatch({
    type: CONTACTS_RESULT_SET,
    payload: contactsList,
  });

  if (updateLoading) {
    dispatch(loading(false));
  }
};

/**
 *
 * @return {function(*, *)}
 */
export const processSearchResultSet = () => (dispatch, getState) => {
  const contactsArr = getState().contacts.get('contacts').toArray();

  if (contactsArr) {
    const contactsSearchArr = [];
    for (let i = 0; i < contactsArr.length; i += 1) {
      const name = contactsArr[i].name.toLowerCase();
      const { user } = getState();

      if (user.userId === contactsArr[i].user) { continue; }

      if (contactsArr[i].user && contactsArr[i].extension) {
        contactsSearchArr.push({
          contact: contactsArr[i],
          number: contactsArr[i].extension,
          searchStr: `${name} ${contactsArr[i].extension}`,
          type: 'extension',
        });
      }

      if (user.sms && user.sms.length) {
        if (contactsArr[i].cell_phone) {
          contactsSearchArr.push({
            contact: contactsArr[i],
            number: contactsArr[i].cell_phone,
            searchStr: `${name} ${contactsArr[i].cell_phone}`,
            type: 'cell',
            sms: true,
          });
        }
        if (contactsArr[i].home_phone) {
          contactsSearchArr.push({
            contact: contactsArr[i],
            number: contactsArr[i].home_phone,
            searchStr: `${name} ${contactsArr[i].home_phone}`,
            type: 'home',
            sms: true,
          });
        }
        if (contactsArr[i].work_phone) {
          contactsSearchArr.push({
            contact: contactsArr[i],
            number: contactsArr[i].work_phone,
            searchStr: `${name} ${contactsArr[i].work_phone}`,
            type: 'work',
            sms: true,
          });
        }
      }
    }

    dispatch({
      type: SEARCH_RESULTS_SET,
      payload: contactsSearchArr,
    });
  }
};

/**
 *
 * @param searchStr
 * @returns {function(*, *)}
 */
export const search = (searchStr) => (dispatch, getState) => {
  if (!searchStr) {
    dispatch({
      type: SEARCHED_RESULTS_SET,
      payload: false,
    });
  } else {
    const contactsResultSet = getState().contacts.get('contactsResultSet');
    const searchResults = _search(searchStr, contactsResultSet);

    dispatch({
      type: SEARCHED_RESULTS_SET,
      payload: searchResults,
    });
  }
};

/**
 *
 * @param {string} payload 'first_name', 'last_name', 'online', 'extension'
 * @returns {function(*, *)}
 */
export const sortedBy = (payload) => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');

  localStorage.setItem(
    `${appName}-${userId}_contacts-sort-by`,
    payload,
  );

  dispatch({
    type: SORTED_BY,
    payload,
  });

  const contactsArr = getState().contacts.get('contacts').toArray();

  if (contactsArr) {
    // process result set
    dispatch(processResultSet());
    dispatch(processSearchResultSet());
  }
};

/**
 *
 * @param {string} payload 'asc', 'desc'
 * @returns {function(*, *)}
 */
export const sortedDirection = (payload) => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');

  localStorage.setItem(
    `${appName}-${userId}_contacts-sorted-direction`,
    payload,
  );

  dispatch({
    type: SORTED_DIRECTION,
    payload,
  });

  const contactsArr = getState().contacts.get('contacts').toArray();

  if (contactsArr) {
    // process result set
    dispatch(processResultSet());
    dispatch(processSearchResultSet());
  }
};

/**
 *
 * @param contact
 * @return {function(*, *)}
 */
export const updateContact = (contact) => (dispatch, getState) => {
  // make a copy of the contact
  // so the object isn't altered before saving the changes in redux
  contact = { ...contact };
  if (contact.email) {
    // make a copy of the contact email
    contact.email = contact.email.slice();
  } else {
    contact.email = [''];
  }

  // clean phone numbers and turn email array into string
  contact.cell_phone = nsUtils.cleanPhoneNumber(contact.cell_phone);
  contact.home_phone = nsUtils.cleanPhoneNumber(contact.home_phone);
  contact.work_phone = nsUtils.cleanPhoneNumber(contact.work_phone);
  if (contact.email) {
    contact.email = _.chain(contact.email).compact().join(';').value();
  }

  // prep post params
  const decodedToken = nsToken.getDecoded();
  const params = {
    object: 'contact',
    action: contact.contact_id ? 'update' : 'create',
    contact_id: contact.contact_id ? contact.contact_id : contact.id ,
    domain: decodedToken.domain,
    cell_phone: contact.cell_phone || '',
    email: contact.email,
    first_name: contact.first_name,
    home_phone: contact.home_phone || '',
    last_name: contact.last_name,
    tags: contact.tags,
    user: decodedToken.user,
    work_phone: contact.work_phone || '',
  };

  // post to api
  nsApi.post(params);

  if (params['action'] == 'create') {
    contact.contact_id = contact.id;
  }

  // format contact
  contact = formatContact(contact);

  if (contact.email) {
    contact.gravatar = crypto.createHash('md5').update(contact.email[0]).digest('hex');
  } else {
    contact.gravatar = false;
  }

  getState().contacts.get('contacts').entities[contact.id] = contact;

  // dispatch contacts
  const contactsArr = getState().contacts.get('contacts').toArray();
  dispatch({
    type: CONTACTS,
    payload: contactsArr,
  });

  // update result sets
  dispatch(processResultSet());
  dispatch(processSearchResultSet());
  dispatch(inCallProcessResultSet());
  dispatch(inCallProcessSearchResultSet());
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @param id
 * @return {*}
 */
export const getContact = (state, id) => state.contacts.get('contacts').entities[id] || null;

/**
 *
 * @param state
 * @return {*}
 */
export const getContactsResultSet = (state) => state.contacts.get('contactsResultSet');

/**
 *
 * @param state
 * @return {*}
 */
export const getDepartments = (state) => state.contacts.get('departments');

/**
 *
 * @param state
 */
export const getFilteredBy = (state) => state.contacts.get('filteredBy');

/**
 *
 * @param state
 * @return {*}
 */
export const getInCallContactsSet = (state) => state.contacts.get('inCallContactsSet');

/**
 *
 * @param state
 */
export const getInCallFilteredBy = (state) => state.contacts.get('inCallFilteredBy');

/**
 *
 * @param state
 */
export const getInCallSearchedSet = (state) => state.contacts.get('inCallSearchedSet');

/**
 *
 * @param state
 */
export const getInCallSortedBy = (state) => state.contacts.get('inCallSortedBy');

/**
 *
 * @param state
 */
export const getInCallSortedDirection = (state) => state.contacts.get('inCallSortedDirection');

/**
 *
 * @param state
 * @return {boolean}
 */
export const getLoading = (state) => state.contacts.get('loading');

/**
 *
 * @param state
 */
export const getSearchResultSet = (state) => state.contacts.get('searchResultsSet');

/**
 *
 * @param state
 */
export const getSearchedResultSet = (state) => state.contacts.get('searchedResultsSet');

/**
 *
 * @param state
 */
export const getSortedBy = (state) => state.contacts.get('sortedBy');

/**
 *
 * @param state
 */
export const getSortedDirection = (state) => state.contacts.get('sortedDirection');

/**
 *
 * @param state
 * @return {array}
 */
export const getBadgeList = (state) => state.contacts.get('badgeList');

/**
 *
 * @param state
 */
export const setBadgeList = (badgeArr) => (dispatch, getState) => {
  dispatch({
    type: BADGE_LIST,
    payload: badgeArr,
  });
};

/** ********************************************
 * Private Helper functions
 ******************************************** */

/**
 *
 * @param dispatch
 * @param getState
 * @param contact
 * @param dispatchResults
 * @private
 */
function _addContact(dispatch, getState, contact, dispatchResults = true) {
  // format contact
  contact = formatContact(contact);

  if (contact.email) {
    contact.gravatar = crypto.createHash('md5').update(contact.email[0]).digest('hex');
  } else {
    contact.gravatar = false;
  }

  let contactsArr;
  if (getState().contacts.get('contacts').entities[contact.id]) {
    getState().contacts.get('contacts').entities[contact.id] = contact;
    contactsArr = getState().contacts.get('contacts').toArray();
  } else {
    contactsArr = getState().contacts.get('contacts').toArray();
    contactsArr.push(contact);
  }

  dispatch({
    type: CONTACTS,
    payload: contactsArr,
  });

  if (dispatchResults) {
    dispatch(processResultSet());
    dispatch(processSearchResultSet());
    dispatch(inCallProcessResultSet());
    dispatch(inCallProcessSearchResultSet());
  }
}

/**
 *
 * @param searchStr
 * @param contactsResultSet
 * @private
 */
function _search(searchStr, contactsResultSet) {
  searchStr = searchStr.toLowerCase();
  return _.chain(contactsResultSet)
    .filter((contact) => {
      if (contact.index !== undefined) {
        return false;
      }
      if (contact.name && contact.name.toLowerCase().indexOf(searchStr) !== -1) {
        return true;
      }
      if (contact.user && contact.user.toLowerCase().indexOf(searchStr) !== -1) {
        return true;
      }
      if (contact.work_phone && contact.work_phone.indexOf(searchStr) !== -1) {
        return true;
      }
      if (contact.cell_phone && contact.cell_phone.indexOf(searchStr) !== -1) {
        return true;
      }
      return contact.home_phone && contact.home_phone.indexOf(searchStr) !== -1;
    })
    .value();
}

import { Map } from 'immutable';

/** ********************************************
 * Redux constants
 ******************************************** */

export const OBJECT = 'core/angular/object';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map();

export default (state = initialState, action) => {
  switch (action.type) {
    case OBJECT:
      return state.set(action.payload.name, action.payload.object);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @param {string} name
 * @param {object} object
 * @return {{type: string, payload: {name: *, object: *}}}
 */
export const setObject = (name, object) => ({
  type: OBJECT,
  payload: { name, object },
});

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @param name
 */
export const getObject = (state, name) => state.angular.get(name);

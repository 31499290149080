import template from './template.html';
import './styles.css';
import {
  createAgentLog, postAgentStatus, updateQueueEntry, getAgentQueues, getAgentStatus, getShowAgentCenter,
} from '../../core/agent-center';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$translate',
];

function controller(
  $ngRedux,
  $translate,
) {
  let unsubscribe;
  const self = this;

  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      agentStatus: getAgentStatus(state),
      showAgentStatus: getShowAgentCenter(state),
      queues: getAgentQueues(state),
      cust1: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_1') || "",
      cust2: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_2') || "",
      cust3: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_3') || "",
      cust4: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_4') || "",
      cust5: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_5') || "",
      cust6: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_6') || "",
      cust7: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_7') || "",
      cust8: state.configs.get('PORTAL_CALL_QUEUE_STATUS_CUSTOM_8') || "",
    }))((state) => {
      _setAgentStatusLabel(state.agentStatus);
      this.showAgentStatus = state.showAgentStatus;
      this.queues = state.queues;
      this.cust1 = state.cust1;
      this.cust2 = state.cust2;
      this.cust3 = state.cust3;
      this.cust4 = state.cust4;
      this.cust5 = state.cust5;
      this.cust6 = state.cust6;
      this.cust7 = state.cust7;
      this.cust8 = state.cust8;
    });
  };

  this.changeStatus = (mode) => {
    let queue_name = '';
    if (this.queues[0] && this.queues[0].queue_name) {
      queue_name = this.queues[0].queue_name;
    }

    switch (mode) {
      case 'online':
        $ngRedux.dispatch(createAgentLog('Auto'));
        $ngRedux.dispatch(postAgentStatus(''));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_im',
          queue_name,
          toAsQueue: 'no',
        }));
        self.agentStatusLabel = $translate.instant('ONLINE');
        self.agentOnline = true;
        break;
      case 'offline':
        $ngRedux.dispatch(createAgentLog('Manual'));
        $ngRedux.dispatch(postAgentStatus(''));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_ma',
          queue_name,
          toAsQueue: 'no',
        }));
        self.agentStatusLabel = $translate.instant('OFFLINE');
        self.agentOnline = false;
        break;
      case 'singlecall':
        $ngRedux.dispatch(createAgentLog('Manual-avail'));
        $ngRedux.dispatch(postAgentStatus(''));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_av',
          queue_name,
          toAsQueue: 'no',
        }));
        self.agentStatusLabel = $translate.instant('SINGLE_CALL');
        self.agentOnline = true;
        break;
      case 'lunch':
        $ngRedux.dispatch(createAgentLog('Lunch'));
        $ngRedux.dispatch(postAgentStatus('Lunch'));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_av',
          queue_name,
          toAsQueue: 'no',
        }));
        break;
      case 'break':
        $ngRedux.dispatch(createAgentLog('Break'));
        $ngRedux.dispatch(postAgentStatus('Break'));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_ma',
          queue_name,
          toAsQueue: 'no',
        }));
        break;
      case 'meeting':
        $ngRedux.dispatch(createAgentLog('Meeting'));
        $ngRedux.dispatch(postAgentStatus('Meeting'));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_ma',
          queue_name,
          toAsQueue: 'no',
        }));
        break;
      case 'other':
        $ngRedux.dispatch(createAgentLog('Other'));
        $ngRedux.dispatch(postAgentStatus('Other'));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_ma',
          queue_name,
          toAsQueue: 'no',
        }));
        break;
      case 'cust1':
      case 'cust2':
      case 'cust3':
      case 'cust4':
      case 'cust5':
      case 'cust6':
      case 'cust7':
      case 'cust8':
        const valString = this[mode];
        $ngRedux.dispatch(createAgentLog(mode));
        $ngRedux.dispatch(postAgentStatus(valString));
        $ngRedux.dispatch(updateQueueEntry({
          action: 'make_ma',
          queue_name,
          toAsQueue: 'no',
        }));
        break;
      default:
        console.error('invalid agent status', mode);
    }
  };
  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     * @param label
     * @private
     */
  function _setAgentStatusLabel(label) {
    switch (label.toLowerCase()) {
      case 'online':
        self.agentStatusLabel = $translate.instant('ONLINE');
        self.agentOnline = true;
        break;
      case 'offline':
        self.agentStatusLabel = $translate.instant('OFFLINE');
        self.agentOnline = false;
        break;
      case 'singlecall':
        self.agentStatusLabel = $translate.instant('SINGLE_CALL');
        self.agentOnline = true;
        break;
      case 'lunch':
        self.agentStatusLabel = $translate.instant('LUNCH');
        self.agentOnline = false;
        break;
      case 'break':
        self.agentStatusLabel = $translate.instant('BREAK');
        self.agentOnline = false;
        break;
      case 'meeting':
        self.agentStatusLabel = $translate.instant('MEETING');
        self.agentOnline = false;
        break;
      case 'other':
        self.agentStatusLabel = $translate.instant('OTHER');
        self.agentOnline = false;
        break;
      default:
        self.agentStatusLabel = label;
        self.agentOnline = false;
    }
  }
}

import './styles.css';
import template from './template.html';
import { getGreetings, updateGreeting, deleteGreeting } from '../../core/greetings';
import * as navActions from '../../core/state-history';

export default {
  template,
  controller,
};

controller.$inject = [
  '$ngRedux',
  '$mdDialog',
  '$mdToast',
  '$state',
  'ngAudio',
  '$translate',
];

function controller(
  $ngRedux,
  $mdDialog,
  $mdToast,
  $state,
  ngAudio,
  $translate,
) {
  let unsubscribe;
  /**
     *
     */
  this.$onInit = () => {
    unsubscribe = $ngRedux.connect((state) => ({
      greetings: getGreetings(state),
      screenSize: state.screenSize,
      stream: state.userMedia.get('stream'),
      user: state.user,
    }))(this);

    this.loaded = true;
    this.audio = null;
    this.WPmicrophone = (localStorage.getItem('WPmicrophone') === 'true');
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  this.noAudioDialog = () => {
    $mdDialog.show({
      controller: ['$scope', function ($scope) {
        $scope.cancel = function () { $mdDialog.hide(); };
      }],
      template: `${'<md-dialog class="p-r-10 p-l-10" style="max-width: 600px; width: 90%">'
              + '   <md-dialog-content class="m-t-20 m-r-10 m-l-10">'
              + '         <h3 class="m-b-0">'}${$translate.instant('MICROPHONE_REQUIRED')}</h3>`
              + `         <p>${$translate.instant('ACCESS_TO_MICROPHONE_REQUIRED')}. ${$translate.instant('ENABLE_MICROPHONE_BROWSER')}</p>`
              + '   </md-dialog-content>'
              + '   <md-dialog-actions>'
              + '        <md-button class="md-primary" ng-click="cancel()">'
              + '           OK'
              + '       </md-button>'
              + '   </md-dialog-actions>'
              + '</md-dialog>',
      clickOutsideToClose: false,
    });
  };

  /**
     *
     */
  this.navPush = (route) => {
    const currentState = $ngRedux.getState().stateHistory.get('currentState');
    $ngRedux.dispatch(
      navActions.navStackPush({
        name: route.name,
        params: route.params || null,
        fromName: currentState.name,
        fromParams: currentState.params,
      }),
    );
    $state.go(route.name, route.params || null);
  };

  /**
     *
     * @param oGreeting
     */
  this.updateGreeting = (oGreeting) => {
    $ngRedux.dispatch(updateGreeting(oGreeting));
    this.user.vmailGreeting = oGreeting.index;
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('GREETING_SAVED'))
        .position('bottom left')
        .hideDelay(1500),
    );
  };

  /**
     *
     * @param oGreeting
     */
  this.deleteGreeting = (oGreeting) => {
    $ngRedux.dispatch(deleteGreeting(oGreeting));
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('GREETING_DELETED'))
        .position('bottom left')
        .hideDelay(1500),
    );
  };

  /**
     * fetches and loads the audio file
     * alerts the user if there is an error
     * @param {string} path
     */
  this.loadAudio = (path) => {
    if (this.audio) {
      if (this.audio.id !== path) {
        this.audio.stop();
        setTimeout(() => {
          this.audio = ngAudio.load(path);
        }, 0);
      }
    } else {
      setTimeout(() => {
        this.audio = ngAudio.load(path);
      }, 0);
    }
  };

  /**
     * plays the audio
     * and has a callback for when the audio is complete to set playing to false
     * and resets the track back to the beginning
     */
  this.playAudio = () => {
    if (isNaN(this.audio.duration)) {
      this.audioError();
    }
    this.audio.play();
  };

  /**
     *
     */
  this.audioError = () => {
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('ERROR_PLAYING_GREETING'))
        .position('bottom left')
        .hideDelay(1500),
    );
  };
}

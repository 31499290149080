import nsToken from '@netsapiens/netsapiens-js/dist/token';

const removeValue = (list, value, separator) => {
  separator = separator || ',';
  const values = list.split(separator);
  for (let i = 0; i < values.length; i += 1) {
    if (values[i] === value) {
      values.splice(i, 1);
      return values.join(separator);
    }
  }
  return list;
};

// input: mesg data, including list of uid's of participants
//       ie. ["9999@portal","1123@portal","5197@portal","1005@portal","2500@portal","2501@portal"]
// return: comma seperated list of destination user uid's
//       ie. "9999@portal,1123@portal,2501@portal,5197@portal"
export const getGroupRemote = ({ data }) => {
  const decodedToken = nsToken.getDecoded();
  const { user } = decodedToken;
  const { domain } = decodedToken;

  let remote = data.participants;
  remote = remote.replace(/['"\]\[]+/g, '');

  remote = removeValue(remote, `${user}@${domain}`, ',');
  console.log('this is remote in getGroupRemote AFTER2: ', remote);
  return remote;
};

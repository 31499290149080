import _ from 'lodash';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsToken from '@netsapiens/netsapiens-js/dist/token';

import './styles.css';
import template from './template.html';
import * as deviceActions from '../../core/devices';
import * as navActions from '../../core/state-history';

function controller(
  $rootScope,
  $ngRedux,
  $state,
  $mdToast,
  $translate,
) {
  let unsubscribe;

  /**
     *
     */
  this.$onInit = () => {
    const token = nsToken.getDecoded();
    nsApi.get({
      object: 'device',
      action: 'read',
      user: token.user,
      domain: token.domain,
      noNDP: 'true',
      mode: 'registered_endpoint',
      format: 'json',
    }).then((res) => {
      let devices = _.compact(res);

      // remove conference bridge device
      devices = _.filter(devices, (device) => device && device.aor && device.aor.indexOf('conference-bridge') === -1 && device.aor.indexOf('vb') === -1);

      // format number
      for (let i = 0; i < devices.length; i += 1) {
        devices[i].display = devices[i].aor.replace('sip:', '');
        devices[i].display = devices[i].display.replace(`@${devices[i].subscriber_domain}`, '');
      }

      this.devices = devices;
      $rootScope.$apply();
    });

    unsubscribe = $ngRedux.connect((state) => ({
      initialSelection: state.devices.get('callFromDevice'),
      screenSize: state.screenSize,
    }))((state) => {
      this.selection = this.selection || state.initialSelection.aor || {};
      this.screenSize = state.screenSize;
    });
  };

  /**
     *
     */
  this.$onDestroy = () => {
    unsubscribe();
  };

  /**
     *
     */
  this.navBack = () => {
    $ngRedux.dispatch(navActions.navStackBack());
    const lastOnStack = navActions.getLastOnStack($ngRedux.getState(), 'navStack');
    $state.go(lastOnStack.name, lastOnStack.params);
  };

  /**
     *
     */
  this.cancel = function () {
    this.navBack();
  };

  /**
     *
     */
  this.save = () => {
    $mdToast.show(
      $mdToast.simple()
        .textContent($translate.instant('CALL_FROM_SAVED'))
        .position('bottom left')
        .hideDelay(1500),
    );

    const token = nsToken.getDecoded();
    const appName = $ngRedux.getState().configs.get('appName');
    const isExtension = !!$ngRedux.getState().extension.get('port');

    let device;
    for (let i = 0; i < this.devices.length; i += 1) {
      if (this.selection === this.devices[i].aor) {
        device = this.devices[i];
        break;
      }
    }

    localStorage.setItem(
      `${appName}-${token.user}_callfrom${isExtension ? '_extension' : ''}`,
      JSON.stringify(device),
    );

    $ngRedux.dispatch(deviceActions.setCallFromDevice(device));

    this.navBack();
  };
}

controller.$inject = [
  '$rootScope',
  '$ngRedux',
  '$state',
  '$mdToast',
  '$translate',
];

export default {
  template,
  controller,
};

import { Map } from 'immutable';

import meta from 'netsapiens-ringtones/audio/ringtones-meta.json';

/** ********************************************
 * Redux constants
 ******************************************** */

export const PATH = 'core/ringtones/PATH';
export const RINGTONES = 'core/ringtones/RINGTONES';
export const TONE_NAME = 'core/ringtones/TONE_NAME';

/** ********************************************
 * Reducer
 ******************************************** */

const initialState = new Map({
  path: null,
  ringtones: null,
  toneName: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case PATH:
      return state.set('path', action.payload);
    case RINGTONES:
      return state.set('ringtones', action.payload);
    case TONE_NAME:
      return state.set('toneName', action.payload);
    default:
      return state;
  }
};

/** ********************************************
 * Actions
 ******************************************** */

/**
 *
 * @return {function(*, *)}
 */
export const ringtones = () => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const pathname = getState().configs.get('pathname');
  const userId = getState().configs.get('userId');
  let toneName = localStorage.getItem(`${appName}-${userId}_ringtone`) || 'default';

  // test if there is a value and if the fileName exists in the meta list
  if (toneName && meta[toneName]) {
    dispatch({
      type: PATH,
      payload: `${pathname}assets/audio/ringtones/${meta[toneName].fileName}`,
    });
  }
  // use the default wav and store setting in local storage
  else {
    toneName = 'default';
    dispatch({
      type: PATH,
      payload: `${pathname}assets/audio/ringtones/default.wav`,
    });
    localStorage.setItem(`${appName}-${userId}_ringtone`, 'default');
  }

  dispatch({
    type: TONE_NAME,
    payload: toneName,
  });

  const orderedRingtones = [];
  for (const prop in meta) {
    if (meta.hasOwnProperty(prop)) {
      // set selected prop for display purposes
      meta[prop].selected = prop === toneName;

      // set the path
      meta[prop].path = `${pathname}assets/audio/ringtones/${meta[prop].fileName}`;

      if (prop === 'default') {
        orderedRingtones.unshift(meta[prop]);
      } else {
        orderedRingtones.push(meta[prop]);
      }
    }
  }

  dispatch({
    type: RINGTONES,
    payload: orderedRingtones,
  });

  return Promise.resolve();
};

/**
 *
 * @param {string} payload - toneName
 * @return {function(*, *)}
 */
export const toneName = (payload) => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const pathname = getState().configs.get('pathname');
  const ringtones = getState().ringtones.get('ringtones');
  const userId = getState().configs.get('userId');

  localStorage.setItem(`${appName}-${userId}_ringtone`, payload);

  for (let i = 0; i < ringtones.length; i += 1) {
    ringtones[i].selected = ringtones[i].name === payload;
  }

  dispatch({
    type: TONE_NAME,
    payload,
  });

  dispatch({
    type: PATH,
    payload: `${pathname}assets/audio/ringtones/${meta[payload].fileName}`,
  });

  dispatch({
    type: RINGTONES,
    payload: ringtones,
  });
};

/** ********************************************
 * Selectors
 ******************************************** */

/**
 *
 * @param state
 * @return {[]}
 */
export const getPath = (state) => state.ringtones.get('path');

/**
 *
 * @param state
 * @return {[]}
 */
export const getRingtones = (state) => state.ringtones.get('ringtones');

/**
 *
 * @param state
 * @return {[]}
 */
export const getSelectedRingtone = (state) => {
  const ringtones = state.ringtones.get('ringtones');
  for (let i = 0; i < ringtones.length; i += 1) {
    if (ringtones[i].selected) {
      return ringtones[i];
    }
  }
};

export const getSelectedCallWaiting = (state) => {
  const appName = state.configs.get('appName');
  const userId = state.configs.get('userId');
  const cKey = `${appName}-${userId}_callwaiting`;
  const cValue = localStorage.getItem(cKey);
  if (cValue === null || cValue === 'true' || cValue === true) return 'enabled';
  if (cValue == 'undefined') return 'one';

  return cValue.toString();
};

export const setSelectedCallWaiting = (payload) => (dispatch, getState) => {
  const appName = getState().configs.get('appName');
  const userId = getState().configs.get('userId');
  const cKey = `${appName}-${userId}_callwaiting`;
  localStorage.setItem(cKey, payload.toString());
};

/**
 *
 * @param state
 * @return {[]}
 */
export const getToneName = (state) => state.ringtones.get('toneName');

import { put, select, takeEvery } from 'redux-saga/effects';

import * as chatActions from '../chat';
import { CONTACTS } from '../contacts';

export default function* () {
  yield takeEvery(CONTACTS, _fetchSessions);
}

const _fetchSessions = function* () {
  const length = yield select((state) => state.chat.get('sessions').ids.length);
  if (!length) {
    yield put(chatActions.fetchSessions());
  } else {
    yield put(chatActions.updateSessionContacts());
  }
};
